export const GROUP_TRANSLATIONS = {
  Deal: 'Deals',
  'Deal::Part': 'Production Parts',
  Provider: 'Partners',
  Organization: 'Organizations',
  Person: 'Persons',
  Enquiry: 'Quotes',
  'Enquiry::Part': 'Quote Parts',
  'Deal::OrderConfirmation': 'Order Confirmations',
  Job: 'Jobs',
  'Deal::ProviderOrder': 'Provider Orders',
  'Deal::Claim': 'Claims',
  'Deal::Shipping': 'Shippings',
  'Deal::QualityControl': 'Quality Controls',
  'Deal::Storage': 'Storages',
  'Deal::OutcomingInvoice': 'Outcoming Invoices',
  'Deal::IncomingInvoice': 'Incoming Invoices',
  'Deal::Payment': 'Payments',
};

export const GROUP_LINKS = {
  Deal: 'deals',
  Provider: 'providers',
  Organization: 'organizations',
  Person: 'persons',
  Enquiry: 'enquiries',
  'Enquiry::Part': 'enquiry_parts',
  'Deal::OrderConfirmation': 'order_confirmations',
  Job: 'jobs',
  'Deal::Part': 'deal_parts',
  'Deal::ProviderOrder': 'provider_orders',
  'Deal::Claim': 'claims',
  'Deal::Shipping': 'shippings',
  'Deal::QualityControl': 'quality_controls',
  'Deal::Storage': 'storages',
  'Deal::OutcomingInvoice': 'outcoming_invoices',
  'Deal::IncomingInvoice': 'incoming_invoices',
  'Deal::Payment': 'payments',
};

export const shortPatternByGroup: Record<keyof typeof GROUP_LINKS, RegExp> = {
  Deal: /^(deal|d)?(-|\s)?(\d+(-|\s)?)?\d+/i,
  Provider: /^(pr)(-|\s)?\d+$/i,
  Organization: /^(o)(-|\s)?(\d+(-|\s)?)?\d+/i,
  Person: /^(p)(-|\s)?(\d+(-|\s)?)?\d+/i,
  Enquiry: /^(enq|e)(-|\s)?(\d+(-|\s)?)?\d+/i,
  'Enquiry::Part': /^(qp)(-|\s)?(\d+(-|\s)?)?\d+/i,
  'Deal::OrderConfirmation': /^(oc)(-|\s)?(\d+(-|\s)?)?\d+/i,
  Job: /^(j)(-|\s)?(\d+(-|\s)?)?\d+/i,
  'Deal::Part': /^(part|pp)(-|\s)?(\d+(-|\s)?)?\d+/i,
  'Deal::ProviderOrder': /^(po)(-|\s)?(\d+(-|\s)?)?\d+/i,
  'Deal::Claim': /^(cl|claim)(-|\s)?(\d+(-|\s)?)?\d+/i,
  'Deal::Shipping': /^(sh)(-|\s)?(\d+(-|\s)?)?\d+/i,
  'Deal::QualityControl': /^(qc)(-|\s)?(\d+(-|\s)?)?\d+/i,
  'Deal::Storage': /^(su)(-|\s)?(\d+(-|\s)?)?\d+/i,
  'Deal::OutcomingInvoice': /^(g|cn)(-|\s)?(\d+(-|\s)?)?\d+/i,
  'Deal::IncomingInvoice': /^(ii|lr|lg|xlr|xlg)(-|\s)?(\d+(-|\s)?)?\d+/i,
  'Deal::Payment': /^(pi|rf)(-|\s)?(\d+(-|\s)?)?\d+/i,
};
