import UploadedFile from 'components/UI/Upload/UploadedFile';
import { IAttachedFile } from 'interfaces';
import { map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React from 'react';

interface Props {
  files: IAttachedFile[];
  onDestroyClick?(id: number, remove?: void): void;
}

const AttachedFilesNames = observer<Props>(({ files, onDestroyClick }) => (
  <>
    {map(files, (file) => (
      <UploadedFile
        id={String(file.id)}
        key={file.id}
        name={file.name}
        createdAt={file.createdAt}
        onDelete={() => onDestroyClick && onDestroyClick(file.id || file.uid)}
      />
    ))}
  </>
));

export default AttachedFilesNames;
