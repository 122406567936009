import { DATE_PLAIN, dateFormat } from 'components/utils/formatters';
import { DATEV_ENABLED, HAS_INTACCT_ENABLED } from 'config/regions/features';
import { DEOInvoice, DEOItem, DEOSearchValues, DEType, Pagination } from 'interfaces';
import { action, computed, observable } from 'mobx';
import { Moment } from 'moment';
import { Key } from 'react';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import api, { camelizeKeys, decamelizeKeys, requestBuilder } from 'utils/axios';

export default class AccountingExportOutcomingsStore {
  rootStore: RootStore;

  @observable currentExportItem: DEOItem = {} as DEOItem;

  @observable latestExports: DEOItem[] = [];

  @observable createInProcess = false;

  @observable exportFetchInProcess = false;

  @observable exportsFetchInProcess = false;

  @observable generateReportInProcess = false;

  @observable searchInProcess = false;

  @observable invoices: DEOInvoice[] = [];

  @observable invoicePagination: Pagination = {} as Pagination;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action setLatestExports = (exports: DEOItem[]) => {
    this.latestExports = exports;
  };

  @action setCurrentExportItem = (item: DEOItem) => {
    this.currentExportItem = item;
  };

  @action setGenerateReportInProcess = (value: boolean) => {
    this.generateReportInProcess = value;
  };

  @action fetchAll = () => {
    api
      .get<{ payload: { collection: DEOItem[] } }>(routesApi.accountingExportOutcomingsPath())
      .then((response) => {
        const exports = response?.data?.payload?.collection || [];

        this.setLatestExports(camelizeKeys(exports));
      })
      .catch(() => null)
      .finally(() => null);
  };

  @action fetchOne = (id: number) => {
    this.setExportFetchInProcess(true);

    api
      .get<{ payload: DEOItem }>(routesApi.accountingExportOutcomingPath(id))
      .then((response) => {
        const currentExportItem = response?.data?.payload;

        this.setCurrentExportItem(camelizeKeys(currentExportItem));
      })
      .catch(() => null)
      .finally(() => this.setExportFetchInProcess(false));
  };

  @action setExportFetchInProcess = (value: boolean) => {
    this.exportFetchInProcess = value;
  };

  @action setCreateInProcess = (value: boolean) => {
    this.createInProcess = value;
  };

  @action setSearchInProcess = (value: boolean) => {
    this.searchInProcess = value;
  };

  @action setInvoicesPagination = (pagination: Pagination) => {
    this.invoicePagination = pagination;
  };

  @action setInvoices = (invoices: DEOInvoice[]) => {
    this.invoices = invoices;
  };

  @action resetSearch = () => {
    this.invoices = [];
    this.invoicePagination = {} as Pagination;
  };

  @action search = async (values: DEOSearchValues): Promise<string[] | null> => {
    const payload = {
      ...values,
      durationFrom: values.durationFrom?.format('YYYY-MM-DD'),
      durationTo: values.durationTo?.format('YYYY-MM-DD'),
      exportType: DATEV_ENABLED ? values.exportType : DEType.Invoices,
      withCreditNotes: true,
    };

    this.setSearchInProcess(true);

    return api
      .get<{ payload: { pagination: Pagination; collection: DEOInvoice[] } }>(
        routesApi.accountingExportOutcomingsSearchPath(),
        {
          // God bless axios and their types
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          params: decamelizeKeys(payload),
        },
      )
      .then((response) => {
        const { pagination, collection } = response.data.payload;

        this.setInvoices(camelizeKeys(collection));
        this.setInvoicesPagination(camelizeKeys(pagination));

        return this.invoicesIds;
      })
      .catch(() => null)
      .finally(() => this.setSearchInProcess(false));
  };

  @computed get invoicesIds() {
    return this.invoices.map((invoice) => String(invoice.id));
  }

  @action create = async (ids: Key[], moment: Moment, exportType: DEType): Promise<string | null> => {
    this.setCreateInProcess(true);

    return api
      .post<{ payload: { id: Key } }>(routesApi.accountingExportOutcomingsPath(), {
        ids: ids.map((id) => Number(id)),
        period: moment.format('YYYY-MM-DD'),
        export_type: exportType,
      })
      .then((response) => {
        const id = response?.data?.payload?.id;

        return routes.accountingExportOutcomingPath(String(id));
      })
      .catch(() => null)
      .finally(() => this.setCreateInProcess(false));
  };

  @action overwriteRegisteredAt = (ids: Key[], overwrite: boolean, registeredAt: Moment) => {
    this.setExportFetchInProcess(true);

    return api
      .patch<{ payload: DEOItem }>(`${routesApi.accountingExportOutcomingPath(this.currentExportItem.id)}/overwrite`, {
        ids,
        overwrite,
        registered_at: registeredAt.format('YYYY-MM-DD'),
      })
      .then((response) => {
        const currentExportItem = response?.data?.payload;
        this.setCurrentExportItem(camelizeKeys(currentExportItem));
      })
      .catch(() => null)
      .finally(() => this.setExportFetchInProcess(false));
  };

  @action generateCSV = async () => {
    this.setGenerateReportInProcess(true);

    const request = requestBuilder({ responseType: 'blob' });
    const response = await request.get(routesApi.accountingExportGenerateCSVPath(this.currentExportItem.id));

    try {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      const intacctFileName = `Intacct_export_AR_Invoices_${this.currentExportItem.id}_${dateFormat(
        this.currentExportItem.created,
        DATE_PLAIN,
      )} - Outcoming Invoices.csv`;

      const datevFileName = `DTVF_Buchungsstapel_${dateFormat(this.currentExportItem.created, DATE_PLAIN)}.csv`;
      const fileName = HAS_INTACCT_ENABLED ? intacctFileName : datevFileName;

      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();
    } catch (e) {
      this.setGenerateReportInProcess(false);
    } finally {
      this.setGenerateReportInProcess(false);
    }
  };
}
