import { ErrorBoundary, useMonitoringClient } from '@xometry/ui';
import { ErrorPage } from 'pages';
import React, { FC, ReactNode, useCallback } from 'react';

interface Props {
  children: ReactNode;
}

export const ErrorBoundaryWrapper: FC<Props> = (props) => {
  const { children } = props;
  const client = useMonitoringClient();

  const handleError = useCallback(
    (error: Error) => {
      client.captureError(error);
    },
    [client],
  );

  return (
    <ErrorBoundary fallback={<ErrorPage />} onDidCatch={handleError}>
      {children}
    </ErrorBoundary>
  );
};
