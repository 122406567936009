import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { AttachmentFragmentFragmentDoc } from '../../fragments/__generated__/attachmentsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ErpMessengerAttachmentsUploadMutationVariables = Types.Exact<{
  attachments: Array<Types.OmsInputsMessengerAttachmentType> | Types.OmsInputsMessengerAttachmentType;
}>;


export type ErpMessengerAttachmentsUploadMutation = { messengerAttachmentsUpload?: { attachments: Array<{ id: string, uuidId: string, name: string, previewUrl?: string | null, previewLargeUrl?: string | null }> } | null };


export const ErpMessengerAttachmentsUploadDocument = gql`
    mutation erpMessengerAttachmentsUpload($attachments: [OmsInputsMessengerAttachmentType!]!) {
  messengerAttachmentsUpload(input: {attachments: $attachments}) {
    attachments {
      ...attachmentFragment
    }
  }
}
    ${AttachmentFragmentFragmentDoc}`;
export type ErpMessengerAttachmentsUploadMutationFn = Apollo.MutationFunction<ErpMessengerAttachmentsUploadMutation, ErpMessengerAttachmentsUploadMutationVariables>;

/**
 * __useErpMessengerAttachmentsUploadMutation__
 *
 * To run a mutation, you first call `useErpMessengerAttachmentsUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useErpMessengerAttachmentsUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [erpMessengerAttachmentsUploadMutation, { data, loading, error }] = useErpMessengerAttachmentsUploadMutation({
 *   variables: {
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useErpMessengerAttachmentsUploadMutation(baseOptions?: Apollo.MutationHookOptions<ErpMessengerAttachmentsUploadMutation, ErpMessengerAttachmentsUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ErpMessengerAttachmentsUploadMutation, ErpMessengerAttachmentsUploadMutationVariables>(ErpMessengerAttachmentsUploadDocument, options);
      }
export type ErpMessengerAttachmentsUploadMutationHookResult = ReturnType<typeof useErpMessengerAttachmentsUploadMutation>;
export type ErpMessengerAttachmentsUploadMutationResult = Apollo.MutationResult<ErpMessengerAttachmentsUploadMutation>;
export type ErpMessengerAttachmentsUploadMutationOptions = Apollo.BaseMutationOptions<ErpMessengerAttachmentsUploadMutation, ErpMessengerAttachmentsUploadMutationVariables>;