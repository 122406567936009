export const getSubstringsBySearch = (value: string, search: string) => {
  let beforeSubstring = '';
  let searchSubstring = '';
  let afterSubstring = '';

  if (search) {
    const index = value.toLocaleLowerCase().indexOf(search.toLocaleLowerCase());

    // If the substring is not found, return the whole string as a single element array
    if (index > -1) {
      beforeSubstring = value.substring(0, index);
      searchSubstring = value.substring(index, index + search.length);
      afterSubstring = value.substring(index + search.length);
    }
  }

  return {
    beforeSubstring,
    searchSubstring,
    afterSubstring,
  };
};
