import { Modal } from 'antd';
import { Notification } from 'components/UI';
import { CreateCaFormValues } from 'interfaces';
import { get, noop } from 'lodash-es';
import { action, observable } from 'mobx';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import omsApi from 'utils/axios';

class PersonStore {
  rootStore: RootStore;

  refetch = noop;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable isEditing = false;

  @action setIsEditing = (isEditing: boolean) => {
    this.isEditing = isEditing;
  };

  @action createContact = async (id: string, values: CreateCaFormValues, onSuccess: () => void, onFinish: () => void) =>
    omsApi
      .post(routesApi.personCreateContactPath(id), {
        password: values.password,
        send_email: values.sendEmail,
        verify: values.verify,
      })
      .then(() => {
        Notification.Success('Online CA has been created');
        this.refetch();
        this.rootStore.activityRecordStore.refetch();
        onSuccess();
      })
      .catch((error) => {
        if (get(error, 'response.data.errors.source.contact')) {
          Modal.error({
            title: 'Validation error',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            content: get(error, 'response.data.errors.source.contact'),
          });
        } else {
          Notification.Error('Failed to create CA');
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return error;
      })
      .finally(() => onFinish());
}

export default PersonStore;
