import { PlusOutlined } from '@ant-design/icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { message } from 'antd';
import { UploadProps } from 'antd/lib/upload/interface';
import { ActionsBlock, ActionsBlockTitle } from 'components/shared/Sidebar/Right/styled';
import Spin from 'components/UI/Spin';
import { Upload, UploadButton } from 'components/UI/Upload/styled';
import { filter } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from 'stores/RootStore';

import AttachedFilesNames from './AttachedFilesNames';

const MAX_UPLOAD_SIZE_IN_MB = 75;

export const defaultBeforeUpload = (file: { type: string; size: number }): boolean => {
  const isLt75M = file.size / 1024 / 1024 < MAX_UPLOAD_SIZE_IN_MB;

  if (!isLt75M) {
    void message.error(`Image must smaller than ${MAX_UPLOAD_SIZE_IN_MB}MB!`);
  }

  return isLt75M;
};

interface Props {
  beforeUpload?(file: { type: string; size: number }): boolean;
  fetchUrl: string;
  uploadUrl?: string;
  destroyUrl?: string;
  layout?: 'default' | 'inline';
  context?: string;
}

const AttachedFilesUploader = observer<Props>(
  ({ beforeUpload = defaultBeforeUpload, fetchUrl, uploadUrl, destroyUrl, layout, context }) => {
    const { attachedFilesStore } = useStore();

    useEffect(() => {
      void attachedFilesStore.fetch(fetchUrl);
    }, [fetchUrl, attachedFilesStore]);

    if (!attachedFilesStore.fetchStatus.isFetched) {
      return <Spin size="large" />;
    }

    const customRequest: UploadProps['customRequest'] = (params) => {
      if (uploadUrl == null) return;

      const formData = new FormData();
      formData.append('file', params.file);

      void attachedFilesStore.upload(uploadUrl, formData);
    };

    const onDestroyClick = (id: number) => {
      if (destroyUrl) {
        void attachedFilesStore.delete(`${destroyUrl}?attached_file_id=${id}`, id);
      }
    };

    const isUploading = attachedFilesStore.uploadStatus.isProcessing;

    const fileList = context ? filter(attachedFilesStore.attachments, { context }) : attachedFilesStore.attachments;
    const sortedFiles = fileList.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

    return (
      <ActionsBlock className={`-${layout}`}>
        {layout !== 'inline' && <ActionsBlockTitle>Attached Files:</ActionsBlockTitle>}

        {uploadUrl != null && (
          <Upload
            disabled={isUploading}
            customRequest={customRequest}
            listType="picture-card"
            showUploadList={false}
            beforeUpload={beforeUpload}
            multiple
          >
            <div>
              {isUploading ? <FontAwesomeIcon icon={faSpinner} spin /> : <PlusOutlined />}
              <UploadButton type="link" disabled={isUploading}>
                Upload
              </UploadButton>
            </div>
          </Upload>
        )}

        <AttachedFilesNames files={sortedFiles} onDestroyClick={onDestroyClick} />
      </ActionsBlock>
    );
  },
);

export default AttachedFilesUploader;
