import { action, observable } from 'mobx';
import { RootStore } from 'stores/RootStore';

class BankPaymentStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable recognitionModalVisibleFor: undefined | string = undefined;

  @observable linkModalVisibleFor: undefined | string = undefined;

  @action toggleRecognitionModalFor = (id: string) => {
    this.recognitionModalVisibleFor = this.recognitionModalVisibleFor === undefined ? id : undefined;
  };

  @action toggleLinkModal = (id: string) => {
    this.linkModalVisibleFor = this.linkModalVisibleFor === undefined ? id : undefined;
  };
}

export default BankPaymentStore;
