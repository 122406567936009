import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { WorkspacePresetFragmentDoc } from '../../fragments/__generated__/workspacePreset';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkspacePresetsUpdateMutationVariables = Types.Exact<{
  input: Types.WorkspacePresetsUpdateInput;
}>;


export type WorkspacePresetsUpdateMutation = { workspacePresetsUpdate?: { workspacePreset: { id: number, name: string, workspaceName: string, public: boolean, query?: string | null, columns?: Array<string> | null, exportColumns?: Array<string> | null, createdAt: string, updatedAt: string, sortColumn?: string | null, sortDirection?: string | null, owner: { id: number, name: string }, lastUpdatedBy?: { id: number, name: string } | null } } | null };


export const WorkspacePresetsUpdateDocument = gql`
    mutation workspacePresetsUpdate($input: WorkspacePresetsUpdateInput!) {
  workspacePresetsUpdate(input: $input) {
    workspacePreset {
      ...workspacePreset
    }
  }
}
    ${WorkspacePresetFragmentDoc}`;
export type WorkspacePresetsUpdateMutationFn = Apollo.MutationFunction<WorkspacePresetsUpdateMutation, WorkspacePresetsUpdateMutationVariables>;

/**
 * __useWorkspacePresetsUpdateMutation__
 *
 * To run a mutation, you first call `useWorkspacePresetsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePresetsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspacePresetsUpdateMutation, { data, loading, error }] = useWorkspacePresetsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWorkspacePresetsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<WorkspacePresetsUpdateMutation, WorkspacePresetsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkspacePresetsUpdateMutation, WorkspacePresetsUpdateMutationVariables>(WorkspacePresetsUpdateDocument, options);
      }
export type WorkspacePresetsUpdateMutationHookResult = ReturnType<typeof useWorkspacePresetsUpdateMutation>;
export type WorkspacePresetsUpdateMutationResult = Apollo.MutationResult<WorkspacePresetsUpdateMutation>;
export type WorkspacePresetsUpdateMutationOptions = Apollo.BaseMutationOptions<WorkspacePresetsUpdateMutation, WorkspacePresetsUpdateMutationVariables>;