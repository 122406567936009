import { Role } from 'collections/roles';
import { useMemo } from 'react';
import { useStore } from 'stores/RootStore';
import { GetUsersQuery, useGetUsersQuery } from 'utils/graphql/queries/__generated__/users';

interface IUserOption {
  label: string;
  value: number;
}

interface UseGetUsersOptions {
  filter?: (user?: NonNullable<GetUsersQuery['users']>[number]) => boolean;
  roleName?: string | string[];
}

export const useGetUsersOptions = ({ filter, roleName }: UseGetUsersOptions) => {
  const { data, loading } = useGetUsersQuery({ variables: { roleName: roleName } });
  const users = data?.users || [];

  const filtered = filter ? users.filter(filter) : users;

  const options: IUserOption[] =
    filtered?.map((user) => ({
      label: user.name,
      value: user.id,
    })) || [];

  return { users: filtered, options, loadingUsers: loading };
};

export interface PmOption {
  label: string;
  value: number;
  podId: number | null | undefined;
  disabled?: boolean;
}

interface Params {
  /** Add initially selected PM to the options and disable it
   * if it was selected before but didn't have a podId */
  initialPm?: {
    id: number | null | undefined;
    name: string | null | undefined;
  } | null;
}

export const useGetPmUserOptions = ({ initialPm }: Params = {}) => {
  const { users, loadingUsers } = useGetUsersOptions({
    filter: (user) => !!user?.active && user.podId !== null && user.podId !== undefined,
    roleName: [Role.ProductionManager, Role.LeadProductionManager],
  });

  const pmOptions: PmOption[] = useMemo(() => {
    const options = users.map((user) => ({
      label: user.name,
      value: user.id,
      podId: user.podId,
    }));

    const initialPmInOptions = Boolean(initialPm?.id && options.find((o) => o.value === initialPm.id));

    if (!initialPmInOptions && initialPm?.id) {
      return [
        {
          label: initialPm?.name || '',
          value: initialPm?.id,
          podId: null,
          disabled: true,
        },
        ...options,
      ];
    }

    return options;
  }, [initialPm?.id, initialPm?.name, users]);

  return { options: pmOptions, loadingUsers };
};

/** Check if the current user is in the options list
 * and the option is not disabled */
export const useCanTakeIt = (options: PmOption[]) => {
  const { userStore } = useStore();

  return useMemo(() => {
    return (
      userStore.id &&
      options.findIndex((option) => {
        return !option.disabled && option.value === Number(userStore.id);
      }) > -1
    );
  }, [options, userStore.id]);
};
