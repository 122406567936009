import { CurrencyEnum } from '__generated__/types';
import { RegionData, RegionLocales, Regions } from 'config/regions/types';
import { ENV_APP_HOST_UK, ENV_CUSTOMERS_APP_HOST_UK, ENV_PARTNERS_APP_HOST_UK } from 'constants/env';

import { ReactComponent as Icon } from './assets/uk.svg';

export const UKRegionConfig: RegionData<Regions.UK> = {
  region: Regions.UK,
  orgName: 'Xometry UK',
  name: 'UK',
  shortName: 'UK',
  locale: RegionLocales.EN,
  currency: 'GBP' as CurrencyEnum,
  icon: Icon,
  host: ENV_APP_HOST_UK,
  customersHost: ENV_CUSTOMERS_APP_HOST_UK,
  partnersHost: ENV_PARTNERS_APP_HOST_UK,
  headerColor: '#19469E',
};
