import { Dimensions, HoopsViewer, HoopsViewerLoader } from '@xometry/ant-ui';
import Spin from 'antd/es/spin';
import React from 'react';

import s from './ScsViewer.module.less';

interface IProps {
  src: string;
  name: string;
  dimensions?: Dimensions;
}

const ScsViewer: React.FC<IProps> = ({ src, name, dimensions }) => {
  return (
    <HoopsViewerLoader loader={<Spin />}>
      <HoopsViewer
        className={s.wrapper}
        containerClassName={s.container}
        url={src}
        dimensions={dimensions}
        originalFilename={name}
      />
    </HoopsViewerLoader>
  );
};

export default ScsViewer;
