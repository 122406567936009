import { useGetUsersQuery } from 'utils/graphql/queries/__generated__/users';

import { generateColumns } from './columns';

export const useColumns = () => {
  const { data: usersData, loading: usersLoading } = useGetUsersQuery();

  const columns = generateColumns({ users: usersData?.users });

  return {
    loading: usersLoading,
    columns,
  };
};
