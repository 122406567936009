import { DealFile, DealPdFile } from 'interfaces';
import { action, observable } from 'mobx';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import api, { camelizeKeys } from 'utils/axios';

class FilesStore {
  rootStore: RootStore;

  @observable isFetching = false;
  @observable isPdFetching = false;
  @observable isFilesUpload = false;
  @observable isFilesDestroy = false;
  @observable isPfFilesUpload = false;
  @observable onlyUnlinked = true;
  @observable pipedriveFiles: DealPdFile[] = [];
  @observable files: DealFile[] = [];
  @observable filteredFiles: DealFile[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action setIsFetching = (value: boolean) => {
    this.isFetching = value;
  };

  @action setIsPdfFetching = (value: boolean) => {
    this.isPdFetching = value;
  };

  @action setIsFilesUpload = (value: boolean) => {
    this.isFilesUpload = value;
  };

  @action setIsFilesDestroy = (value: boolean) => {
    this.isFilesDestroy = value;
  };

  @action setIsPdFilesUpload = (value: boolean) => {
    this.isPfFilesUpload = value;
  };

  @action toggleUnlinked = () => {
    this.onlyUnlinked = !this.onlyUnlinked;
    this.filterFiles();
  };

  @action fetchFiles = async (dealId: number, withEnquiryFiles = false) => {
    this.setIsFetching(true);

    try {
      const response = await api.get(
        `${routesApi.dealFetchFiles(String(dealId))}?with_enquiry_files=${String(withEnquiryFiles)}`,
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { payload } = response.data;

      this.assignFiles(camelizeKeys(payload));

      this.setIsFetching(false);
    } catch (e) {
      this.setIsFetching(false);
    }
  };

  @action fetchPipedriveFiles = async (dealId: number) => {
    this.setIsPdfFetching(true);

    try {
      const response = await api.get(routesApi.dealFetchPipedriveFiles(String(dealId)));

      // TODO: http handler
      if (response.status !== 200) throw new Error();

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { payload } = response.data;

      this.assignPdFiles(camelizeKeys(payload));

      this.setIsPdfFetching(false);
    } catch (e) {
      this.setIsPdfFetching(false);
    }
  };

  @action uploadUserFiles = async (dealId: number, files: File[]) => {
    this.setIsFilesUpload(true);

    const fd = new FormData();
    files.forEach((file: File) => {
      fd.append('files[][file]', file);
    });

    try {
      const response = await api.post(routesApi.dealUploadUserFiles(String(dealId)), fd, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status !== 200) throw new Error();

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { payload } = response.data;

      this.assignFiles(camelizeKeys(payload));

      this.setIsFilesUpload(false);
    } catch {
      this.setIsFilesUpload(false);
    }
  };

  @action uploadPdFiles = async (dealId: number, fileIds: number[]) => {
    this.setIsPdFilesUpload(true);

    try {
      const response = await api.post(routesApi.dealUploadPdFiles(String(dealId)), { pd_files_ids: fileIds });

      if (response.status !== 200) throw new Error();

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { payload } = response.data;

      this.assignFiles(camelizeKeys(payload));

      this.setIsPdFilesUpload(false);
    } catch {
      this.setIsPdFilesUpload(false);
    }
  };

  @action destroyFiles = async (dealId: number, fileIds: number[]) => {
    this.setIsFilesDestroy(true);

    try {
      const response = await api.post(routesApi.dealDestroyFiles(String(dealId)), { files_ids: fileIds });

      if (response.status !== 200) throw new Error();

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { payload } = response.data;

      this.assignFiles(camelizeKeys(payload));

      this.setIsFilesDestroy(false);
    } catch (e) {
      this.setIsFilesDestroy(false);
    }
  };

  @action assignFiles = (files: DealFile[]) => {
    this.files = files;
    this.filterFiles();
  };

  @action filterFiles = () => {
    this.filteredFiles = this.onlyUnlinked ? this.files.filter((file: DealFile) => !file.isLinked) : this.files;
  };

  @action assignPdFiles = (files: DealPdFile[]) => {
    this.pipedriveFiles = files;
  };
}

export default FilesStore;
