import styled from '@emotion/styled';
import { Upload as AntUpload } from 'antd';
import { Button } from 'components/UI/Button/styled';
import { colors } from 'components/UI/colors';
import { Modal as UIModal } from 'components/UI/Modal/styled';

export const Span = styled('span')`
  color: ${colors.blue};
`;

export const EllipsisedText = styled.span`
  text-overflow: ellipsis;
  max-width: 145px;
  overflow: hidden;
  white-space: nowrap;
`;

export const Modal = styled(UIModal)`
  .ant-modal-content {
    height: calc(100vh - 80px);
  }

  iframe {
    top: 55px !important;
  }
`;

export const FileWrap = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 3px;
  padding: 0 5px;
  background: ${colors.gray3};
  width: 100%;

  .ant-image {
    display: none;
  }

  .name {
    display: inline-flex;
  }

  .button {
    min-width: 0;
    display: flex;
    align-items: center;
  }
`;

/**
 * Note(pkocherov-xometry): https://4x.ant.design/components/upload/
 * Antd fixed their bug:
 *
 * Please note that there does exist a bug which makes an onChange
 * event still trigger even when the file is not in the list before 4.13.0.
 */
export const Upload = styled(AntUpload)`
  display: flex;
  justify-content: center;

  svg {
    color: ${colors.gray7};
    font-size: 30px;
    margin-top: 7px;
    margin-bottom: 5px;
  }

  .ant-upload {
    height: 90px;
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 11px;
  }
`;

export const UploadButton = styled(Button)`
  &.ant-btn-link {
    span {
      text-decoration: none;
    }
  }
`;
