import { AmplitudeLogger } from '@xometry/ui';
import { Amplitude } from 'interfaces/amplitude';
import UserStore from 'stores/UserStore';
import { getDeviceType } from 'utils/hooks/useDeviceType';

import { CURRENT_REGION } from './config/regions/config';

const winInterface = window as {
  amplitude?: Amplitude;
};

const APP_NAME = 'erp';

export const amplitudeSetContact = (user: Pick<UserStore, 'id' | 'name' | 'email'>) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  try {
    if (typeof winInterface.amplitude === 'object' && user.id && !Number.isNaN(Number(user.id))) {
      winInterface.amplitude.getInstance().setUserId(Number(user.id));

      const identify = new winInterface.amplitude.Identify()
        .set('UserEmail', user.email || '')
        .set('UserId', user.id || '')
        .set('UserName', user.name || '')
        .set('UserRegion', CURRENT_REGION)
        .set('AppName', APP_NAME)
        .set('DeviceType', getDeviceType());
      winInterface.amplitude.getInstance().identify(identify);
    } else {
      console.error('Invalid user.id for Amplitude', user.id);
    }
  } catch (e: unknown) {
    console.error("Can't set user for Amplitude", e);
  }
};

export const amplitudeLogger: AmplitudeLogger = ({ event, attrs = {} }): void => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  try {
    if (!winInterface.amplitude || typeof winInterface.amplitude !== 'object' || !winInterface.amplitude.getInstance) {
      console.error("Can't find amplitude", event, attrs);

      return;
    }

    const pageLink = window.location.href;
    winInterface.amplitude.getInstance().logEvent(event, { ...attrs, pageLink, domain: CURRENT_REGION });
  } catch (error) {
    console.error('Error logging event to Amplitude:', error);
  }
};
