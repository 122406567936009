import { Col, Progress, Row } from 'antd';
import { NA, percent, valueOrNa } from 'components/utils/formatters';
import React from 'react';
import { routes } from 'routes';

interface IProps {
  total?: number;
  timing?: number;
  overdueRatio?: number;
  quality?: number;
  packing?: number;
  communication?: number;
  cancelRatio?: number;
  qualityRatio?: number;
  providerId: number;
}

const Rating: React.FC<IProps> = ({
  total,
  timing,
  overdueRatio,
  quality,
  packing,
  communication,
  cancelRatio,
  qualityRatio,
  providerId,
}) => (
  <a className="rating" href={`${routes.providerPath(providerId)}/rating_by_parts`} target="_blank" rel="noreferrer">
    <Row wrap={false}>
      <Col className="rating-name">
        <Row>Timing</Row>
        <Row>Quality</Row>
        <Row>Packing</Row>
        <Row>Commun</Row>
      </Col>
      <Col className="rating-progress">
        <div className="rating-title">
          Cancel Ratio:&nbsp;
          {cancelRatio !== null ? percent((cancelRatio || 0) * 100) : 'N/A'}
        </div>
        <Row>
          <Progress
            percent={100 / (5 / (timing || 0))}
            showInfo={false}
            strokeColor="rgba(23, 113, 241, 0.75)"
            trailColor="#E8E8E8"
            size="small"
            style={{ height: '20px' }}
          />
        </Row>
        <Row>
          <Progress
            percent={100 / (5 / (quality || 0))}
            showInfo={false}
            strokeColor="rgba(23, 113, 241, 0.58)"
            trailColor="#E8E8E8"
            size="small"
            style={{ height: '20px' }}
          />
        </Row>
        <Row>
          <Progress
            percent={100 / (5 / (packing || 0))}
            showInfo={false}
            strokeColor="rgba(23, 113, 241, 0.22)"
            trailColor="#E8E8E8"
            size="small"
            style={{ height: '20px' }}
          />
        </Row>
        <Row>
          <Progress
            percent={100 / (5 / (communication || 0))}
            showInfo={false}
            strokeColor="rgba(23, 113, 241, 0.98)"
            trailColor="#E8E8E8"
            size="small"
            style={{ height: '20px' }}
          />
        </Row>
      </Col>
      <Col className="rating-total">
        <Row>
          {valueOrNa(timing)}
          &nbsp;(
          {overdueRatio !== null ? percent((overdueRatio || 0) * 100) : NA})
        </Row>
        <Row>
          {valueOrNa(quality)}
          &nbsp;(
          {qualityRatio !== null ? percent((qualityRatio || 0) * 100) : NA})
        </Row>
        <Row>{valueOrNa(packing)}</Row>
        <Row>{valueOrNa(communication)}</Row>
      </Col>
      <Col className="rating-circle">
        <Progress
          type="circle"
          percent={100 / (5 / (total || 0))}
          format={() => total || <span className="rating-circle-not-available">NA</span>}
          width={75}
          strokeWidth={6}
          strokeColor="#1871F1"
          trailColor="#E8E8E8"
        />
      </Col>
    </Row>
  </a>
);

export default Rating;
