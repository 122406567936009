import { Merge } from '@xometry/ui';
import { DefaultOptionType, SelectProps } from 'antd/lib/select';
import { ReactComponent as UserIcon } from 'assets/images/icons/user.svg';
import { debounce } from 'lodash-es';
import React, { useMemo, useRef, useState } from 'react';
import { useStore } from 'stores/RootStore';
import { DeviceTypes, useDeviceType } from 'utils/hooks/useDeviceType';

import {
  OrganizationPersonsEmailsQuery,
  useOrganizationPersonsEmailsLazyQuery,
} from './graphql/__generated__/organizationPersonsEmails';
import s from './TemplatesModalForm.module.less';
import { getSubstringsBySearch } from './utils';

const DEBOUNCE_TIMEOUT = 800;

interface Params {
  organizationId?: string | null;
}

type EmailType = 'personal' | 'cc' | 'invoice' | 'logistics' | 'oc' | 'paymentReminder' | 'technical';

const LABELS_MAP: Record<EmailType, string> = {
  personal: 'Personal',
  cc: 'CC',
  invoice: 'Invoices',
  logistics: 'Logistics',
  oc: 'OC',
  paymentReminder: 'Payment reminder',
  technical: 'Technical',
};

const renderEmailOption = (email: string, type: EmailType, search: string) => {
  let emailElement = <span>{email}</span>;

  const { beforeSubstring, searchSubstring, afterSubstring } = getSubstringsBySearch(email, search);

  const spans: React.ReactNode[] = [];

  if (beforeSubstring) {
    spans.push(<span>{beforeSubstring}</span>);
  }

  if (searchSubstring) {
    spans.push(<b>{searchSubstring}</b>);
  }

  if (afterSubstring) {
    spans.push(<span>{afterSubstring}</span>);
  }

  if (spans.length) {
    emailElement = <span>{spans}</span>;
  }

  return (
    <div className={s.emailOption}>
      {emailElement}
      <span className={s.emailType}>{LABELS_MAP[type]}</span>
    </div>
  );
};

const getEmailsOptions = (
  data: OrganizationPersonsEmailsQuery,
  searchValue: string,
): NonNullable<SelectProps['options']> => {
  const {
    organizationPersons: { persons },
  } = data;

  const result: NonNullable<SelectProps['options']> = [];

  persons.forEach(
    (
      {
        name,
        email,
        carbonCopyEmails,
        invoiceEmails,
        logisticEmails,
        ocEmails,
        paymentReminderEmails,
        technicalEmails,
      },
      index,
    ) => {
      const { beforeSubstring, searchSubstring, afterSubstring } = getSubstringsBySearch(name, searchValue);

      const nameElement = searchSubstring ? (
        <>
          {beforeSubstring ? <span>{beforeSubstring}</span> : null}
          {searchSubstring ? <b>{searchSubstring}</b> : null}
          {afterSubstring ? <span>{afterSubstring}</span> : null}
        </>
      ) : (
        <span>{name}</span>
      );

      const label = (
        <span className={s.personEmailOptionLabel}>
          <UserIcon />
          {nameElement}
        </span>
      );

      const options: NonNullable<SelectProps['options']> = [];

      const emailTypesMap: Record<EmailType, string[]> = {
        personal: [email],
        cc: carbonCopyEmails,
        invoice: invoiceEmails,
        logistics: logisticEmails,
        oc: ocEmails,
        paymentReminder: paymentReminderEmails,
        technical: technicalEmails,
      };

      Object.entries(emailTypesMap).forEach(([type, emails]) => {
        emails.forEach((email, index) => {
          options.push({
            value: email,
            label: renderEmailOption(email, type as EmailType, searchValue),
            key: email + type + searchValue + index,
          });
        });
      });

      result.push({ label, options, key: name + index });
    },
  );

  return result;
};

export const useSuggestEmails = ({
  organizationId,
}: Params): Merge<
  Pick<
    SelectProps,
    'options' | 'onSearch' | 'popupClassName' | 'onDropdownVisibleChange' | 'filterOption' | 'getPopupContainer'
  >,
  {
    showDropdown?: boolean;
  }
> => {
  const { templatesModalStore } = useStore();
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  const { messageVisibilityAttributes } = templatesModalStore;

  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  const [fetchSuggestedEmails, { loading, data }] = useOrganizationPersonsEmailsLazyQuery();

  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    if (!messageVisibilityAttributes?.isVisibleToClient || !organizationId) {
      return undefined;
    }

    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      void fetchSuggestedEmails({ variables: { id: organizationId, searchByRelatedEmail: value } }).then(({ data }) => {
        if (!data) return;

        // for fetch callback order
        if (fetchId !== fetchRef.current) return;

        const newOptions = getEmailsOptions(data, value);

        setOptions(newOptions);
      });
    };

    return debounce(loadOptions, DEBOUNCE_TIMEOUT);
  }, [fetchSuggestedEmails, messageVisibilityAttributes?.isVisibleToClient, organizationId]);

  const handleDropdownVisibleChange = (open: boolean) => {
    if (open) {
      debounceFetcher?.('');
    }
  };

  return {
    options,
    onSearch: debounceFetcher,
    popupClassName: isMobile ? undefined : s.selectDropdown,
    onDropdownVisibleChange: handleDropdownVisibleChange,
    showDropdown: Boolean(data?.organizationPersons.persons.length || loading),
    filterOption: false, // Disable automatic filtering
    getPopupContainer: (trigger: HTMLElement) => trigger.parentElement as HTMLElement,
  };
};
