import styled from '@emotion/styled';

import { bc } from '../colors';

interface NoticeProps {
  secondary?: boolean;
  italic?: boolean;
}

export const Notice = styled.div<NoticeProps>`
  font-size: 13px;
  word-break: break-all;
  font-style: ${(props) => (props.italic ? 'italic' : 'inherit')};
  color: ${(props) => (props.secondary ? bc.noticeSecondary : bc.notice)};
`;
