import { EditMasterShippingPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

export const MasterShippingsController = () => {
  return (
    <>
      <Route
        path={routes.editMasterShippingPath(':shippingId', ':urlAction?')}
        component={EditMasterShippingPage}
        exact
      />
    </>
  );
};
