import { getRegionPartnersHost } from '../config/regions/utils';

const partnersEndpoint = getRegionPartnersHost();

const verifyUrl = (verifyKey: string, email: string) =>
  `${partnersEndpoint}/profile/verify/${verifyKey}?email=${email}`;

export default {
  verifyUrl,
};
