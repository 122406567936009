import { observer } from 'mobx-react-lite';
import React from 'react';

import NoPreview from './NoPreview';
import { ImgPreviewBig as ImgPreviewBigStyle } from './styled';

interface Props {
  ext?: string;
  src?: string;
}

const ImgPreviewBig = observer<Props>(({ src, ext }) =>
  src ? <ImgPreviewBigStyle src={src} /> : <NoPreview ext={ext} />,
);

export default ImgPreviewBig;
