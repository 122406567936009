import styled from '@emotion/styled';
import { ALink, Link } from 'components/UI/Link/styled';

export const ActionsBlock = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 15px;

  &.-inline {
    padding: 0;

    .ant-upload-select {
      width: 100%;
      height: 46px;
    }

    .ant-upload {
      .anticon {
        padding-top: 5px;
      }
      .ant-btn {
        position: relative;
        top: -13px;
      }
    }
  }

  .ant-row.ant-row-space-between.ant-row-middle {
    padding: 0;
  }

  .anticon {
    font-size: 12px;
  }

  .ellipsis-text {
    font-size: 12px;
    max-width: 75px;
  }
`;

export const ActionsBlockTitle = styled.div`
  width: 100%;

  margin-bottom: 10px;

  font-weight: 600;
  line-height: 22px;
  text-align: center;
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Action = styled(Link)`
  width: 100%;

  font-size: 12px;
  line-height: 16px;

  :not(:last-of-type) {
    margin-bottom: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const ActionALink = styled(ALink)<{ disabled?: boolean }>`
  width: 100%;

  font-size: 12px;
  line-height: 16px;

  :not(:last-of-type) {
    margin-bottom: 10px;
  }

  text-decoration: none;
`;

export const SamplesText = styled.span`
  margin-left: 5px;
`;
