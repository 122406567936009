import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type TemplateGroupFragment = { id: string, constantName?: string | null, name?: string | null, type: Types.TemplateTypeEnum, direction: Types.TemplateDirectionEnum, readOnly: boolean, shared: boolean, createdBy?: string | null, createdAt?: string | null, updatedBy?: string | null, updatedAt?: string | null, blindCarbonCopy?: Array<string> | null, carbonCopy?: Array<string> | null, recipients?: Array<string> | null, category?: { id: string, title: string } | null, templates: Array<{ id: string, locale: Types.TemplateLocaleEnum, content: string, subject?: string | null }> };

export const TemplateGroupFragmentDoc = gql`
    fragment templateGroup on ManagerTemplateGroupType {
  id
  constantName
  name
  type
  direction
  readOnly
  shared
  category {
    id
    title
  }
  templates {
    id
    locale
    content
    subject
  }
  createdBy
  createdAt
  updatedBy
  updatedAt
  blindCarbonCopy
  carbonCopy
  recipients
}
    `;