import { gql } from 'apollo-boost';

import { PERSON_FRAGMENT } from '../fragments';

export const PERSONS_UPDATE = gql`
  mutation personsUpdate($id: ID!, $attributes: OmsInputsPersonType!) {
    personsUpdate(input: { id: $id, attributes: $attributes }) {
      person {
        ...personFragment
      }
    }
  }
  ${PERSON_FRAGMENT}
`;

export const PERSONS_UPDATE_DEFAULT_ADDRESS = gql`
  mutation personsUpdateDefaultAddress($id: ID!, $shippingAddressId: ID!) {
    personsUpdateDefaultAddress(input: { id: $id, shippingAddressId: $shippingAddressId }) {
      person {
        ...personFragment
      }
    }
  }
  ${PERSON_FRAGMENT}
`;

export const PERSONS_IMPERSONATE = gql`
  mutation personsImpersonate($id: ID!) {
    personsImpersonate(input: { id: $id }) {
      accessToken
    }
  }
`;
