import { Modal as AntdModal, ModalProps } from 'antd';
import React, { FC, useState } from 'react';
import Draggable from 'react-draggable';

export const Modal: FC<ModalProps & { isMobile?: boolean }> = ({ title, isMobile, ...props }) => {
  const [draggable, setDraggable] = useState(false);

  return (
    <AntdModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title={
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div onMouseOver={() => setDraggable(true)} onMouseOut={() => setDraggable(false)}>
          {title}
        </div>
      }
      modalRender={
        isMobile
          ? (modal) => <div>{modal}</div>
          : (modal) => (
              <Draggable disabled={!draggable}>
                <div>{modal}</div>
              </Draggable>
            )
      }
    />
  );
};
