import Notification from 'components/UI/Notification';
import omsApi from 'utils/axios';

export default () => (url: string) =>
  omsApi
    .get(url)
    .then((response) => {
      window.open(response.data.payload.redirect, '_blank');
    })
    .catch(() => {
      Notification.Error('Could not redirect');
    });
