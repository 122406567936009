import { PartnersPage } from 'pages';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

export const RfqWorkspacesController = () => (
  <Switch>
    <Route path={routes.workspacesRfqPartnersPath()} component={PartnersPage} exact />
  </Switch>
);
