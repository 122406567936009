import { RETURN_INVOICE_REQUEST } from 'collections/invoiceConstants';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';
import { IncomingInvoiceState } from 'interfaces';

interface InvoiceShortNameParams {
  id: string | number;
  number?: number | string | null;
  selfBilling?: boolean | null;
  creditNote?: boolean | null;
  state?: string | null;
}

export const getInvoiceShortName = (invoice?: InvoiceShortNameParams) => {
  if (!invoice) return '';

  const { id, number, creditNote, selfBilling, state } = invoice;

  let name = creditNote ? `LG` : `LR`;

  if (selfBilling) {
    switch (state) {
      case IncomingInvoiceState.Published:
        name = `X${name}-${number || ''}`;
        break;

      case IncomingInvoiceState.Draft:
      case IncomingInvoiceState.Canceled:
        name = `PCN #${id}`;
        break;
    }
  } else {
    name = `${name}-${id}`;
  }

  return name;
};

interface InvoiceFullNameParams {
  isNew: boolean;
  isCreditNote: boolean;
  selfBilling: boolean;
  state?: IncomingInvoiceState;
  shortName: string;
}

const CREDIT_NOTE_NAME: Record<Regions, string> = {
  [Regions.EU]: 'Credit Note',
  [Regions.UK]: 'Credit Note',
  [Regions.TR]: RETURN_INVOICE_REQUEST,
};

export const getInvoiceFullName = ({ isNew, selfBilling, shortName, isCreditNote }: InvoiceFullNameParams) => {
  const creditNoteType = (isCreditNote: boolean) => (isCreditNote ? CREDIT_NOTE_NAME[CURRENT_REGION] : 'Invoice');
  const selfBillingCreditNoteType = (isCreditNote: boolean) => (isCreditNote ? 'Correction' : '');

  if (isNew) {
    if (selfBilling) {
      return `New Self-billing CN ${selfBillingCreditNoteType(isCreditNote)}`;
    } else {
      return `New Incoming ${creditNoteType(isCreditNote)}`;
    }
  } else {
    if (selfBilling) {
      return `Self-billing CN ${selfBillingCreditNoteType(isCreditNote)} ${shortName}`;
    } else {
      return `Incoming ${creditNoteType(isCreditNote)} ${shortName}`;
    }
  }
};
