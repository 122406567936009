import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  ServerError,
  TypedDocumentNode,
  useQuery,
} from '@apollo/react-hooks';
import { Notification } from 'components/UI';
import { useState } from 'react';

export type QueryState = 'loading' | 'success' | 'unauthorized' | 'failed';

type AuthorizationResult<TData = any, TVariables extends OperationVariables = OperationVariables> = QueryResult<
  TData,
  TVariables
> & {
  authorized?: boolean;
  serverMessage?: string;
};

interface AuthorizedQueryOptions {
  showNotification?: boolean;
}

function useAuthorizedQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & AuthorizedQueryOptions,
): AuthorizationResult<TData, TVariables> {
  const [queryState, setQueryState] = useState<QueryState>('loading');
  const [serverMessage, setServerMessage] = useState<string>();

  const queryResult = useQuery(query, {
    ...options,
    onError: (error) => {
      const { networkError } = error;

      if (networkError && 'statusCode' in networkError && networkError.statusCode === 403) {
        setQueryState('unauthorized');
        const errorMessage = String(((networkError as ServerError).result as { error?: string }).error);

        setServerMessage(errorMessage);
        if (options?.showNotification) Notification.Error(errorMessage);
      } else {
        setQueryState('failed');
        if (options?.onError != null) options.onError(error);
      }
    },
    onCompleted: (data) => {
      setQueryState('success');
      if (options?.onCompleted) options.onCompleted(data);
    },
  });

  return {
    ...queryResult,
    loading: queryResult.loading || queryState === 'loading',
    authorized: queryState !== 'unauthorized',
    serverMessage,
  };
}

export default useAuthorizedQuery;
