import { CheckCircleFilled } from '@ant-design/icons';
import { Row } from 'antd';
import { Button } from 'components/UI';
import React, { FC, ReactElement } from 'react';

import { Inner, ScreenContainer } from '../styled';

interface Props {
  onCancel: () => void;
}

const Success: FC<Props> = ({ onCancel }): ReactElement => (
  <ScreenContainer>
    <Inner success>
      <Row justify="center">
        <CheckCircleFilled />
      </Row>

      <Row justify="center">
        <h2>Success!</h2>
      </Row>

      <Row justify="center">
        <span>Email has been sent</span>
      </Row>

      <Row justify="center">
        <Button type="primary" onClick={onCancel}>
          Close
        </Button>
      </Row>
    </Inner>
  </ScreenContainer>
);

export default Success;
