import { gql } from 'apollo-boost';
import { ExistingWorkspacePreset } from 'interfaces/graphql/workspacePreset';

import { WORKSPACE_PRESET_FRAGMENT } from './fragments';

export interface IWorkspacePresetsCreateResponse {
  workspacePresetsCreate: {
    workspacePreset: ExistingWorkspacePreset;
  };
}

export const WORKSPACE_PRESETS_CREATE = gql`
  mutation workspacePresetsCreate($attributes: OmsInputsWorkspacePresetType!) {
    workspacePresetsCreate(input: { attributes: $attributes }) {
      workspacePreset {
        ...workspacePresetFragment
      }
    }
  }
  ${WORKSPACE_PRESET_FRAGMENT}
`;

export interface IWorkspacePresetsDeleteResponse {
  workspacePresetsDelete: {
    success: boolean;
  };
}

export const WORKSPACE_PRESETS_DELETE = gql`
  mutation workspacePresetsDelete($id: ID!) {
    workspacePresetsDelete(input: { id: $id }) {
      success
    }
  }
`;

export interface IWorkspacePresetsAssignDefaultPresetResponse {
  workspacePresetsAssignDefaultPreset: {
    success: boolean;
  };
}

export interface IWorkspacePresetsRemoveDefaultPresetResponse {
  workspacePresetsRemoveDefaultPreset: {
    success: boolean;
  };
}
