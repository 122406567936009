import styled from '@emotion/styled';
import { colors } from 'components/UI/colors';
import { isString } from 'lodash-es';
import React from 'react';

type IconForm = 'circle' | 'square';

interface FrameIconWrapProps {
  color?: string;
  form: IconForm;
  padding?: number | string;
}

export const FrameIconWrap = styled.span<FrameIconWrapProps>`
  color: ${colors.gray1};
  display: inline-block;
  text-align: center;
  width: 22px;
  height: 22px;

  background-color: ${({ color }) => color ?? 'inherit'};
  border-radius: ${({ form }) => (form === 'circle' ? '50%' : '3px')};
  padding: ${({ padding = 0 }) => (isString(padding) ? padding : `${padding}px`)};

  .svg-inline--fa {
    width: 18px;
    height: 18px;
  }
`;

export interface Props {
  color?: string;
  className?: string;
  children: React.ReactNode;
  form?: IconForm;
  padding?: number | string;
}

const FrameIcon: React.FC<Props> = ({
  color = 'inherit',
  className = 'circle-icon-wrap',
  children,
  form = 'circle',
  padding = 0,
}) => (
  <FrameIconWrap className={className} color={color} form={form} padding={padding}>
    {children}
  </FrameIconWrap>
);
export default FrameIcon;
