import { FormInstance, Input, Row } from 'antd';
import { Button } from 'components/UI';
import FormItemCache from 'components/UI/Form/FormItemCache';
import { observer } from 'mobx-react-lite';
import React, { FC, MutableRefObject, ReactElement } from 'react';
import { useStore } from 'stores/RootStore';

import { MailerFormGroup, RecipientItem, SendDirectEmailButton, StyledTextAreaItem } from '../styled';
import { Attachments, Recipient, Template } from '.';

interface Props {
  changesMadeRef?: MutableRefObject<boolean>;
  form: FormInstance;
  withUploads?: boolean;
  bulkSendMode?: boolean;
  onUpload?: (file: File) => void;
  onDirectSubmit?: () => void;
  textAreaRows?: number;
}

const Form: FC<Props> = observer(
  ({ changesMadeRef, form, withUploads, bulkSendMode, onUpload, onDirectSubmit, textAreaRows }): ReactElement => {
    const { mailerStore } = useStore();

    return (
      <>
        <FormItemCache name={['attachments']} />

        <Template changesMadeRef={changesMadeRef} form={form} />

        <MailerFormGroup>
          <Recipient name="recipient" label="TO" options={mailerStore.recipients} form={form} required />

          <Recipient name="carbonCopy" label="CC" options={mailerStore.carbonCopy} form={form} />

          <Recipient name="blindCarbonCopy" label="BCC" options={mailerStore.blindCarbonCopy} form={form} />

          <RecipientItem name="subject" label="Subject">
            <Input />
          </RecipientItem>
        </MailerFormGroup>

        <Attachments form={form} withUploads={withUploads} onUpload={onUpload} />

        <StyledTextAreaItem name="content">
          <Input.TextArea rows={textAreaRows} placeholder="Enter you text here..." />
        </StyledTextAreaItem>

        {!bulkSendMode && (
          <Row justify={onDirectSubmit ? 'space-between' : 'end'}>
            {onDirectSubmit && (
              <SendDirectEmailButton
                className="p-none"
                size="small"
                type="link"
                onClick={onDirectSubmit}
                disabled={mailerStore.isUploadInProcess}
              >
                Send direct email
              </SendDirectEmailButton>
            )}
            <Button type="primary" htmlType="submit" disabled={mailerStore.isUploadInProcess}>
              Send email
            </Button>
          </Row>
        )}
      </>
    );
  },
);

export default Form;
