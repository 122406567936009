import { gql } from 'apollo-boost';
import { QueryNameKey } from 'components/Workspaces/collections';

export type ExportMutationReponse = Record<string, { jobId: string }>;

export const buildExportMutation = (query: QueryNameKey) => {
  const exportQuery = `${query}Export`;

  return {
    exportMutationName: exportQuery,
    exportMutation: gql`
      mutation ${exportQuery}(
        $filename: String!,
        $format: String!,
        $columns: [WorkspacesInputsExportColumnType!]!,
        $timezoneOffset: Int!,
        $filterQuery: String!,
        $queryOptions: JSON,
        $sortParams: [WorkspacesInputsGenericSortingType!],
        $paginationParams: WorkspacesInputsGenericPaginationType!,
        $selectedRecords: [Int!],
      ) {
        ${exportQuery} (
          input: {
            filename: $filename
            format: $format
            columns: $columns
            timezoneOffset: $timezoneOffset
            filterQuery: $filterQuery
            queryOptions: $queryOptions
            sortParams: $sortParams
            paginationParams: $paginationParams
            selectedRecords: $selectedRecords
          }
        ) {
          jobId
        }
      }
    `,
  };
};
