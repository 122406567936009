/* eslint react/jsx-props-no-spreading: "off" */
import { CaretDownFilled, CaretRightFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { FilterSettings } from 'components/Workspaces/collections';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { Column, WorkspaceGraphQLField, WorkspaceGraphQLObjectField } from '../../collections';
import { FilterItem } from '../../FiltersStore';
import FilterNode from './FilterNode';
import { AttributesFiltersContainer } from './styled';

interface Props {
  additionalFilters: FilterItem;
  attributes: WorkspaceGraphQLField[];
  columns: Column[];
  customFilters?: FilterSettings[];
  hideUndefinedColumns?: boolean;
  objectAttributes: WorkspaceGraphQLObjectField[];
  presetFilters?: FilterItem;
}

const SplitFilters = observer<Props>(
  ({
    additionalFilters,
    attributes,
    columns,
    customFilters,
    hideUndefinedColumns,
    objectAttributes,
    presetFilters,
  }) => {
    const [presetFiltersHidden, setPresetFiltersHidden] = useState(true);

    const combinedFilters = presetFilters != null ? presetFilters.combine(additionalFilters) : additionalFilters;

    return (
      <AttributesFiltersContainer>
        {presetFilters != null &&
          (presetFiltersHidden ? (
            <Button
              className="expand-preset"
              icon={<CaretRightFilled />}
              onClick={() => setPresetFiltersHidden(false)}
              type="link"
            >
              Expand preset filters
            </Button>
          ) : (
            <>
              <Button
                className="expand-preset"
                icon={<CaretDownFilled />}
                onClick={() => setPresetFiltersHidden(true)}
                type="link"
              >
                Hide preset filters
              </Button>
              <h3>Preset filters:</h3>
              <FilterNode
                attributes={attributes}
                columns={columns}
                customFilters={customFilters}
                hideUndefinedColumns={hideUndefinedColumns}
                objectAttributes={objectAttributes}
                node={presetFilters}
                remove={undefined}
              />
            </>
          ))}

        {additionalFilters != null && (
          <>
            {!presetFiltersHidden && <h3>Custom filters:</h3>}
            <FilterNode
              attributes={attributes}
              columns={columns}
              customFilters={customFilters}
              hideUndefinedColumns={hideUndefinedColumns}
              objectAttributes={objectAttributes}
              node={additionalFilters}
              remove={undefined}
            />
          </>
        )}

        <div style={{ marginTop: '10px' }}>
          {additionalFilters.lastOperator !== 'AND' && (
            <Button icon={<PlusOutlined />} size="small" type="link" onClick={() => additionalFilters.addChild('AND')}>
              Add AND condition
            </Button>
          )}

          {additionalFilters.value.kind === 'group' && additionalFilters.lastOperator !== 'OR' && (
            <Button
              icon={<PlusOutlined />}
              onClick={() => additionalFilters.addChild('OR')}
              size="small"
              style={{ marginLeft: '10px' }}
              type="link"
            >
              Add OR condition
            </Button>
          )}
        </div>

        <Input
          disabled
          style={{
            color: 'black',
            flexGrow: 1,
            fontFamily: 'Monospace',
          }}
          value={combinedFilters.toQuery}
        />
      </AttributesFiltersContainer>
    );
  },
);

export default SplitFilters;
