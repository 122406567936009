import { gql } from 'apollo-boost';
import { IErpPermissionsGroup } from 'interfaces/graphql/erpPermission';
import { ERP_PERMISSION_FRAGMENT } from 'utils/graphql/fragments/erpPermissions';

export interface IErpPermissionsResponse {
  erpPermissions: IErpPermissionsGroup[];
}

export const GET_ERP_PERMISSIONS = gql`
  query erpPermissions {
    erpPermissions {
      group
      erpPermissions {
        ...erpPermissionFragment
      }
    }
  }
  ${ERP_PERMISSION_FRAGMENT}
`;
