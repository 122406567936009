import { gql } from 'apollo-boost';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';

const ORGANIZATION_FRAGMENT_CONFIG: Record<Regions, ReturnType<typeof gql>> = {
  [Regions.EU]: gql`
    fragment organizationFragment on Organization {
      id
      isImportant
      createdAt
      updatedAt
      deletedAt
      deleted
      pmId
      pipedriveId
      pipedriveUrl
      externalCrmId
      externalCrmUrl
      name
      customerOrderGrade
      customerType
      industry
      isForSoftwareTesting
      emailDomains
      website
      phone
      notes

      productionManager {
        id
        name
      }

      defaultShippingAddress {
        id
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      kam {
        id
        name
      }
      owner {
        id
        name
      }

      persons {
        id
        name
        deals {
          id
          customerOrderDate
        }
      }

      deals {
        id
        createdAt
        currency
        stage
        pipedriveId
        pipedriveUrl
        pipedriveStatus
        pipedrivePipeline
        pipedriveOrderValue

        person {
          id
          name
        }
      }
    }
  `,
  [Regions.UK]: gql`
    fragment organizationFragment on Organization {
      id
      createdAt
      updatedAt
      deletedAt
      deleted
      pmId
      pipedriveId
      pipedriveUrl
      externalCrmId
      externalCrmUrl
      name
      customerOrderGrade
      customerType
      industry
      isForSoftwareTesting
      emailDomains
      website
      phone
      notes

      productionManager {
        id
        name
      }

      defaultShippingAddress {
        id
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      kam {
        id
        name
      }
      owner {
        id
        name
      }

      persons {
        id
        name
        deals {
          id
          customerOrderDate
        }
      }

      deals {
        id
        createdAt
        stage
        pipedriveId
        pipedriveUrl
        pipedriveStatus
        pipedrivePipeline
        pipedriveOrderValue

        person {
          id
          name
        }
      }
    }
  `,
  [Regions.TR]: gql`
    fragment organizationFragment on Organization {
      id
      createdAt
      updatedAt
      deletedAt
      deleted
      pmId
      pipedriveId
      pipedriveUrl
      externalCrmId
      externalCrmUrl
      name
      customerOrderGrade
      customerType
      industry
      isForSoftwareTesting
      emailDomains
      website
      phone
      notes

      productionManager {
        id
        name
      }

      defaultShippingAddress {
        id
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      kam {
        id
        name
      }
      owner {
        id
        name
      }

      persons {
        id
        name
        deals {
          id
          customerOrderDate
        }
      }

      deals {
        id
        createdAt
        currency
        stage
        pipedriveId
        pipedriveUrl
        pipedriveStatus
        pipedrivePipeline
        pipedriveOrderValue

        person {
          id
          name
        }
      }
    }
  `,
};

export const ORGANIZATION_FRAGMENT = ORGANIZATION_FRAGMENT_CONFIG[CURRENT_REGION];

export const ORGANIZATION_DEAL_HEADER_FRAGMENT = gql`
  fragment organizationDealHeaderFragment on Organization {
    id
    createdAt
    updatedAt
    deletedAt
    deleted
    pipedriveId
    pipedriveUrl
    name
    customerOrderGrade
    customerType
    industry
    isImportant
    emailDomains
    website
    phone
    notes

    productionManager {
      id
      name
    }

    kam {
      id
      name
    }
    owner {
      id
      name
    }
  }
`;
