import { CURRENT_REGION } from 'config/regions/config';
import { LOGISTIC_INVOICES_ENABLED } from 'config/regions/features';
import { Regions } from 'config/regions/types';
import {
  BankPaymentsDraftPage,
  BankPaymentsPage,
  BillingAccountsPage,
  ClaimsPage,
  CouponsEUPage,
  CouponsRegionsPage,
  DealsPage,
  EnquiriesPage,
  FrameworksPage,
  IncomingInvoicesPage,
  JobOffersListPage,
  JobsPage,
  LogisticInvoicesPage,
  MessagesPage,
  MessageSubscriptionsPage,
  OrganizationBalancesPage,
  OrganizationsPage,
  OutcomingInvoicesPage,
  PartnerBalancesPage,
  PaymentsPage,
  PayoutsPage,
  PersonsPage,
  ProviderOrdersPage,
  ProvidersPage,
  QualityControlsPage,
  RefundsPage,
  RfqsPage,
  ShippingAddressesPage,
  ShippingsListPage,
  StoragesPage,
  TasksPage,
  UsersPage,
} from 'pages';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

export const GeneralWorkspacesController = () => (
  <Switch>
    <Route path={routes.workspacesGeneralIncomingInvoicesPath()} component={IncomingInvoicesPage} exact />
    <Route path={routes.workspacesGeneralOutcomingInvoicesPath()} component={OutcomingInvoicesPage} exact />
    {LOGISTIC_INVOICES_ENABLED && (
      <Route path={routes.workspacesGeneralLogisticInvoicesPath()} component={LogisticInvoicesPage} exact />
    )}
    <Route
      path={routes.workspacesGeneralBankPaymentsPath()}
      render={(props) => <BankPaymentsPage key={props.location.key} />}
      exact
    />
    <Route
      path={routes.workspacesGeneralBankPaymentsDraftPath()}
      render={(props) => <BankPaymentsDraftPage key={props.location.key} />}
      exact
    />
    <Route path={routes.workspacesGeneralPartnersPath()} component={ProvidersPage} exact />
    <Route path={routes.workspacesGeneralPartnerBalancesPath()} component={PartnerBalancesPage} exact />
    <Route path={routes.workspacesGeneralQualityControlsPath()} component={QualityControlsPage} exact />
    <Route path={routes.workspacesGeneralShippingsPath()} component={ShippingsListPage} exact />
    <Route path={routes.workspacesGeneralStoragesPath()} component={StoragesPage} exact />
    <Route path={routes.workspacesGeneralBillingAccountsPath()} component={BillingAccountsPage} exact />
    <Route path={routes.workspacesGeneralShippingAddressesPath()} component={ShippingAddressesPage} exact />
    <Route path={routes.workspacesGeneralPaymentsPath()} component={PaymentsPage} exact />
    <Route path={routes.workspacesGeneralRefundsPath()} component={RefundsPage} exact />

    <Route path={routes.workspacesGeneralOrganizationBalancesPath()} component={OrganizationBalancesPage} exact />

    <Route path={routes.workspacesGeneralOrganizationsPath()} component={OrganizationsPage} exact />

    <Route path={routes.workspacesGeneralDealsPath()} component={DealsPage} exact />

    <Route path={routes.workspacesGeneralFrameworksPath()} component={FrameworksPage} exact />

    <Route path={routes.workspacesGeneralPersonsPath()} component={PersonsPage} exact />
    <Route path={routes.workspacesGeneralEnquiriesPath()} component={EnquiriesPage} exact />
    <Route path={routes.workspacesGeneralRfqsPath()} component={RfqsPage} exact />
    <Route path={routes.workspacesGeneralJobsPath()} component={JobsPage} exact />
    <Route path={routes.workspacesGeneralJobOffersPath()} component={JobOffersListPage} />
    <Route path={routes.workspacesGeneralClaimsPath()} component={ClaimsPage} />
    <Route path={routes.workspacesGeneralPayoutsPath()} component={PayoutsPage} exact />
    <Route path={routes.workspacesGeneralProviderOrdersPath()} component={ProviderOrdersPage} exact />
    <Route path={routes.workspacesGeneralUsersPath()} component={UsersPage} exact />
    <Route path={routes.workspacesGeneralMessagesPath()} component={MessagesPage} exact />
    <Route path={routes.workspacesGeneralTasksPath()} component={TasksPage} exact />
    <Route path={routes.workspacesGeneralMessageSubscriptionsPath()} component={MessageSubscriptionsPage} exact />

    {CURRENT_REGION === Regions.EU && <Route path={routes.promotionsPath()} component={CouponsEUPage} exact />}

    {(CURRENT_REGION === Regions.UK || CURRENT_REGION === Regions.TR) && (
      <Route path={routes.workspacesGeneralCouponsPath()} component={CouponsRegionsPage} exact />
    )}
  </Switch>
);
