import styled from '@emotion/styled';
import { DatePicker, Row } from 'antd';
import { colors } from 'components/UI/colors';

export const AntdDatePicker = styled(DatePicker)`
  .ant-picker-input {
    input {
      ::placeholder {
        color: ${colors.gray8};
        font-family: Monospace;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  padding: 0 4px 8px 0;
`;

export const ButtonsRow = styled(Row)`
  width: 300px;

  button {
    margin-top: 8px;
    margin-left: 8px;
  }
`;
