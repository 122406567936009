import { BillingAccountBalancePage, BillingAccountPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

const BillingAccountsController: React.FC = () => (
  <>
    <Route exact path={routes.billingAccountPath(':id')} render={() => <BillingAccountPage />} />

    <Route path={routes.billingAccountBalancePath(':id')} render={() => <BillingAccountBalancePage />} />
  </>
);

export default BillingAccountsController;
