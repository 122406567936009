import { bc } from 'components/UI/variables';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';
import { DealPipedriveStatus } from 'interfaces';
import { compact, map, sortBy, uniqBy } from 'lodash-es';
import { TagsQuery } from 'utils/graphql/queries/__generated__/tags';
import { GetUsersQuery } from 'utils/graphql/queries/__generated__/users';

import { FilterKeywords } from './General/shared/GeneralWorkspace/collections';

export interface Option {
  label: string;
  value: string;
}

export const formatNumber = (value?: number) => value?.toLocaleString('ru-RU')?.replace(',', '.');

export const formatUrl = (url: string) => (/^https?/.test(url) ? url : `http://${url}`);

export const prepareUsers = (users: GetUsersQuery['users']) =>
  uniqBy(
    sortBy(users, (u) => ((u.active ? 1 : 0) * 100 + (u.podId ?? 0)) * -1),
    'name',
  );

export const addMeUserOption = (options: Option[]) => [
  {
    label: 'Me',
    value: FilterKeywords.Me,
  },
  ...options,
];

export const billingAccountTypeOptions = [
  {
    label: 'Corporate',
    value: 'Corporate',
  },
  {
    label: 'Private Person',
    value: 'Private Person',
  },
];

export const mapDealStatusStyle = (pipedriveStatus: string) => {
  switch (pipedriveStatus) {
    case DealPipedriveStatus.won:
      return { color: bc.success };
    case DealPipedriveStatus.open:
      return { color: bc.primary };
    default:
      return { color: bc.error };
  }
};

const defaultPodIdOptions = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
];

const trPodIdOptions = [
  ...defaultPodIdOptions,
  {
    label: '5',
    value: '5',
  },
];

const euPodIdOptions = [
  ...defaultPodIdOptions,
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
];

const podIdOptionsConfig = {
  [Regions.EU]: euPodIdOptions,
  [Regions.TR]: trPodIdOptions,
  [Regions.UK]: defaultPodIdOptions,
};

export const podIdOptions = podIdOptionsConfig[CURRENT_REGION];

export const prepareTagsOptions = (tags: TagsQuery['tags']) =>
  (tags ?? []).map(({ id, name }) => ({
    label: name,
    value: id.toString(),
  }));

export const prepareUserOptions = (
  users: GetUsersQuery['users'],
  { enableMeOption = true }: { enableMeOption?: boolean } = {},
) => {
  let userOptions = map(prepareUsers(users), (u) => ({
    label: compact([u.name, u.podId && `POD: ${u.podId}`]).join(' | '),
    value: u.id.toString(),
  }));

  if (enableMeOption) userOptions = addMeUserOption(userOptions);

  return userOptions;
};

export const prepareUserNameOptions = (users: GetUsersQuery['users']) =>
  addMeUserOption(
    map(prepareUsers(users), (u) => ({
      label: compact([u.name, u.podId && `POD: ${u.podId}`]).join(' | '),
      value: u.name,
    })),
  );
