import { LockOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { parseGroupAndAction } from 'helpers/accessErrors/helpers';
import React from 'react';
import { RootStore } from 'stores/RootStore';

import { AccessRequestLink } from '../AccessRequestLink';

export const accessErrorNotification = (message = "You don't have permissions", rootStore?: RootStore) => {
  const groupAndAction = parseGroupAndAction(message);
  notification.error({
    message: message,
    description: (
      <AccessRequestLink rootStore={rootStore} group={groupAndAction?.group} action={groupAndAction?.action} />
    ),
    duration: 15,
    icon: <LockOutlined style={{ color: '#ff4d4f' }} />,
    style: {
      border: '2px solid #ff4d4f',
    },
  });
};
