import { LinkOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import { Notice } from 'components/UI';
import { Modal, ModalTitle } from 'components/UI/Modal/styled';
import { dateTimeFormat } from 'components/utils/formatters';
import { FilterSettings, QueryNameKey } from 'components/Workspaces/collections';
import {
  Column,
  ISortParam,
  PermissionsSettings,
  WorkspaceGraphQLField,
  WorkspaceGraphQLObjectField,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { FilterItem, useFilters } from 'components/Workspaces/General/shared/GeneralWorkspace/FiltersStore';
import Columns from 'components/Workspaces/General/shared/GeneralWorkspace/shared/Columns';
import { SortColumn } from 'components/Workspaces/General/shared/GeneralWorkspace/SortColumn';
import { useAllowedToUpdatePresets } from 'components/Workspaces/General/shared/GeneralWorkspace/useAllowedToUpdatePresets';
import { useFiltersEditPermission } from 'components/Workspaces/General/shared/GeneralWorkspace/useFiltersEditPermission';
import { PresetOptionData } from 'components/Workspaces/General/shared/GeneralWorkspace/withPresets';
import { decamelize } from 'humps';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import { filter, map } from 'lodash-es';
import React, { useState } from 'react';
import { copyToClipboard } from 'utils/clipboard';

import s from './EditFiltersModal.module.less';
import Filters from './Filters';
import SplitFilters from './Filters/SplitFilters';
import PresetForm from './PresetForm';
import { SetAsMyDefaultButton } from './PresetForm/SetAsMyDefaultButton';
import { useSortColumns } from './useSortColumns';

interface Props {
  attributes: WorkspaceGraphQLField[];
  objectAttributes: WorkspaceGraphQLObjectField[];
  columns: Column[];
  currentPreset: WorkspacePreset;
  customFilters?: FilterSettings[];
  hidePresetFilters?: boolean;
  hideUndefinedColumns?: boolean;
  defaultSort?: ISortParam;
  queryName: QueryNameKey;
  visible?: boolean;
  permissionsSettings: PermissionsSettings;
  presetsList: PresetOptionData[];
  onCancel: () => void;
  onPresetCreate: (name: string, isPublic: boolean, columns: Column[], sort?: ISortParam) => void;
  onPresetUpdate: (preset: ExistingWorkspacePreset) => void;
  onPresetDelete: (preset: ExistingWorkspacePreset) => void;
  onPresetCopy: (preset: ExistingWorkspacePreset) => void;
  onSubmit: (columns: Column[], sort?: ISortParam) => void;
}

const EditFiltersModal: React.FC<Props> = ({
  attributes,
  objectAttributes,
  columns,
  currentPreset,
  customFilters,
  hidePresetFilters,
  hideUndefinedColumns,
  queryName,
  visible,
  defaultSort,
  permissionsSettings,
  presetsList,
  onCancel,
  onPresetCreate,
  onPresetUpdate,
  onPresetDelete,
  onPresetCopy,
  onSubmit,
}) => {
  const [currentColumns, setCurrentColumns] = useState<Column[]>(columns);

  const filters = useFilters();

  const allowedToUpdatePresets = useAllowedToUpdatePresets();
  const { isFiltersChangingAllowed } = useFiltersEditPermission(permissionsSettings);

  let presetFilters: FilterItem | undefined;
  let additionalFilters: FilterItem | undefined;

  if (hidePresetFilters && currentPreset.id != null && currentPreset.query != null) {
    [presetFilters, additionalFilters] = filters.partition(FilterItem.fromQuery(currentPreset.query));
  } else {
    additionalFilters = filters.deepClone();
  }

  const [presetFiltersClone] = useState(presetFilters);
  const [additionalFiltersClone] = useState(additionalFilters ?? new FilterItem());

  const { sortColumn, sortDirection, sortOptions, handleChangeSort } = useSortColumns({
    sort: defaultSort,
    columns: currentColumns,
  });

  const sort = sortColumn && sortDirection ? { sortBy: decamelize(sortColumn), direction: sortDirection } : undefined;

  const applyFilterChanges = () => {
    if (hidePresetFilters && presetFiltersClone != null) {
      filters.assignRoot(presetFiltersClone.combine(additionalFiltersClone));
    } else {
      filters.assignRoot(additionalFiltersClone);
    }
  };

  const handleFiltersSubmit = () => {
    applyFilterChanges();

    // Sort columns after applying filters and columns.
    const sortedByEnabled = currentColumns.sort((x, y) => {
      // true values first
      return x.enabled === y.enabled ? 0 : x.enabled ? -1 : 1;
    });

    onSubmit(sortedByEnabled, sort);
  };

  const handlePresetCreate = (name: string, isPublic: boolean, columns: Column[]) => {
    applyFilterChanges();
    onPresetCreate(name, isPublic, columns, sort);
  };

  const handlePresetUpdate = (preset: ExistingWorkspacePreset) => {
    applyFilterChanges();

    onPresetUpdate({
      ...preset,
      columns: map(filter(currentColumns, { enabled: true }), 'key'),
      query: filters.toQuery,
      sortColumn: sort?.sortBy,
      sortDirection: sort?.direction,
    });
  };

  const handlePresetCopy = (preset: ExistingWorkspacePreset) => {
    applyFilterChanges();

    onPresetCopy(preset);
  };

  const handleCopyPresetLinkClick = () => {
    const url = window.location.href.split('?')[0];
    copyToClipboard(url + `?preset=${currentPreset.id}`);
  };

  return (
    <Modal
      bodyStyle={{ padding: '10px 12px' }}
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      // centered
      title={<ModalTitle>Edit filters and columns</ModalTitle>}
      open={visible}
      style={{ maxWidth: '1300px' }}
      width="95%"
    >
      <div className={s.body}>
        <div className={s.content}>
          <div className="m-b-sm">
            {hidePresetFilters ? (
              <SplitFilters
                additionalFilters={additionalFiltersClone}
                attributes={attributes}
                columns={columns}
                customFilters={customFilters}
                hideUndefinedColumns={hideUndefinedColumns}
                objectAttributes={objectAttributes}
                presetFilters={presetFiltersClone}
              />
            ) : (
              <Filters
                attributes={attributes}
                columns={columns}
                customFilters={customFilters}
                filters={additionalFiltersClone}
                permissionsSettings={permissionsSettings}
                hideUndefinedColumns={hideUndefinedColumns}
                objectAttributes={objectAttributes}
              />
            )}
          </div>

          <Row justify="space-between" align="top" className="m-b-sm" gutter={[16, 16]}>
            <Col>
              {currentPreset.id && (
                <Space direction="vertical">
                  <Space>
                    <Typography.Text className={s.presetName}>
                      <Typography.Text strong>Preset:</Typography.Text> {currentPreset.name}
                    </Typography.Text>
                    <Tooltip title="Copy link to the clipboard">
                      <Button
                        size="small"
                        className="p-none"
                        type="link"
                        icon={<LinkOutlined />}
                        onClick={handleCopyPresetLinkClick}
                      />
                    </Tooltip>
                    <SetAsMyDefaultButton currentPreset={currentPreset} queryName={queryName} />
                  </Space>
                  <div>
                    <Notice>
                      Created by {currentPreset.owner?.name} at {dateTimeFormat(currentPreset.createdAt)}
                    </Notice>

                    {currentPreset.lastUpdatedBy != null && (
                      <Notice>
                        Updated by {currentPreset.lastUpdatedBy.name} at {dateTimeFormat(currentPreset.updatedAt)}
                      </Notice>
                    )}
                  </div>
                </Space>
              )}
            </Col>
            <Col flex={1}>
              <Row justify="end">
                <Button onClick={handleFiltersSubmit} type="primary" disabled={!isFiltersChangingAllowed}>
                  Apply filters and columns
                </Button>
              </Row>
            </Col>
          </Row>

          {allowedToUpdatePresets && (
            <PresetForm
              currentColumns={currentColumns}
              currentPreset={currentPreset}
              onPresetCreate={handlePresetCreate}
              onPresetUpdate={handlePresetUpdate}
              onPresetCopy={handlePresetCopy}
              onPresetDelete={onPresetDelete}
              queryName={queryName}
              presetsList={presetsList}
            />
          )}
        </div>
        <div className={s.sidebar}>
          <Space direction="vertical">
            <Columns
              columns={currentColumns}
              onColumnsChange={setCurrentColumns}
              permissionsSettings={permissionsSettings}
            />
            <SortColumn
              sortOptions={sortOptions}
              onChange={handleChangeSort}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
            />
          </Space>
        </div>
      </div>
    </Modal>
  );
};

export default EditFiltersModal;
