import { ErpPermissionGroup, OrganizationsActions, PersonsActions } from 'collections/authorization';
import { AuthorizedRoute } from 'controllers/AuthorizedRoute';
import {
  OrganizationBalancePage,
  OrganizationNewPage,
  OrganizationPage,
  PersonNewPage,
  PersonPage,
  PromotionEditPage,
  PromotionNewPage,
  SearchPage,
} from 'pages';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

const CrmController: React.FC = () => (
  <Switch>
    <AuthorizedRoute
      group={ErpPermissionGroup.Persons}
      action={PersonsActions.Create}
      path={routes.newPersonPath()}
      exact
    >
      <PersonNewPage />
    </AuthorizedRoute>

    <AuthorizedRoute
      group={ErpPermissionGroup.Persons}
      action={PersonsActions.View}
      path={[routes.personPath(':id'), routes.pPersonPath(':id')]}
      exact
    >
      <PersonPage />
    </AuthorizedRoute>

    <AuthorizedRoute
      group={ErpPermissionGroup.Organizations}
      action={OrganizationsActions.Create}
      path={routes.newOrgPath()}
      exact
    >
      <OrganizationNewPage />
    </AuthorizedRoute>

    <AuthorizedRoute
      group={ErpPermissionGroup.Organizations}
      action={OrganizationsActions.View}
      path={[routes.orgPath(':id'), routes.pOrgPath(':id')]}
      exact
    >
      <OrganizationPage />
    </AuthorizedRoute>

    <Route path={routes.orgBalancePath(':id')} exact component={OrganizationBalancePage} />

    <Route path={routes.searchPagePath(':model')} exact component={SearchPage} />

    <Route path={routes.editPromotionPath(':id')} exact component={PromotionEditPage} />

    <Route path={routes.newPromotionPath()} exact component={PromotionNewPage} />
  </Switch>
);

export default CrmController;
