import lookers from 'collections/lookers';
import { LookerPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

const PartnersController: React.FC = () => (
  <>
    <Route
      path={routes.lookers.partners.network.competenceBalancePath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          url={lookers.partners.network.competenceBalance}
          title="Competence Balance | Partners Network | ERP"
        />
      )}
    />

    <Route
      path={routes.lookers.partners.network.claimAndDelayPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          url={lookers.partners.network.claimAndDelay}
          title="Claim & Delay | Partners Network | ERP"
        />
      )}
    />

    <Route
      path={routes.lookers.partners.network.providersByCohortsPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          url={lookers.partners.network.providersByCohorts}
          title="Providers by cohorts | Partners Network | ERP"
        />
      )}
    />

    <Route
      path={routes.lookers.partners.instantPayPath()}
      exact
      render={(props) => (
        <LookerPage {...props} url={lookers.partners.instantPay} title="Instant Pay | Partners | ERP" />
      )}
    />

    <Route
      path={routes.lookers.partners.expressOrdersPath()}
      exact
      render={(props) => (
        <LookerPage {...props} url={lookers.partners.expressOrders} title="Express Orders | Partners | ERP" />
      )}
    />

    <Route
      path={routes.lookers.partners.network.performancePath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Network Performance | Partners Network | ERP"
          url={lookers.partners.network.performance}
        />
      )}
    />

    <Route
      path={routes.lookers.partners.network.profilesPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Profiles | Partners Network | ERP" url={lookers.partners.network.profiles} />
      )}
    />

    <Route
      path={routes.lookers.partners.network.generalInfoPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Partner General Info | Partners Network | ERP"
          url={lookers.partners.network.generalInfo}
        />
      )}
    />

    <Route
      path={routes.lookers.partners.network.newbiesPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Newbies | Partners Network | ERP" url={lookers.partners.network.newbies} />
      )}
    />

    {/* partners engagement */}
    <Route
      path={routes.lookers.partners.engagement.lifetimeMilestonesPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Lifetime milestones | Partners Engagement | ERP"
          url={lookers.partners.engagement.lifetimeMilestones}
        />
      )}
    />

    <Route
      path={routes.lookers.partners.engagement.onboardingPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Onboarding | Partners Engagement | ERP"
          url={lookers.partners.engagement.onboarding}
        />
      )}
    />

    <Route
      path={routes.lookers.partners.engagement.probationPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Probation | Partners Engagement | ERP"
          url={lookers.partners.engagement.probation}
        />
      )}
    />

    <Route
      path={routes.lookers.partners.engagement.testPartPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Test part | Partners Engagement | ERP"
          url={lookers.partners.engagement.testPart}
        />
      )}
    />

    <Route
      path={routes.lookers.partners.engagement.trustedPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Partners Engagement | ERP" url={lookers.partners.engagement.trusted} />
      )}
    />

    <Route
      path={routes.lookers.partners.kpiPath()}
      exact
      render={(props) => <LookerPage {...props} title="KPI | Partners | ERP" url={lookers.partners.kpi} />}
    />

    <Route
      path={routes.lookers.partners.rootPath()}
      exact
      render={(props) => <LookerPage {...props} title="Partners | ERP" url={lookers.partners.list} />}
    />

    <Route
      path={routes.lookers.partners.cPool.analyticsPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Analytics | C Pool | Partners | ERP" url={lookers.partners.cPool.analytics} />
      )}
    />

    <Route
      path={routes.lookers.partners.cPool.partnersPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Partners | C Pool | Partners | ERP" url={lookers.partners.cPool.partners} />
      )}
    />
    <Route
      path={routes.lookers.partners.cPool.posPath()}
      exact
      render={(props) => <LookerPage {...props} title="PO | C Pool | Partners | ERP" url={lookers.partners.cPool.po} />}
    />

    <Route
      path={routes.lookers.partners.engagement.poByCountryAnalysisPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="PO by country analysis | Partners Engagement | ERP"
          url={lookers.partners.engagement.poByCountryAnalysis}
        />
      )}
    />
  </>
);

export default PartnersController;
