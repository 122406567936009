import { gql } from 'apollo-boost';

import { CONTACT_FRAGMENT, PROVIDER_FRAGMENT } from '../fragments/providers';

export const CONTACTS_CREATE = gql`
  mutation providersContactsCreate($providerId: ID!, $attributes: OmsInputsContactType!) {
    providersContactsCreate(input: { providerId: $providerId, attributes: $attributes }) {
      contact {
        ...contactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const CONTACTS_UPDATE = gql`
  mutation providersContactsUpdate($id: ID!, $attributes: OmsInputsContactType!) {
    providersContactsUpdate(input: { id: $id, attributes: $attributes }) {
      contact {
        ...contactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const CONTACTS_DELETE = gql`
  mutation providersContactsDelete($id: ID!) {
    providersContactsDelete(input: { id: $id }) {
      success
    }
  }
`;

export const CONTACTS_MOVE = gql`
  mutation providersContactsMove($id: ID!, $providerNumber: String!) {
    providersContactsMove(input: { id: $id, providerNumber: $providerNumber }) {
      contact {
        id
        providerId
      }
    }
  }
`;

export const PROVIDERS_CREATE = gql`
  mutation providersCreate($attributes: OmsInputsProviderType) {
    providersCreate(input: { attributes: $attributes }) {
      provider {
        ...providerFragment
      }
    }
  }
  ${PROVIDER_FRAGMENT}
`;

export const PROVIDERS_UPDATE = gql`
  mutation providersUpdate($id: ID!, $attributes: OmsInputsProviderType!) {
    providersUpdate(input: { id: $id, attributes: $attributes }) {
      provider {
        ...providerFragment
      }
    }
  }
  ${PROVIDER_FRAGMENT}
`;

export const PROVIDERS_DELETE = gql`
  mutation providersDelete($id: ID!) {
    providersDelete(input: { id: $id }) {
      provider {
        ...providerFragment
      }
    }
  }
  ${PROVIDER_FRAGMENT}
`;

export const PROVIDERS_CHANGE_PRIMARY = gql`
  mutation providersChangePrimary($id: ID!, $newPrimaryContactId: ID!) {
    providersChangePrimary(input: { id: $id, newPrimaryContactId: $newPrimaryContactId }) {
      provider {
        ...providerFragment
      }
    }
  }
  ${PROVIDER_FRAGMENT}
`;
