import styled from '@emotion/styled';

export const H1 = styled.h1`
  font-size: 21px;
  font-weight: bold;
  line-height: 34px;
`;

export const H2 = styled.h2`
  font-size: 20px;
  line-height: 27px;
  font-style: normal;
  font-weight: normal;
`;

export const H3 = styled.h3`
  font-size: 16px;
  line-height: 22px;
  font-style: normal;
  font-weight: 600;
`;

export const H4 = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
`;

export const PageTitle = styled(H2)`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

interface DivProps {
  capitalize?: boolean;
}

export const SemiBold = styled.div<DivProps>`
  font-weight: 600;
  ${({ capitalize }) => capitalize && 'text-transform: capitalize;'}
`;

export const Bold = styled.div`
  font-weight: bold;
`;

export const HeadSubTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  margin-left: 10px;
`;
