import { PositionSource, ServerGetPositionSource } from 'interfaces';
import { map } from 'lodash-es';
import { action, observable } from 'mobx';
import FetchableStore from 'stores/shared/FetchableStore';

class DealOutInvoicePositionsSourcesStore extends FetchableStore {
  @observable sources: PositionSource[] = [];

  @observable selected: PositionSource | null = null;

  @action assignOnFetch = (payload: ServerGetPositionSource[]): void => {
    this.sources = map(payload, (source: ServerGetPositionSource) => ({
      sourceType: source.source_type,
      sourceId: source.source_id,
      sourceName: source.source_name,
    }));
  };

  @action select = (source: PositionSource): void => {
    this.selected = source;
  };

  @action reset = (): void => {
    this.selected = null;
    this.sources = [];
  };
}

export default DealOutInvoicePositionsSourcesStore;
