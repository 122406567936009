import { action, observable } from 'mobx';
import { RootStore } from 'stores/RootStore';

class UIStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable contentBorderColor: string | null = null;

  @action setContentBorderColor = (color: string | null) => {
    this.contentBorderColor = color;
  };
}

export default UIStore;
