import { Checkbox, Form, Input } from 'antd';
import React, { FC, ReactElement } from 'react';

interface Props {
  name: string[];
  initialValue?: string;
  bool?: boolean;
}

const FormItemCache: FC<Props> = ({ name, bool, initialValue }): ReactElement => (
  <Form.Item name={name} hidden valuePropName={bool ? 'checked' : 'value'} initialValue={initialValue}>
    {bool ? <Checkbox /> : <Input />}
  </Form.Item>
);

export default FormItemCache;
