export enum SmartSearchClassName {
  IncomingInvoice = 'Deal::IncomingInvoice',
  PO = 'Deal::ProviderOrder',
  OutcomingInvoice = 'Deal::OutcomingInvoice',
  Payment = 'Deal::Payment',
  CL = 'Deal::Claim',
  Job = 'Job',
  JobOffer = 'Job::Offer',
  Part = 'Deal::Part',
  OrderConfirmation = 'Deal::OrderConfirmation',
  Deal = 'Deal',
  Delivery = 'Deal::Delivery',
  Shipping = 'Deal::Shipping',
  Storage = 'Deal::Storage',
  Payout = 'Deal::Payout',
  QualityControl = 'Deal::QualityControl',
  Quotation = 'Deal::Quotation',
  BA = 'BillingAccount',
  SA = 'ShippingAddress',
  Enquiry = 'Enquiry',
  Event = 'Event',
  Provider = 'Provider',
  Organization = 'Organization',
  Person = 'Person',
  EnquiryPart = 'Enquiry::Part',
  MasterShipping = 'MasterShipping',
  Rfq = 'Rfq',
  RfqOffer = 'Rfq::Offer',
}

export interface SmartSearchPayload {
  id: string;
  dealId: string;
  jobId?: string;
  enquiryId?: string;
  personId?: string;
  sourceType?: string;
  sourceId?: number;
  rfqId: number;
  className: SmartSearchClassName;
}
