import { Tag } from 'interfaces';
import { action, observable } from 'mobx';
import { routesApi } from 'routes/api';
import FetchableStore from 'stores/shared/FetchableStore';
import { camelizeKeys } from 'utils/axios';

class JobStrategyStore extends FetchableStore {
  @observable tags: Tag[] = [];

  @action assignOnFetch = (payload: Tag[]): void => {
    this.tags = camelizeKeys(payload);
  };

  @action fetchTags = (includeContexts: string[] = []): void => {
    this.fetch(routesApi.autocompleteAllTags(), { contexts: includeContexts });
  };
}

export default JobStrategyStore;
