import { useErrorBoundaryContext } from '@xometry/ui';
import clsx from 'clsx';
import { Button } from 'components/UI';
import React, { FC, useCallback, useMemo } from 'react';
import { copyToClipboard } from 'utils/clipboard';

import errorImage from './assets/error.svg';
import slackIconImage from './assets/slack.svg';
import styles from './ErrorPage.module.less';

interface Props {
  className?: string;
}

const SLACK_CHANNEL_URL = 'https://xometry.slack.com/archives/C03EK697HQ9';

/** @deprecated import the page from 'src/pages' */
export const ErrorPage: FC<Props> = (props) => {
  const { className } = props;
  const { state, onRefresh } = useErrorBoundaryContext();
  const { error } = state;
  const errorMessage = error?.message || '';

  const handleReloadPage = useCallback(() => {
    window.location.href = `${window.location.href}`;
  }, []);

  const messageData = useMemo(() => {
    try {
      const currentURL = window.location.href;
      const URLParam = encodeURIComponent(currentURL);
      const sentryURL = `https://sentry.xometry.eu/organizations/xometry/issues/?environment=production&project=11&query=is%3Aunresolved+http.url%3A${URLParam}&statsPeriod=24h`;

      return `There is a critical error here ${currentURL}
Please take a look!

For developers:

Error: ${errorMessage}
Sentry URL: ${sentryURL}`;
    } catch (_e: unknown) {
      return null;
    }
  }, [errorMessage]);

  const handleCopy = useCallback(() => {
    if (!messageData) {
      return;
    }

    copyToClipboard(messageData);
  }, [messageData]);

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.content}>
        <div className={styles.titleBlock}>
          <img src={errorImage} alt="Error icon" width={24} height={24} />
          <h1 className={styles.title}>Oops, critical error!</h1>
        </div>
        <div className={styles.mainContent}>
          <div className={clsx(styles.card, styles.titleCard)}>
            <h2 className={styles.title}>
              Step 1<br />
              Copy message
            </h2>
            <div className={styles.cardContent}>
              <div className={styles.textToCopy}>{messageData}</div>
              <Button type="primary" size="large" onClick={handleCopy} className={styles.actionButton}>
                Click to Copy
              </Button>
            </div>
          </div>
          <div className={clsx(styles.card, styles.titleCard)}>
            <h2 className={styles.title}>
              Step 2<br />
              Send to ERP Support channel
            </h2>
            <div className={styles.cardContent}>
              <a href={SLACK_CHANNEL_URL} rel="noopener noreferrer" target="_blank" className={styles.slackInfo}>
                <img width={56} height={56} src={slackIconImage} alt="Slack icon" />
                <p className={styles.subtitle}>#xe_erp-support</p>
              </a>
              <Button
                href={SLACK_CHANNEL_URL}
                rel="noopener noreferrer"
                target="_blank"
                size="large"
                type="ghost"
                className={clsx(styles.actionButton, styles.slackButton)}
              >
                <img width={16} height={16} src={slackIconImage} alt="Slack icon" />
                <p className={styles.subtitle}>Open Slack Channel</p>
              </Button>
            </div>
          </div>
        </div>

        <div className={styles.errorContent}>
          <h3 className={clsx(styles.card, styles.errorInfoTitle)}>
            <span className={styles.subtitle}>What can you do yourself:</span>
            <button className={styles.linkButton} onClick={onRefresh}>
              Try again
            </button>
            <span>or</span>
            <button className={styles.linkButton} onClick={handleReloadPage}>
              Refresh
            </button>
            <span>the page</span>
          </h3>
          {error ? (
            <div className={clsx(styles.card, styles.errorInfoCard)}>
              <pre className={styles.stacktrace}>{[`${errorMessage} at`, error.stack].filter(Boolean).join('\n')}</pre>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
