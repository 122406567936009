import { gql } from 'apollo-boost';

import { CURRENT_REGION } from '../../../config/regions/config';
import { Regions } from '../../../config/regions/types';

const PAYOUT_FRAGMENT_CONFIG: Record<Regions, ReturnType<typeof gql>> = {
  [Regions.EU]: gql`
    fragment payoutFragment on Payout {
      id
      dealId
      number
      providerId
      isRefund
      state
      amount
      paidAt
      sourceType
      sourceId
      methodId
      methodType
      method {
        ... on Payout {
          id
          number
          amount
        }
        ... on BankPayment {
          id
          transactionId
          paymentDetails
          amount
        }
      }
      approvedAt
      approvedBy {
        id
        name
      }
      internalComment
      datevComment
      paymentDetails
    }
  `,
  [Regions.UK]: gql`
    fragment payoutFragment on Payout {
      id
      dealId
      number
      providerId
      isRefund
      state
      amount
      amountMoney {
        amount
        amountCents
        currencyCode
      }
      paidAt
      sourceType
      sourceId
      methodId
      methodType
      method {
        ... on Payout {
          id
          number
          amount
        }
        ... on BankPayment {
          id
          transactionId
          paymentDetails
          amount
        }
      }
      approvedAt
      approvedBy {
        id
        name
      }
      internalComment
      intacctComment
      paymentDetails
    }
  `,
  [Regions.TR]: gql`
    fragment payoutFragment on Payout {
      id
      dealId
      number
      providerId
      isRefund
      state
      amount
      amountMoney {
        amount
        amountCents
        currencyCode
      }
      paidAt
      sourceType
      sourceId
      methodId
      methodType
      method {
        ... on Payout {
          id
          number
          amount
        }
        ... on BankPayment {
          id
          transactionId
          paymentDetails
          amount
        }
      }
      approvedAt
      approvedBy {
        id
        name
      }
      internalComment
      intacctComment
      paymentDetails
    }
  `,
};

export const PAYOUT_FRAGMENT = PAYOUT_FRAGMENT_CONFIG[CURRENT_REGION];
