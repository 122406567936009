import { Money } from '__generated__/types';
import { Moment } from 'moment';

// alias DEO: DatevExportOutcomings

export enum DEType {
  Prepayments = 'prepayments',
  Invoices = 'invoices',
}

export interface DEISearchValues {
  durationFrom: Moment;
  durationTo: Moment;
  numbers: string;
  withRegistered: boolean;
  exportType: DEType;
}

export interface DEIInvoice {
  id: number;
  number: string;
  issuedAt: string;
  creditNote: boolean;
  registeredAt: string;
  paidAt: string;
  dealId: number;
  poId: number;
  poNumber: string;
  provider: string;
  providerId: number;
  amountNet: number;
  amountNetMoney: Money;
  amountBrut: number;
  amountBrutMoney: Money;
  vatAvg: number;
}

export interface DEIItem {
  id: number;
  count: number;
  created: string;
  user: string;
  title: string;
  withCreditNotes: boolean;
  fileId: number;
  csvReport?: {
    id: string;
    name: string;
  };
  csvPrepaymentReport?: {
    id: string;
    name: string;
  };
  collection?: DEIInvoice[];
}
