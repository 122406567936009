import styled from '@emotion/styled';
import { Row } from 'antd';
import { colors } from 'components/UI/colors';

export const Notice = styled.p`
  color: ${colors.gray7};
  margin-left: 6px;
  margin-top: 16px;
`;

export const SelectCountRow = styled(Row)`
  align-items: center;

  .ant-select {
    margin-left: 10px;
  }

  margin-bottom: 10px;
`;
