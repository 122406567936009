import { Spin } from 'antd';
import React, { FC, ReactElement } from 'react';

import { Overlay, ProcessText } from '../styled';

interface Props {
  message: string | undefined;
}

const Process: FC<Props> = ({ message }): ReactElement => (
  <Overlay>
    <Spin size="large" />
    <ProcessText>{message}</ProcessText>
  </Overlay>
);

export default Process;
