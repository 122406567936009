import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { Image, Spin } from 'antd';
import { LinkButton, Notification } from 'components/UI';
import PdfViewer from 'components/UI/PdfViewer';
import { PdfViewerWrapper } from 'components/UI/Preview/Modal/styled';
import React, { FC, useState } from 'react';
import { routesApi } from 'routes/api';
import omsApi, { camelizeKeys } from 'utils/axios';
import useModal from 'utils/hooks/useModal';

import { Modal } from './styled';

interface Props {
  id: string | number;
  isPDF?: boolean;
}

interface Preview {
  name: string;
  bigUrl: string;
}

const UploadedPreview: FC<Props> = ({ id, isPDF }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [previewSrc, setPreviewSrc] = useState<Preview | undefined>(undefined);
  const { modalVisible, openModal, closeModal } = useModal();

  const preview = async (id: string | number) => {
    setIsDownloading(true);

    try {
      const response = await omsApi.get(routesApi.attachedFilePreviewPath(id));

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      setPreviewSrc(camelizeKeys(response.data.payload));
    } catch (e) {
      Notification.Error('Failed to fetch Preview');
    }

    setIsDownloading(false);

    if (isPDF) {
      openModal();
    }
  };

  return (
    <>
      <LinkButton type="link" onClick={() => void preview(id)}>
        {isDownloading ? (
          <Spin size="small" indicator={<LoadingOutlined />} style={{ marginRight: '5px' }} />
        ) : (
          <EyeOutlined
            style={{
              marginRight: '5px',
              paddingTop: '4px',
            }}
          />
        )}
      </LinkButton>

      {!isPDF && previewSrc && (
        <Image
          width={200}
          src={previewSrc.bigUrl}
          preview={{
            visible: !!previewSrc,
            onVisibleChange: () => setPreviewSrc(undefined),
          }}
        />
      )}

      {isPDF && modalVisible && previewSrc && (
        <Modal isFullPage title={previewSrc.name} visible footer={null} onCancel={closeModal} destroyOnClose>
          <PdfViewerWrapper>
            <PdfViewer src={previewSrc.bigUrl} />
          </PdfViewerWrapper>
        </Modal>
      )}
    </>
  );
};

export default UploadedPreview;
