import { WorkspacesRfqOffer } from '__generated__/types';
import { ObjectType } from 'interfaces';
import React from 'react';

import { PROVIDER_STATE_MAPPING } from '../../../../Partner/Header/PartnerMain';
import { colors } from '../../../../UI/colors';
import documentUrl from '../../../../utils/documentUrl';
import { BlackLink } from '../../styled';

export const synProviderDecorator = (rfqOffer: WorkspacesRfqOffer) =>
  rfqOffer.providerId && (
    <>
      <BlackLink
        href={documentUrl(ObjectType.PR, rfqOffer.providerId, rfqOffer.providerId)}
        target="blank"
        rel="noreferrer noopener"
      >
        PR-
        {rfqOffer.providerId}
        <br />
        <b>{rfqOffer.providerName}</b>
      </BlackLink>
      <br />
      <b
        style={{
          color:
            PROVIDER_STATE_MAPPING[rfqOffer.providerState as keyof typeof PROVIDER_STATE_MAPPING]?.color ||
            colors.gray7,
        }}
      >
        {PROVIDER_STATE_MAPPING[rfqOffer.providerState as keyof typeof PROVIDER_STATE_MAPPING]?.label}
      </b>
      <br />
      <span>{rfqOffer.providerCountry}</span>
    </>
  );
