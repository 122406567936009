import { gql } from 'apollo-boost';

export const BANK_PAYMENTS_DESTROY = gql`
  mutation bankPaymentsDestroy($id: ID!) {
    bankPaymentsDestroy(input: { id: $id }) {
      bankPayment {
        id
      }
    }
  }
`;

export const BANK_PAYMENTS_UPDATE = gql`
  mutation bankPaymentsUpdate($id: ID!, $attributes: OmsInputsBankPaymentsUpdateType!) {
    bankPaymentsUpdate(input: { id: $id, attributes: $attributes }) {
      bankPayment {
        id
      }
    }
  }
`;

export const BANK_PAYMENTS_PAYMENT_CREATE = gql`
  mutation bankPaymentsPaymentsCreate($attributes: OmsInputsBankPaymentsPaymentsCreateType!) {
    bankPaymentsPaymentsCreate(input: { attributes: $attributes }) {
      bankPayment {
        id
        payments {
          id
          number
          isRefund
          sourceType
          sourceId
          methodType
          methodId
          amountBrut
        }
      }
    }
  }
`;

export interface IBankPaymentsUpdateOutlingType {
  bankPaymentsUpdateOutlyingType: {
    bankPayment: {
      id: string;
      counterpartyId?: string;
      counterpartyType?: string;
      outlyingTransactionType?: string;
    };
  };
}

export const BANK_PAYMENTS_UPDATE_OUTLYING_TYPE = gql`
  mutation bankPaymentsUpdateOutlyingType($id: ID!, $attributes: OmsInputsBankPaymentsUpdateOutlyingTypeType!) {
    bankPaymentsUpdateOutlyingType(input: { id: $id, attributes: $attributes }) {
      bankPayment {
        id
        counterpartyId
        counterpartyType
        outlyingTransactionType
      }
    }
  }
`;

export interface IBankPaymentsLinkToAccountResponse {
  bankPaymentsLinkToAccount: {
    bankPayment: {
      id: string;
      counterpartyId: number;
      counterpartyType: string;
      counterparty: Record<string, unknown>;
      outlyingTransactionType: string;
    };
  };
}

export const BANK_PAYMENTS_LINK_TO_ACCOUNT = gql`
  mutation bankPaymentsLinkToAccount($id: ID!, $counterpartyId: ID!, $counterpartyType: String!) {
    bankPaymentsLinkToAccount(
      input: { id: $id, counterpartyId: $counterpartyId, counterpartyType: $counterpartyType }
    ) {
      bankPayment {
        id
        counterpartyId
        counterpartyType
        counterparty
        outlyingTransactionType
      }
    }
  }
`;

export interface IBankPaymentsBulkApproveResponse {
  bankPaymentsBulkApprove: {
    bankPayments: {
      id: string;
      approvedAt: string;
      approvedBy: {
        id: number;
        name: string;
      };
    }[];
  };
}

export const BANK_PAYMENTS_BULK_APPROVE = gql`
  mutation bankPaymentsBulkApprove($ids: [ID!]) {
    bankPaymentsBulkApprove(input: { ids: $ids }) {
      bankPayments {
        id
        approvedAt
        approvedBy {
          id
          name
        }
      }
    }
  }
`;

export interface IBankPaymentsBulkRecognizeAsResponse {
  bankPaymentsBulkRecognizeAs: {
    bankPayments: {
      id: string;
      outlyingTransactionType?: string;
      counterpartyId?: string;
      counterpartyType?: string;
      counterparty?: Record<string, unknown>;
    }[];
  };
}

export const BANK_PAYMENTS_BULK_RECOGNIZE_AS = gql`
  mutation bankPaymentsBulkRecognizeAs($ids: [ID!]!, $recognizeAs: String!) {
    bankPaymentsBulkRecognizeAs(input: { ids: $ids, recognizeAs: $recognizeAs }) {
      bankPayments {
        id
        outlyingTransactionType
        counterpartyId
        counterpartyType
        counterparty
      }
    }
  }
`;
