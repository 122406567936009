import { Notification } from 'components/UI';
import { Autocomplete, User } from 'interfaces';
import { map } from 'lodash-es';
import { action, observable } from 'mobx';
import FetchableStore from 'stores/shared/FetchableStore';

class UsersStore extends FetchableStore {
  @observable users: User[] = [];

  @action addUser = (user: User) => {
    this.users.unshift(user);
  };

  @action assignOnFetch = (payload: Autocomplete[]): void => {
    this.users = map(payload, (autocomplete: Autocomplete) => ({
      id: Number(autocomplete.value),
      name: autocomplete.label,
    }));
  };

  @action onFetchError(error: any) {
    super.onFetchError(error);

    Notification.Error('Failed to load Users');
  }
}

export default UsersStore;
