import { SyncOutlined } from '@ant-design/icons';
import { Button, Pagination } from 'antd';
import clsx from 'clsx';
import { BulkAction, ExportColumnsMap, QueryNameKey } from 'components/Workspaces/collections';
import { BulkActions } from 'components/Workspaces/General/shared/GeneralWorkspace/Actions/BulkActions';
import { ExportActions } from 'components/Workspaces/General/shared/GeneralWorkspace/Actions/ExportActions';
import {
  Column,
  IDataItem,
  IPaginationParam,
  ISortParam,
  PermissionsSettings,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import React from 'react';

import s from './TableTop.module.less';

interface ExportActionsProps {
  columns: Column[];
  exportColumns?: ExportColumnsMap;
  header: string;
  queryName: QueryNameKey;
  queryOptions?: Record<string, unknown>;
  preset?: WorkspacePreset;
  sortParams: ISortParam[];
  timezoneOffset: number;
  total: number;
  selectedRecords: IDataItem[];

  onPresetUpdate: (preset: ExistingWorkspacePreset) => void;
}
interface Props extends ExportActionsProps {
  paginationParams: IPaginationParam;
  total: number;
  totalComment?: React.ReactNode;
  bulkActions?: BulkAction<any>[];
  permissionsSettings: PermissionsSettings;
  onPageChange: (page: number, pageSize?: number) => void;
  onRefresh: () => void;
}

export const TableTop = (props: Props) => {
  const {
    paginationParams,
    total,
    totalComment,
    onPageChange,
    onRefresh,
    columns,
    exportColumns,
    header,
    queryName,
    queryOptions,
    preset,
    sortParams,
    timezoneOffset,
    selectedRecords,
    onPresetUpdate,
    bulkActions,
    permissionsSettings,
  } = props;

  return (
    <div className={s.container}>
      <div className={s.column}>
        <BulkActions bulkActions={bulkActions} selectedRecords={selectedRecords} />
        <span className={s.total}>
          Total:&nbsp;
          {total}
          &nbsp;
          {totalComment ?? null}
        </span>
      </div>

      <div className={s.column}>
        <div className={clsx(s.section, s.section_withIcon)}>
          <ExportActions
            columns={columns}
            exportColumns={exportColumns}
            timezoneOffset={timezoneOffset}
            header={header}
            queryName={queryName}
            queryOptions={queryOptions}
            preset={preset}
            selectedRecords={selectedRecords}
            sortParams={sortParams}
            total={total}
            onPresetUpdate={onPresetUpdate}
            permissionsSettings={permissionsSettings}
          />
          <Button
            id="refresh"
            className={s.section__icon}
            title="Refresh"
            icon={<SyncOutlined />}
            type="link"
            onClick={onRefresh}
          />
        </div>
        <div className={s.section}>
          <Pagination
            size="small"
            current={paginationParams.page}
            pageSize={paginationParams.pageSize}
            pageSizeOptions={['50', '100', '500', '1000']}
            onChange={onPageChange}
            total={total}
          />
        </div>
      </div>
    </div>
  );
};
