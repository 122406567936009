/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-underscore-dangle */
import { defaultPaymentTermsDays } from 'collections/billingAccountConstants';
import { dateFormatToBackend } from 'components/utils/formatters';
import { CUSTOM_IO_NAME_ENABLED, XOMETRY_VAT_ENABLED } from 'config/regions/features';
import { OrgType, OutInvoiceFormValues, Position, ServerUpdateInvoice } from 'interfaces';
import { filter } from 'lodash-es';
import moment from 'moment';
import { decamelizeKeys } from 'utils/axios';
import { XMoney } from 'utils/money';

export const mapServerInvoice = (data: OutInvoiceFormValues, { filterDestroyed = false } = {}): ServerUpdateInvoice => {
  let dataPositions: Position[] = Object.values(data.positions);

  if (filterDestroyed) {
    dataPositions = filter(dataPositions, (p) => !p._destroy);
  }

  const positions = dataPositions.map((position) => ({
    accounting_category: position.categoryName,
    name: position.name,
    position: position.position,
    quantity: position.quantity,
    price_per_piece: String(position.price),
    provider_price: position.providerPrice,
    vat_rate: position.vat,
    ref_type: position.refType,
    ref_id: position.refId,
    id: position.id,
    _destroy: position._destroy,
  }));

  const defaultResult = {
    is_storno: data.isStorno,
    credit_note: data.creditNote,
    credit_note_reason: data.creditNoteReason,
    credit_note_reason_claim_id: data.creditNoteReasonClaimId,
    issued_at: dateFormatToBackend(data.issuedAt),
    paid_at: data.paidAt && dateFormatToBackend(data.paidAt),

    person_name: data.billing.contactPerson,
    person_phone: data.billing.phone,
    person_email: data.billing.email,

    sachkonto_number: data.sachkontoNumber,

    is_published_in_ca: data.isPublishedInCA,
    send_reminders: data.sendReminders,
    email_invoice_on_publish: data.emailInvoiceOnPublish,

    comment: data.comment,
    intacct_comment: data.intacctComment,
    datev_comment: data.datevComment,

    positions_delivery_date: data.positionsDeliveryDate && dateFormatToBackend(data.positionsDeliveryDate),
    ref_positions_sources: data.refPositionsSources,

    purchase_order_number: data.purchaseOrderNumber === '' ? null : data.purchaseOrderNumber,
    purchase_order_date: data.purchaseOrderDate && dateFormatToBackend(data.purchaseOrderDate),

    invoice_ready: {
      by: data.invoiceReady.by,
      at: data.invoiceReady.at,
    },

    regenerate: data.regenerate,
    number: data.number,

    invoice_positions_attributes: positions,

    payment_terms_days: data.paymentDue,
    payment_terms_date: data.paymentDueDate && dateFormatToBackend(data.paymentDueDate),

    payment_relations: decamelizeKeys(data.paymentRelations),

    to_be_paid_amount_cents: data.toBePaidInDifferentCurrency
      ? XMoney(data.toBePaidAmount ?? 0, data.toBePaidCurrency).toIMoney().amountCents
      : null,
    to_be_paid_amount_currency: data.toBePaidInDifferentCurrency ? data.toBePaidCurrency : null,
    to_be_paid_in_different_currency: data.toBePaidInDifferentCurrency,
  };

  const resultWithVat = {
    ...defaultResult,
    datev_vat_code: data.datevVatCode,
    xometry_vat_number: decamelizeKeys(data.xometryVatNumber),
  };

  const result: any = XOMETRY_VAT_ENABLED ? resultWithVat : defaultResult;

  if (data.discountDueDays) {
    result.payment_terms_discount_days = data.discountDueDays;
  }

  if (data.discountDueDate) {
    result.payment_terms_discount_date = dateFormatToBackend(data.discountDueDate);
  }

  if (data.discountPercentage) {
    result.payment_terms_discount_percentage = data.discountPercentage;
  }

  if (data.refInvoiceId) {
    result.ref_invoice_id = data.refInvoiceId;
  }

  if (data.invoiceChecked) {
    result.invoice_checked = {
      by: data.invoiceChecked.by,
      at: data.invoiceChecked.at,
    };
  }

  if (data.invoiceNeedFix) {
    result.invoice_need_fix = {
      by: data.invoiceNeedFix.by,
      at: data.invoiceNeedFix.at,
    };
  }

  return result;
};

export const initialValuesOutInvoice = (): OutInvoiceFormValues => {
  const defaultValues = {
    creditNote: false,
    creditNoteReason: null,
    creditNoteReasonClaimId: null,
    isStorno: false,

    addDiscount: false,
    paymentDue: defaultPaymentTermsDays,
    paymentDueDate: moment().add(defaultPaymentTermsDays, 'days'),
    discountPercentage: undefined,
    paidAt: undefined,

    issuedAt: moment(),

    refPositionsSources: '',
    positionsDeliveryDate: undefined,

    invoiceReady: {
      by: '',
      at: '',
      checked: false,
    },
    invoiceChecked: {
      by: '',
      at: '',
      checked: false,
    },
    invoiceNeedFix: {
      by: '',
      at: '',
      checked: false,
    },

    positions: {},

    refInvoiceId: undefined,

    comment: '',
    datevComment: '',
    intacctComment: '',

    purchaseOrderNumber: '',
    purchaseOrderDate: undefined,

    isPublishedInCA: true,

    emailInvoiceOnPublish: true,

    billing: {
      phone: '',
      contactPerson: '',
      address: '',
      zip: '',
      city: '',
      country: '',
      email: '',
      vatId: undefined,
      taxId: undefined,
      name: '',
      intacctId: undefined,
      datevId: undefined,
      type: 'customer',
      accountType: OrgType.Private,
      vatRegistered: true,
      vatRegistrationCountry: null,
    },

    toBePaidInDifferentCurrency: false,
  };

  const valuesWithVat = {
    ...defaultValues,
    overrideAccountingDataDisabled: false,
  };

  return XOMETRY_VAT_ENABLED ? valuesWithVat : defaultValues;
};

export const oiName = (id: number | string, number?: number | string, creditNote?: boolean) => {
  if (CUSTOM_IO_NAME_ENABLED) {
    if (number) {
      return creditNote ? `Return ${number}` : `${number}`;
    }

    return creditNote ? `Return OI #${id}` : `OI #${id}`;
  }

  if (number) {
    return creditNote ? `G-${number}` : `R-${number}`;
  }

  return creditNote ? `OCN #${id}` : `OI #${id}`;
};
