import { Notification } from 'components/UI';
import { useCallback, useState } from 'react';
import { requestBuilder } from 'utils/axios';

import { handleDownloadError } from './shared/downloadUtils';

export default () => async (url: string, contentType?: string) => {
  const request = requestBuilder({ responseType: 'blob' });

  try {
    const response = await request.get(url);
    const file = new Blob([response.data], {
      type: String(contentType || response.headers['content-type']),
    });
    const fileUrl = URL.createObjectURL(file);
    window.open(fileUrl);
  } catch (e) {
    Notification.Error("Can't open file :(");
  }
};

interface FileOpenerOptions {
  print?: boolean;
  contentType?: string;
}

export const openFile = (url: string, options?: FileOpenerOptions) => {
  return requestBuilder({ responseType: 'blob' })
    .get(url)
    .then((response) => {
      const print = options?.print;
      const contentType = options?.contentType;
      const responseContentType = String(response.headers['content-type']);
      const fileData = new Blob([response.data], {
        type: contentType || responseContentType,
      });
      const fileUrl = URL.createObjectURL(fileData);
      const fileWindow = window.open(fileUrl, '_blank');

      if (print && fileWindow) {
        fileWindow.onload = function () {
          fileWindow.print();
        };
      }
    })
    .catch(handleDownloadError);
};

export const useFileOpener = () => {
  const [loading, setLoading] = useState(false);

  const open = useCallback(
    (url: string, options?: FileOpenerOptions) => {
      if (loading) return;

      setLoading(true);

      return openFile(url, options).finally(() => setLoading(false));
    },
    [loading],
  );

  return { open, loading };
};
