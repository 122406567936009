/* eslint-disable camelcase */

import { CurrencyEnum, OutcomingInvoicesCreditNoteReasonEnumType, PaymentTermsTypeEnum } from '__generated__/types';
import { IMoney } from '@xometry/ui/dist/utils/money';
import { ICachedXometryVatNumber, IFormXometryVatNumber } from 'interfaces/shared';
import { Moment } from 'moment';

import { IAttachedFile } from './AttachedFilesStore';

export interface IPaymentRelation {
  paymentId: number;
  totalAmountBrutto: number;
}

export interface ServerPosition {
  accounting_category: string;
  name: string;
  position: number;
  quantity: number;
  price_per_piece: number;
  price_per_piece_money: {
    amount: string;
    amount_cents: string;
    currency_code: CurrencyEnum;
  };
  provider_price: number;
  provider_price_money: {
    amount: string;
    amount_cents: string;
    currency_code: CurrencyEnum;
  };
  price_per_piece_in_provider_price_currency_money: IMoney;
  vat_rate: number;
  ref_type?: string;
  ref_id?: number;
  id?: number;
  files?: IAttachedFile[];
  part_id?: number;
  tags?: string[];
  production_remark?: string;
  ref_source?: string;
  delivery_date?: string;
  finish?: string;
  claims?: number[];
  material?: string;
}

interface ServerInvoiceCommon {
  credit_note: boolean;
  credit_note_reason: OutcomingInvoicesCreditNoteReasonEnumType | null;
  credit_note_reason_claim_id: number | null;
  is_intercompany: boolean;
  is_storno: boolean;
  issued_at: string;
  number?: string;

  organization_type?: string;
  organization_name: string;
  organization_vat?: string;
  organization_datev_konto?: number;
  organization_intacct_id?: number;

  person_name: string;
  person_phone?: string;
  person_email?: string;

  is_published_in_ca?: boolean;
  send_reminders?: boolean;
  sachkonto_number?: number;
  datev_vat_code?: number;
  email_invoice_on_publish?: boolean;

  positions_delivery_date?: string;
  ref_positions_sources?: string;

  ref_invoice_id?: number;

  comment: string;
  datev_comment: string;
  intacct_comment: string;
  paid_at?: string;

  purchase_order_number: string | null | undefined;
  purchase_order_date?: string;

  invoice_ready: {
    by: string;
    at: string;
  };

  invoice_checked?: {
    by: string;
    at: string;
  };

  invoice_need_fix?: {
    by: string;
    at: string;
  };

  billing: {
    id?: number;
    datev_id?: string;
    intacct_id?: string;
    tax_id?: string;
    vat_id?: string;
    vat_validation_status?: string;
    type?: string;
    account_type?: string;
    payment_terms_days?: string;
    address?: string;
    zip?: string;
    city?: string;
    country?: string;
    name?: string;
  };

  payment_terms_days?: number;
  payment_terms_date?: string;
  payment_terms_type?: PaymentTermsTypeEnum;

  payment_terms_discount_days?: number;
  payment_terms_discount_percentage?: number;
  payment_terms_discount_date?: string;

  xometry_vat_number?: {
    id: string;
    country: string;
    number: string;
    validation?: string;
    reverse_charge: boolean;
    reverse_charge_remark?: string;
  };
}

export enum InvoiceState {
  Canceled = 'canceled',
  Draft = 'draft',
  Published = 'published',
  Rejected = 'rejected',
  SentToMikrojump = 'sent_to_mikrojump',
}

export interface ServerBillingInfo {
  country: string;
  city: string;
  zip: string;
  address: string;

  phone: string;
  contact_name: string;
  email: string;

  organization_vat: string;
  organization_name: string;
  organization_type: string;
}

export interface ServerGetInvoice extends ServerInvoiceCommon {
  invoice_positions: ServerPosition[];
  state: InvoiceState;
  last_invoice?: {
    name: string;
    issued_at: string;
    next_number: string;
  };
  currency: string;
  id: number;
  total_amount_brutto: string;
  total_amount_brutto_money?: {
    amount: string;
    currency_code: string;
    amount_cents: string;
  };
  total_amount_netto: string;
  total_amount_netto_money?: {
    amount: string;
    currency_code: string;
    amount_cents: string;
  };
  vat_avg: string;
  vat_rate: number;
  remark?: string | null;
  override_accounting_data_disabled?: boolean;
  to_be_paid_amount_money?: {
    amount: string;
    currency_code: CurrencyEnum;
    amount_cents: string;
  };
  to_be_paid_in_different_currency?: boolean;
  updated_at: string;
}

export interface ServerUpdateInvoice extends ServerInvoiceCommon {
  id?: number;
  regenerate?: boolean;
  invoice_positions_attributes: ServerPosition[];
}

export interface OutInvoiceFormBillingValues {
  id?: number;
  address?: string;
  phone?: string;
  contactPerson: string;
  datevId?: string;
  intacctId?: string;
  country?: string;
  zip?: string;
  city?: string;
  email?: string;
  vatId?: string;
  taxId?: string;
  vatValidationStatus?: string;
  internalComment?: string;
  name?: string;
  accountType?: string;
  paymentTermsDays?: number;
  paymentTermsType?: PaymentTermsTypeEnum;
  type?: string;
  vatRegistered?: boolean;
  vatRegistrationCountry?: string | null;
}

export interface IPaymentRelation {
  paymentId: number;
  totalAmountBrutto: number;
}

export interface OutInvoiceFormValues {
  creditNote: boolean;
  creditNoteReason: OutcomingInvoicesCreditNoteReasonEnumType | null;
  creditNoteReasonClaimId?: number | null;
  isStorno: boolean;

  regenerate?: boolean;
  number?: string;

  addDiscount: boolean;
  discountDueDays?: number;
  discountDueDate?: Moment;
  discountPercentage?: number;

  paymentDueDate?: Moment;
  paymentDue?: number;
  paymentTermsType?: PaymentTermsTypeEnum;
  paymentRelations?: IPaymentRelation[];

  sachkontoNumber?: string;
  datevVatCode?: string;
  xometryVatNumber?: IFormXometryVatNumber;
  xometryVatNumberId?: IFormXometryVatNumber['id'];
  overrideAccountingDataDisabled?: boolean;

  issuedAt: Moment;
  paidAt?: Moment;

  refInvoiceId?: number;

  comment: string;
  datevComment: string;
  intacctComment: string;

  purchaseOrderNumber: string | null | undefined;
  purchaseOrderDate?: Moment;

  positionsDeliveryDate?: Moment;
  refPositionsSources?: string;

  positions: {
    [key: string]: any;
  };

  invoiceReady: {
    by: string;
    at: string;
    checked: boolean;
  };

  invoiceChecked: {
    by: string;
    at: string;
    checked: boolean;
  };

  invoiceNeedFix: {
    by: string;
    at: string;
    checked: boolean;
  };

  isPublishedInCA?: boolean;
  sendReminders?: boolean;
  emailInvoiceOnPublish?: boolean;

  billing: OutInvoiceFormBillingValues;
  toBePaidAmount?: string;
  toBePaidCurrency?: CurrencyEnum;
  toBePaidInDifferentCurrency?: boolean;
}

export interface OutInvoice {
  id: number;
  issuedAt: string;
  number?: string;
  paidAt?: string;
  totalAmountNetto: number;
  totalAmountBrutto: number;
  vatAvg: string;
  xometryVatNumber?: ICachedXometryVatNumber;
}
