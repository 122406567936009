import { AxiosResponse } from 'axios';
import { action, observable } from 'mobx';
import { RootStore } from 'stores/RootStore';
import { omsGet } from 'utils/axios';

interface FetchStatus {
  isFetched: boolean;
  isFetching: boolean;
  fetchError: boolean;
}

class FetchableStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fetchStatus = {
    isFetched: false,
    isFetching: false,
    fetchError: false,
  };

  @action setFetchStatus = (status: FetchStatus): void => {
    this.fetchStatus = status;
  };

  @action fetch = async (url: string, params?: Record<string, unknown>): Promise<AxiosResponse> => {
    this.fetchStatus.isFetched = false;
    this.fetchStatus.isFetching = true;

    try {
      const response = (await omsGet(url, params)) as AxiosResponse<{ payload: unknown }>;

      this.onFetchSuccess(response);

      return response;
    } catch (err: unknown) {
      this.onFetchError(err);

      return err as AxiosResponse<unknown>;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @action onFetchSuccess = (response: AxiosResponse<{ payload: unknown }>): void => {
    const { payload } = response.data;

    this.assignOnFetch(payload);
    this.fetchStatus.isFetched = true;
    this.fetchStatus.isFetching = false;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @action onFetchError(error: any) {
    this.fetchStatus.fetchError = true;
    this.fetchStatus.isFetching = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  @action assignOnFetch = (payload: any): void => {
    // eslint-disable-next-line no-console
    console.log('payload', payload);
    // eslint-disable-next-line no-alert
    alert('!!!OVERRIDE assignOnFetch for FetchableStore!!!');
  };
}

export default FetchableStore;
