import { gql } from 'apollo-boost';
import { DEAL_FRAGMENT } from 'utils/graphql/fragments';

export const DEALS_UPDATE = gql`
  mutation dealsUpdate($id: ID!, $attributes: OmsInputsDealsDealType!) {
    dealsUpdate(input: { id: $id, attributes: $attributes }) {
      deal {
        ...dealFragment
      }
    }
  }
  ${DEAL_FRAGMENT}
`;

export const DEALS_UPDATE_PM = gql`
  mutation dealsUpdateProductionManager($id: ID!, $attributes: OmsInputsDealUpdateProductionManager!) {
    dealsUpdateProductionManager(input: { id: $id, attributes: $attributes }) {
      deal {
        ...dealFragment
      }
    }
  }
  ${DEAL_FRAGMENT}
`;

export const CRM_FORCE_CREATE_DEAL = gql`
  mutation crmForceCreateDeal($id: ID!) {
    crmForceCreateDeal(input: { id: $id }) {
      deal {
        ...dealFragment
      }
    }
  }
  ${DEAL_FRAGMENT}
`;
