import { WorkspacesRfqOffer } from '__generated__/types';
import { shortName } from 'components/utils/formatters';
import React from 'react';
import { routes } from 'routes';

export const idDecorator = (rfqOffer: WorkspacesRfqOffer) => (
  <a
    href={routes.rfqOfferPath(rfqOffer.dealId, rfqOffer.rfqId, rfqOffer.id)}
    target="_blank"
    rel="noreferrer noopener"
    title={String(rfqOffer.id)}
  >
    <b>{shortName(String(rfqOffer.id), 5)}</b>
  </a>
);
