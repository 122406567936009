import { gql } from 'apollo-boost';

import { LOT_FRAGMENT, PART_FRAGMENT } from './base';

export const QUALITY_CONTROL_POSITION_FRAGMENT = gql`
  fragment qualityControlsPositionFragment on QualityControlsPositionType {
    id

    quantity
    customerPrice
    providerPrice

    partDetails
    name
    material
    postProcessing

    partId
    part {
      ...partFragment
    }

    lotId
    lot {
      ...lotFragment
    }

    measurementsCount

    temperature
    humidity
    atmPressure

    qtyChecked
    qtyAccepted
    qtyHold
    qtyRejected

    techVerdict
    techVerdictComment
    techVerdictPublished

    businessVerdict
    businessVerdictComment
    businessVerdictPublished

    officialVerdictComment

    qcPackaging
    qcPackagingComment

    qcDocuments
    qcDocumentsComment

    qcConservation
    qcConservationComment

    qcQuantity
    qcQuantityObserved
    qcQuantityComment

    qcCompliance
    qcComplianceComment

    qcMarking
    qcMarkingComment

    qcFinish
    qcFinishComment

    qcCoating
    qcCoatingComment

    qcOther
    qcOtherComment

    qcCreatedBy {
      by
      at
    }

    positionUpdatedBy {
      by
      at
    }

    techVerdictUpdatedBy {
      by
      at
    }

    businessVerdictUpdatedBy {
      by
      at
    }

    providerOrderId
    providerOrder {
      id
      dealId
      number
      provider {
        id
      }
    }

    createdAt
  }
  ${PART_FRAGMENT}
  ${LOT_FRAGMENT}
`;

export const QUALITY_CONTROL_FRAGMENT = gql`
  fragment qualityControlFragment on QualityControl {
    id
    dealId
    number
    kind
    state
    reportComment
    internalComment
    createdAt
    positions {
      id
      providerOrder {
        id
        dealId
        conformityCheck
        number
        provider {
          id
          name
        }
      }
    }
  }
`;

export const QC_MEASUREMENT_MARK_FRAGMENT = gql`
  fragment measurementMarkFragment on QualityControlsMeasurementMarkType {
    key
    canvasId
    position
    v
    fill
    active
    label {
      x
      y
      r
      offsetX
      offsetY
    }
    point {
      x
      y
      r
      offsetX
      offsetY
    }
  }
`;

export const QUALITY_CONTROL_MEASUREMENT_FRAGMENT = gql`
  fragment qualityControlsMeasurementFragment on QualityControlsMeasurementType {
    id
    createdAt
    partId
    qcCanvasId
    qcPositionId
    mark {
      ...measurementMarkFragment
    }
    measurements
    position
    name
    kind
    toleranceClass
    nominalValue
    minValue
    maxValue
    minMaxStr
    units
    toolset
    verdict
    comment
  }
  ${QC_MEASUREMENT_MARK_FRAGMENT}
`;
