import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { MonitoringInstance } from '@xometry/ui';
import { createBrowserHistory, History } from 'history';
import { createContext, useContext } from 'react';
import AccountingExportIncomingsStore from 'stores/Accounting/ExportIncomingsStore';
import AccountingExportOutcomingsStore from 'stores/Accounting/ExportOutcomingsStore';
import DealProviderOrderStore from 'stores/Deal/DealProviderOrderStore';
import DealStore from 'stores/Deal/DealStore';
import PaymentStore from 'stores/Deal/Payment/PaymentStore';
import JobOfferStore from 'stores/Job/JobOfferStore';
import JobStore from 'stores/Job/JobStore';
import OrgStore from 'stores/Org/OrgStore';
import PartsStore from 'stores/Parts/PartsStore';
import PersonStore from 'stores/Person/PersonStore';
import ProvidersStore from 'stores/ProvidersStore';
import UserStore from 'stores/UserStore';

import UsersPipedriveStore from './Autocompletes/UsersPipedriveStore';
import UsersStore from './Autocompletes/UsersStore';
import BankPaymentStore from './BankPayment/BankPaymentStore';
import ClaimStore from './Claim/ClaimStore';
import { DealSidebarStore } from './Deal/DealSidebarStore';
import EnquiriesStore from './Deal/EnquiriesStore';
import DealFilesStore from './Deal/FilesStore';
import IncomingInvoicesStore from './Deal/IncomingInvoicesStore';
import OrderConfirmationStore from './Deal/OrderConfirmationStore';
import DealOutInvoiceCommentStore from './Deal/OutInvoice/DealOutInvoiceCommentStore';
import DealOutInvoiceEditStore from './Deal/OutInvoice/DealOutInvoiceEditStore';
import DealOutInvoicePositionsSourcesStore from './Deal/OutInvoice/DealOutInvoicePositionsSourcesStore';
import DealOutInvoicePositionsStore from './Deal/OutInvoice/DealOutInvoicePositionsStore';
import DealOutInvoicesStore from './Deal/OutInvoice/DealOutInvoicesStore';
import DealOutInvoiceStore from './Deal/OutInvoice/DealOutInvoiceStore';
import DealReferencePositionsStore from './Deal/OutInvoice/DealReferencePositionsStore';
import DealSourcedPositionsStore from './Deal/OutInvoice/DealSourcedPositionsStore';
import DealOutInvoicePublishedStore from './Deal/OutInvoice/Published/DealOutInvoicePublishedStore';
import { ShippingStore } from './Deal/ShippingStore';
import StorageStore from './Deal/StorageStore';
import { ErpPermissionsStore } from './ErpPermissionsStore';
import EventsStore from './EventsStore';
import JobStrategyStore from './Job/JobStrategyStore';
import MailerStore from './Mailer/MailerStore';
import PartsViewStore from './PartsDataView/PartsDataViewStore';
import SalesforceStore from './SalesforceStore';
import ActivityRecordStore from './shared/ActivityRecordStore';
import AttachedFilesStore from './shared/AttachedFilesStore';
import PageLoaderStore from './shared/PageLoaderStore';
import PreviewsStore from './shared/PreviewsStore';
import UIStore from './shared/UIStore';
import SideOverlayStore from './SideOverlay/SideOverlayStore';
import SmartSearchStore from './SmartSearchStore';
import TagStore from './TagStore';
import TemplatesManagerModalStore from './TemplatesManagerModalStore';
import TemplatesModalStore from './TemplatesModalStore';

export class RootStore {
  monitoring: MonitoringInstance;
  protected _gql: ApolloClient<NormalizedCacheObject> | undefined;

  history: History;

  smartSearchStore: SmartSearchStore;

  accountingExportOutcomingsStore: AccountingExportOutcomingsStore;

  accountingExportIncomingsStore: AccountingExportIncomingsStore;

  userStore: UserStore;

  uiStore: UIStore;

  partsStore: PartsStore;

  dealStore: DealStore;

  dealFilesStore: DealFilesStore;

  previewsStore: PreviewsStore;

  pageLoaderStore: PageLoaderStore;

  personStore: PersonStore;

  orgStore: OrgStore;

  claimStore: ClaimStore;

  dealOutInvoiceStore: DealOutInvoiceStore;

  dealOutInvoicesStore: DealOutInvoicesStore;

  dealOutInvoicePositionsStore: DealOutInvoicePositionsStore;

  dealOutInvoicePositionsSourcesStore: DealOutInvoicePositionsSourcesStore;

  dealSourcedPositionsStore: DealSourcedPositionsStore;

  dealReferencePositionsStore: DealReferencePositionsStore;

  dealOutInvoiceEditStore: DealOutInvoiceEditStore;

  dealOutInvoiceCommentStore: DealOutInvoiceCommentStore;

  dealOutInvoicePublishedStore: DealOutInvoicePublishedStore;

  incomingInvoicesStore: IncomingInvoicesStore;

  partsViewStore: PartsViewStore;

  eventsStore: EventsStore;

  dealSidebarStore: DealSidebarStore;

  dealShippingStore: ShippingStore;

  enquiriesStore: EnquiriesStore;

  orderConfirmationStore: OrderConfirmationStore;

  paymentStore: PaymentStore;

  dealProviderOrderStore: DealProviderOrderStore;

  jobStore: JobStore;

  jobOfferStore: JobOfferStore;

  jobStrategyStore: JobStrategyStore;

  storageStore: StorageStore;

  mailerStore: MailerStore;

  salesforceStore: SalesforceStore;

  providersStore: ProvidersStore;

  usersPipedriveStore: UsersPipedriveStore;

  usersStore: UsersStore;

  attachedFilesStore: AttachedFilesStore;

  activityRecordStore: ActivityRecordStore;

  tagStore: TagStore;

  bankPaymentStore: BankPaymentStore;

  sideOverlayStore: SideOverlayStore;

  templatesModalStore: TemplatesModalStore;

  templatesManagerModalStore: TemplatesManagerModalStore;

  erpPermissionsStore: ErpPermissionsStore;

  constructor(params: { monitoring: MonitoringInstance; gql?: ApolloClient<NormalizedCacheObject> }) {
    const { monitoring, gql } = params;
    this.monitoring = monitoring;
    this._gql = gql;

    this.smartSearchStore = new SmartSearchStore(this);
    this.userStore = new UserStore();
    this.eventsStore = new EventsStore(this);
    this.uiStore = new UIStore(this);
    this.tagStore = new TagStore(this);
    this.partsStore = new PartsStore(this);
    this.dealProviderOrderStore = new DealProviderOrderStore(this);
    this.jobStore = new JobStore(this);
    this.jobOfferStore = new JobOfferStore(this);
    this.jobStrategyStore = new JobStrategyStore(this);
    this.salesforceStore = new SalesforceStore(this);
    this.providersStore = new ProvidersStore(this);

    this.mailerStore = new MailerStore(this);
    this.previewsStore = new PreviewsStore(this);
    this.attachedFilesStore = new AttachedFilesStore(this);
    this.activityRecordStore = new ActivityRecordStore();

    this.enquiriesStore = new EnquiriesStore(this);

    this.dealStore = new DealStore(this);
    this.dealFilesStore = new DealFilesStore(this);
    this.personStore = new PersonStore(this);
    this.orgStore = new OrgStore(this);
    this.dealOutInvoiceStore = new DealOutInvoiceStore(this);
    this.dealOutInvoicesStore = new DealOutInvoicesStore(this);
    this.dealOutInvoicePositionsStore = new DealOutInvoicePositionsStore(this);

    this.dealOutInvoicePositionsSourcesStore = new DealOutInvoicePositionsSourcesStore(this);
    this.dealSourcedPositionsStore = new DealSourcedPositionsStore(this);
    this.dealReferencePositionsStore = new DealReferencePositionsStore(this);

    this.incomingInvoicesStore = new IncomingInvoicesStore(this);

    this.dealOutInvoiceEditStore = new DealOutInvoiceEditStore(this);
    this.dealOutInvoiceCommentStore = new DealOutInvoiceCommentStore(this);
    this.dealOutInvoicePublishedStore = new DealOutInvoicePublishedStore(this);
    this.dealSidebarStore = new DealSidebarStore(this);
    this.dealShippingStore = new ShippingStore(this);
    this.orderConfirmationStore = new OrderConfirmationStore(this);
    this.pageLoaderStore = new PageLoaderStore(this);
    this.paymentStore = new PaymentStore(this);
    this.usersPipedriveStore = new UsersPipedriveStore(this);
    this.usersStore = new UsersStore(this);
    this.storageStore = new StorageStore(this);
    this.partsViewStore = new PartsViewStore();

    this.accountingExportOutcomingsStore = new AccountingExportOutcomingsStore(this);
    this.accountingExportIncomingsStore = new AccountingExportIncomingsStore(this);

    this.claimStore = new ClaimStore(this);

    this.bankPaymentStore = new BankPaymentStore(this);

    this.sideOverlayStore = new SideOverlayStore(this);

    this.templatesModalStore = new TemplatesModalStore();

    this.templatesManagerModalStore = new TemplatesManagerModalStore();

    this.erpPermissionsStore = new ErpPermissionsStore(this);

    this.history = createBrowserHistory();
  }

  public get gql(): ApolloClient<object> {
    if (!this._gql) {
      throw new Error('RootStore: No GQL Client!');
    }

    return this._gql;
  }

  setGraphlQLClient = (client: ApolloClient<NormalizedCacheObject>) => {
    this._gql = client;
  };
}

export const StoreContext = createContext<RootStore>(undefined as unknown as RootStore);

export const useStore: () => RootStore = () => useContext(StoreContext);
