import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import { bc } from '../colors';
import { sizes } from '../variables';

export const RowFlex = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ColFullPage = styled(Col)`
  min-height: calc(100vh - ${sizes.headerHeight});
`;

export const Container = styled.div`
  padding-top: ${sizes.headerHeight};
`;

export const HL = styled.div`
  border-top: 1px solid ${bc.border};
  height: 1px;
  width: 100%;
`;

export const VL = styled.div`
  border-left: 1px solid ${bc.border};
  height: auto;
  width: 1px;
`;

export const CommaSeparated = styled.span`
  display: content;
  a:not(:last-child):after {
    content: ', ';
  }
`;

export const Attention = styled.div`
  background-color: #faf0f0;
  padding: 10px;

  .colored {
    font-weight: 600;
    color: ${bc.errorDark};
  }
`;
