import { gql } from 'apollo-boost';

export const PART_STATE_CORRECTION_FRAGMENT = gql`
  fragment partStateCorrectionFragment on PartStateCorrection {
    id
    partId
    publishedById

    state
    value
    comment

    createdAt
    updatedAt
  }
`;
