import { Row, Tag } from 'antd';
import { dateFormat } from 'components/utils/formatters';
import { IProvider } from 'interfaces/graphql';
import React, { FC } from 'react';

import { IdAndStatus, MainInfo, PartnerHeaderDiv, PartnerId, PartnerTag, RowPipeline } from './styled';

interface IProps {
  provider: IProvider;
}

export const PROVIDER_STATE_MAPPING = {
  found_by_us: { label: 'Found By Us', color: '#1979C3' },
  enrolled_via_website: { label: 'Enrolled Via Website', color: '#2690DD' },
  probation: { label: 'Probation', color: '#73BE7F' },
  test_part: { label: 'Test part', color: '#D46B08' },
  trusted: { label: 'Trusted', color: '#219653' },
  blacklist: { label: 'Blacklist', color: '#9E3C3C' },
  deleted: { label: 'Deleted', color: '#9E3C3C' },
};

const PartnerMain: FC<IProps> = ({ provider }) => (
  <PartnerHeaderDiv>
    <MainInfo>
      <Row>
        <IdAndStatus>
          <PartnerId title={`PR-${provider.id}`}>
            PR-
            {provider.id}
          </PartnerId>

          {PROVIDER_STATE_MAPPING[provider.state] && (
            <PartnerTag color={PROVIDER_STATE_MAPPING[provider.state].color}>
              {PROVIDER_STATE_MAPPING[provider.state].label}
            </PartnerTag>
          )}
        </IdAndStatus>
      </Row>

      <RowPipeline>
        Registred At:&nbsp;
        {dateFormat(provider.createdAt)}
      </RowPipeline>

      <RowPipeline>
        Last PA visit:&nbsp;
        {dateFormat(provider.lastVisitAt)}
      </RowPipeline>
      {provider.publicHoliday ? <Tag className="holiday-tag public-holiday-tag">Public holidays</Tag> : null}
      {provider.productionHoliday ? (
        <Tag className="holiday-tag production-holiday-tag">Production holidays</Tag>
      ) : null}
    </MainInfo>
  </PartnerHeaderDiv>
);

export default PartnerMain;
