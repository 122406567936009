import { DeleteOutlined, DownloadOutlined, LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Popconfirm, Spin, Tooltip } from 'antd';
import { Button } from 'components/UI';
import { bc } from 'components/UI/colors';
import { RightActionsBlock } from 'components/UI/Link/styled';
import { EllipsisMiddle } from 'components/UI/Typography/EllipsisMiddle';
import { last } from 'lodash-es';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { routesApi } from 'routes/api';
import useDownload from 'utils/hooks/useDownload';

import { FileWrap } from './styled';
import s from './Upload.module.less';
import UploadedPreview from './UploadedPreview';

interface Props {
  name: string;
  createdAt?: string;
  disabled?: boolean;
  isUploading?: boolean;
  readonly?: boolean;
  id?: string | number;
  onDelete?: () => void;
}

const UploadedFile: FC<Props> = ({ name, disabled, id, onDelete, readonly, isUploading, createdAt }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const downloader = useDownload();

  const download = async (id: string | number, name: string) => {
    setIsDownloading(true);
    await downloader(routesApi.attachedFileDownloadPath(id), name);
    setIsDownloading(false);
  };

  const extension = last(name.split('.'));
  const isPreviewableImg = id && /png|jp(e)?g/i.test(String(extension));
  const isPreviewablePDF = id && /pdf/i.test(String(extension));

  const date = createdAt ? moment(createdAt).format('DD.MM.YY') : null;

  return (
    <FileWrap className="file-item">
      {!isUploading && !id && <PaperClipOutlined style={{ marginRight: '5px' }} />}

      {isUploading && <Spin size="small" indicator={<LoadingOutlined />} style={{ marginRight: '5px' }} />}

      {id && (
        <Button
          type="link"
          size="small"
          className="button"
          loading={isDownloading}
          onClick={() => {
            void download(id, name);
          }}
          icon={<DownloadOutlined />}
        >
          <Tooltip title={name}>
            <EllipsisMiddle suffixCount={5} className="name">
              {name}
            </EllipsisMiddle>
          </Tooltip>
        </Button>
      )}

      {!id && (
        <Tooltip title={name}>
          <EllipsisMiddle suffixCount={5}>{name}</EllipsisMiddle>
        </Tooltip>
      )}

      <RightActionsBlock>
        {date && <span className={s.createdAt}>{date}</span>}
        {id && isPreviewableImg && <UploadedPreview id={id} />}
        {id && isPreviewablePDF && <UploadedPreview id={id} isPDF />}
        {!readonly && onDelete && (
          <Popconfirm
            title="Are you sure you want to delete this file?"
            onConfirm={onDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" htmlType="button" style={{ padding: 0 }} disabled={disabled}>
              <DeleteOutlined style={{ color: bc.errorDark }} />
            </Button>
          </Popconfirm>
        )}
      </RightActionsBlock>
    </FileWrap>
  );
};

export default UploadedFile;
