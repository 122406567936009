import styled from '@emotion/styled';
import { Tree } from 'antd';
import { bc, colors } from 'components/UI/variables';

interface ColumnsContainerProps {
  span?: number;
}

export const ColumnsContainer = styled.div<ColumnsContainerProps>`
  display: flex;
  background-color: ${colors.gray3};
  flex-direction: column;
  padding: 10px 8px 2px 10px;
  width: ${({ span }) => (span != null ? 'auto' : '280')}px;
  max-height: 70vh;
  overflow: auto;

  h3 {
    font-size: 1.1rem;
    font-weight: 600;
  }
`;

interface ColumnsTreeProps {
  span?: number;
  total?: number;
}

export const ColumnsTree = styled(Tree)<ColumnsTreeProps>`
  background-color: ${colors.gray3};

  .ant-tree-treenode {
    align-items: center;
    background-color: ${colors.gray3};
    border-radius: 2px;
    display: flex;
    margin-bottom: 0;
    padding: 4px 0 4px 8px;

    .ant-tree-switcher {
      width: 0;
    }
  }

  ${({ span, total }) =>
    span != null &&
    total != null &&
    `
    .ant-tree-list-holder-inner {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: ${Math.ceil(total / span) * 36}px;
    }

    .ant-tree-treenode {
      margin-right: 26px;
    }
  `}
`;

interface ContainerProps {
  relative?: boolean;
}

export const MainContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ relative }) => (relative ? '' : 'height: calc(100vh - 50px);')}
`;

export const PageContainer = styled.div<ContainerProps>`
  display: flex;
  background-color: ${bc.collapse};
  position: relative;
  ${({ relative }) => (relative ? 'height: 100%;' : '')}
`;

export const PageHeader = styled.div`
  margin-bottom: 10px;
  background-color: ${colors.white};
  padding: 10px 20px;
`;

export const PageContent = styled.div`
  background-color: ${colors.white};
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  min-height: 0;
`;

export const TableWrapper = styled.div`
  border: 1px solid ${colors.gray5};
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

export const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 10px;
  width: 100%;

  .total {
    font-size: 1rem;
  }
`;

export const ErrorMessage = styled.h3`
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  padding-top: 30px;
  font-size: 20px;
`;
