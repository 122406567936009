import { TemplateDirectionEnum } from '__generated__/types';
import { DIRECTION_ICON_MAP, DIRECTION_LABEL_MAP } from 'components/TemplatesPage/TemplatesPageTable/consts';
import React, { FC } from 'react';

import s from './DirectionLabel.module.less';

export const DirectionLabel: FC<{ direction: TemplateDirectionEnum }> = ({ direction }) => {
  const label = DIRECTION_LABEL_MAP[direction];
  const Icon = DIRECTION_ICON_MAP[direction];

  return (
    <div className={s.directionLabel}>
      <Icon />
      <span>{label}</span>
    </div>
  );
};
