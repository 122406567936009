import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type TemplateFragment = { id: string, name?: string | null, constantName?: string | null, locale: Types.TemplateLocaleEnum };

export const TemplateFragmentDoc = gql`
    fragment template on RenderedTemplate {
  id
  name
  constantName
  locale
}
    `;