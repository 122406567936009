import Notification from 'components/UI/Notification';
import { useState } from 'react';
import { routesApi } from 'routes/api';
import api from 'utils/axios';

export type JobStatus = 'queued' | 'working' | 'complete' | 'failed' | 'interrupted' | null;

export type JobTerminalStatus = 'complete' | 'failed';

export interface IAsyncJobStatusResponse {
  status: JobStatus;
  values: Record<string, string>;
}

export interface IAsyncJobStatusProps {
  errorMessage?: string;
  keys?: string[];
  onCompleted: (status: JobTerminalStatus, values: Record<string, string>) => void;
  onProgress?: (status: JobStatus, values: Record<string, string>) => void;
}

export type AsyncJobStatusHookReturnType = [(jobId: string) => void, boolean];

const useAsyncJobStatus = ({
  errorMessage,
  keys,
  onCompleted,
  onProgress,
}: IAsyncJobStatusProps): AsyncJobStatusHookReturnType => {
  const [loading, setLoading] = useState(false);

  const startPolling = (jobId: string) => {
    setLoading(true);

    const checkStatus = async () => {
      const handleFailure = () => {
        setLoading(false);
        const message = errorMessage || 'Failed to fetch async job status. Please try again.';
        Notification.Error(message);
      };

      try {
        const params = {} as Record<string, string | string[]>;

        if (Array.isArray(keys)) {
          params.keys = keys;
        }

        const { data } = await api.get<IAsyncJobStatusResponse>(routesApi.asyncJobPath(jobId), { params });

        if (data.status === 'complete') {
          setLoading(false);
          onCompleted('complete', data.values);

          return;
        }

        if (data.status === 'failed' || data.status === 'interrupted' || data.status == null) {
          setLoading(false);
          onCompleted('failed', data.values);

          return;
        }

        if (data.status === 'queued' || data.status === 'working') {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setTimeout(checkStatus, 1000);

          if (typeof onProgress === 'function') {
            onProgress(data.status, data.values);
          }

          return;
        }

        // Unknown status received
        handleFailure();
      } catch (_e: unknown) {
        handleFailure();
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(checkStatus, 1000);
  };

  return [startPolling, loading];
};

export default useAsyncJobStatus;
