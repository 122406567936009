import { CurrencyEnum } from '__generated__/types';
import { IMoney } from '@xometry/ui/dist/utils/money';
import { FormInstance } from 'antd';
import { AxiosError } from 'axios';
import { IncomingInvoiceFormValues } from 'components/Deal/IncomingInvoice/Form/shared/models';
import Notification from 'components/UI/Notification';
import { dateFormatToBackend } from 'components/utils/formatters';
import { CURRENT_REGION } from 'config/regions/config';
import { II_POSITIONS_NEED_CURRENCY, XOMETRY_VAT_ENABLED } from 'config/regions/features';
import { Regions } from 'config/regions/types';
import { getRegionCurrency } from 'config/regions/utils';
import {
  ICachedXometryVatNumber,
  IncomingInvoice,
  IncomingInvoiceState,
  Position,
  PositionII,
  PreloadedFile,
} from 'interfaces';
import { OIShippingDetails } from 'interfaces/graphql';
import { IncomingInvoiceValidityEnum } from 'interfaces/graphql/incomingInvoice';
import { each, find, get, groupBy, last, map, omit, size, startsWith, uniq } from 'lodash-es';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import React from 'react';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import uniqid from 'uniqid';
import omsApi, { camelizeKeys, decamelizeKeys } from 'utils/axios';
import { getInvoiceFullName, getInvoiceShortName } from 'utils/deal/invoiceName';
import { XMoney } from 'utils/money';

const DEFAULT_VAT = 0;

export interface UploadedFile extends File {
  uid: number;
}

export interface PositionsByKey {
  [key: string]: PositionII;
}

interface IPrepareFormDataArgs {
  attachedFiles: PreloadedFile[];
  dealId: number;
  positions: PositionsByKey;
  publish: boolean;
  uploads: UploadedFile[];
  values: IncomingInvoiceFormValues;
}

interface UpdateAttributesArgs {
  dealId: number;
  invoice?: IncomingInvoice;
  positions: PositionsByKey;
  values: IncomingInvoiceFormValues;
}

const prepareFormData = ({ attachedFiles, dealId, positions, publish, uploads, values }: IPrepareFormDataArgs) => {
  const invoicePositionsAttributes = map(Object.values(positions), (position) => ({
    accounting_category: position.categoryName,
    name: position.name,
    position: position.position,
    quantity: position.quantity,
    refId: position.refId,
    refType: position.refType,
    price_per_piece: position.price,
    vat_rate: position.vat,
    id: position.id,
    _destroy: position._destroy,
  }));

  const attachedFilesAttributes = map(attachedFiles, (file) => ({
    id: file.id,
    _destroy: file._destroy,
  }));

  const data = {
    ...omit(values, 'positions'),
    invoicePositionsAttributes,
    attachedFilesAttributes,
  };

  each(['holdUntil', 'issuedAt', 'paidAt', 'registeredAt', 'paymentDueDate'], (valueKey) => {
    // @ts-expect-error TODO: FIX TS
    if (data[valueKey]) {
      // @ts-expect-error TODO: FIX TS
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      data[valueKey] = dateFormatToBackend(data[valueKey]);
    } else {
      // @ts-expect-error TODO: FIX TS
      data[valueKey] = null;
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const decamelizedData = decamelizeKeys(data);

  const formData = new FormData();

  each(uploads, (file: UploadedFile) => {
    formData.append('invoice[uploads[][file]]', file);
  });

  formData.append('invoice[deal_id]', String(dealId));

  if (publish) {
    formData.append('invoice[state]', JSON.stringify('published'));
  }

  each(decamelizedData, (value, key) => {
    if (value !== undefined) {
      formData.append(`invoice[${key}]`, JSON.stringify(value));
    } else {
      formData.append(`invoice[${key}]`, 'null');
    }
  });

  return formData;
};

export const getUpdateAttributes = ({ dealId, invoice, positions, values }: UpdateAttributesArgs) => ({
  ...omit(values, 'positions'),
  attachedFilesAttributes: invoice?.attachedFiles.map((file) => ({
    _destroy: file._destroy,
    id: String(file.id),
  })),
  dealId,
  holdUntil: values.holdUntil ? dateFormatToBackend(values.holdUntil) : null,
  invoicePositionsAttributes: Object.values(positions).map((position) => ({
    accountingCategory: position.categoryName,
    destroy: position._destroy,
    id: position.id,
    name: position.name,
    position: position.position,
    pricePerPiece: Number(position.price),
    quantity: position.quantity,
    refId: position.refId,
    refType: position.refType,
    vatRate: Number(position.vat),
  })),
  issuedAt: values.issuedAt ? dateFormatToBackend(values.issuedAt) : null,
  paidAt: values.paidAt ? dateFormatToBackend(values.paidAt) : null,
  paymentDueDate: values.paymentDueDate ? dateFormatToBackend(values.paymentDueDate) : null,
  provider: values.provider
    ? {
        ...values.provider,
        id: Number(values.provider.id),
        intacctId: values.provider.intacctId ?? '',
        legalName: values.provider.legalName ?? '',
        payoutTransferType: values.provider.payoutTransferType ?? '',
        vatId: values.provider.vatId ?? '',
      }
    : undefined,
  providerContact: {
    ...values.providerContact,
    id: String(values.providerContact.id),
  },
  registeredAt: values.registeredAt ? dateFormatToBackend(values.registeredAt) : null,
});

type AxiosResponseError = AxiosError<{ errors: { source: Record<string, string> } }>;

export interface Provider {
  value: number;
  label: string;
  providerId: number;
  providerAcceptSelfBilling: boolean;
  paymentDueDate: string;
  paymentTerms?: number;
  xometryVatNumber?: ICachedXometryVatNumber;
}

interface IVatInfo {
  sachkontoNumber?: number | null;
  vatCode?: number | null;
  vatRate?: number | null;
}

class IncomingInvoicesStore {
  public rootStore: RootStore;

  form?: FormInstance = undefined;
  currentPosition = 0;

  @observable invoice?: IncomingInvoice = undefined;
  @observable refInvoiceCurrency: CurrencyEnum = getRegionCurrency();
  @observable uploads: UploadedFile[] = [];
  @observable positions: PositionsByKey = {};
  @observable providers: Provider[] = [];
  @observable selectedProvider?: Provider;
  @observable sourceShipping?: OIShippingDetails;
  @observable vatInfo?: IVatInfo;
  @observable isCreditNote = false;

  @observable private newXometryCreditNote = false;
  @observable private newXometryCreditNoteCorrection = false;
  @observable private providerAcceptSelfBilling?: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  incCurrentPosition = (inc = 10): number => {
    this.currentPosition += inc;

    return this.currentPosition;
  };

  @action setIsCreditNote = (isCreditNote: boolean) => {
    this.isCreditNote = isCreditNote;
  };

  @action setVatInfo = (vatInfo: IVatInfo) => {
    this.vatInfo = vatInfo;
  };

  @action loadInvoice = (invoiceId: string) => {
    void omsApi.get<{ payload: unknown }>(routesApi.incomingInvoicePath(invoiceId)).then((response) => {
      this.setInvoice(camelizeKeys(response.data.payload));
    });
  };

  @action setProviders = (providers: Provider[]) => {
    this.providers = providers;
  };

  @action setSelectedProvider = (selectedProvider: Provider) => {
    this.selectedProvider = selectedProvider;
  };

  @action setCurrentPosition = (position: number): void => {
    this.currentPosition = position;
  };

  @action setSourceShipping = (shipping: OIShippingDetails) => {
    this.sourceShipping = shipping;
  };

  @action setInvoice = (invoice: IncomingInvoice) => {
    this.positions = {};
    this.invoice = invoice;

    each(invoice.invoicePositions, (pos) => {
      const newPosition = {
        categoryName: pos.accountingCategory,
        id: pos.id,
        name: pos.name,
        quantity: pos.quantity,
        price: pos.pricePerPiece,
        priceMoney: pos.pricePerPieceMoney,
        part: camelizeKeys(pos.part),
        vat: this.vatInfo?.vatRate != null ? this.vatInfo.vatRate : pos.vatRate || DEFAULT_VAT,
        position: pos.position,
        partId: pos.partId,
        refType: pos.refType,
        refSource: startsWith(String(pos.refSource), 'SH') ? pos.refSource : null,
        refId: pos.refId,
      };

      this.addPosition(newPosition);
    });

    this.currentPosition = get(last(Object.values(this.positions)), 'position') || this.currentPosition;
  };

  @computed({ keepAlive: true })
  get positionsByShippingArray(): PositionII[] {
    return Object.keys(this.positionsByShipping).reduce((acc: PositionII[], cur) => {
      return [...acc, ...this.positionsByShipping[cur]];
    }, []);
  }

  @action addPositions = (positions: PositionII[]) => {
    // cast old positions currency
    if (II_POSITIONS_NEED_CURRENCY) {
      each(this.positions, (position) => {
        this.positions[position.key].priceMoney = {
          ...this.positions[position.key].priceMoney,
          currencyCode: positions[0].priceMoney?.currencyCode,
        } as IMoney;
      });
    }

    each(positions, (position) => {
      this.positions[position.key] = {
        ...position,
        vat: this.vatInfo?.vatRate != null ? this.vatInfo.vatRate : position.vat || DEFAULT_VAT,
        position: position.position || this.incCurrentPosition(),
      };
    });
  };

  @action setPositions = (positions: PositionII[]) => {
    this.positions = {};
    this.addPositions(positions);
  };

  @action addPosition = (pos: Partial<PositionII> & Pick<PositionII, 'categoryName' | 'name'>) => {
    const key = uniqid();
    const firstPositionCurrency = this.positionsArray[0]?.priceMoney?.currencyCode;
    const currencyCode = firstPositionCurrency || this.refInvoiceCurrency || getRegionCurrency();

    const newPosition = {
      categoryName: pos.categoryName,
      id: pos.id,
      name: pos.name || '',
      quantity: pos.quantity || 1,
      price: pos.price || 0,
      priceMoney: pos.priceMoney || XMoney(0, currencyCode).toIMoney(),
      vat: this.vatInfo?.vatRate != null ? this.vatInfo.vatRate : pos.vat || DEFAULT_VAT,
      position: pos.position || this.incCurrentPosition(),
      part: pos.part,
      partId: pos.partId,
      refType: pos.refType,
      refSource: startsWith(String(pos.refSource), 'SH') ? pos.refSource : null,
      refId: pos.refId,
      key,
    };

    this.positions[key] = newPosition;

    return newPosition;
  };

  @action deletePosition = (key: string) => {
    this.positions[key] = { ...this.positions[key], _destroy: true };
  };

  @action setUploads = (uploads: UploadedFile[]) => {
    this.uploads = uploads;
  };

  @action reset = () => {
    this.uploads = [];
    this.invoice = undefined;
    this.positions = {};
    this.currentPosition = 0;
    this.form = undefined;
    this.newXometryCreditNote = false;
    this.newXometryCreditNoteCorrection = false;
    this.providerAcceptSelfBilling = undefined;
  };

  @action destroyFile = (id: number) => {
    if (!this.invoice) {
      return;
    }

    const file = find(this.invoice.attachedFiles, { id });

    if (file) {
      file._destroy = true;
    }
  };

  @action create = (values: IncomingInvoiceFormValues) => {
    this.rootStore.pageLoaderStore.enable('Creating Invoice');

    const formData = prepareFormData({
      attachedFiles: [],
      dealId: this.rootStore.dealStore.data.id,
      positions: this.positions,
      publish: false,
      uploads: this.uploads,
      values: { ...values, selfBilling: this.isXometryCreditNote },
    });

    omsApi
      .post<{ id: number }>(routesApi.createIncomingInvoicePath(), formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        Notification.Success('Invoice has been created');

        window.location.href = routes.editDealIncomingInvoicePath(this.rootStore.dealStore.data.id, response.data.id);
      })
      .catch((error: AxiosResponseError) => {
        Notification.Error(
          <>
            <div>Failed to create Invoice</div>
            <br />
            {map(error.response?.data.errors.source, (value, key) => (
              <div key={key}>{`${key}: ${value}`}</div>
            ))}
          </>,
        );
      })
      .finally(() => {
        this.rootStore.pageLoaderStore.disable();
      });
  };

  @action update = (values: IncomingInvoiceFormValues, publish = false) => {
    if (!this.invoice) {
      return;
    }

    this.rootStore.pageLoaderStore.enable(publish ? 'Publishing Invoice' : 'Updating Invoice');

    const formData = prepareFormData({
      attachedFiles: this.invoice.attachedFiles,
      dealId: this.rootStore.dealStore.data.id,
      positions: this.positions,
      publish,
      uploads: this.uploads,
      values: { ...values, selfBilling: this.isXometryCreditNote },
    });

    omsApi
      .patch(routesApi.incomingInvoicePath(this.invoice.id), formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        if (this.invoice) {
          window.location.href = `${routes.editDealIncomingInvoicePath(
            this.rootStore.dealStore.data.id,
            this.invoice.id,
          )}?publish=${publish.toString()}`;
        }
      })
      .catch((error: AxiosResponseError) => {
        Notification.Error(
          <>
            <div>Failed to update Invoice</div>
            <br />
            {map(error.response?.data.errors?.source, (value, key) => (
              <div key={key}>{`${key}: ${value}`}</div>
            ))}
          </>,
        );
      })
      .finally(() => {
        this.rootStore.pageLoaderStore.disable();
      });
  };

  @action updatePositionAndCloseEdit = (key: string, position: Position): void => {
    this.positions[key] = { ...this.positions[key], ...position };

    if (II_POSITIONS_NEED_CURRENCY) {
      this.positions[key].priceMoney = {
        ...XMoney(this.positions[key].price).toIMoney(),
        currencyCode: this.positions[key].priceMoney?.currencyCode || '',
      };
    }
  };

  @action changeState = (state: IncomingInvoiceState) => {
    if (!this.invoice) {
      return;
    }

    this.rootStore.pageLoaderStore.enable(`Incoming Invoice #${this.invoice.id} is being changed to "${state}" state`);

    omsApi
      .patch(routesApi.changeStateIncomingInvoicePath(this.invoice.id), {
        state,
      })
      .then(() => {
        Notification.Success(`Invoice has been changed to "${state}"`);
        window.location.reload();
      })
      .catch(() => Notification.Error('Failed to change state for Invoice'))
      .finally(() => {
        this.rootStore.pageLoaderStore.disable();
      });
  };

  @action manualChangeState = (state: IncomingInvoiceState) => {
    if (this.invoice) {
      this.invoice = { ...this.invoice, state };
    }
  };

  @action setIsNewXometryCN = (value: boolean) => {
    this.newXometryCreditNote = value;
  };

  @action setIsNewXometryCNCorrection = (value: boolean) => {
    this.newXometryCreditNoteCorrection = value;
  };

  @action setProviderAcceptXometryCreditNote = (value: boolean | undefined) => {
    this.providerAcceptSelfBilling = value;
  };

  @action setRefInvoicesCurrency = (value: CurrencyEnum) => {
    this.refInvoiceCurrency = value;
  };

  @computed
  get warningPartnerNotAcceptCreditNote() {
    if (!this.isXometryCreditNote) return false;

    const { providerAcceptSelfBilling } = this;

    if (providerAcceptSelfBilling === undefined) return false;

    return !providerAcceptSelfBilling;
  }

  @computed
  get warningUseXometryCreditNote() {
    if (this.isXometryCreditNote) return false;

    const { providerAcceptSelfBilling } = this;

    if (providerAcceptSelfBilling === undefined) return false;

    return providerAcceptSelfBilling;
  }

  @computed({ keepAlive: true })
  get positionsArray() {
    const positionsArrayDefault = Object.values(this.positions);

    return XOMETRY_VAT_ENABLED
      ? positionsArrayDefault.map((position) => ({
          ...position,
          vat: this.vatInfo?.vatRate != null ? this.vatInfo.vatRate : position.vat || DEFAULT_VAT,
        }))
      : positionsArrayDefault;
  }

  @computed({ keepAlive: true })
  get positionsByShipping() {
    const positionsArrayDefault = Object.values(this.positions);

    const positionsArrayWithVat = positionsArrayDefault.map((position) => ({
      ...position,
      vat: this.vatInfo?.vatRate != null ? this.vatInfo?.vatRate : position.vat,
    }));

    const positions = XOMETRY_VAT_ENABLED ? positionsArrayWithVat : positionsArrayDefault;

    return groupBy(positions, 'refSource');
  }

  @computed
  get isXometryCreditNote() {
    return Boolean(this.newXometryCreditNote || this.invoice?.selfBilling);
  }

  @computed
  get invoiceName() {
    return getInvoiceShortName(this.invoice);
  }

  @computed get pageTitleComputed() {
    return getInvoiceFullName({
      isNew: this.invoice?.id == undefined,
      isCreditNote: this.invoice?.creditNote || this.isCreditNote,
      selfBilling: this.isXometryCreditNote,
      shortName: this.invoiceName,
    });
  }

  @computed({ keepAlive: true })
  get initialValues(): IncomingInvoiceFormValues {
    if (!this.invoice) {
      const query = new URLSearchParams(window.location.search);
      const refId = query.get('ref_id');

      const defaultCleanValues = {
        creditNote: !!refId || this.newXometryCreditNoteCorrection,
        selfBilling: this.isXometryCreditNote,
        refInvoiceId: refId ? Number(refId) : undefined,
        isStorno: false,
        isPartial: false,
        isPublishedInPa: false,
        issuedAt: moment(),
        providerBankDetails: {
          bankCountry: null,
          bankAccountHolderName: null,
          iban: null,
          swiftbic: null,
          name: null,
        },
        providerBillingAddress: {
          country: null,
          zip: null,
          city: null,
          address: null,
        },
        providerContact: {
          name: null,
          phone: null,
          email: null,
        },
        positions: {},
        validity: IncomingInvoiceValidityEnum.ManagerReady,
      };

      const defaultCleanValuesEU = {
        ...defaultCleanValues,
        vatPdfCorrectionRequired: false,
      };

      const defaultCleanValuesConfig = {
        [Regions.EU]: defaultCleanValuesEU,
        [Regions.TR]: defaultCleanValues,
        [Regions.UK]: defaultCleanValues,
      };

      return defaultCleanValuesConfig[CURRENT_REGION];
    }

    const defaultValues = {
      number: this.invoice.number,
      comment: this.invoice.comment,
      datevComment: this.invoice.datevComment,
      intacctComment: this.invoice.intacctComment,
      needFixComment: this.invoice.needFixComment,
      selfBilling: this.isXometryCreditNote,
      creditNote: this.invoice.creditNote,
      isStorno: this.invoice.isStorno,
      isPartial: this.invoice.isPartial,
      refInvoiceId: this.invoice.refInvoiceId,
      dealProviderOrderId: this.invoice.dealProviderOrderId,
      isPublishedInPa: this.invoice.isPublishedInPa,
      issuedAt: moment(this.invoice.issuedAt),
      paymentDueDate: this.invoice.paymentDueDate ? moment(this.invoice.paymentDueDate) : null,
      registeredAt: this.invoice.registeredAt ? moment(this.invoice.registeredAt) : null,
      paidAt: this.invoice.paidAt ? moment(this.invoice.paidAt) : null,
      holdUntil: this.invoice.holdUntil ? moment(this.invoice.holdUntil) : null,
      providerBankDetails: {
        bankAccountHolderName: this.invoice.providerBankDetails.bankAccountHolderName,
        bankCountry: this.invoice.providerBankDetails.bankCountry,
        iban: this.invoice.providerBankDetails.iban,
        swiftbic: this.invoice.providerBankDetails.swiftbic,
        name: this.invoice.providerBankDetails.name,
      },
      providerBillingAddress: {
        country: this.invoice.providerBillingAddress.country ?? null,
        zip: this.invoice.providerBillingAddress.zip ?? null,
        city: this.invoice.providerBillingAddress.city ?? null,
        address: this.invoice.providerBillingAddress.address ?? null,
      },
      provider: this.invoice.provider,
      providerContact: this.invoice.providerContact,
      positions: this.positions,
      validity: this.invoice.validity as unknown as IncomingInvoiceValidityEnum,
    };

    const defaultValuesEU = {
      ...defaultValues,
      vatPdfCorrectionRequired: this.invoice.vatPdfCorrectionRequired,
      xometryVatNumber: this.invoice.xometryVatNumber,
      xometryVatNumberId: this.invoice.xometryVatNumber?.id,
      sachkontoNumber: this.invoice.sachkontoNumber,
      datevVatCode: this.invoice.datevVatCode,
    };

    const defaultValuesConfig = {
      [Regions.EU]: defaultValuesEU,
      [Regions.TR]: defaultValues,
      [Regions.UK]: defaultValues,
    };

    return defaultValuesConfig[CURRENT_REGION];
  }

  @computed({ keepAlive: true })
  get positionsSummary() {
    const summary = {
      netto: XMoney(0),
      brutto: XMoney(0),
      vatAvg: 0,
      positions: 0,
      items: 0,
    };

    const positionsArray = this.positionsArray.filter((position) => !position._destroy);

    let vatAmount = XMoney(this.vatInfo?.vatRate || DEFAULT_VAT);

    const isSameVat = size(uniq(map(positionsArray, (position) => Number(Number(position.vat).toFixed(2))))) === 1;

    each(positionsArray, (position) => {
      const newVatAmount = XMoney(vatAmount.toIMoney().amount, position.priceMoney?.currencyCode);
      const newNetto = XMoney(summary.netto.toIMoney().amount, position.priceMoney?.currencyCode);
      const defaultPositionPrice = position.priceMoney || position.price;
      const totalPositionPrice = XMoney(defaultPositionPrice).mult(position.quantity);

      summary.netto = newNetto.plus(totalPositionPrice);
      summary.vatAvg += Number(Number(position.vat).toFixed(2));
      vatAmount = newVatAmount.plus(totalPositionPrice.mult(position.vat || 0).divide(100));

      summary.positions += 1;
      summary.items += position.quantity;
    });

    summary.vatAvg /= summary.positions;
    summary.vatAvg = Number(summary.vatAvg.toFixed(2));

    if (XOMETRY_VAT_ENABLED) {
      summary.vatAvg = this.vatInfo?.vatRate || summary.vatAvg || 0;
    }

    if (isSameVat) {
      vatAmount = summary.netto.mult(summary.vatAvg).divide(100);
    }

    summary.brutto = summary.netto.plus(vatAmount);

    return summary;
  }

  @computed({ keepAlive: true })
  get isInputDisabled() {
    return this.invoice?.state === IncomingInvoiceState.Published;
  }
}

export default IncomingInvoicesStore;
