import { DATEV_ENABLED } from 'config/regions/features';
import { DEIInvoice, DEIItem, DEISearchValues, DEType, Pagination } from 'interfaces';
import { action, computed, observable } from 'mobx';
import { Moment } from 'moment';
import { Key } from 'react';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import api, { camelizeKeys, decamelizeKeys } from 'utils/axios';

export default class AccountingExportIncomingsStore {
  rootStore: RootStore;

  @observable currentExportItem: DEIItem = {} as DEIItem;

  @observable latestExports: DEIItem[] = [];

  @observable createInProcess = false;

  @observable exportFetchInProcess = false;

  @observable exportsFetchInProcess = false;

  @observable searchInProcess = false;

  @observable invoices: DEIInvoice[] = [];

  @observable invoicePagination: Pagination = {} as Pagination;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action setLatestExports = (exports: DEIItem[]) => {
    this.latestExports = exports;
  };

  @action setCurrentExportItem = (item: DEIItem) => {
    this.currentExportItem = item;
  };

  @action fetchAll = () => {
    api
      .get<{ payload: { collection: DEIItem[] } }>(routesApi.accountingExportIncomingsPath())
      .then((response) => {
        const exports = response?.data?.payload?.collection || [];

        this.setLatestExports(camelizeKeys(exports));
      })
      .catch(() => null)
      .finally(() => null);
  };

  @action fetchOne = (id: number) => {
    this.setExportFetchInProcess(true);

    api
      .get<{ payload: DEIItem }>(routesApi.accountingExportIncomingPath(id))
      .then((response) => {
        const currentExportItem = response?.data?.payload;

        this.setCurrentExportItem(camelizeKeys(currentExportItem));
      })
      .catch(() => null)
      .finally(() => this.setExportFetchInProcess(false));
  };

  @action setExportFetchInProcess = (value: boolean) => {
    this.exportFetchInProcess = value;
  };

  @action setCreateInProcess = (value: boolean) => {
    this.createInProcess = value;
  };

  @action setSearchInProcess = (value: boolean) => {
    this.searchInProcess = value;
  };

  @action setInvoicesPagination = (pagination: Pagination) => {
    this.invoicePagination = pagination;
  };

  @action setInvoices = (invoices: DEIInvoice[]) => {
    this.invoices = invoices;
  };

  @action resetSearch = () => {
    this.invoices = [];
    this.invoicePagination = {} as Pagination;
  };

  @action search = async (values: DEISearchValues): Promise<string[] | null> => {
    const payload = {
      ...values,
      durationFrom: values.durationFrom?.format('YYYY-MM-DD'),
      durationTo: values.durationTo?.format('YYYY-MM-DD'),
      exportType: DATEV_ENABLED ? values.exportType : DEType.Invoices,
      withCreditNotes: true,
    };

    this.setSearchInProcess(true);

    return api
      .get<{ payload: { pagination: Pagination; collection: DEIInvoice[] } }>(
        routesApi.accountingExportIncomingsSearchPath(),
        {
          // God bless axios and their types
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          params: decamelizeKeys(payload),
        },
      )
      .then((response) => {
        const { pagination, collection } = response.data.payload;

        this.setInvoices(camelizeKeys(collection));
        this.setInvoicesPagination(camelizeKeys(pagination));

        return this.invoicesIds;
      })
      .catch(() => null)
      .finally(() => this.setSearchInProcess(false));
  };

  @computed get invoicesIds() {
    return this.invoices.map((invoice) => String(invoice.id));
  }

  @action create = async (ids: Key[], moment: Moment, exportType: DEType): Promise<string | null> => {
    this.setCreateInProcess(true);

    return api
      .post<{ payload: { id: Key } }>(routesApi.accountingExportIncomingsPath(), {
        ids: ids.map((id) => Number(id)),
        period: moment.format('YYYY-MM-DD'),
        export_type: exportType,
      })
      .then((response) => {
        const id = response?.data?.payload?.id;

        return routes.accountingExportIncomingPath(String(id));
      })
      .catch(() => null)
      .finally(() => this.setCreateInProcess(false));
  };

  @action overwriteRegisteredAt = (ids: Key[], overwrite: boolean, registeredAt: Moment) => {
    this.setExportFetchInProcess(true);

    return api
      .patch<{ payload: DEIItem }>(`${routesApi.accountingExportIncomingPath(this.currentExportItem.id)}/overwrite`, {
        ids,
        overwrite,
        registered_at: registeredAt.format('YYYY-MM-DD'),
      })
      .then((response) => {
        const currentExportItem = response?.data?.payload;
        this.setCurrentExportItem(camelizeKeys(currentExportItem));
      })
      .catch(() => null)
      .finally(() => this.setExportFetchInProcess(false));
  };
}
