import { Button } from 'antd';
import moment from 'moment';
import React, { FC, useState } from 'react';

import { FilterKeywords } from '../../../../collections';
import { AntdDatePicker, ButtonsContainer, ButtonsRow } from './styled';

const supportedKeywords = [
  FilterKeywords.Today,
  FilterKeywords.Now,
  FilterKeywords.StartOfMonth,
  FilterKeywords.EndOfMonth,
  FilterKeywords.StartOfYear,
];

interface Props {
  index: number;
  onChange: (value: any, index: number) => void;
  readonly?: boolean;
  value?: string | number;
  withKeywords?: boolean;
  withTime?: boolean;
}

const DatePicker: FC<Props> = ({ index, onChange, readonly, value, withKeywords, withTime }) => {
  const dateFormat = withTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';

  const [open, setOpen] = useState(false);

  const setCustomValue = (value: FilterKeywords) => {
    onChange(value, index);
    setOpen(false);
  };

  const submit = (v: moment.Moment | null) => onChange(v?.format(dateFormat), index);

  let placeholder;
  let dpValue;

  if (value && (supportedKeywords.includes(value as FilterKeywords) || /\{[^{}]+\}/.test(String(value)))) {
    placeholder = String(value);
  } else {
    dpValue = value != null ? moment(value, dateFormat) : undefined;
  }

  const extraFooter = () => (
    <ButtonsContainer>
      <ButtonsRow>
        <Button onClick={() => setCustomValue(FilterKeywords.StartOfMonth)}>Start of month</Button>

        <Button onClick={() => setCustomValue(FilterKeywords.EndOfMonth)}>End of month</Button>

        <Button onClick={() => setCustomValue(FilterKeywords.StartOfYear)}>Start of year</Button>
      </ButtonsRow>

      <ButtonsRow>
        <Button onClick={() => setCustomValue(FilterKeywords.Today)}>Today</Button>

        {withTime && <Button onClick={() => setCustomValue(FilterKeywords.Now)}>Now</Button>}
      </ButtonsRow>
    </ButtonsContainer>
  );

  return (
    <AntdDatePicker
      disabled={readonly}
      format={dateFormat}
      onChange={submit}
      onOk={submit}
      onOpenChange={setOpen}
      open={open}
      showNow={false}
      showTime={withTime && { defaultValue: moment('00:00:00', 'HH:mm:ss') }}
      showToday={false}
      style={{ width: 240 }}
      placeholder={placeholder}
      value={dpValue}
      renderExtraFooter={withKeywords ? extraFooter : undefined}
    />
  );
};

export default DatePicker;
