import { LogisticsShippingsPage } from 'pages';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

export const LogisticsWorkspacesController = () => (
  <Switch>
    <Route path={routes.workspacesLogisticsShippingsPath()} component={LogisticsShippingsPage} exact />
  </Switch>
);
