export interface IAttachedFile {
  id: number;
  cleanedId?: number;
  context: string;
  createdAt: string;
  isCleaned: boolean;
  isStp?: boolean;
  name: string;
  parentId?: number;
  uid: number;
  url: string;
  mediumUrl: string;
  largeUrl: string;
  description?: string;
}

export enum AttacheFileContext {
  Default = 'default',
  XomIqe = 'xom_iqe',
  Additional = 'additional',
  Original = 'original',
  Custom = 'custom',
}

export enum IAttachedFileSource {
  DEAL_PART = 'Deal::Part',
}

export enum IAttachedFileContext {
  default = 'default',
  preQuote = 'preQuote',
  protocol = 'protocol',
  custom = 'custom',
  original = 'original',
  xomPreview = 'xomPreview',
  additional = 'additional',
  deliveryReport = 'deliveryReport',
  deliveryLabel = 'deliveryLabel',
  shippingDeliveryNote = 'shippingDeliveryNote',
  shippingPackagingReport = 'shippingPackagingReport',
  shippingLabel = 'shippingLabel',
  qualityControlCanvas = 'quality_control_canvas',
  qualityContorlMeasurementsPreview = 'quality_control_measurements_preview',
}
