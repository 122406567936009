import React from 'react';

import { DrawingsWorkspacesController } from './DrawingsWorkspacesController';
import { GeneralWorkspacesController } from './GeneralWorkspacesController';
import { LogisticsWorkspacesController } from './LogisticsWorkspacesController';
import { RfqWorkspacesController } from './RfqWorkspacesController';

const WorkspacesController = () => (
  <>
    <GeneralWorkspacesController />

    <DrawingsWorkspacesController />

    <LogisticsWorkspacesController />

    <RfqWorkspacesController />
  </>
);

export default WorkspacesController;
