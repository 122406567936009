import * as Sentry from '@sentry/react';
import { Integration } from '@sentry/types';
import { DummyMonitoring, MonitoringInstance } from '@xometry/ui';
import { ENV_API_ENDPOINT, ENV_CUSTOM_ENV, RELEASE, SENTRY_DSN } from 'constants/env';

export const getMonitoringClient = (): Promise<MonitoringInstance> =>
  import('./Sentry')
    .then(
      (r) =>
        new r.SentryMonitoring({
          production: ENV_CUSTOM_ENV === 'production',
          environment: ENV_CUSTOM_ENV,
          release: RELEASE,
          dsn: SENTRY_DSN,
          configure: (draft) => {
            const integrations = (
              Array.isArray(draft.integrations) ? [...draft.integrations] : [draft.integrations]
            ) as Integration[];

            integrations.push(
              // https://docs.sentry.io/platforms/javascript/session-replay/privacy/#privacy-configuration
              new Sentry.Replay({
                maskAllText: false,
                maskAllInputs: true,
                networkDetailAllowUrls: [`${ENV_API_ENDPOINT}/oms/graphql`],
              }),
            );

            return {
              ...draft,
              dist: draft.release,
              integrations,
              tracePropagationTargets: [ENV_API_ENDPOINT],
            };
          },
        }),
    )
    .catch(() => new DummyMonitoring());
