import {
  DatevExportBankPaymentsPage,
  DatevExportBankPaymentsShowPage,
  DatevExportPaymentsShowPage,
  DatevExportPayoutsShowPage,
  ExportPaymentAllocationsListPage,
  ExportPaymentAllocationsShowPage,
} from 'pages';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

const DatevController: React.FC = () => (
  <Switch>
    <Route path={routes.datevExportsPaymentsPath()} component={DatevExportBankPaymentsPage} exact />
    <Route path={routes.datevExportsPaymentAllocationsPath()} component={ExportPaymentAllocationsListPage} exact />
    <Route path={routes.datevExportsPaymentAllocationPath(':id')} component={ExportPaymentAllocationsShowPage} exact />
    <Route path={routes.datevExportBankPaymentPath(':id')} component={DatevExportBankPaymentsShowPage} exact />
    <Route path={routes.datevExportPaymentsPath(':id')} component={DatevExportPaymentsShowPage} exact />
    <Route path={routes.datevExportPayoutsPath(':id')} component={DatevExportPayoutsShowPage} exact />
  </Switch>
);

export default DatevController;
