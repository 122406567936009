import { Empty, Form, FormInstance, Input, Select, Spin } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { debounce, map, set } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import omsApi from 'utils/axios';

export interface IOptionItem {
  value: unknown;
  label: React.ReactNode;
}

interface Props extends Pick<FormItemProps, 'rules' | 'wrapperCol' | 'labelCol' | 'extra'> {
  form: FormInstance;
  label?: React.ReactNode;
  name: string | string[];
  className?: string;
  endpoint: string;
  prefetch?: boolean;
  size?: SizeType;
  mode?: 'multiple' | 'tags' | undefined;
  defaultActiveFirstOption?: boolean;
  defaultValue?: IOptionItem | IOptionItem[];
  disabled?: boolean;
  customStyles?: FormItemProps['style'];
  placeholder?: string;
  allowClear?: boolean;
  onChange?: (value: any) => void;
  changeOptions?: (options?: IOptionItem[]) => IOptionItem[];
}

const Autocomplete = observer<Props>(
  ({
    form,
    label,
    name,
    endpoint,
    rules,
    disabled,
    prefetch,
    mode = undefined,
    size = 'small',
    defaultActiveFirstOption,
    className = 'ant-form-autocomplete',
    onChange,
    defaultValue = undefined,
    labelCol,
    wrapperCol,
    customStyles,
    extra,
    placeholder,
    allowClear = true,
    changeOptions,
  }) => {
    const lastFetchId = useRef(0);
    const [collection, setCollection] = useState<IOptionItem[]>([]);
    const [value, setValue] = useState<IOptionItem | IOptionItem[] | undefined>();
    const [isFetching, setIsFetching] = useState(false);

    const fetchCollection = async (q: string) => {
      setIsFetching(true);

      lastFetchId.current += 1;
      const fetchId = lastFetchId.current;

      const response = await omsApi.get<{ payload: IOptionItem[] }>(endpoint, {
        params: { q },
      });

      if (fetchId !== lastFetchId.current) {
        return;
      }

      setCollection(changeOptions ? changeOptions(response?.data?.payload) : response?.data?.payload);
      setIsFetching(false);
    };

    const fetchCollectionDebounced = debounce(fetchCollection, 800);

    const handleChange = (newOption: IOptionItem | IOptionItem[] | undefined) => {
      let newValue;

      if (Array.isArray(newOption)) {
        newValue = map(newOption, 'value');
      } else {
        newValue = newOption?.value;
      }

      setValue(newOption);
      const newValues = form.getFieldsValue(true) as object;
      const key = typeof name === 'object' ? name.join('.') : name;
      set(newValues, key, newValue);
      form.setFieldsValue(newValues);
      if (onChange) {
        onChange(newValue);
      }

      setIsFetching(false);
    };

    useEffect(() => {
      if (changeOptions) {
        setCollection(changeOptions());
      }

      if (prefetch) {
        void fetchCollection('');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className={`erp-autocomplete ${className}`}>
        <Form.Item hidden name={name}>
          <Input />
        </Form.Item>
        <Form.Item
          label={label}
          name={typeof name === 'object' ? `${name.join('_')}Option` : `${name}Option`}
          rules={rules}
          wrapperCol={wrapperCol}
          labelCol={labelCol}
          initialValue={defaultValue}
          style={customStyles}
          extra={extra}
        >
          <Select
            defaultActiveFirstOption={defaultActiveFirstOption}
            labelInValue
            showSearch
            mode={mode}
            size={size}
            value={value}
            allowClear={allowClear}
            placeholder={placeholder || 'Type in to search'}
            notFoundContent={isFetching ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            filterOption={false}
            onChange={handleChange}
            onSearch={(q: string) => {
              void fetchCollectionDebounced(q);
            }}
            disabled={disabled}
            options={collection}
          />
        </Form.Item>
      </div>
    );
  },
);

export default Autocomplete;
