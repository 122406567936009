import { ProfilePage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

const UsersController: React.FC = () => (
  <>
    <Route path={routes.userProfilePath(':userId')} component={ProfilePage} />
  </>
);

export default UsersController;
