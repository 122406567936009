import { SelectProps } from 'antd/lib/select';
import { NotAuthorized } from 'components/shared/NotAuthorized';
import { PermissionsSettings } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { PresetOptionData } from 'components/Workspaces/General/shared/GeneralWorkspace/withPresets';
import { WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import { filter as lFilter, find, get, last, map, size } from 'lodash-es';
import React, { PropsWithChildren, useEffect } from 'react';
import useCurrentUserPermissions from 'utils/hooks/useCurrentUserPermissions';

import PresetSelectorWrapper from './PresetSelectorWrapper';

interface PermissionsValidatorProps {
  permissionsSettings: PermissionsSettings;
  preset: WorkspacePreset;
  presetSelector: React.ReactElement<SelectProps<number>>;
  onPresetSelectorFilter: (filter: (option: PresetOptionData) => boolean) => any;
}

const PermissionsValidator: React.FC<PropsWithChildren<PermissionsValidatorProps>> = ({
  children,
  permissionsSettings,
  preset,
  presetSelector,
  onPresetSelectorFilter,
}) => {
  const { allPermissions } = useCurrentUserPermissions();

  const { group, filter, view } = permissionsSettings;

  const presetRegexp = new RegExp(`${view ?? ''}_\\d+`, 'ig');

  const permittedPresets = map(
    lFilter(get(allPermissions, group), (p) => presetRegexp.test(p.name)),
    (p) => Number.parseInt(last(p.name.split('_')) as string, 10),
  );

  const canFilter = find(get(allPermissions, group), (p) => p.name === filter) != null;
  const canView = view == null || find(get(allPermissions, group), (p) => p.name === view) != null;

  // Filter presets list only if user not permitted to view all of them
  useEffect(() => {
    if (canFilter || canView) return;

    if (!(size(permittedPresets) > 0)) return;

    onPresetSelectorFilter((opt: PresetOptionData) => permittedPresets.includes(opt?.value));
  }, [canFilter, canView, onPresetSelectorFilter, permittedPresets]);

  // Workspace access is not limited
  if (allPermissions == null) return null;

  // User has unlimited permissions on workspace: view all presets, apply custom filters
  if (canFilter) {
    return <>{children}</>;
  }

  // User is permitted only to view all available presets, without custom filtering
  if (canView) {
    if (preset.id != null) {
      return <>{children}</>;
    }

    return <PresetSelectorWrapper presetSelector={presetSelector} />;
  }

  // User is petmitted only to view specific presets
  if (size(permittedPresets) > 0) {
    if (preset.id != null && permittedPresets.includes(preset.id)) {
      return <>{children}</>;
    }

    return <PresetSelectorWrapper presetSelector={presetSelector} />;
  }

  return (
    <NotAuthorized
      group={group}
      action={view}
      message={`Permission ${group}.${view ?? ''} required to access the list`}
    />
  );
};

export default PermissionsValidator;
