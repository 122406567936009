import { gql } from 'apollo-boost';

import { PAYOUT_FRAGMENT } from '../fragments';

export const PAYOUTS_COMPLETE = gql`
  mutation payoutsComplete($id: ID!, $attributes: OmsInputsPayoutCompleteType!) {
    payoutsComplete(input: { id: $id, attributes: $attributes }) {
      payout {
        ...payoutFragment
      }
    }
  }
  ${PAYOUT_FRAGMENT}
`;

export const PAYOUTS_UPDATE = gql`
  mutation payoutsUpdate($id: ID!, $attributes: OmsInputsPayoutType!) {
    payoutsUpdate(input: { id: $id, attributes: $attributes }) {
      payout {
        ...payoutFragment
      }
    }
  }
  ${PAYOUT_FRAGMENT}
`;

export const PAYOUTS_BULK_PAID = gql`
  mutation payoutsBulkSetAsPaid($ids: [ID]!) {
    payoutsBulkSetAsPaid(input: { ids: $ids }) {
      success
    }
  }
`;

export interface IPayoutsBulkBankPaymentApproveAndCreateResponse {
  payoutsBulkBankPaymentApproveAndCreate: {
    jobId: string;
  };
}

export const PAYOUTS_BULK_BANK_PAYMENT_APPROVE_AND_CREATE = gql`
  mutation payoutsBulkBankPaymentApproveAndCreate($attributes: JSON!) {
    payoutsBulkBankPaymentApproveAndCreate(input: { attributes: $attributes }) {
      jobId
    }
  }
`;

export const PAYOUTS_COMPLETE_AGAINST_ANOTHER = gql`
  mutation payoutsCompleteAgainstAnother($id: ID!, $attributes: OmsInputsPayoutCompleteAgainstAnotherType!) {
    payoutsCompleteAgainstAnother(input: { id: $id, attributes: $attributes }) {
      payout {
        ...payoutFragment
      }
    }
  }
  ${PAYOUT_FRAGMENT}
`;

export const PAYOUTS_CREATE = gql`
  mutation payoutsCreate($attributes: OmsInputsPayoutType!) {
    payoutsCreate(input: { attributes: $attributes }) {
      payout {
        ...payoutFragment
      }
    }
  }
  ${PAYOUT_FRAGMENT}
`;

export const PAYOUTS_DESTROY = gql`
  mutation payoutsDestroy($id: ID!) {
    payoutsDestroy(input: { id: $id }) {
      payout {
        ...payoutFragment
      }
    }
  }
  ${PAYOUT_FRAGMENT}
`;
