/* eslint-disable camelcase */
export enum PreviewsTab {
  Drawings = 'drawings',
  Documents = 'documents',
}

export interface BucketItem {
  id: number;
  name: string;
  context: string;
  parentId?: number;
  cleanedId?: number;
  isCleaned: boolean;
  url: string;
  dimensions?: { x: number; y: number; z: number };
}

export type PreviewsPayload = {
  [tab in PreviewsTab]: {
    bucket_name: string;
    bucket_id?: number;
    items: BucketItem[];
  };
};

export interface AttachmentsBucket {
  bucketName: string;
  bucketId?: number;
  items: BucketItem[];
}

export type PreviewsSchema = {
  [tab in PreviewsTab]?: AttachmentsBucket[];
};

export interface CurrentPreview extends BucketItem {
  bigUrl: string;
}
