import { TemplateDirectionEnum, TemplateLocaleEnum, TemplateTypeEnum } from '__generated__/types';
import { FormInstance } from 'antd';
import {
  TemplateCategoryEnum,
  TemplateModalFormFieldEnum,
  TemplateModalFormValues,
} from 'components/TemplatesPage/TemplatesManagerModal/TemplatesManagerModal.types';
import { TemplateGroupFragment } from 'components/TemplatesPage/TemplatesPageTable/graphql/fragments/__generated__/templateGroup';
import { action, computed, observable } from 'mobx';

interface Locales {
  locale: TemplateLocaleEnum;
  content: string;
  subject: string;
}

export enum ModeEnum {
  CREATE = 'create',
  UPDATE = 'update',
}

class TemplatesManagerModalStore {
  @observable isOpen = false;
  @observable mode: ModeEnum | null = null;

  @observable templateName: string | null | undefined = '';
  @observable categoryName: string | null | undefined = '';
  @observable categoryId: string | null | undefined = '';
  @observable constantName: string | null | undefined = '';
  @observable relatedType: TemplateTypeEnum | null = null;
  @observable direction: TemplateDirectionEnum | null = null;
  @observable to: string[] | null | undefined = [];
  @observable cc: string[] | null | undefined = [];
  @observable bcc: string[] | null | undefined = [];
  @observable createdBy: string | null | undefined = '';
  @observable createdAt: string | null | undefined = '';
  @observable updatedBy: string | null | undefined = '';
  @observable updatedAt: string | null | undefined = '';
  @observable shared = false;
  @observable readOnly = false;

  @observable locales: Map<TemplateLocaleEnum, Locales> = new Map();
  @observable selectedLocale = TemplateLocaleEnum.En;

  @action openCreateTemplatesModal = () => {
    this.mode = ModeEnum.CREATE;
    this.isOpen = true;
    this.resetFields();
  };

  @action openUpdateTemplatesModal = (templateGroup: TemplateGroupFragment) => {
    this.mode = ModeEnum.UPDATE;

    this.templateName = templateGroup.name;
    this.categoryName = templateGroup.category?.title;
    this.categoryId = templateGroup.category?.id;
    this.constantName = templateGroup.constantName;
    this.relatedType = templateGroup.type;
    this.direction = templateGroup.direction;
    this.to = templateGroup.recipients;
    this.cc = templateGroup.carbonCopy;
    this.bcc = templateGroup.blindCarbonCopy;
    this.createdAt = templateGroup.createdAt;
    this.createdBy = templateGroup.createdBy;
    this.updatedAt = templateGroup.updatedAt;
    this.updatedBy = templateGroup.updatedBy;
    this.shared = templateGroup.shared;
    this.readOnly = templateGroup.readOnly;

    this.locales = new Map(
      templateGroup.templates.map((template) => [
        template.locale,
        {
          locale: template.locale,
          content: template.content,
          subject: template.subject || '',
        },
      ]),
    );

    this.isOpen = true;
  };

  @action setFormFields = (form: FormInstance<TemplateModalFormValues>) => {
    form.setFieldsValue({
      [TemplateModalFormFieldEnum.TemplateName]: this.templateName ?? '',
      [TemplateModalFormFieldEnum.Category]: this.categoryName ?? '',
      [TemplateModalFormFieldEnum.RelatedType]: this.relatedType ?? '',
      [TemplateModalFormFieldEnum.Direction]: this.direction ?? '',
      [TemplateModalFormFieldEnum.Subject]: this.locales.get(this.selectedLocale)?.subject || '',
      [TemplateModalFormFieldEnum.Content]: this.locales.get(this.selectedLocale)?.content || '',
      [TemplateModalFormFieldEnum.To]: this.to || [],
      [TemplateModalFormFieldEnum.Cc]: this.cc || [],
      [TemplateModalFormFieldEnum.Bcc]: this.bcc || [],
      [TemplateModalFormFieldEnum.Shared]: this.shared,
    });
  };

  @action closeTemplatesModal = () => {
    this.isOpen = false;
    this.resetFields();
  };

  @action resetFields = () => {
    this.templateName = '';
    this.categoryName = '';
    this.categoryId = '';
    this.constantName = '';
    this.relatedType = null;
    this.direction = null;
    this.locales.clear();
    this.to = [];
    this.cc = [];
    this.bcc = [];
    this.createdAt = '';
    this.createdBy = '';
    this.updatedAt = '';
    this.updatedBy = '';
    this.shared = false;
  };

  @action setModalMode = (mode: ModeEnum) => {
    this.mode = mode;
  };

  @action setTemplateName = (form: FormInstance<TemplateModalFormValues>, templateName: string) => {
    form.setFieldValue(TemplateModalFormFieldEnum.TemplateName, templateName);
    this.templateName = templateName;
  };

  @action setTemplateCategory = (
    form: FormInstance<TemplateModalFormValues>,
    categoryName: string,
    categoryId: string,
  ) => {
    if (categoryName === TemplateCategoryEnum.WHATS_APP) {
      this.setRelatedType(form, TemplateTypeEnum.Whatsapp);
    } else if (categoryName === TemplateCategoryEnum.CUSTOM) {
      this.setRelatedType(form, TemplateTypeEnum.Custom);
    } else {
      this.setRelatedType(form, null);
    }

    form.setFieldValue(TemplateModalFormFieldEnum.Category, categoryName);
    this.categoryName = categoryName;
    this.categoryId = categoryId;
  };

  @action setTemplateCategoryId = (categoryId: string) => {
    this.categoryId = categoryId;
  };

  @action setRelatedType = (form: FormInstance<TemplateModalFormValues>, relatedType: TemplateTypeEnum | null) => {
    form.setFieldValue(TemplateModalFormFieldEnum.RelatedType, relatedType);
    this.relatedType = relatedType;
  };

  @action setDirection = (form: FormInstance<TemplateModalFormValues>, direction: TemplateDirectionEnum) => {
    form.setFieldValue(TemplateModalFormFieldEnum.Direction, direction);
    this.direction = direction;
  };

  @action setSelectedLocale(form: FormInstance<TemplateModalFormValues>, locale: TemplateLocaleEnum) {
    this.selectedLocale = locale;
    form.setFieldValue(TemplateModalFormFieldEnum.Locale, locale);
    form.setFieldValue(TemplateModalFormFieldEnum.Subject, this.locales.get(locale)?.subject);
    form.setFieldValue(TemplateModalFormFieldEnum.Content, this.locales.get(locale)?.content);
  }

  @action setLocales(locale: TemplateLocaleEnum, localeDataToUpdate: Partial<Locales>) {
    const currentlocaleData = this.locales.get(locale) || { subject: '', content: '', locale };
    this.locales.set(locale, { ...currentlocaleData, ...localeDataToUpdate });
  }

  @action setTo = (form: FormInstance<TemplateModalFormValues>, to: string[]) => {
    form.setFieldValue(TemplateModalFormFieldEnum.To, to);
    this.to = to;
  };

  @action setCc = (form: FormInstance<TemplateModalFormValues>, cc: string[]) => {
    form.setFieldValue(TemplateModalFormFieldEnum.Cc, cc);
    this.cc = cc;
  };

  @action setBcc = (form: FormInstance<TemplateModalFormValues>, bcc: string[]) => {
    form.setFieldValue(TemplateModalFormFieldEnum.Bcc, bcc);
    this.bcc = bcc;
  };

  @action setShared = (form: FormInstance<TemplateModalFormValues>, shared: boolean) => {
    form.setFieldValue(TemplateModalFormFieldEnum.Shared, shared);
    this.shared = shared;
  };

  hasContent = (locale: TemplateLocaleEnum) => {
    return Boolean(this.locales.get(locale)?.content);
  };

  @computed get getLocalesArray() {
    return Array.from(this.locales.values());
  }

  @computed get requestAttributes() {
    if (!this.categoryId || !this.direction || !this.templateName || !this.relatedType) {
      return null;
    }

    const createAttributes = {
      blindCarbonCopy: this.bcc,
      carbonCopy: this.cc,
      categoryId: this.categoryId,
      direction: this.direction,
      locales: this.getLocalesArray,
      name: this.templateName,
      recipients: this.to,
      shared: this.shared,
      type: this.relatedType,
    };

    const updateAttributes = {
      constantName: this.constantName,
      ...createAttributes,
    };

    return this.mode === ModeEnum.CREATE ? createAttributes : updateAttributes;
  }
}

export default TemplatesManagerModalStore;
