import PageLoaderModal from 'components/UI/Loader/PageLoader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'stores/RootStore';

const PageLoader = observer(() => {
  const { pageLoaderStore } = useStore();

  return <PageLoaderModal isVisible={pageLoaderStore.isVisible} title={pageLoaderStore.text} />;
});

export default PageLoader;
