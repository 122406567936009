import { MinusCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

interface Props {
  expanded: boolean;
  onCollapse: () => any;
  onExpand: () => any;
}

const ExpansionButton: React.FC<Props> = ({ expanded, onCollapse, onExpand }) => (
  <Button
    icon={expanded ? <MinusCircleFilled /> : <PlusCircleOutlined />}
    type="link"
    onClick={() => (expanded ? onCollapse() : onExpand())}
  />
);

export default ExpansionButton;
