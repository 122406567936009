import styled from '@emotion/styled';
import { LinkProps } from 'antd/lib/typography/Link';
import { Link } from 'components/UI/Link/styled';
import { colors } from 'components/UI/variables';

export interface ISidebarMenuItem extends LinkProps {
  href?: string;
  selected?: boolean;
  canceled?: boolean;
}

export const SidebarInner = styled.div`
  color: ${colors.gray9};
`;

export const SidebarMenu = styled.div`
  padding: 0;
`;

export const SidebarMenuItemAnchor = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 40px;
  color: ${colors.gray9};
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: ${colors.blueSky};
    color: ${colors.blue};
    transition: 0.2s;
  }
`;

export const SidebarMenuItemLink = styled(Link)<ISidebarMenuItem>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 40px;
  color: ${colors.gray9};
  cursor: pointer;
  transition: 0.2s;

  ${({ selected }) =>
    selected &&
    `
    background: ${colors.blueSky};
    border-right: 3px solid ${colors.blue};
    color: ${colors.blue};
  `}

  &:hover {
    background: ${colors.blueSky};
    color: ${colors.blue};
    transition: 0.2s;
  }
`;

export const SidebarMenuItem = styled.a<ISidebarMenuItem>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 40px;
  color: ${colors.gray9};
  cursor: pointer;
  transition: 0.2s;

  ${({ selected }) =>
    selected &&
    `
    background: ${colors.blueSky};
    border-right: 3px solid ${colors.blue};
    color: ${colors.blue};
  `}

  &:hover {
    background: ${colors.blueSky};
    color: ${colors.blue};
    transition: 0.2s;
  }
`;

export const SidebarTitle = styled.div`
  font-size: 14px;
  font-weight: 600;

  & > span {
    margin-right: 10px;
  }
`;
