import clsx from 'clsx';
import React from 'react';

import s from './EllipsisMiddle.module.less';

interface Props {
  suffixCount: number;
  children: string;
  className?: string;
}

export const EllipsisMiddle = ({ suffixCount, children, className, ...rest }: Props) => {
  const start = children.slice(0, children.length - suffixCount).trim();
  const suffix = children.slice(-suffixCount).trim();

  return (
    <span className={clsx(s.root, className)} title={children} {...rest}>
      <span className={s.start}>{start}</span>
      <span className={s.suffix}>{suffix}</span>
    </span>
  );
};
