import { Space } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React from 'react';

interface Props {
  presetSelector: React.ReactElement<SelectProps<number>>;
}

const PresetSelectorWrapper: React.FC<Props> = ({ presetSelector }) => (
  <h2 style={{ marginTop: 14, textAlign: 'center' }}>
    <Space direction="horizontal" size={8}>
      Please choose preset from the list:
      {presetSelector}
    </Space>
  </h2>
);

export default PresetSelectorWrapper;
