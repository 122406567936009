import { DownloadOutlined, WarningOutlined } from '@ant-design/icons';
import { Col, Empty as AntEmpty, Radio, Row, Tabs } from 'antd';
import { Button, If } from 'components/UI';
import PdfViewer from 'components/UI/PdfViewer';
import PreviewItem from 'components/UI/Preview/PreviewItem';
import ScsViewer from 'components/UI/ScsViewer';
import Spin from 'components/UI/Spin';
import { AttachmentsBucket, BucketItem, PreviewsTab } from 'interfaces';
import { first, isEmpty, last, map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { routesApi } from 'routes/api';
import { useStore } from 'stores/RootStore';
import useDownload from 'utils/hooks/useDownload';

import ImgPreviewBig from './ImgPreviewBig';
import {
  BucketFilename,
  BucketName,
  Divider,
  Empty,
  PdfViewerWrapper,
  RowPreviewBig,
  ScsViewerWrapper,
} from './styled';

interface Props {
  url: string;
  skipFetch?: boolean;
  onSkipCleaningComplete?: () => void;
}

const Content = observer<Props>(({ url, skipFetch, onSkipCleaningComplete }) => {
  const { previewsStore } = useStore();
  const downloader = useDownload();

  useEffect(() => {
    if (!skipFetch) {
      void previewsStore.fetch(url);
    }
  }, [previewsStore, url, skipFetch]);

  useEffect(() => {
    if (previewsStore.openingPreviewId && previewsStore.fetchStatus.isFinished) {
      void previewsStore.loadPreview(previewsStore.openingPreviewId);
    }
  });

  const download = (filename: string) => {
    if (previewsStore.currentPreview) {
      void downloader(routesApi.attachedFileDownloadPath(String(previewsStore.currentPreview.id)), filename);
    }
  };

  const openOriginal = () => {
    if (previewsStore.currentPreview?.parentId) {
      void previewsStore.loadPreview(previewsStore.currentPreview.parentId);
    }
  };

  const openCleaned = () => {
    if (previewsStore.currentPreview?.cleanedId) {
      void previewsStore.loadPreview(previewsStore.currentPreview.cleanedId);
    }
  };

  const skipCleaning = () => {
    if (previewsStore.currentPreview && !previewsStore.currentPreview.cleanedId) {
      void previewsStore.skipCleaning(previewsStore.currentPreview.id, url).then(() => {
        onSkipCleaningComplete?.();
      });
    }
  };

  const onClick = (item: BucketItem) => {
    void previewsStore.loadPreview(item.cleanedId || item.id);
  };

  if (previewsStore.fetchStatus.isProcessing || !previewsStore.fetchStatus.isFinished) {
    return <Spin size="large" />;
  }

  const isPDF = last(previewsStore.currentPreview?.name?.split('.'))?.toLowerCase() === 'pdf';

  const isSCS = !!String(previewsStore.currentPreview?.bigUrl).match(/.+\.(scs)[?]?/);

  const activeKey = (): PreviewsTab | undefined => {
    if (previewsStore.currentPreview) {
      return previewsStore.currentPreview.context === 'default' ? PreviewsTab.Drawings : PreviewsTab.Documents;
    }

    return first(Object.keys(previewsStore.schema)) as PreviewsTab | undefined;
  };

  const hasCleanedFile =
    previewsStore.currentPreview &&
    (previewsStore.currentPreview.cleanedId ||
      (previewsStore.currentPreview.parentId && !previewsStore.currentPreview.cleanedId));

  return (
    <Row style={{ height: '100%' }}>
      <Col sm={17} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {previewsStore.currentPreview && (
          <Row justify="space-between" style={{ alignItems: 'center' }}>
            <div>
              <Radio.Group style={{ marginBottom: '10px' }} value={previewsStore.isOriginal ? 'original' : 'cleaned'}>
                <Radio.Button value="original" onClick={openOriginal}>
                  Original file
                </Radio.Button>

                {hasCleanedFile ? (
                  <Radio.Button value="cleaned" onClick={openCleaned}>
                    Cleaned
                  </Radio.Button>
                ) : (
                  <Radio.Button value="cleaned" disabled>
                    <WarningOutlined style={{ marginRight: '5px' }} />
                    No Cleaned file
                  </Radio.Button>
                )}
              </Radio.Group>

              {!hasCleanedFile && (
                <Button
                  type="link"
                  onClick={skipCleaning}
                  disabled={previewsStore.isLoadingPreview}
                  style={{ paddingRight: 0 }}
                >
                  Skip cleaning
                </Button>
              )}
            </div>

            <Button
              type="link"
              onClick={() => download(previewsStore?.currentPreview?.name || '')}
              style={{ paddingRight: 0 }}
              icon={<DownloadOutlined />}
            >
              {previewsStore.currentPreview.name}
            </Button>
          </Row>
        )}

        <RowPreviewBig justify="center">
          {previewsStore.isLoadingPreview && <Spin size="large" />}

          <If condition={!previewsStore.isLoadingPreview}>
            {previewsStore.currentPreview ? (
              <>
                {isPDF && (
                  <PdfViewerWrapper>
                    <PdfViewer src={previewsStore.currentPreview.bigUrl} />
                  </PdfViewerWrapper>
                )}
                {isSCS && (
                  <ScsViewerWrapper>
                    <ScsViewer
                      src={previewsStore.currentPreview.bigUrl}
                      name={previewsStore.currentPreview.name}
                      dimensions={previewsStore.currentPreview.dimensions}
                    />
                  </ScsViewerWrapper>
                )}
                {!isPDF && !isSCS && (
                  <ImgPreviewBig
                    src={previewsStore.currentPreview.bigUrl}
                    ext={last(previewsStore.currentPreview.name.split('.'))}
                  />
                )}
              </>
            ) : (
              <Empty image={AntEmpty.PRESENTED_IMAGE_SIMPLE} description="No preview selected" />
            )}
          </If>
        </RowPreviewBig>
      </Col>

      <Col sm={1}>
        <Divider />
      </Col>

      <Col sm={6}>
        {!isEmpty(previewsStore.schema) && (
          <Tabs defaultActiveKey={activeKey()} size="small">
            {map(previewsStore.schema, (buckets: AttachmentsBucket[], tab: string) => (
              <Tabs.TabPane tab={tab} key={tab} disabled={isEmpty(buckets)}>
                {map(buckets, (bucket: AttachmentsBucket) => (
                  <Row key={`${Number(bucket.bucketId)}-${bucket.bucketName}`}>
                    <Col sm={24}>
                      <BucketName>
                        {bucket.bucketId && <span>{`ID-${bucket.bucketId} `}</span>}
                        {bucket.bucketName}
                      </BucketName>
                    </Col>

                    <Col sm={24} style={{ marginTop: '5px' }}>
                      <Row gutter={[16, 16]}>
                        {map(bucket.items, (item: BucketItem) => (
                          <Col sm={8} key={item.id}>
                            <Row
                              justify="center"
                              style={{
                                flexDirection: 'column',
                                textAlign: 'center',
                              }}
                            >
                              <PreviewItem preview={item} onClick={() => onClick(item)} />
                              <BucketFilename active={item.id === previewsStore.currentPreview?.id}>
                                {item.name}
                              </BucketFilename>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                ))}
              </Tabs.TabPane>
            ))}
          </Tabs>
        )}
      </Col>
    </Row>
  );
});

export default Content;
