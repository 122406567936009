import {
  EMessengerChatSourceType,
  EMessengerScreenName,
  EMessengerTasksFilterField,
  EMessengerTasksSortOrder,
} from '@xometry/ui';
import { amplitudeLogger } from 'amplitude';
import { AmplitudeAttributeKey, AmplitudeMessengerEvent, MessengerEntryPoint } from 'collections/amplitudeEvents';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStore } from 'stores/RootStore';
import {
  ESideOverlayType,
  IMessengerInitialMessageIdParams,
  IMessengerInitialScreenParams,
} from 'stores/SideOverlay/SideOverlayStore';
import { getURLSearchParamsMap } from 'utils/url';

import { DeviceTypes, useDeviceType } from './useDeviceType';
import { useListenCommonMessengerEvents } from './useListenCommonMessengerEvents';

const isSourceType = (sourceType: string | undefined): sourceType is EMessengerChatSourceType => {
  const tmpSourceType = sourceType as EMessengerChatSourceType | undefined;

  if (!tmpSourceType) {
    return false;
  }

  return Object.values(EMessengerChatSourceType).includes(tmpSourceType);
};

const getInitialParams = (
  options: Partial<Record<string, string>>,
): IMessengerInitialScreenParams | IMessengerInitialMessageIdParams => {
  const {
    messenger: messengerScreenName,
    initialMessageId,
    sourceType,
    sourceId,
    dealId,
    dealTitle,
    sourceTitle,
  } = options;

  switch (messengerScreenName) {
    case EMessengerScreenName.TASKS_CENTER: {
      return {
        initialScreen: {
          name: messengerScreenName,
          params: {
            filter: Object.entries(options).reduce<Record<string, unknown>>((prev, current) => {
              const [key, value] = current;

              if (value && Object.values(EMessengerTasksFilterField).includes(key as EMessengerTasksFilterField)) {
                const propKey = key;
                prev[propKey] = JSON.parse(value);
              }

              return prev;
            }, {}),
            sort: { orderBy: EMessengerTasksSortOrder.DESC },
          },
        },
      };
    }

    case EMessengerScreenName.CHAT: {
      if (initialMessageId) {
        return {
          initialMessageId,
        };
      }

      if (isSourceType(sourceType) && sourceId && sourceTitle && dealId) {
        return {
          initialScreen: {
            name: EMessengerScreenName.CHAT,
            params: {
              chatSource: {
                id: sourceId,
                dealId,
                sourceType,
                sourceId,
                dealTitle: dealTitle,
                title: sourceTitle,
              },
            },
          },
        };
      }
    }
  }

  // We don't know which chat to open. So, open Subscriptions for last unread messages.
  return {
    initialScreen: {
      name: EMessengerScreenName.SUBSCRIPTIONS,
    },
  };
};

export const useMessengerInit = () => {
  const {
    sideOverlayStore: { openSideOverlay },
  } = useStore();
  const location = useLocation();
  const history = useHistory();
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  const hashParamsMap = getURLSearchParamsMap(location.hash.substring(1));
  useEffect(() => {
    if (hashParamsMap?.messenger && Object.values(EMessengerScreenName).map(String).includes(hashParamsMap.messenger)) {
      openSideOverlay({
        type: ESideOverlayType.Messenger,
        ...getInitialParams(hashParamsMap),
      });

      amplitudeLogger({
        event: AmplitudeMessengerEvent.MESSENGER_OPENED,
        attrs: {
          [AmplitudeAttributeKey.ENTRY_POINT]: MessengerEntryPoint.LINK,
        },
      });

      if (!isMobile) {
        history.replace(location.pathname + location.search);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useListenCommonMessengerEvents();
};
