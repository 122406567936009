import { gql } from 'apollo-boost';

import {
  QUALITY_CONTROL_FRAGMENT,
  QUALITY_CONTROL_MEASUREMENT_FRAGMENT,
  QUALITY_CONTROL_POSITION_FRAGMENT,
} from '../fragments';

export const QUALITY_CONTROLS_CREATE = gql`
  mutation qualityControlsCreate($attributes: OmsInputsQualityControlType!) {
    qualityControlsCreate(input: { attributes: $attributes }) {
      qualityControl {
        ...qualityControlFragment
        positions {
          ...qualityControlsPositionFragment
        }
      }
    }
  }
  ${QUALITY_CONTROL_FRAGMENT}
  ${QUALITY_CONTROL_POSITION_FRAGMENT}
`;

export const QUALITY_CONTROLS_UPDATE = gql`
  mutation qualityControlsUpdate($id: Int!, $attributes: OmsInputsQualityControlType!) {
    qualityControlsUpdate(input: { id: $id, attributes: $attributes }) {
      qualityControl {
        ...qualityControlFragment
        positions {
          ...qualityControlsPositionFragment
        }
      }
    }
  }
  ${QUALITY_CONTROL_FRAGMENT}
  ${QUALITY_CONTROL_POSITION_FRAGMENT}
`;

export const QUALITY_CONTROLS_POSITION_UPDATE = gql`
  mutation qualityControlsPositionUpdate($id: Int!, $attributes: OmsInputsQualityControlsPositionType!) {
    qualityControlsPositionUpdate(input: { id: $id, attributes: $attributes }) {
      qualityControlPosition {
        ...qualityControlsPositionFragment
        measurements {
          ...qualityControlsMeasurementFragment
        }
      }
    }
  }
  ${QUALITY_CONTROL_POSITION_FRAGMENT}
  ${QUALITY_CONTROL_MEASUREMENT_FRAGMENT}
`;

export const QUALITY_CONTROLS_POSITION_DELETE = gql`
  mutation qualityControlsPositionDelete($id: Int!) {
    qualityControlsPositionDelete(input: { id: $id }) {
      success
    }
  }
`;

export const QUALITY_CONTROLS_POSITION_UPDATE_MEASUREMENTS = gql`
  mutation qualityControlsPositionsUpdateMeasurements(
    $id: ID!
    $attributes: [OmsInputsQualityControlsMeasurementType]
    $previewBase64: String
  ) {
    qualityControlsPositionsUpdateMeasurements(
      input: { id: $id, attributes: $attributes, previewBase64: $previewBase64 }
    ) {
      qualityControlPosition {
        ...qualityControlsPositionFragment
        measurements {
          ...qualityControlsMeasurementFragment
        }
      }
    }
  }
  ${QUALITY_CONTROL_POSITION_FRAGMENT}
  ${QUALITY_CONTROL_MEASUREMENT_FRAGMENT}
`;
