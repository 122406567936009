import { OCAddress } from 'interfaces';
import { IAddress } from 'interfaces/stores/Shipping';

export const convertOcToShAddress = (address: OCAddress): IAddress => {
  return {
    id: String(address.id),
    address: address.address || '',
    city: address.city || '',
    zip: address.zip || '',
    country: address.country || '',
    phone: address.phone || address.contactPhone || '',
    email: address.email || address.personEmail || '',
    name: address.name || address.contactName || '',
    comment: address.comment || '',
    internalComment: address.internalComment || '',
    company: address.company || address.companyName || '',
  };
};
