import { Spin } from 'antd';
import AttachedFilesUploader from 'components/shared/AttachedFilesUploader';
import { ActionALink, Actions, ActionsBlock, ActionsBlockTitle } from 'components/shared/Sidebar/Right/styled';
import { HL } from 'components/UI/shared/styled';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { routesApi } from 'routes/api';
import useDownload from 'utils/hooks/useDownload';

interface RouteParams {
  providerId: string;
}

const SidebarRightProvider = observer(() => {
  const { providerId } = useParams<RouteParams>();
  const [loading, setLoading] = useState(false);
  const downloadHelper = useDownload();

  if (!providerId) {
    return null;
  }

  const onRatingDownload = async () => {
    if (loading) return;

    setLoading(true);
    await downloadHelper(routesApi.providerRatingInfoPdf(providerId), `Rating Info-PR-${providerId}.pdf`);
    setLoading(false);
  };

  return (
    <>
      <HL />
      <AttachedFilesUploader
        fetchUrl={routesApi.entityAttachments('providers', providerId)}
        uploadUrl={routesApi.entityAttachmentsUploadPath('providers', providerId)}
        destroyUrl={routesApi.entityAttachmentsDestroyPath('providers', providerId)}
      />
      <HL />
      <ActionsBlock>
        <ActionsBlockTitle>Related Actions:</ActionsBlockTitle>

        <Actions>
          <ActionALink
            onClick={() => {
              void onRatingDownload();
            }}
          >
            Rating Info (pdf)
            {loading && <Spin />}
          </ActionALink>
        </Actions>
      </ActionsBlock>
    </>
  );
});

export default SidebarRightProvider;
