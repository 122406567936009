import { Form, FormInstance } from 'antd';
import {
  Column,
  ITopFilter,
  WorkspaceGraphQLField,
  WorkspaceGraphQLObjectField,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { FilterItem, useFilters } from 'components/Workspaces/General/shared/GeneralWorkspace/FiltersStore';
import {
  FilterItemGroupValue,
  FilterItemSingleValue,
} from 'components/Workspaces/General/shared/GeneralWorkspace/FiltersStore/FilterItem';
import { compact, every, forEach, isArray, isEmpty, isNumber, last, map, mapValues, reduce } from 'lodash-es';
import { toJS } from 'mobx';
import React, { PropsWithChildren } from 'react';

import TopFilter from './TopFilter';

interface Props {
  attributes: WorkspaceGraphQLField[];
  columns: Column[];
  form: FormInstance;
  objectAttributes: WorkspaceGraphQLObjectField[];
  topFilters: ITopFilter[];
  onFiltersUpdate?: (filters: FilterItem) => any;
}

const findFilterItemForAttribute = (filter: ITopFilter, filterItem: FilterItem) => {
  if (filterItem.value.kind === 'filter') {
    if (filterItem.value.filter.attribute === filter.attribute && filterItem.value.filter.pattern === filter.pattern) {
      return filterItem;
    }

    return undefined;
  }

  const children: FilterItem[] = compact(
    map(filterItem.value.children, (child) => findFilterItemForAttribute(filter, child)),
  );

  return children.length > 0 ? children[0] : undefined;
};

const TopFiltersForm: React.FC<PropsWithChildren<Props>> = ({
  attributes,
  children,
  columns,
  form,
  objectAttributes,
  topFilters,
  onFiltersUpdate,
}) => {
  const filters = useFilters();

  const handleFormSubmit = (formValues: Record<string, FilterItem>) => {
    const filtersClone = filters.deepClone();

    const cloneFilterItems = reduce(
      topFilters,
      (mem, filter) => ({
        ...mem,
        [filter.attribute]: findFilterItemForAttribute(filter, filtersClone),
      }),
      {} as Record<string, FilterItem | undefined>,
    );

    forEach(topFilters, (filter) => {
      let filterItem: FilterItem;

      if (cloneFilterItems[filter.attribute] != null) {
        filterItem = cloneFilterItems[filter.attribute] as FilterItem;
      } else if (filtersClone.value.kind === 'filter' && filtersClone.value.filter.attribute == null) {
        // No filters applied, assign values to current empty filter
        filterItem = filtersClone;
      } else {
        // Other filters exist, add new filtering condition
        filtersClone.addChild('AND');
        filterItem = last((filtersClone.value as FilterItemGroupValue).children) as FilterItem;
      }

      const values = formValues[filter.attribute];

      if (every(values, (v) => isEmpty(v) && !isNumber(v))) {
        if (filtersClone.value.kind === 'group') filtersClone.removeChild(filterItem.id);
        else filtersClone.setFilter({ values: [] });
      } else {
        filterItem.setFilter({ ...filter, values: isArray(values) ? values : [values] });
      }
    });
    filters.assignRoot(filtersClone);

    if (onFiltersUpdate != null) onFiltersUpdate(filters);
  };

  const filterItems = reduce(
    topFilters,
    (mem, filter) => ({
      ...mem,
      [filter.attribute]: findFilterItemForAttribute(filter, filters),
    }),
    {} as Record<string, FilterItem | undefined>,
  );

  const initialValues = mapValues(filterItems, (filterItem) =>
    filterItem == null ? filterItem : toJS((filterItem.value as FilterItemSingleValue).filter.values),
  );

  return (
    <Form
      className="top-filters"
      name="topFiltersForm"
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={handleFormSubmit}
      onSubmitCapture={(e) => {
        e.preventDefault();
        form.submit();
      }}
    >
      {children != null
        ? children
        : map(topFilters, (filterSettings) => (
            <TopFilter
              key={`${filterSettings.attribute}${filterSettings.pattern}`}
              attributes={attributes}
              columns={columns}
              objectAttributes={objectAttributes}
              filterSettings={filterSettings}
              onFiltersUpdate={onFiltersUpdate}
            />
          ))}
    </Form>
  );
};

export default TopFiltersForm;
