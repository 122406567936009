import styled from '@emotion/styled';
import { Spin } from 'antd';
import { bc } from 'components/UI/colors';

const size = '90px';

interface Props {
  isCleaned?: boolean;
  isSelected?: boolean;
  imgSize?: string;
}

export const PreviewItemDiv = styled.div<Props>`
  cursor: pointer;

  display: flex;
  justify-content: center;
  position: relative;

  img {
    width: ${size};
    height: ${size};
    border: 1px solid #e8e8e8;
    display: block;
  }

  ${({ imgSize }) =>
    imgSize &&
    `
    img {
      width: ${imgSize};
      height: ${imgSize};
    }
  `}

  ${({ isCleaned }) =>
    !isCleaned &&
    `
    img {
      border-color: #ad4949;
    }
  `}

  ${({ isSelected }) =>
    isSelected &&
    `
    > div {
      border-color: ${bc.link};
    }
    > span {
      border-color: ${bc.link} !important;
    }
  `}
`;

interface NotCleanedProps {
  imgSize?: string;
}

export const NotCleaned = styled.span<NotCleanedProps>`
  background-color: #ad4949;
  display: block;
  margin: 0 auto;
  font-size: 10px;
  color: white;
  position: absolute;
  text-align: center;

  ${({ imgSize }) =>
    `
    width: ${imgSize || size};
  `}
`;

interface PreviewDivProps {
  imgSize?: string;
}

export const PreviewDiv = styled.div<PreviewDivProps>`
  text-align: center;
  text-transform: none;

  ${({ imgSize }) =>
    imgSize
      ? `
        > div:not(:last-of-type) {
          margin-right: 5px;
        }
      `
      : `
        > div:not(:last-of-type) {
          margin-bottom: 5px;
        }
  `}
`;

export const DivImg = styled.div<PreviewDivProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8e8e8;

  ${({ imgSize }) =>
    `
    width: ${imgSize || size};
    height: ${imgSize || size};
  `}

  ${({ imgSize }) =>
    imgSize &&
    `
    .no-preview-text {
      display: none;
    }
  `}

  svg {
    font-size: 35px;
  }
`;

interface PreviewShowMoreProps {
  imgSize?: string;
}

export const PreviewShowMore = styled.div<PreviewShowMoreProps>`
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #e8e8e8;

  ${({ imgSize }) =>
    `
    width: ${imgSize || size};
    height: ${imgSize || size};
  `}

  ${({ imgSize }) =>
    imgSize &&
    `
      button {
        font-size: 10px;
      }
  `}
`;

export const OverlaySpinner = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
