import { Part, PartsSortOptions } from 'interfaces';
import { orderBy } from 'lodash-es';

export const sortBy = (parts: Part[], by: PartsSortOptions): Part[] => {
  switch (by) {
    case PartsSortOptions.ID_ASC:
      return orderBy(parts, 'id', ['asc']);
    case PartsSortOptions.ID_DESC:
      return orderBy(parts, 'id', ['desc']);
    case PartsSortOptions.CREATION_ASC:
      return orderBy(parts, 'createdAt', ['asc']);
    case PartsSortOptions.CREATION_DESC:
      return orderBy(parts, 'createdAt', ['desc']);
    case PartsSortOptions.POSITION_ASC:
      return orderBy(parts, 'position', ['asc']);
    case PartsSortOptions.POSITION_DESC:
      return orderBy(parts, 'position', ['desc']);
    default:
      return [];
  }
};
