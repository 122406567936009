import { notification } from 'antd';
import React from 'react';

enum NotificationType {
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

const notify = (type: NotificationType, message: React.ReactNode, description?: string) => {
  notification[type]({
    message,
    description,
  });
};

const info = (message: React.ReactNode, description?: string) => {
  notify(NotificationType.Info, message, description);
};

const error = (message: React.ReactNode = 'Something went wrong', description?: string) => {
  notify(NotificationType.Error, message, description);
};

const success = (message: React.ReactNode, description?: string) => {
  notify(NotificationType.Success, message, description);
};

const Notification = {
  Info: info,
  Error: error,
  Success: success,
};

export default Notification;
