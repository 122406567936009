import { UploadOutlined } from '@ant-design/icons';
import { Checkbox, Form, Row, Spin, Upload } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormInstance } from 'antd/lib/form';
import { Button } from 'components/UI';
import { humanFileSize } from 'components/utils/humanFileSize';
import { MailerAttach } from 'interfaces/stores/MailerStore';
import { map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useStore } from 'stores/RootStore';

import { Container, FilesGroup, Item } from './styled';

interface Props {
  form: FormInstance;
  withUploads?: boolean;
  onUpload?: (file: File) => void;
}

const Attachments: FC<Props> = observer(({ form, withUploads, onUpload }) => {
  const { mailerStore } = useStore();

  const onChangeAttachedFile = (event: CheckboxChangeEvent): void => {
    const checked = event.target.checked;
    const value = event.target.value as number;
    const attachments = form.getFieldValue('attachments') as number[];

    if (checked) {
      form.setFieldsValue({
        attachments: [...attachments, value],
      });
    } else {
      form.setFieldsValue({
        attachments: attachments.filter((id: number) => id !== value),
      });
    }
  };

  return (
    <Container>
      <Row>
        <Form.Item name="documents" noStyle>
          <FilesGroup onChange={(v) => mailerStore.setSelectedFiles('documents', v)}>
            {map(mailerStore.documents, (doc: MailerAttach) => (
              <Item key={doc.value}>
                <Checkbox value={doc.value}>{`${doc.label} (${humanFileSize(doc.size || 0)})`}</Checkbox>
              </Item>
            ))}
          </FilesGroup>
        </Form.Item>

        <Form.Item name="tempDocuments" noStyle>
          <FilesGroup onChange={(v) => mailerStore.setSelectedFiles('tempDocuments', v)}>
            {map(mailerStore.tempDocuments, (doc: MailerAttach) => (
              <Item key={doc.value}>
                <Checkbox value={doc.value}>{`${doc.label} (${humanFileSize(doc.size || 0)})`}</Checkbox>
              </Item>
            ))}
          </FilesGroup>
        </Form.Item>

        <FilesGroup onChange={(v) => mailerStore.setSelectedFiles('attachments', v)}>
          {map(mailerStore.attachments, (attach: MailerAttach) => (
            <Item key={attach.value}>
              <Checkbox value={attach.value} onChange={onChangeAttachedFile}>
                {`${attach.label} (${humanFileSize(attach.size || 0)})`}
              </Checkbox>
            </Item>
          ))}
        </FilesGroup>

        {withUploads && (
          <Upload
            name="attachment"
            customRequest={() => null}
            onChange={({ file }) => {
              if (onUpload && file.originFileObj instanceof File) {
                onUpload(file.originFileObj);
              }
            }}
            showUploadList={false}
            multiple
          >
            <Button type="primary" disabled={mailerStore.isUploadInProcess}>
              <UploadOutlined />
              {mailerStore.isUploadInProcess ? <Spin size="small" /> : 'Upload file'}
            </Button>
          </Upload>
        )}
      </Row>
    </Container>
  );
});

export default Attachments;
