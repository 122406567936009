import { useCallback, useState } from 'react';

export default () => {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = useCallback(() => setModalVisible(true), []);
  const closeModal = useCallback(() => setModalVisible(false), []);
  const toggleVisibility = useCallback(() => {
    setModalVisible((openModal) => !openModal);
  }, []);

  return { modalVisible, openModal, closeModal, setModalVisible, toggleVisibility };
};
