import { QueryNameKey } from 'components/Workspaces/collections';
import { Column } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { PresetOptionData } from 'components/Workspaces/General/shared/GeneralWorkspace/withPresets';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import React from 'react';

import EditPresetForm from './EditPresetForm';
import NewPresetForm from './NewPresetForm';

interface Props {
  currentColumns: Column[];
  currentPreset: WorkspacePreset;
  queryName: QueryNameKey;
  presetsList: PresetOptionData[];
  onPresetCreate: (name: string, isPublic: boolean, columns: Column[]) => void;
  onPresetUpdate: (preset: ExistingWorkspacePreset) => void;
  onPresetDelete: (preset: ExistingWorkspacePreset) => void;
  onPresetCopy: (preset: ExistingWorkspacePreset) => void;
}

const PresetForm: React.FC<Props> = ({
  currentColumns,
  currentPreset,
  queryName,
  presetsList,
  onPresetCreate,
  onPresetCopy,
  onPresetUpdate,
  onPresetDelete,
}) => (
  <>
    {currentPreset.id == null ? (
      <NewPresetForm currentColumns={currentColumns} onPresetCreate={onPresetCreate} />
    ) : (
      <EditPresetForm
        currentColumns={currentColumns}
        currentPreset={currentPreset}
        onPresetUpdate={onPresetUpdate}
        onPresetDelete={onPresetDelete}
        onPresetCopy={onPresetCopy}
        queryName={queryName}
        presetsList={presetsList}
      />
    )}
  </>
);

export default PresetForm;
