import { ColumnsMap } from 'components/Workspaces/collections';

interface PrepareSelectiveFieldsParams<T> {
  columns: ColumnsMap<T>;
  enabledColumns: string[];
}

export function prepareSelectiveFields<T extends object>({
  columns,
  enabledColumns,
}: PrepareSelectiveFieldsParams<T>): string[] {
  const result = new Set<string>();
  // gql won't build query if there no fields listed
  // so as a workaround we use array with 'id' attribute only
  result.add('id');

  enabledColumns.forEach((columnKey) => {
    const column = columns[columnKey];
    // Remove nested keys in column key.
    const key = columnKey.split('__')[0];

    if (column) {
      if (column.synthetic) {
        if (column.renderSelectiveFields) {
          column.renderSelectiveFields.forEach((item) => result.add(item as string));
        }
      } else {
        result.add(key);
        if (column.decoratorSelectiveFields) {
          column.decoratorSelectiveFields.forEach((item) => result.add(item as string));
        }
      }
    } else {
      // In case when we have fields from backend, but have not added them to columns.
      result.add(key);
    }
  });

  return [...result];
}
