import { gql } from 'apollo-boost';

/** @deprecated use erpPermissionFragment instead */
export const ERP_PERMISSION_FRAGMENT = gql`
  fragment erpPermissionFragment on ErpPermission {
    id
    group
    action
    name
    label
  }
`;
