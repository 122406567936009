import { FormInstance } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { useEffect, useRef, useState } from 'react';

import { TemplateModalFormFieldEnum, TemplateModalFormValues } from './TemplatesManagerModal.types';

interface Args {
  form: FormInstance<TemplateModalFormValues>;
  handleContentChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubjectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const useTemplatesPlaceholders = ({ form, handleContentChange, handleSubjectChange }: Args) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const textAreaElement = textAreaRef.current;
  const setRef = (instance: TextAreaRef | null) => {
    if (instance) {
      textAreaRef.current = instance.resizableTextArea?.textArea ?? null;
    }
  };

  const updateCursorPosition = (e: MouseEvent | KeyboardEvent | React.ChangeEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    setCursorPosition(target.selectionStart);
  };

  const handleSelectChange = (field: TemplateModalFormFieldEnum) => (value: string) => {
    const currentValue = (form.getFieldValue(field) as string) || '';
    const newValue = currentValue.slice(0, cursorPosition) + value + currentValue.slice(cursorPosition);

    form.setFieldValue(field, newValue);

    if (field === TemplateModalFormFieldEnum.Content) {
      handleContentChange({ target: { value: newValue } } as React.ChangeEvent<HTMLTextAreaElement>);
    }

    if (field === TemplateModalFormFieldEnum.Subject) {
      handleSubjectChange({ target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>);
    }

    const newCursorPosition = cursorPosition + value.length;
    setCursorPosition(newCursorPosition);

    requestAnimationFrame(() => {
      if (textAreaElement) {
        textAreaElement.setSelectionRange(newCursorPosition, newCursorPosition);
        textAreaElement.focus();
      }
    });
  };

  useEffect(() => {
    if (textAreaElement) {
      textAreaElement.addEventListener('click', updateCursorPosition);
      textAreaElement.addEventListener('keyup', updateCursorPosition);
    }

    return () => {
      if (textAreaElement) {
        textAreaElement.removeEventListener('click', updateCursorPosition);
        textAreaElement.removeEventListener('keyup', updateCursorPosition);
      }
    };
  }, [textAreaElement]);

  return {
    setRef,
    handleSelectChange,
    updateCursorPosition,
  };
};
