import { WarningOutlined } from '@ant-design/icons';
import { bc } from 'components/UI/variables';
import React from 'react';

interface NoPreviewProps {
  ext?: string;
}

const NoPreview: React.FC<NoPreviewProps> = ({ ext }) => (
  <>
    <WarningOutlined style={{ color: bc.border }} />
    <span className="no-preview-text">{ext ? `${ext.toUpperCase()} - no preview` : 'No preview'}</span>
  </>
);

export default NoPreview;
