import { gql } from 'apollo-boost';

export const WORKSPACE_PRESET_FRAGMENT = gql`
  fragment workspacePresetFragment on WorkspacePreset {
    id
    name
    workspaceName
    public

    query
    columns
    exportColumns

    createdAt
    updatedAt

    sortColumn
    sortDirection

    owner {
      id
      name
    }

    lastUpdatedBy {
      id
      name
    }
  }
`;
