import Notification from 'components/UI/Notification';
import { ServerUpdateInvoice } from 'interfaces';
import { action } from 'mobx';
import { routes } from 'routes';
import CreatableStore from 'stores/shared/CreatableStore';

import { mapServerInvoice } from './utils';

interface CreatePayload {
  id: string;
}

class DealOutInvoiceStore extends CreatableStore {
  dealId?: string;

  @action assignOnCreate = (payload: CreatePayload): void => {
    if (this.dealId && payload.id) {
      Notification.Success('Invoice has been created');

      this.rootStore.dealOutInvoicePositionsSourcesStore.reset();

      this.rootStore.history.push(routes.editOutcomingInvoicePath(this.dealId, payload.id));
    }
  };

  mapData = (data: any): ServerUpdateInvoice => mapServerInvoice(data, { filterDestroyed: true });
}

export default DealOutInvoiceStore;
