import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';

export interface XometryAddress {
  address: string;
  city: string;
  zip: string;
  country: string;
  company: string;
  phone: string;
  name: string;
}

export const xometryAddressPoland: XometryAddress = {
  address: 'c/o ul. Kasztanowa 6A/1',
  city: 'Mlodasko',
  company: 'Xometry Europe GmbH',
  country: 'Poland',
  name: 'Marcin Chyla',
  phone: '+48 695 332 666',
  zip: '64-542',
};

const xometryAddressEurope: XometryAddress = {
  company: 'Xometry Europe GmbH',
  name: 'Dmitry Kafidov',
  phone: '+49 893 803 4818 *14',
  address: 'Ada-Lovelace-Str. 9',
  city: 'Ottobrunn',
  zip: '85521',
  country: 'Germany',
};

const xometryAddressTurkey: XometryAddress = {
  address: 'Yeşilbağlar Mah. D 100 Bul. Pendik Pera Residence A Blok No: 20B Pendik',
  city: 'Istanbul',
  phone: '+90 212 221 06 35',
  company: 'XOMETRY TR TEKNOLOJİ ANONİM ŞİRKETİ',
  country: 'Turkey',
  zip: '34893',
  name: 'Ali Karagoz',
};

const xometryAddressUK: XometryAddress = {
  address: 'Unit 2 Park Road, City Park West',
  city: 'Chelmsford',
  phone: '+44 1245 840 035',
  company: 'Xometry UK Ltd',
  country: 'United Kingdom',
  zip: 'CM1 1HF',
  name: 'Dmitry Kafidov',
};

const defaultXometryAddressByRegion: Record<Regions, XometryAddress> = {
  [Regions.EU]: xometryAddressEurope,
  [Regions.TR]: xometryAddressTurkey,
  [Regions.UK]: xometryAddressUK,
};

export const defaultXometryAddress = defaultXometryAddressByRegion[CURRENT_REGION];

const xometryShippingAddressesEU: Record<string, XometryAddress> = {
  Ottobrunn: xometryAddressEurope,
  Glonn: {
    company: 'Xometry Europe GmbH',
    name: 'Dmitry Kafidov',
    phone: '+49 893 803 4818 *14',
    address: 'Steinhausen 20',
    city: 'Glonn',
    zip: '85625',
    country: 'Germany',
  },
  'DB Schenker': {
    company: 'Xometry Europe GmbH',
    name: 'Dmitry Kafidov',
    phone: '+49 893 803 4818 *14',
    address: 'Dieselstr. 1, D-85386 Eching',
    city: 'Eching',
    zip: '85386',
    country: 'Germany',
  },
};

const xometryShippingAddressesTR: Record<string, XometryAddress> = {
  Istanbul: xometryAddressTurkey,
};

const xometryShippingAddressesUK: Record<string, XometryAddress> = {
  Chelmsford: xometryAddressUK,
};

const xometryShippingAddressesByRegion: Record<Regions, Record<string, XometryAddress>> = {
  [Regions.EU]: xometryShippingAddressesEU,
  [Regions.TR]: xometryShippingAddressesTR,
  [Regions.UK]: xometryShippingAddressesUK,
};

export const xometryShippingAddresses = xometryShippingAddressesByRegion[CURRENT_REGION];

export const xometryCustomsEmail = 'customs@xometry.de';
