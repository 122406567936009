/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import {
  Column,
  GraphQLScalarType,
  IDataItem,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { formatNumber } from 'components/Workspaces/utils';
import { find, get } from 'lodash-es';
import moment from 'moment';
import React from 'react';

const alignMap = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
};

const formatValue = (value: string | number | undefined, columnType: string) => {
  if (value == null) return value;

  switch (columnType) {
    case GraphQLScalarType.ISO8601:
      return moment(value).format('DD.MM.YYYY HH:mm');
    case GraphQLScalarType.ISO8601Date:
      return moment(value).format('DD.MM.YYYY');
    case GraphQLScalarType.Boolean:
      return value ? <CheckOutlined /> : <CloseOutlined />;
    case GraphQLScalarType.Float:
    case GraphQLScalarType.Int:
      return formatNumber(value as number);
    default:
      return value;
  }
};

interface Props {
  cellRef?: React.Ref<HTMLDivElement>;
  colIndex: number;
  column: Column;
  item: IDataItem;
  recordIndex: number;
  rowIndex: number;
  selected?: boolean;
  onClick?: () => any;
}

const TableCell: React.FC<Props> = ({ cellRef, colIndex, column, item, recordIndex, rowIndex, selected, onClick }) => {
  const align = column.settings?.align ?? 'right';

  let cellContent: React.ReactNode;

  const handleCellClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onClick == null) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const linkTarget = find(e.nativeEvent.composedPath(), (el: any) => el.tagName === 'A');

    if (linkTarget != null) return;

    onClick();
  };

  const customProps = column.settings?.cellWrapperProps?.(item.data);
  const { className: customPropsClassName, style: customPropsStyle, ...rest } = customProps ?? { className: undefined };

  const cellClassName = clsx('table-cell', customPropsClassName, {
    nowrap: column.settings?.nowrap,
    odd: recordIndex % 2 > 0,
    even: recordIndex % 2 === 0,
    selected,
  });

  if (column.synthetic) {
    cellContent = <div>{column.settings.render(item.data)}</div>;
  } else {
    let value: React.ReactNode;
    const decorator = column.settings?.decorator;

    if (decorator != null) {
      value = decorator(item.data);
    } else {
      const rawValue = get(item.data, column.key.split('__')) as string | number | undefined;

      value = formatValue(rawValue, column.type);
    }

    cellContent = <div>{value}</div>;
  }

  return (
    <div
      className={cellClassName}
      style={{
        gridRow: rowIndex,
        gridColumn: colIndex + 1,
        justifyContent: alignMap[align],
        textAlign: align,
        ...customPropsStyle,
      }}
      {...rest}
      onClick={handleCellClick}
      ref={cellRef}
      role="gridcell"
    >
      {cellContent}
    </div>
  );
};

export default TableCell;
