import { gql } from 'apollo-boost';

export const FILE_FRAGMENT = gql`
  fragment fileFragment on AttachedFile {
    id
    context
    isCleaned
    isStp
    name
    parentId
    uid
    url
    largeUrl
  }
`;

export const PROVIDER_PREQUOTE_FRAGMENT = gql`
  fragment providerPrequoteFragment on Provider {
    id
    name
  }
`;

export const PART_FRAGMENT = gql`
  fragment partFragment on Part {
    id
    name
    position
    dealId
    material
    postProcessing
    preQuoted
    preQuotedBy {
      ...providerPrequoteFragment
    }
    preQuotedComment
    samplesNeeded
    samplesQuantity
    samplesComment
    measurementProtocol
    dimensions
    volumeMm3
    itemMassKg
    customerNote
    productionRemark
    internalComment
    hsCode
    hsCodeName
    files {
      ...fileFragment
    }
    createdAt
  }
  ${PROVIDER_PREQUOTE_FRAGMENT}
  ${FILE_FRAGMENT}
`;

export const LOT_FRAGMENT = gql`
  fragment lotFragment on Lot {
    id
    parentId
    partId
    dealId
    customerPrice
    quantity
    providerPrice
    sourceType
    sourceId
    unit
    createdAt
  }
`;

export const TAG_FRAGMENT = gql`
  fragment TagFragment on Tag {
    id
    name
  }
`;
