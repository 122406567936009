import { routes } from 'routes';

export const getAuthToken = () => {
  const token = window.localStorage.getItem('token');

  return `Bearer ${token}`;
};

export const redirectToRoot = () => window.location.assign(routes.rootPath());

export const isLoginPage = () => window.location.pathname.includes('sign_in');

export const redirectTo404 = () => {
  window.location.assign(routes.notFound());
};
