import { Input } from 'antd';
import { TreeProps } from 'antd/lib/tree';
import { useAccessErrorNotification } from 'components/shared/AccessError/useAccessErrorNotification';
import { Column, PermissionsSettings } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { ColumnsContainer, ColumnsTree } from 'components/Workspaces/General/shared/GeneralWorkspace/styled';
import { useFiltersEditPermission } from 'components/Workspaces/General/shared/GeneralWorkspace/useFiltersEditPermission';
import { columnVisible } from 'components/Workspaces/General/shared/GeneralWorkspace/utils';
import { filter, findIndex, map } from 'lodash-es';
import React, { useState } from 'react';

type OnDrop = TreeProps['onDrop'];

interface Props {
  columns: Column[];
  onColumnsChange: (columns: Column[]) => void;
  span?: number;
  permissionsSettings: PermissionsSettings;
}

const Columns: React.FC<Props> = ({ columns, onColumnsChange, span, permissionsSettings }) => {
  const accessErrorNotification = useAccessErrorNotification();
  const [columnsFilter, setColumnsFilter] = useState<string>();
  const visibleColumns = filter(columns, (col) => columnVisible(col.settings?.visibility));
  const { isFiltersChangingAllowed, permissionErrorMessage } = useFiltersEditPermission(permissionsSettings);

  const filteredColumns =
    columnsFilter == null || columnsFilter === ''
      ? visibleColumns
      : filter(visibleColumns, (col) => col.title.toLowerCase().includes(columnsFilter.toLowerCase()));

  const columnsData = map(filteredColumns, ({ key, title, synthetic, settings }) => ({
    key,
    title: `${title}${synthetic && !settings?.skipAsterisk ? ' *' : ''}`,
    isLeaf: true,
  }));

  const checkedKeys = map(filter(columns, { enabled: true }), 'key');

  const handleDrop: OnDrop = ({ dragNode, dropPosition }) => {
    // When element is moved to the start of list, dropPosition is set to -1,
    // and we need to treat it as zero
    let newIndex = dropPosition === -1 ? 0 : dropPosition;

    const columnIndex = findIndex(columns, { key: dragNode.key as string });
    const column = columns[columnIndex];

    // dropPosition is set with counting dragged element as copied, so we
    // need to reduce it's value by 1 when element is the first in the list
    if (columnIndex < newIndex) {
      if (span == null) {
        newIndex -= 1;
      } else {
        // but when item moved to next column,
        // for some reason antd returns already reduced by 1 index,
        // we should check first which column will receive current item
        const colSize = Math.ceil(columns.length / span);
        const sourceColumn = Math.floor(columnIndex / colSize);
        const destColumn = Math.floor(newIndex / colSize);
        newIndex = destColumn > sourceColumn ? newIndex : newIndex - 1;
      }
    }

    const newColumns = [...columns];
    newColumns.splice(columnIndex, 1);
    newColumns.splice(newIndex, 0, column);
    onColumnsChange(newColumns);
  };

  return (
    <ColumnsContainer span={span}>
      <Input
        allowClear
        placeholder="Search columns..."
        onChange={(e) => setColumnsFilter(e.target.value)}
        style={{ maxWidth: 240 }}
        value={columnsFilter}
      />

      <ColumnsTree
        blockNode
        checkable
        checkedKeys={checkedKeys}
        draggable={(columnsFilter || '').length === 0}
        onCheck={(_, info) => {
          if (!permissionsSettings.group || isFiltersChangingAllowed) {
            const newColumns = map(columns, (column) => ({
              ...column,
              enabled: info.node.key === column.key ? info.checked : column.enabled,
            }));

            onColumnsChange(newColumns);
          } else {
            accessErrorNotification(permissionErrorMessage);
          }
        }}
        onDrop={handleDrop}
        selectable={false}
        treeData={columnsData}
        // Styled component props
        span={span}
        total={columns.length}
      />
    </ColumnsContainer>
  );
};

export default Columns;
