import loadable, { DefaultComponent } from '@loadable/component';
import { PageLoader } from 'components/UI';
import { createElement } from 'react';
import { hardReloadPage } from 'utils/hardReloadPage';
import { withAttempts } from 'utils/promiseUtils';

export const lazyLoadPage = <Props>(
  loadFn: (props: Props) => Promise<DefaultComponent<Props>>,
  options?: Parameters<typeof loadable>[1],
) => {
  const load = async (props: Props): Promise<DefaultComponent<Props>> => {
    const factoryWithAttempts = withAttempts(() => loadFn(props), {
      attempts: 3,
      retryDelayMs: 5000,
    });

    try {
      return await factoryWithAttempts();
    } catch (e: unknown) {
      console.error(e);

      if (process.env.NODE_ENV !== 'development') {
        hardReloadPage();
      }

      return await factoryWithAttempts();
    }
  };

  return loadable(load, { fallback: createElement(PageLoader), ...options });
};
