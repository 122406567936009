import { gql } from 'apollo-boost';
import { map } from 'lodash-es';
import { PROVIDER_FRAGMENT } from 'utils/graphql/fragments';

import { QueryBuilder } from './interfaces';

export const GET_PROVIDER = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      ...providerFragment
    }
  }
  ${PROVIDER_FRAGMENT}
`;

export const GET_PROVIDER_PO = gql`
  query providerPO($id: ID!) {
    provider(id: $id) {
      id
      providerOrders {
        id
        number
      }
    }
  }
`;

export const GET_PROVIDER_II = gql`
  query providerII($id: ID!, $filter: String) {
    provider(id: $id) {
      id
      incomingInvoices(filter: $filter) {
        id
        creditNote
        number
        dealProviderOrderId
      }
    }
  }
`;

export const GET_PROVIDER_INVOICES_CASH_BALANCE = gql`
  query providerInvoicesCashBalance($providerId: ID!) {
    provider(id: $providerId) {
      id
      incomingInvoices(filter: "published") {
        id
        creditNote
        totalAmountBrutto
        totalAmountBruttoMoney {
          amount
          amountCents
          currencyCode
        }
        payouts {
          id
          state
          isRefund
          amount
          amountMoney {
            amount
            amountCents
            currencyCode
          }
          refunds {
            id
            state
            isRefund
            amount
            amountMoney {
              amount
              amountCents
              currencyCode
            }
          }
        }
      }
      providerOrders {
        id
        payouts {
          id
          state
          isRefund
          amount
          amountMoney {
            amount
            amountCents
            currencyCode
          }
          refunds {
            id
            state
            isRefund
            amount
            amountMoney {
              amount
              amountCents
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export const findProviderBalanceRecord: QueryBuilder = (query, { fragments = [] }) => gql`
  query providerBalanceRecordsFind($providerId: ID!, $date: ISO8601Date) {
    providerBalanceRecordsFind(providerId: $providerId, date: $date) {
      ${query}
    }
  }
  ${map(fragments, (f) => f.loc?.source?.body).join('\n')}
`;
