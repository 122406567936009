import { PlusOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { FilterSettings } from 'components/Workspaces/collections';
import {
  Column,
  FilterNextOperator,
  WorkspaceGraphQLField,
  WorkspaceGraphQLObjectField,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { FilterItem } from 'components/Workspaces/General/shared/GeneralWorkspace/FiltersStore';
import { map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React from 'react';

import FilterRow from './FilterRow';
import { FilterNodeContainer } from './styled';

interface Props {
  attributes: WorkspaceGraphQLField[];
  objectAttributes: WorkspaceGraphQLObjectField[];
  columns: Column[];
  customFilters?: FilterSettings[];
  hideUndefinedColumns?: boolean;
  node: FilterItem;
  operator?: FilterNextOperator;
  readonly?: boolean;
  remove?: (id: string) => void;
}

const FilterNode = observer<Props>(
  ({
    attributes,
    objectAttributes,
    columns,
    customFilters,
    hideUndefinedColumns,
    node,
    operator,
    readonly,
    remove,
  }) => {
    if (node.value.kind === 'filter') {
      return (
        <FilterRow
          attributes={attributes}
          objectAttributes={objectAttributes}
          columns={columns}
          customFilters={customFilters}
          hideUndefinedColumns={hideUndefinedColumns}
          node={node}
          operator={operator}
          readonly={readonly}
          remove={remove && (() => remove(node.id))}
        />
      );
    }

    const nodeChildren = node.value.children;

    const children = map(nodeChildren, (child) => (
      <div key={child.id}>
        <FilterNode
          attributes={attributes}
          objectAttributes={objectAttributes}
          columns={columns}
          node={child}
          operator={node.lastOperator === 'AND' ? 'OR' : 'AND'}
          readonly={readonly}
          remove={node.removeChild}
        />
        {child.operator != null && <div className="value">{child.operator}</div>}
      </div>
    ));

    return (
      <FilterNodeContainer>
        <div className="content">{children}</div>
        <div className="border" />

        <Row className="buttons">
          {node.lastOperator === 'AND' ? (
            <Button
              disabled={readonly}
              icon={<PlusOutlined />}
              size="small"
              type="link"
              onClick={() => node.addChild('AND')}
            >
              Add AND condition
            </Button>
          ) : (
            <Button
              disabled={readonly}
              icon={<PlusOutlined />}
              size="small"
              type="link"
              onClick={() => node.addChild('OR')}
            >
              Add OR condition
            </Button>
          )}
        </Row>
      </FilterNodeContainer>
    );
  },
);

export default FilterNode;
