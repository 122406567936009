import { gql } from 'apollo-boost';

export const ACCOUNT_SEARCH_DEAL_FRAGMENT = gql`
  fragment accountSearchDealFragment on Deal {
    pipedriveId
    customerOrderCode

    orderConfirmation {
      id
      billingAccount
      deal {
        pipedriveId
      }
    }
  }
`;
