import { AxiosResponse } from 'axios';
import { Provider } from 'interfaces';
import { map } from 'lodash-es';
import { action, observable } from 'mobx';
import { routesApi } from 'routes/api';
import FetchableStore from 'stores/shared/FetchableStore';
import uniqid from 'uniqid';
import { camelizeKeys, decamelizeKeys, omsPost } from 'utils/axios';

class ProvidersStore extends FetchableStore {
  @observable providers: Provider[] = [];

  @observable loading = false;

  @action assignOnFetch = (payload: Provider[]): void => {
    this.providers = camelizeKeys(payload);
    this.loading = false;
  };

  @action search = (filter: any): void => {
    this.loading = true;

    void this.fetch(routesApi.providersSearchPath(), {
      filter: decamelizeKeys(filter),
    });
  };

  @action byIds = async (ids: number[]): Promise<Provider[]> => {
    this.loading = true;
    const response = (await omsPost(routesApi.providersSearchPath(), {
      filter: { ids },
    })) as AxiosResponse<{ payload: Provider[] }>;
    this.loading = false;

    return map(response.data.payload, (provider) => ({
      ...provider,
      key: uniqid(),
    }));
  };
}

export default ProvidersStore;
