import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { RoleWithPermissionsFragmentDoc } from '../../fragments/__generated__/roleWithPermissions';
import { UserPermissionFragmentDoc } from '../../fragments/__generated__/userPermission';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RolesAndPermissionsQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type RolesAndPermissionsQuery = { rolesForUser?: Array<{ id: number, name: string, label?: string | null, erpPermissions: Array<{ id: number, group: string, action: string, name: string, label?: string | null }> }> | null, userPermissions?: Array<{ id: number, expiresAt?: string | null, erpPermission: { id: number, group: string, action: string, name: string, label?: string | null } }> | null };


export const RolesAndPermissionsDocument = gql`
    query rolesAndPermissions($userId: ID!) {
  rolesForUser(userId: $userId) {
    ...roleWithPermissions
  }
  userPermissions(userId: $userId) {
    ...userPermission
  }
}
    ${RoleWithPermissionsFragmentDoc}
${UserPermissionFragmentDoc}`;

/**
 * __useRolesAndPermissionsQuery__
 *
 * To run a query within a React component, call `useRolesAndPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesAndPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesAndPermissionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRolesAndPermissionsQuery(baseOptions: Apollo.QueryHookOptions<RolesAndPermissionsQuery, RolesAndPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesAndPermissionsQuery, RolesAndPermissionsQueryVariables>(RolesAndPermissionsDocument, options);
      }
export function useRolesAndPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesAndPermissionsQuery, RolesAndPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesAndPermissionsQuery, RolesAndPermissionsQueryVariables>(RolesAndPermissionsDocument, options);
        }
export function useRolesAndPermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RolesAndPermissionsQuery, RolesAndPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RolesAndPermissionsQuery, RolesAndPermissionsQueryVariables>(RolesAndPermissionsDocument, options);
        }
export type RolesAndPermissionsQueryHookResult = ReturnType<typeof useRolesAndPermissionsQuery>;
export type RolesAndPermissionsLazyQueryHookResult = ReturnType<typeof useRolesAndPermissionsLazyQuery>;
export type RolesAndPermissionsSuspenseQueryHookResult = ReturnType<typeof useRolesAndPermissionsSuspenseQuery>;
export type RolesAndPermissionsQueryResult = Apollo.QueryResult<RolesAndPermissionsQuery, RolesAndPermissionsQueryVariables>;