import { Button } from 'antd';
import { Notification } from 'components/UI';
import { ExistingWorkspacePreset } from 'interfaces/graphql/workspacePreset';
import React from 'react';
import { useWorkspacePresetsAssignDefaultPresetMutation } from 'utils/graphql/mutations/__generated__/workspacePresetsAssignDefaultPreset';
import { useWorkspacePresetsRemoveDefaultPresetMutation } from 'utils/graphql/mutations/__generated__/workspacePresetsRemoveDefaultPreset';
import {
  DefaultWorkspacePresetDocument,
  useDefaultWorkspacePresetQuery,
} from 'utils/graphql/queries/__generated__/defaultWorkspacePreset';

interface Props {
  queryName: string;
  currentPreset: ExistingWorkspacePreset;
}

export const SetAsMyDefaultButton: React.FC<Props> = (props) => {
  const { queryName, currentPreset } = props;

  const { data: defaultPresetData, loading: defaultPresetLoading } = useDefaultWorkspacePresetQuery({
    variables: { workspaceName: queryName },
  });

  const isMyDefault = currentPreset.id === defaultPresetData?.defaultWorkspacePreset.personalDefaultPreset?.id;

  const [assignDefaultPreset, { loading: assignDefaultPresetLoading }] = useWorkspacePresetsAssignDefaultPresetMutation(
    {
      onCompleted: () => Notification.Success('Default preset successfully updated'),
      refetchQueries: [
        {
          query: DefaultWorkspacePresetDocument,
          variables: { workspaceName: queryName },
        },
      ],
    },
  );

  const [removeDefaultPreset, { loading: removeDefaultPresetLoading }] = useWorkspacePresetsRemoveDefaultPresetMutation(
    {
      onCompleted: () => Notification.Success('Default preset successfully removed'),
      variables: {
        workspaceName: queryName,
      },
      refetchQueries: [
        {
          query: DefaultWorkspacePresetDocument,
          variables: { workspaceName: queryName },
        },
      ],
    },
  );

  const handleMakeDefaultClick = async () => {
    await assignDefaultPreset({
      variables: {
        workspaceName: queryName,
        presetId: String(currentPreset.id),
      },
    });
  };

  if (defaultPresetLoading) {
    return null;
  }

  if (isMyDefault) {
    return (
      <Button danger loading={removeDefaultPresetLoading} onClick={() => void removeDefaultPreset()}>
        Unset as my default
      </Button>
    );
  }

  return (
    <Button loading={assignDefaultPresetLoading} onClick={() => void handleMakeDefaultClick()}>
      Set as my default
    </Button>
  );
};
