import { Spin } from 'antd';
import React from 'react';

import { ModalPageLoader } from './styled';

interface Props {
  title: string;
  isVisible: boolean;
}

const PageLoader: React.FC<Props> = ({ title, isVisible }) => (
  <ModalPageLoader
    centered
    keyboard={false}
    closable={false}
    destroyOnClose
    maskClosable={false}
    open={isVisible}
    footer={null}
  >
    <Spin spinning size="large" tip={title} />
  </ModalPageLoader>
);

export default PageLoader;
