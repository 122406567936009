import React, { PropsWithChildren } from 'react';

interface Props {
  align: 'left' | 'right' | 'center';
  colIndex: number;
  justify: 'flex-start' | 'flex-end' | 'center';
  recordIndex: number;
  rowIndex: number;
  selected?: boolean;
}

const RawTableCell: React.FC<PropsWithChildren<Props>> = ({
  align,
  children,
  colIndex,
  justify,
  recordIndex,
  rowIndex,
  selected,
}) => {
  const orderClass = recordIndex % 2 > 0 ? 'odd' : 'even';
  const selectionClass = selected ? 'selected' : '';

  return (
    <div
      className={`table-cell ${orderClass} ${selectionClass}`}
      role="gridcell"
      style={{
        gridRow: rowIndex,
        gridColumn: colIndex,
        justifyContent: justify,
        textAlign: align,
      }}
    >
      {children}
    </div>
  );
};

export default RawTableCell;
