import { Rule } from 'antd/lib/form';

import { REQUIRED } from './messages';

export const required = (value = true): Rule => ({
  required: value,
  message: REQUIRED,
});

export const trimmedRequired = (value = true): Rule => ({
  required: value,
  whitespace: true,
  message: REQUIRED,
});
