import styled from '@emotion/styled';
import { bc, colors } from 'components/UI/colors';
import { H3, H4 } from 'components/UI/H/styled';

export const FormStyledFields = styled.div`
  .ant-form-item-label {
    padding-bottom: 2px;
    font-weight: 600;
  }

  .ant-form-item-has-error.ant-form-item-hidden {
    display: block;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-switch-checked {
    background-color: ${bc.primary};
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-form-autocomplete .ant-select-selector {
    height: 32px !important;
    padding: 4px 7px !important;
  }

  .ant-form-autocomplete label {
    width: 100%;
  }

  .ant-collapse:not(.ant-collapse-ghost) .ant-collapse-header {
    background-color: #f0f2f5;
  }

  .partners-selector .partners-selector-filters {
    margin-top: 14px;
  }

  label {
    .ant-btn-link {
      height: 22px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .ant-btn-link,
  a {
    color: ${bc.link};
  }

  .ant-table-thead .ant-table-cell {
    font-weight: 600;
  }

  .statistic-grid {
    .ant-col:first-of-child {
      margin-right: 18px;
    }
    .ant-col + .ant-col {
      border-left: 1px solid #e8e8e8;
      padding-left: 18px;
    }
  }
`;

export const FormWrapper = styled(FormStyledFields)`
  color: ${colors.gray9};
  max-width: 1100px;
  margin: 0 auto;
`;

export const Label = styled(H3)`
  margin-bottom: 0;
  cursor: pointer;
`;

export const FormGroupLabel = styled(H4)`
  font-weight: 600;
  margin-bottom: 0;
  justify-content: space-between;
  display: flex;
  align-items: flex-end;

  > div {
    padding-top: 10px;
  }

  button {
    padding-right: 0;
  }
`;

export const FormGroupHR = styled.hr`
  background-color: ${bc.formGroupDivider};
  height: 1px;
  border: 0;
  margin-top: 0;
  margin-bottom: 10px;
`;

export const FormStyled = styled(FormWrapper)`
  .ant-form-item-label {
    font-weight: 600;
  }

  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-form-item-control-input {
    min-height: 24px;
  }
`;

export const FormItemBlock = styled.div`
  margin-bottom: 15px;
`;
