import { FormInstance } from 'antd';
import { AxiosResponse } from 'axios';
import Notification from 'components/UI/Notification';
import { Event, EventType, EventTypeDependence, LoadStatus } from 'interfaces';
import { noop } from 'lodash-es';
import { action, observable } from 'mobx';
import { routesApi } from 'routes/api';
import omsApi, { camelizeKeys, omsGet } from 'utils/axios';

import { RootStore } from './RootStore';

class EventsStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  afterSubmit = noop;

  scope: EventTypeDependence = 'claim';

  @observable sourceId = '';

  sourceType = '';

  @observable form: FormInstance | undefined = undefined;

  @observable loadStatus = {
    eventTypes: LoadStatus.NotInit,
    events: LoadStatus.NotInit,
  };

  @observable isModalOpen = false;

  @observable events: Event[] = [];

  @observable eventTypes: EventType[] = [];

  @observable currentEventId: number | undefined = undefined;

  @action setSourceId = (sourceId: string) => {
    this.sourceId = sourceId;
  };

  @action setForm = (form: FormInstance) => {
    this.form = form;
  };

  @action setEvents = (events: Event[]) => {
    this.events = events;
  };

  @action setEventTypes = (eventTypes: EventType[]) => {
    this.eventTypes = eventTypes;
  };

  @action setEventsLoadStatus = (eventsLoadStatus: LoadStatus) => {
    this.loadStatus.events = eventsLoadStatus;
  };

  @action setEventTypesLoadStatus = (eventTypesLoadStatus: LoadStatus) => {
    this.loadStatus.eventTypes = eventTypesLoadStatus;
  };

  @action setCurrentEventId = (eventId: number | undefined) => {
    this.currentEventId = eventId;
  };

  @action toggleIsModalOpen = () => {
    this.isModalOpen = !this.isModalOpen;
  };

  @action loadEvents = async () => {
    this.setEventsLoadStatus(LoadStatus.IsLoading);

    try {
      const response = (await omsGet(routesApi.eventsPath(), {
        source_id: this.sourceId,
        source_type: this.sourceType,
      })) as AxiosResponse<{ payload: Event[] }>;

      this.setEvents(camelizeKeys(response.data.payload));
      this.setEventsLoadStatus(LoadStatus.IsFinished);
    } catch (e: unknown) {
      Notification.Error('Failed to load Events');
      this.setEventsLoadStatus(LoadStatus.IsFinished);

      return e;
    }
  };

  @action loadEventTypes = async (dependence: string) => {
    this.setEventTypesLoadStatus(LoadStatus.IsLoading);

    try {
      const response: { data: { payload: EventType[] } } = await omsApi.get(
        `${routesApi.eventTypesPath()}?dependence=${dependence}`,
      );
      this.setEventTypes(camelizeKeys(response.data.payload));
      this.setEventTypesLoadStatus(LoadStatus.IsFinished);
    } catch (e: unknown) {
      Notification.Error('Failed to load Event Types');
      this.setEventTypesLoadStatus(LoadStatus.Error);

      return e;
    }
  };
}

export default EventsStore;
