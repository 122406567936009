import { Checkbox } from 'antd';
import {
  ColIndexMap,
  Column,
  ColumnHeaderDecorator,
  IDataItem,
  ISortParam,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { map, throttle } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';

import ColumnHeader from './ColumnHeader';

interface Props {
  colIndexMap: ColIndexMap;
  colIndexOffset: number;
  columns: Column[];
  columnsWidth: Record<string, number>;
  dataSource: IDataItem[];
  expandRows?: boolean;
  hideRowNumbers?: boolean;
  showCheckboxes?: boolean;
  selectedRows?: IDataItem[];
  sortedColumns: ISortParam[];
  onColumnHeader?: ColumnHeaderDecorator;
  onColumnResize: (columnKey: string, diff: number) => any;
  onColumnSort: (sortBy: string) => any;
  onColumnWidthUpdate: (columnKey: string, value: number) => any;
  onRowSelectAll?: (records: IDataItem[]) => void;
  onRowDeselectAll?: () => void;
}

const TableHeader: React.FC<Props> = ({
  colIndexMap,
  colIndexOffset,
  columns,
  columnsWidth,
  dataSource,
  expandRows,
  hideRowNumbers,
  sortedColumns,
  showCheckboxes,
  selectedRows,
  onColumnHeader,
  onColumnResize,
  onColumnSort,
  onColumnWidthUpdate,
  onRowSelectAll,
  onRowDeselectAll,
}) => {
  const [currentDragColumn, setCurrentDragColumn] = useState<Column>();

  const lastPageXRef = useRef<number>();

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, column: Column) => {
    lastPageXRef.current = e.pageX;
    setCurrentDragColumn(column);
    document.body.style.cursor = 'col-resize';

    if (columnsWidth[column.key] == null) {
      const width = (e.target as HTMLElement).parentElement?.clientWidth;
      onColumnWidthUpdate(column.key, width || 0);
    }
  };

  useEffect(() => {
    const mouseMoveHandler = throttle((e: MouseEvent) => {
      e.preventDefault();
      if (currentDragColumn == null) return;

      if (lastPageXRef.current == null) return;

      const diff = e.pageX - lastPageXRef.current;
      lastPageXRef.current = e.pageX;

      onColumnResize(currentDragColumn.key, diff);
    }, 100);

    document.addEventListener('mousemove', mouseMoveHandler);

    return () => document.removeEventListener('mousemove', mouseMoveHandler);
  }, [currentDragColumn, onColumnResize]);

  useEffect(() => {
    if (currentDragColumn == null) return undefined;

    const mouseUpHandler = () => {
      setCurrentDragColumn(undefined);
      lastPageXRef.current = undefined;
      document.body.style.cursor = 'default';
    };

    document.addEventListener('mouseup', mouseUpHandler);

    return () => document.removeEventListener('mouseup', mouseUpHandler);
  }, [currentDragColumn]);

  return (
    <>
      {showCheckboxes && selectedRows != null && onRowSelectAll != null && onRowDeselectAll != null && (
        <div
          className="table-column-header"
          style={{
            display: 'flex',
            gridColumn: colIndexMap.checkbox,
            justifyContent: 'center',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Checkbox
            checked={selectedRows.length === dataSource.length}
            onChange={(e) => (e.target.checked ? onRowSelectAll(dataSource) : onRowDeselectAll())}
          />
        </div>
      )}

      {/* Row number column */}
      {hideRowNumbers || <div className="table-column-header" style={{ gridColumn: colIndexMap.rowNumber }} />}

      {expandRows && <div className="table-column-header" style={{ gridColumn: colIndexMap.expandButton }} />}

      {map(columns, (column, index) => (
        <ColumnHeader
          column={column}
          index={index + colIndexOffset}
          key={column.key}
          onColumnHeader={onColumnHeader}
          onColumnSort={onColumnSort}
          onMouseDown={(e) => handleMouseDown(e, column)}
          sortedColumns={sortedColumns}
        />
      ))}
    </>
  );
};

export default TableHeader;
