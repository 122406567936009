import { Row } from 'antd';
import Donut from 'assets/images/donut.svg';
import React from 'react';

import { ModalSubtitle, ModalTitle } from './styled';

export const Maintenance = (
  <div>
    <Row justify="center">
      <img src={Donut} alt="" />
    </Row>
    <ModalTitle justify="center">We are updating system now, please wait a few minutes...</ModalTitle>
    <ModalSubtitle justify="center">To save recent activity don’t close this window. </ModalSubtitle>
  </div>
);
