import { EditLogisticInvoicePage, NewLogisticInvoicePage } from 'pages';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

const LogisticInvoicesController = () => (
  <Switch>
    <Route path={`${routes.logisticInvoicesPath()}/new`} component={NewLogisticInvoicePage} />
    <Route path={`${routes.logisticInvoicesPath()}/:invoiceId`} component={EditLogisticInvoicePage} />
  </Switch>
);

export default LogisticInvoicesController;
