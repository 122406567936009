import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type UserFragmentFragment = { id: number, email: string, name: string, imageUrl?: string | null, pipedriveId?: string | null, salesforceId?: string | null, jobTitle?: string | null, podId?: number | null, active: boolean, isRobot: boolean, phone?: string | null, birthday?: string | null, comment?: string | null, deputyUserId?: number | null, rfqLimit?: number | null, region?: Types.UserRegionEnum | null, autoAssignmentSettings?: { customerOrderGrade?: Array<Types.CustomerOrderGradeEnum> | null, languages?: Array<Types.LanguageEnum> | null, maxDeals?: number | null, overloadPossible?: boolean | null, technology?: Array<number> | null } | null, deputyUser?: { id: number, name: string, email: string } | null };

export const UserFragmentFragmentDoc = gql`
    fragment userFragment on User {
  id
  email
  name
  imageUrl
  pipedriveId
  salesforceId
  jobTitle
  podId
  active
  isRobot
  phone
  birthday
  comment
  deputyUserId
  rfqLimit
  region
  autoAssignmentSettings {
    customerOrderGrade
    languages
    maxDeals
    overloadPossible
    technology
  }
  deputyUser {
    id
    name
    email
  }
}
    `;