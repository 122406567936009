import React from 'react';

import { ModalSubtitle, ModalTitle } from './styled';

interface Props {
  operationName?: string;
  url?: string;
  variables?: string;
}

export const NotFound = ({ operationName, url, variables }: Props) => (
  <div>
    <ModalTitle justify="center">404. Requested resource has not been found.</ModalTitle>
    {url && <ModalSubtitle justify="center">{`Target URL: ${url}`}</ModalSubtitle>}
    {operationName && <ModalSubtitle justify="center">{`Target operation: ${operationName}`}</ModalSubtitle>}
    {variables && <ModalSubtitle justify="center">{`Target variables: ${variables}`}</ModalSubtitle>}
    <ModalSubtitle style={{ marginTop: '20px' }} justify="center">
      Check your request url and try again{' '}
    </ModalSubtitle>
  </div>
);
