import { IMoney } from '@xometry/ui/dist/utils/money';
import {
  ColumnsMap,
  ColumnVisibility,
  NativeColumnSettings,
  SyntheticColumnSettings,
} from 'components/Workspaces/collections';
import humanizeString from 'humanize-string';
import React from 'react';
import { Money } from 'utils/money';

import { columnFilterable, columnSortable, columnVisible } from './utils';

const isMoney = (key: string) => /Money$/.test(key);

const centsFromMoneyKey = (key: string) => key.replace(/Money$/, 'Cents');
const rawFromMoneyKey = (key: string) => key.replace(/Money$/, '');

export function columnsWithMoney<T extends Record<string, object>>(columns: ColumnsMap<T>): ColumnsMap<T> {
  const result: ColumnsMap<T> = {};

  Object.keys(columns).forEach((key) => {
    result[key] = { ...columns[key] };

    if (!isMoney(key)) return;

    // In workspaces, column != field. Object fields can be requested, native column cannot be binded to object field.
    // To correctly handle money columns, we define <x>Money as synthetic and add <x>Money field to dependencies.

    const centsKey = centsFromMoneyKey(key);
    const rawKey = rawFromMoneyKey(key);
    const originalVisiblity = result[key].visibility;

    if (!result[key].synthetic) {
      result[key] = {
        ...result[key],
        synthetic: true,
        skipAsterisk: result[key].skipAsterisk ?? true,
        title: result[key].title ?? humanizeString(key),
        renderSelectiveFields: (result[key] as NativeColumnSettings<T>).decoratorSelectiveFields ?? [],
        render:
          (result[key] as NativeColumnSettings<T>).decorator ??
          ((object: T) => <Money value={object[key] as IMoney} />),
        visibility: columnVisible(originalVisiblity) ? ColumnVisibility.Visible : ColumnVisibility.Hidden,
      };
    }

    const settings = result[key] as SyntheticColumnSettings<T>;
    settings.renderSelectiveFields = [...(settings.renderSelectiveFields ?? []), key, centsKey];

    if (columnSortable(originalVisiblity)) settings.sortBy = centsKey;

    [`${rawKey}Currency`, `${key}__amount`, `${key}__amountCents`, `${key}__currencyCode`].forEach(
      (currentKey) => (result[currentKey] = { visibility: ColumnVisibility.Hidden, ...result[currentKey] }),
    );

    result[centsKey] = {
      ...result[centsKey],
      title: result[key].title,
      synthetic: false,
      skipAsterisk: false,
      visibility: columnFilterable(originalVisiblity) ? ColumnVisibility.OnlyFilterable : ColumnVisibility.Hidden,
      filterPrepareValues: {
        wrap: (values: unknown[]) => values.map((value) => Math.round((value as number) * 100)),
        unwrap: (values: unknown[]) => values.map((value) => (value as number) / 100),
      },
    };
  });

  return result;
}
