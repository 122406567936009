export interface Tag {
  id: number;
  name: string;
  context: string;
  contextName: string;
}

export enum TagContexts {
  Materials = 'materials',
  ProductionMethods = 'production_methods',
  ProductionMethodFeatures = 'production_method_features',
  RestrictedMethods = 'restricted_methods',
  ProductionRisks = 'production_risks',
}
