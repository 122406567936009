/* eslint-disable @typescript-eslint/restrict-template-expressions,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-assignment */
import {
  CustomFilterPattern,
  FilterKeywords,
  FilterNextOperator,
  FilterPatternName,
  IFilter,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { decamelize } from 'humps';
import { findIndex, findLast, forEach, isEmpty, isEqual as _isEqual, last, map, nth, reduce } from 'lodash-es';
import { action, computed, observable, toJS } from 'mobx';
import uniqid from 'uniqid';

import { arithmeticExpressionPattern, parseFilters } from './utils';

export interface FilterItemArgs {
  children?: FilterItem[];
  filter?: IFilter;
  operator?: FilterNextOperator;
  root?: boolean;
}

export interface FilterItemSingleValue {
  kind: 'filter';
  filter: IFilter;
}

export interface FilterItemGroupValue {
  kind: 'group';
  children: FilterItem[];
}

export type FilterItemValue = FilterItemSingleValue | FilterItemGroupValue;

// Using single class for both types instead of two subclasses
// is required because we need to change object type at runtime.
// For example, convert current 'group' to 'filter'
// when there's only one child left.
class FilterItem {
  @observable id: string;

  @observable value: FilterItemValue;

  @observable operator?: FilterNextOperator;

  @observable root: boolean;

  static fromQuery = (query: string) => parseFilters(query);

  constructor({ children, filter, operator, root }: FilterItemArgs = {}) {
    this.id = uniqid();
    this.operator = operator;
    this.root = root ?? false;

    if (children != null) {
      this.value = observable({
        kind: 'group',
        children,
      });
    } else {
      this.value = observable({
        kind: 'filter',
        filter: filter ?? { values: [] },
      });
    }
  }

  @computed get isEmpty(): boolean {
    if (this.value.kind === 'filter') {
      return (
        this.value.filter.attribute == null &&
        this.value.filter.pattern == null &&
        (this.value.filter.values == null || this.value.filter.values.length === 0)
      );
    }

    return reduce(this.value.children, (mem, child) => mem && child.isEmpty, true as boolean);
  }

  @computed get lastOperator(): FilterNextOperator | undefined {
    if (this.value.kind === 'filter') return undefined;

    return findLast(this.value.children, (x) => x.operator != null)?.operator;
  }

  @computed get toQuery(): string {
    if (this.value.kind === 'filter') {
      let { filter: value } = this.value;

      value = this._convertCustomFilter(value);

      if (isEmpty(value.attribute) || isEmpty(value.pattern) || isEmpty(value.values.join(''))) {
        return '';
      }

      const values = value.values || [];

      const keywords = Object.values(FilterKeywords);
      let strValue;

      if (values.length === 1 && (keywords.includes(values[0]) || arithmeticExpressionPattern.test(values[0]))) {
        [strValue] = values;
      } else {
        strValue = `'${values.join(',')}'`;
      }

      const attrName = value?.attribute
        ?.replace(/^_custom_/, '')
        ?.split('__')
        ?.map((x) => decamelize(x))
        ?.join('__');

      return `${attrName || ''}` + ` ${value.pattern || ''}` + ` ${strValue || ''}`;
    }

    const subQuery = map(this.value.children, (child, index) => {
      if (child.isEmpty) return '';

      const childQuery = child.toQuery;

      if (index >= (this.value as FilterItemGroupValue).children.length - 1) return childQuery;

      if (child.operator == null) return childQuery;

      return `${childQuery} ${child.operator}`;
    }).join(' ');

    return this.root ? subQuery : `(${subQuery})`;
  }

  @action addChild = (operator: FilterNextOperator) => {
    if (this.value.kind === 'filter') {
      const { filter } = this.value;

      this.value = observable({
        kind: 'group',
        children: [new FilterItem({ filter, operator }), new FilterItem()],
      });
    } else {
      const penultChild = nth(this.value.children, -2) as FilterItem;

      if (penultChild.operator !== operator) {
        this.value.children = [new FilterItem({ children: this.value.children })];
      }

      const lastChild = last(this.value.children) as FilterItem;
      lastChild.operator = operator;
      this.value.children.push(new FilterItem());
    }
  };

  @action assignRoot = (item: FilterItem) => {
    this.id = item.id;
    this.value = item.value;
    this.root = true;
  };

  @action removeChild = (id: string) => {
    if (this.value.kind !== 'group') return;

    const index = findIndex(this.value.children, (x) => x.id === id);

    // Child with provided id is not found
    // That means requested filter is either nested or doesn't exist
    if (index === -1) {
      forEach(this.value.children, (c) => c.removeChild(id));

      return;
    }

    this.value.children.splice(index, 1);
    (last(this.value.children) as FilterItem).operator = undefined;

    if (this.value.children.length === 1) {
      this.value = this.value.children[0].value;
    }
  };

  @action setFilter = (filter: IFilter) => {
    if (this.value.kind !== 'filter') return;

    this.value.filter = filter;
  };

  isEqual = (other?: FilterItem): boolean => {
    if (other == null) return false;

    if (this.value.kind !== other.value.kind) return false;

    if (this.value.kind === 'filter') {
      const otherFilter = (other.value as FilterItemSingleValue).filter;

      return (
        this.value.filter.attribute === otherFilter.attribute &&
        this.value.filter.pattern === otherFilter.pattern &&
        _isEqual(toJS(this.value.filter.values), toJS(otherFilter.values))
      );
    }

    const otherGroup = other.value as FilterItemGroupValue;

    if (this.value.children.length !== otherGroup.children.length) return false;

    return reduce(
      this.value.children,
      (mem, child, index) =>
        mem &&
        otherGroup.children[index] != null &&
        child.operator === otherGroup.children[index].operator &&
        child.isEqual(otherGroup.children[index]),
      true as boolean,
    );
  };

  // Splits filter into two parts:
  // first part is equal to provided chunk,
  // second part is the rest of current filter
  //    const thisClone = partitionChunk.deepClone();
  // Self is equal to partitionChunk: [partitionChunk, undefined]
  // Self starts with partitionChunk: [partitionChunk, secondChunk]
  // Self doesn't start with partitionChunk: [undefined, self]
  partition = (partitionChunk?: FilterItem): [FilterItem | undefined, FilterItem | undefined] => {
    const thisClone = this.deepClone({ root: true });

    if (partitionChunk == null) return [thisClone, undefined];

    if (this.isEqual(partitionChunk)) return [thisClone, undefined];

    const partitionClone = partitionChunk.deepClone();

    // *********************
    // partition is a filter
    // *********************
    //
    //                  Verify first filter in 'this' is equal to partition
    //                  /                                            \
    //                 YES                                            NO
    //                /                                                 \
    //           [partition, rest]                                 [undefined, partition]
    if (partitionChunk.value.kind === 'filter' && this.value.kind === 'group') {
      // First filter in 'this' is equal to partition and followed by 'AND' operator
      if (this.value.children[0]?.isEqual(partitionChunk) && this.value.children[0].operator === 'AND') {
        const thisCloneGroup = thisClone.value as FilterItemGroupValue;
        thisClone.removeChild(thisCloneGroup.children[0].id);

        return [partitionClone, thisClone];
      }

      // First filter in 'this' is different from partition
      return [undefined, thisClone];
    }

    if (partitionChunk.value.kind === 'filter' && this.value.kind === 'filter') {
      // At this step we're sure that filters are not equal
      return [undefined, thisClone];
    }

    // *********************
    // partition is a group
    // *********************
    //
    //  Iterate through children until not equal children met.
    //  Put all equal into left side, rest - into right side

    // Filter cannot include group so return immediately
    if (this.value.kind === 'filter') {
      return [undefined, thisClone];
    }

    const partitionGroup = partitionChunk.value as FilterItemGroupValue;

    const iterationClone = this.deepClone();
    const iterationCloneGroup = iterationClone.value as FilterItemGroupValue;

    let unwrappedLeftChildren = iterationCloneGroup.children;
    let unwrappedRightChildren = partitionGroup.children;

    // In some cases matching pattern is wrapped into additional group, for example:
    // Current filter: "(age < 20 OR age > 50) AND gender = 'male'"
    // Preset filter:  "age < 20 OR age > 50"
    //
    // Current filter strucutre:
    //
    //                                group[(age < 20 OR age > 50) AND gender = 'male']
    //                                    /                                 \
    //                                   /                                   \
    //                   group[age < 20 OR age > 50] ------ AND ------ filter[gender = 'male']
    //                   /                \
    //                  /                  \
    //  filter[age < 20] ------ OR ------ filter[age > 50]
    //
    // Preset filter structure:
    //
    //               group[age < 20 OR age > 50]
    //                   /                \
    //                  /                  \
    //  filter[age < 20] ------ OR ------ filter[age > 50]
    //
    //
    // The top level groups are not equal and we need to unwrap
    // first group in current filter to get the next structure:
    //
    //                  group[age < 20 OR age > 50 AND gender = 'male']
    //                 /                       |                      \
    //                /                        |                       \
    //  filter[age < 20] ---- OR ---- filter[age > 50] ---- AND ---- filter[gender = 'male']
    //
    // The resulting query will be not equal to original
    // but will be correclty handled by algorithm since it's top group
    // matches to preset's query top group

    // The safiest way to detect groups that we need to unwrap
    // is to count leftmost groups for each query.
    //
    // If the counts are equal - there's nothing to unwrap.
    // If the counts are NOT equal - we should put first group's children to the top level
    while (unwrappedLeftChildren[0].value.kind === 'group' && unwrappedRightChildren[0].value.kind === 'group') {
      unwrappedLeftChildren = unwrappedLeftChildren[0].value.children;
      unwrappedRightChildren = unwrappedRightChildren[0].value.children;
    }

    // Counts are not equal so unwrap
    if (unwrappedLeftChildren[0].value.kind === 'group') {
      const actualLeftChildren = (iterationCloneGroup.children[0].value as FilterItemGroupValue).children;
      (last(actualLeftChildren) as FilterItem).operator = iterationCloneGroup.children[0].operator;
      iterationClone.removeChild(iterationCloneGroup.children[0].id);
      iterationCloneGroup.children = [...actualLeftChildren, ...iterationCloneGroup.children];
    }

    const leftChildren: FilterItem[] = [];
    const rightChildren: FilterItem[] = [];
    let unequalMet = false;

    forEach(iterationCloneGroup.children, (child, index) => {
      if (unequalMet) {
        rightChildren.push(child);

        return;
      }

      if (
        partitionGroup.children.length >= index + 1 &&
        partitionGroup.children[index] != null &&
        child.isEqual(partitionGroup.children[index]) &&
        ((index < partitionGroup.children.length - 1 && child.operator === partitionGroup.children[index].operator) ||
          child.operator === 'AND')
      ) {
        leftChildren.push(child);

        return;
      }

      unequalMet = true;
      rightChildren.push(child);
    });

    if (leftChildren.length < partitionGroup.children.length) {
      return [undefined, thisClone];
    }

    const leftItem =
      leftChildren.length > 1
        ? new FilterItem({ children: leftChildren, root: true })
        : leftChildren[0].deepClone({ root: true });

    const rightItem =
      rightChildren.length > 1
        ? new FilterItem({ children: rightChildren, root: true })
        : rightChildren[0].deepClone({ root: true });

    if (leftItem != null && leftItem.value.kind === 'group') {
      (last(leftItem.value.children) as FilterItem).operator = undefined;
    }

    return [leftItem, rightItem];
  };

  combine = (rightFilter?: FilterItem): FilterItem => {
    if (rightFilter == null || rightFilter.isEmpty) return this.deepClone();

    const thisClone = this.deepClone({ root: false });
    thisClone.operator = 'AND';

    return new FilterItem({
      children: [thisClone, rightFilter.deepClone({ root: false })],
      root: true,
    });
  };

  inspect = (level = 0) => {
    const lines: string[] = [];

    const printLine = (text: string, noSpace = false) => {
      if (noSpace) lines.push(text);
      else lines.push(`${' '.repeat(noSpace ? 0 : level * 4)}| ${text}`);
    };

    printLine(`id: ${this.id}`);
    printLine(`kind: ${this.value.kind}`);
    printLine(`root: ${this.root}`);
    printLine(`operator: ${this.operator ?? 'null'}`);

    if (this.value.kind === 'filter') {
      const { filter } = this.value;
      printLine(`item: ${filter.attribute} ${filter.pattern} '${filter.values.join(',')}'`);
      printLine('');
    } else {
      printLine('[');
      printLine(map(this.value.children, (x) => x.inspect(level + 1)).join('\n'), true);
      printLine(']');
    }

    return lines.join('\n');
  };

  deepClone = (overrides?: { root?: boolean }): FilterItem =>
    new FilterItem({
      children:
        this.value.kind === 'group'
          ? map(this.value.children, (c) => c.deepClone({ ...overrides, root: false }))
          : undefined,

      filter: this.value.kind === 'filter' ? toJS(this.value.filter) : undefined,

      operator: toJS(this.operator),

      root: overrides?.root != null ? overrides.root : toJS(this.root),
    });

  _convertCustomFilter = (filter: IFilter) => {
    switch (filter.pattern) {
      case CustomFilterPattern.IsNull: {
        return {
          ...filter,
          pattern: FilterPatternName.Equal,
          values: ['null'],
        };
      }

      case CustomFilterPattern.IsNotNull: {
        return {
          ...filter,
          pattern: FilterPatternName.NotEqual,
          values: ['null'],
        };
      }

      case CustomFilterPattern.IsTrue: {
        return {
          ...filter,
          pattern: FilterPatternName.Equal,
          values: ['true'],
        };
      }

      case CustomFilterPattern.IsFalse: {
        return {
          ...filter,
          pattern: FilterPatternName.Equal,
          values: ['false'],
        };
      }

      default:
        return filter;
    }
  };
}

export default FilterItem;
