import { IMoney } from '@xometry/ui/dist/utils/money';
import { TransactionTypes } from 'collections/paymentConstants';

import { MeasurementProtocols } from './PartsStore';

export enum PaymentScreenType {
  request = 'request',
  received = 'received',
  refund = 'refund',
}

export enum PaymentStateType {
  preauthorized = 'preauthorized',
  published = 'published',
  completed = 'completed',
  canceled = 'canceled',
  draft = 'draft',
  processing = 'processing',
}

export enum BPStateEnum {
  completed = 'completed',
  draft = 'draft',
  in_progress = 'in_progress',
}

export enum MethodType {
  Bank = 'BankPayment',
  DealPayment = 'Deal::Payment',
  DealPayout = 'Deal::Payout',
  Paypal = 'PaypalPayment',
  Stripe = 'StripePayment',
  Iyzico = 'IyzicoPayment',
}

export const methodTypeLabel = {
  [MethodType.Bank]: 'Bank',
  [MethodType.Paypal]: 'PayPal',
  [MethodType.Stripe]: 'Stripe',
};

export interface PaymentPositionsByKey {
  [key: string]: PaymentPosition;
}

export interface PaymentSourceSummary {
  vatRate: number;
  amountNet: number;
  amountBrut: number;
  amountVat: number;
}

export interface PaymentSource {
  id: number;
  type: string;
  state: string;
  number: string;
  title: string;
  vatRate: number;
  amountNet: number;
  amountNetMoney: IMoney;
  amountBrut: number;
  amountBrutMoney: IMoney;
  amountVat: number;
  amountVatMoney: IMoney;
  billingAccountId: string;
  meta?: {
    refundRemainingBrutto?: string;
    refundRemainingBruttoMoney?: IMoney;
  };
  paidAt?: string;
  transactionId?: string | null;
  methodType: MethodType | null;
  refundedBrutto?: string;
  refundedBruttoMoney?: IMoney;
  fullyRefunded?: boolean;
  currency: string;
}

export interface PaymentPart {
  id: number;
  name: string;
  position?: number;
  isExpress: boolean;
  isPreQuote: boolean;
  isSamplesNeeded: boolean;
  mpNeeded?: MeasurementProtocols;
  internalComment?: string;

  preQuotedByLabel?: string;
  preQuotedComment?: string;

  samplesQuantity?: number;
  samplesComment?: string;

  material: string;
  postProcessing: string;
  customerNote?: string;
  productionRemark?: string;
}

export interface PaymentPosition {
  id?: number;
  _destroy?: boolean;
  key: string;
  name: string;
  quantity: number;
  part?: PaymentPart;
  pricePerPiece: number;
  pricePerPieceMoney: IMoney;
  vatRate: number;
  position: number;
  dealId?: number;
  ref?: string;
  refId?: number;
  refType?: string;
}

export interface PaymentMethod {
  amount?: number;
  amountMoney?: IMoney;
  boCustomer?: string;
  createdAt?: string;
  id: number;
  name: 'Bank' | 'Paypal' | 'Stripe' | 'Manual';
  state?: PaymentStateType | BPStateEnum;
  remark?: string;
  sentToBankAt?: string;
  validUntil?: string;
  transactionId: string;
}

export interface Payment {
  id: number;
  dealId: number;
  state: PaymentStateType;
  number: string;
  sourceType?: string;
  sourceId?: number;
  sourceNumber?: string;
  method?: PaymentMethod;
  note?: string;
  datevComment?: string;
  intacctComment?: string;
  currency: string;
  paidAt?: string;
  registeredAt?: string;
  uuid?: string;
  amountFee?: number;
  amountFeeMoney: IMoney;
  amountBrut?: number;
  amountBrutMoney: IMoney;
  amountNet?: number;
  amountNetMoney: IMoney;
  amountReceived?: number;
  amountReceivedMoney: IMoney;
  totalAmountCalc?: number;
  totalAmountCalcMoney: IMoney;
  vat?: number;
  vatPercent?: number;
  primary: boolean;
  isRefund: boolean;
  refundedBrutto: number;
  refundedBruttoMoney: IMoney;
  fullyRefunded: boolean;
  refundRemainingBrutto: number;
  refundRemainingBruttoMoney: IMoney;
  paymentPageUrl?: string;
  positions: PaymentPosition[];
  positionsAttributes?: PaymentPosition[];
  methodType: MethodType | null;
  methodId: string | null;
  linkedDealId: number | null;
  bankDetails?: {
    bankAccountHolderName: string;
    bankAccountNumber: string;
    bankCode: string;
  };
  transactionType?: TransactionTypes | null;
  refundMethodType?: string;
  updatedAt: string;
}
