import lookers from 'collections/lookers';
import { LookerPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

export const DrawingsController = () => (
  <>
    <Route
      path={routes.lookers.drawings.tagErrors()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Errors in parts process tags" url={lookers.drawings.tagErrors} />
      )}
    />
  </>
);
