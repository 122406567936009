import styled from '@emotion/styled';
import { Modal } from 'antd';

export const ModalPageLoader = styled(Modal)`
  z-index: 100000;

  .ant-modal-body {
    text-align: center;
  }
`;
