import { SortDirectionEnum } from '__generated__/types';
import { Column, ISortParam } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { get, uniqBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

interface UseSortColumnsProps {
  sort?: ISortParam;
  columns: Column[];
}

const isSortableColumn = (column: Column) => {
  if (!column.enabled) {
    return false;
  }

  if (column.synthetic === true && !column.settings?.sortBy) {
    return false;
  }

  return true;
};

export const useSortColumns = ({ sort, columns }: UseSortColumnsProps) => {
  const [sortColumn, setSortColumn] = useState<string | undefined>(sort?.sortBy);
  const [sortDirection, setSortDirection] = useState<SortDirectionEnum | undefined>(sort?.direction);
  const handleChangeSort = (column?: string, direction?: SortDirectionEnum) => {
    setSortColumn(column);
    setSortDirection(direction);
  };

  // Filter out columns that are not sortable.
  const sortColumns = useMemo(() => {
    return columns.filter(isSortableColumn);
  }, [columns]);

  const sortOptions = useMemo(() => {
    const options = sortColumns.map((column) => ({
      label: column.title,
      // If column is synthetic column, use sortBy from settings.
      // Otherwise use column key.
      value: get(column, 'settings.sortBy', column.key),
    }));

    return uniqBy(options, 'value');
  }, [sortColumns]);

  // If the current sort column is not sortable, reset the sort column.
  useEffect(() => {
    if (sortColumn) {
      const sortColumnNotInSelectedColumns =
        sortColumns.findIndex((column) => {
          if (column.key === sortColumn) {
            return true;
          }

          if (column.settings?.sortBy === sortColumn) {
            return true;
          }

          return false;
        }) === -1;

      if (sortColumnNotInSelectedColumns) {
        handleChangeSort();
      }
    }
  }, [sortColumns, sortColumn]);

  return {
    sortColumn,
    sortDirection,
    handleChangeSort,
    sortOptions,
  };
};
