import { gql } from 'apollo-boost';

export interface IPaymentTermsUpdateCascadeResponse {
  paymentTermsUpdateCascade: {
    paymentTerm: {
      id: string;
      creditLimit: number;
      paymentDueDays: number;
    };
  };
}

export const PAYMENT_TERMS_UPDATE_CASCADE = gql`
  mutation paymentTermsUpdateCascade($id: ID!, $attributes: OmsInputsPaymentTermUpdateCascadeType!) {
    paymentTermsUpdateCascade(input: { id: $id, attributes: $attributes }) {
      paymentTerm {
        id
        creditLimit
        paymentDueDays
        termType
      }
    }
  }
`;
