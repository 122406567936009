import { notification } from 'antd';
import { ISupportTicket } from 'interfaces';
import { each } from 'lodash-es';
import { action, observable } from 'mobx';
import { routesApi } from 'routes/api';
import FetchableStore from 'stores/shared/FetchableStore';
import omsApi, { camelizeKeys, decamelizeKeys, handleErrorMessage } from 'utils/axios';

const prepareFormData = (values: any, uploads: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data = decamelizeKeys(values);

  const formData = new FormData();

  each(uploads, (file: UploadedFile) => {
    formData.append('ticket[uploads[][file]]', file);
  });

  each(data, (value, key) => {
    if (value !== undefined) {
      formData.append(`ticket[${key}]`, JSON.stringify(value));
    } else {
      formData.append(`ticket[${key}]`, 'null');
    }
  });

  return formData;
};

export interface UploadedFile extends File {
  uid: number;
}

class SalesforceStore extends FetchableStore {
  @observable loading = false;

  @observable uploads: UploadedFile[] = [];

  @action setLoading = (isLoading: boolean): void => {
    this.loading = isLoading;
  };

  @action setUploads = (uploads: UploadedFile[]): void => {
    this.uploads = uploads;
  };

  @action create = async (data: any): Promise<ISupportTicket | undefined> => {
    this.setLoading(true);

    const formData = prepareFormData(data, this.uploads);

    try {
      const response = await omsApi.post<{ payload: { ticket: unknown } }>(routesApi.supportTicketsPath(), formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      this.setLoading(false);

      return camelizeKeys(response.data.payload.ticket);
    } catch (err) {
      const receivedMessage = handleErrorMessage(err);
      notification.error({ message: receivedMessage });
      this.setLoading(false);
    }
  };
}

export default SalesforceStore;
