import { IErpPermissionsGroup } from 'interfaces/graphql/erpPermission';
import { action, observable } from 'mobx';
import { GET_ERP_PERMISSIONS, IErpPermissionsResponse } from 'utils/graphql/queries/erpPermissions';

import { RootStore } from './RootStore';

/**
 * Using for fetching and storing erp permissions
 * for use them in access error notifications.
 */
export class ErpPermissionsStore {
  rootStore: RootStore;

  @observable erpPermissions: IErpPermissionsGroup[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  fetchErpPermissions = () => {
    void this.rootStore.gql
      .query<IErpPermissionsResponse>({
        query: GET_ERP_PERMISSIONS,
      })
      .then((res) => {
        this.setErpPermissions(res.data.erpPermissions);
      });
  };

  @action setErpPermissions = (permissions: IErpPermissionsGroup[]) => {
    this.erpPermissions = permissions;
  };
}
