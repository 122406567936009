import { gql } from 'apollo-boost';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';

const DEAL_FRAGMENT_CONFIG: Record<Regions, ReturnType<typeof gql>> = {
  [Regions.EU]: gql`
    fragment dealFragment on Deal {
      id
      createdAt
      stage
      grade
      currency
      pipedriveStatus
      pipedrivePipeline
      pipedriveId
      pipedriveUrl
      externalCrmId
      externalCrmUrl
      vat
      googleDriveUrl
      slackUrl
      slackUrlRequested
      pipedriveOrderValue
      testDeal
      organizationId
      personId
      pmId
      invoiced
      isForSoftwareTesting
      frameworkAgreement
      customerOrderCode
      customerOrderDate
      purchaseOrderDate
      deliveryDeadline
      totalPaidNett
      isNpg
      originalDeals {
        id
      }

      testDeal

      isExpress
      isSamplesNeeded
      measurementProtocols
      isPreQuoted
      isImportant
      isOnHold

      manualProcessingFinance
      manualProcessingJobs
      manualProcessingEmails
      manualProcessingOutcomingInvoices
      manualProcessingComment
      manualProcessingOrderConfirmation
      manualProcessingShippings
      manualProcessingStages

      productionCompleted

      certificates

      orderConfirmation {
        id
        comment
        shippingAddress
      }

      owner {
        id
        name
      }

      utm {
        campaign
        medium
        term
      }

      productionManager {
        id
        name
      }
    }
  `,
  [Regions.UK]: gql`
    fragment dealFragment on Deal {
      id
      number
      currency
      createdAt
      stage
      grade
      pipedriveStatus
      pipedrivePipeline
      pipedriveId
      pipedriveUrl
      externalCrmId
      externalCrmUrl
      vat
      googleDriveUrl
      slackUrl
      slackUrlRequested
      pipedriveOrderValue
      testDeal
      organizationId
      personId
      pmId
      invoiced
      isForSoftwareTesting
      frameworkAgreement
      customerOrderCode
      customerOrderDate
      purchaseOrderDate
      deliveryDeadline
      totalPaidNett
      isNpg
      originalDeals {
        id
      }

      testDeal

      isExpress
      isSamplesNeeded
      measurementProtocols
      isPreQuoted
      isImportant
      isOnHold

      manualProcessingFinance
      manualProcessingJobs
      manualProcessingEmails
      manualProcessingOutcomingInvoices
      manualProcessingComment
      manualProcessingOrderConfirmation
      manualProcessingShippings
      manualProcessingStages

      productionCompleted

      certificates

      orderConfirmation {
        id
        comment
        shippingAddress
      }

      owner {
        id
        name
      }

      utm {
        campaign
        medium
        term
      }

      productionManager {
        id
        name
      }
    }
  `,
  [Regions.TR]: gql`
    fragment dealFragment on Deal {
      id
      number
      createdAt
      stage
      grade
      currency
      pipedriveStatus
      pipedrivePipeline
      pipedriveId
      pipedriveUrl
      externalCrmId
      externalCrmUrl
      vat
      googleDriveUrl
      slackUrl
      slackUrlRequested
      pipedriveOrderValue
      testDeal
      organizationId
      personId
      pmId
      invoiced
      isForSoftwareTesting
      frameworkAgreement
      customerOrderCode
      customerOrderDate
      purchaseOrderDate
      deliveryDeadline
      totalPaidNett
      isNpg
      originalDeals {
        id
      }

      testDeal

      isExpress
      isSamplesNeeded
      measurementProtocols
      isPreQuoted
      isImportant
      isOnHold

      manualProcessingFinance
      manualProcessingJobs
      manualProcessingEmails
      manualProcessingOutcomingInvoices
      manualProcessingComment
      manualProcessingOrderConfirmation
      manualProcessingShippings
      manualProcessingStages

      productionCompleted

      certificates

      orderConfirmation {
        id
        comment
        shippingAddress
        lots {
          id
          quantity
          part {
            id
            qtyOrdered
            qtyInProduction
            qtyShipped
            qtyDelivered
            qtyClaim
          }
        }
      }

      owner {
        id
        name
      }

      utm {
        campaign
        medium
        term
      }

      productionManager {
        id
        name
      }
    }
  `,
};

/** @deprecated use fragments/deal.graphql instead */
export const DEAL_FRAGMENT = DEAL_FRAGMENT_CONFIG[CURRENT_REGION];

export const DEAL_RELATIONS_FRAGMENT = gql`
  fragment dealRelationsFragment on Deal {
    id
    outcomingInvoices {
      id
      createdAt
      creditNote
      refInvoice {
        id
      }
      issuedAt
      number
      paidAt
      state
      totalAmountBrutto
      totalAmountBruttoMoney {
        amount
        amountCents
        currencyCode
      }
      paymentRelations {
        creditNote {
          id
          number
          totalAmountBrutto
          totalAmountBruttoMoney {
            amount
            amountCents
            currencyCode
          }
          issuedAt
        }
        totalAmountBrutto
        totalAmountBruttoMoney {
          amount
          amountCents
          currencyCode
        }
        createdAt
        date
        updatedAt
        id
        registeredAt
        outcomingInvoice {
          id
          number
          totalAmountBrutto
          totalAmountBruttoMoney {
            amount
            amountCents
            currencyCode
          }
          issuedAt
        }
        payment {
          id
          amountBrut
          amountBrutMoney {
            amount
            amountCents
            currencyCode
          }
          isRefund
          number
          paidAt
          refunds {
            id
            amountBrut
            amountBrutMoney {
              amount
              amountCents
              currencyCode
            }
            number
            paidAt
            registeredAt
            state
          }
          registeredAt
        }
        refund {
          id
          amountBrut
          amountBrutMoney {
            amount
            amountCents
            currencyCode
          }
          paidAt
        }
      }
    }
    payments {
      amountBrut
      amountBrutMoney {
        amount
        amountCents
        currencyCode
      }
      createdAt
      id
      number
      paidAt
      isRefund
      state
      refunds {
        id
        amountBrut
        amountBrutMoney {
          amount
          amountCents
          currencyCode
        }
        state
      }
      invoiceRelations {
        creditNote {
          id
          number
          totalAmountBrutto
          totalAmountBruttoMoney {
            amount
            amountCents
            currencyCode
          }
          issuedAt
        }
        createdAt
        date
        updatedAt
        id
        registeredAt
        totalAmountBrutto
        totalAmountBruttoMoney {
          amount
          amountCents
          currencyCode
        }
        outcomingInvoice {
          id
          number
          totalAmountBrutto
          totalAmountBruttoMoney {
            amount
            amountCents
            currencyCode
          }
          issuedAt
        }
        payment {
          id
          amountBrut
          amountBrutMoney {
            amount
            amountCents
            currencyCode
          }
          number
          paidAt
          refunds {
            id
            amountBrut
            amountBrutMoney {
              amount
              amountCents
              currencyCode
            }
            number
            paidAt
            registeredAt
          }
          registeredAt
        }
        refund {
          id
          amountBrut
          amountBrutMoney {
            amount
            amountCents
            currencyCode
          }
          paidAt
        }
      }
    }
  }
`;
