import { ErpPermissionGroup, GroupAndAction } from 'collections/authorization';
import { ObjectType } from 'interfaces';
import React, { ReactNode } from 'react';
import { useAuthorizeActionQuery } from 'utils/graphql/queries/__generated__/authorizeAction';

interface BaseProps {
  placeholder?: React.ReactNode;
}

interface WithSubjectProps extends BaseProps {
  subjectClass: ObjectType;
  subjectId: number;
}

interface WithoutSubjectProps extends BaseProps {
  subjectClass?: undefined;
  subjectId?: undefined;
}

export type AuthorizeVisibilityProps = {
  children: ReactNode;
  group: ErpPermissionGroup;
  action: GroupAndAction['action'];
} & (WithSubjectProps | WithoutSubjectProps);

const AuthorizeVisibility: React.FC<AuthorizeVisibilityProps> = ({
  group,
  action,
  placeholder,
  subjectClass,
  subjectId,
  children,
  ...props
}) => {
  const { data } = useAuthorizeActionQuery({
    variables: {
      group,
      action,
      subjectClass,
      subjectId,
    },
  });

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }

    return child;
  });

  if (data == null) return null;

  if (!data.authorizeAction.authorized) {
    return placeholder != null ? <>{placeholder}</> : null;
  }

  return <>{childrenWithProps}</>;
};

export default AuthorizeVisibility;
