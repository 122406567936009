import { getPermissionLabel } from 'helpers/accessErrors/helpers';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import external, { ServiceDeskAccessRequestArgs } from 'routes/external';
import { RootStore, useStore } from 'stores/RootStore';

interface AccessRequestLinkProps extends ServiceDeskAccessRequestArgs {
  linkText?: string;
  group?: string;
  action?: string;
  rootStore?: RootStore;
}

const newLine = encodeURIComponent('\n');

export const AccessRequestLink = observer((props: AccessRequestLinkProps) => {
  const { summary, description, linkText = 'Please fill the form to request access', group, action, rootStore } = props;

  const _rootStore = useStore();
  const store = rootStore || _rootStore;
  const erpPermissionsStore = store?.erpPermissionsStore;

  useEffect(() => {
    if (erpPermissionsStore) {
      erpPermissionsStore.fetchErpPermissions();
    }
  }, [erpPermissionsStore]);

  const permissionsLabel = erpPermissionsStore
    ? getPermissionLabel(erpPermissionsStore.erpPermissions, group, action)
    : undefined;
  const descriptionList = [`Page: ${window.location.href}`];

  if (permissionsLabel) descriptionList.push(permissionsLabel);

  if (description) descriptionList.push(description);

  return (
    <a
      href={external.serviceDeskAccessRequest({
        summary,
        description: descriptionList.join(newLine),
      })}
      target="_blank"
      rel="noopener noreferrer"
    >
      {linkText}
    </a>
  );
});
