import { IErpPermissionsGroup } from 'interfaces/graphql/erpPermission';

// TODO: It's better to get group and action from backend instead of parsing from the message.
/**
 * Parse an access error from backed and returns group and action.
 * Error example - "Permission "billing_accounts.update_without_datev_id" required to proceed with the action"
 * @param message - access error from backend
 * @return [group, action] | undefined
 */
export const parseGroupAndAction = (message: string) => {
  const regex = /"(.*)\.(.*)"/g;
  const result = regex.exec(message);

  if (result) {
    const group = result[1];
    const action = result[2];

    return { group, action };
  }

  return;
};

export function getPermissionLabel(
  erpPermissions?: IErpPermissionsGroup[],
  group?: string,
  actionOrName?: string,
): string | undefined {
  let label = '';

  if (group && actionOrName) {
    label = `Permissions: ${group}.${actionOrName}`;
  } else {
    return;
  }

  if (!erpPermissions) {
    return label;
  }

  const erpPermission = erpPermissions
    .find((p) => p.group === group)
    ?.erpPermissions.find((p) => p.action === actionOrName || p.name === actionOrName);

  if (!erpPermission?.label) {
    return label;
  }

  return `${label} - ${erpPermission.label}`;
}
