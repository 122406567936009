/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { get } from 'lodash-es';
import { action, observable } from 'mobx';
import { RootStore } from 'stores/RootStore';
import omsApi from 'utils/axios';

class UpdatableStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable updateStatus = {
    isFinished: false,
    isProcessing: false,
    error: false,
  };

  @observable showStatus = {
    isFinished: false,
    isProcessing: false,
    error: false,
  };

  @observable deleteStatus = {
    isFinished: false,
    isProcessing: false,
    error: false,
  };

  @action show = (url: string, params: Record<string, unknown> = {}): void => {
    this.showStatus.isProcessing = true;

    omsApi.get(url, { params }).then(this.onShowSuccess, this.onShowError);
  };

  @action update = (url: string, data: unknown): void => {
    this.rootStore.pageLoaderStore.enable('Updating');
    this.updateStatus.isProcessing = true;

    omsApi.patch(url, this.mapData(data)).then(this.onUpdateSuccess, this.onUpdateError);
  };

  @action delete = (url: string): void => {
    this.deleteStatus.isProcessing = true;

    omsApi.delete(url).then(this.onDeleteSuccess, this.onDeleteError);
  };

  @action onUpdateSuccess = (response: AxiosResponse<any>): void => {
    const payload: unknown = get(response, 'data.payload');
    this.assignOnUpdateSuccess(payload);

    this.updateStatus.isFinished = true;
    this.updateStatus.isProcessing = false;
    this.rootStore.pageLoaderStore.disable();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @action onUpdateError = (response: any): void => {
    this.updateStatus.isFinished = true;
    this.updateStatus.isProcessing = false;
    this.rootStore.pageLoaderStore.disable();
  };

  @action onDeleteSuccess = (): void => {
    this.doOnDeleteSuccess();

    this.deleteStatus.isFinished = true;
    this.deleteStatus.isProcessing = false;
  };

  @action onDeleteError = (): void => {
    this.deleteStatus.isFinished = true;
    this.deleteStatus.isProcessing = false;
  };

  @action onShowSuccess = (response: AxiosResponse<any>): void => {
    const payload: unknown = get(response, 'data.payload');
    this.assignOnShowSuccess(payload);

    this.showStatus.isFinished = true;
    this.showStatus.isProcessing = false;
  };

  @action onShowError = (): void => {
    this.showStatus.isFinished = true;
    this.showStatus.isProcessing = false;
  };

  @action assignOnShowSuccess = (payload: any): void => {
    // eslint-disable-next-line no-console
    console.log('payload', payload);
    // eslint-disable-next-line no-alert
    alert('!!!OVERRIDE assignOnUpdateSuccess for UpdatableStore!!!');
  };

  @action assignOnUpdateSuccess = (payload: any): void => {
    // eslint-disable-next-line no-console
    console.log('payload', payload);
    // eslint-disable-next-line no-alert
    alert('!!!OVERRIDE assignOnUpdateSuccess for UpdatableStore!!!');
  };

  @action doOnDeleteSuccess = (): void => {
    // eslint-disable-next-line no-alert
    alert('!!!OVERRIDE doOnDeleteSuccess for UpdatableStore!!!');
  };

  mapData = (data: unknown): any => data;
}

export default UpdatableStore;
