import { Position, ServerPosition } from 'interfaces';
import { map } from 'lodash-es';
import { action, observable } from 'mobx';
import uniqid from 'uniqid';
import { camelizeKeys } from 'utils/axios';

import DealSourcedPositionsStore from './DealSourcedPositionsStore';

class DealReferencePositionsStore extends DealSourcedPositionsStore {
  @observable positions: Position[] = [];

  @action assignOnFetch = (payload: ServerPosition[]) => {
    this.positions = map(payload, (position: ServerPosition) => ({
      categoryName: position.accounting_category,
      quantity: position.quantity || 1,
      price: position.price_per_piece || 0,
      priceMoney: camelizeKeys(position.price_per_piece_money),
      providerPrice: position.provider_price,
      name: position.name,
      vat: position.vat_rate || 0,
      position: position.position,
      refId: position.ref_id,
      refSource: position.ref_source,
      refType: position.ref_type,
      key: uniqid(),
    }));
  };

  @action setPositions = (positions: Position[]) => {
    this.positions = positions;
  };
}

export default DealReferencePositionsStore;
