import { Notification } from 'components/UI';
import { MailerTemplate } from 'interfaces/stores/MailerStore';
import { uniqBy } from 'lodash-es';
import { action, computed, observable } from 'mobx';

type Locale = string;

interface TemplatesByName {
  [name: string]: Record<Locale, MailerTemplate>;
}

export interface TemplateOption {
  value: string;
  label: string;
}

export class TemplatesStore {
  private templatesByName: TemplatesByName = {};

  @observable currentTemplate: MailerTemplate | null = null;
  @observable templateOptions: TemplateOption[] = [];
  @observable private defaultLocale: Locale = 'en';

  @action
  reset = () => {
    this.templatesByName = {};
    this.currentTemplate = null;
    this.templateOptions = [];
  };

  @action
  setTemplates(templates: MailerTemplate[]) {
    this.templatesByName = templates.reduce((acc, template) => {
      const { constantName, locale } = template;

      if (!acc[constantName]) {
        acc[constantName] = {};
      }

      acc[constantName][locale] = template;

      return acc;
    }, {} as TemplatesByName);

    this.templateOptions = uniqBy(templates, 'constantName').map(({ constantName, name }) => ({
      value: constantName,
      label: name,
    }));

    if (templates?.length) {
      const templateName = templates[0].constantName;
      this.currentTemplate = this.templatesByName[templateName][this.defaultLocale];
    }
  }

  @action
  setClientLocale(locale: Locale) {
    this.defaultLocale = locale;
  }

  @action
  setCurrentTemplate = (templateName: string) => {
    // Get the locale from current selected template
    // to preserve the locale when changing template
    const locale = this.currentTemplate?.locale;
    const templates = this.templatesByName[templateName];
    // Check if the template has the locale we want
    const hasTemplate = (locale && locale in templates) || this.defaultLocale in templates;
    const hasEnglishTemplate = 'en' in templates;

    if (hasTemplate) {
      const template = (locale && templates[locale]) || templates[this.defaultLocale];
      this.currentTemplate = template;
    } else if (hasEnglishTemplate) {
      const template = templates['en'];
      this.currentTemplate = template;
      this.setLocale(template.locale);
    } else {
      const template = Object.values(templates)[0];
      this.currentTemplate = template;
      this.setLocale(template.locale);
    }
  };

  @action
  setLocale = (locale: Locale) => {
    if (this.currentTemplate == null) {
      return;
    }

    const newTemplate = this.templatesByName[this.currentTemplate.constantName][locale];

    if (!newTemplate) {
      Notification.Error(`Template ${this.currentTemplate.constantName} does not have locale ${locale}`);

      return;
    }

    this.currentTemplate = newTemplate;
  };

  @action
  changeTemplateLocale = (locale: Locale) => {
    if (this.currentTemplate == null) return;

    const currentTemplate = this.currentTemplate;
    const newTemplate = this.templatesByName[currentTemplate.constantName]?.[locale];

    if (!newTemplate) return;

    this.currentTemplate = newTemplate;
  };

  @computed
  get getCurrentTemplateLocaleOptions() {
    if (!this.currentTemplate) {
      return [];
    }

    const templates = this.templatesByName[this.currentTemplate.constantName] || {};

    return Object.keys(templates).map((locale) => ({
      value: locale,
      label: locale.toLocaleUpperCase(),
    }));
  }
}
