import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { AxiosResponse } from 'axios';
import { MailerAttach, MailerFile, MailerScreen, MailerTemplate } from 'interfaces/stores/MailerStore';
import { uniq } from 'lodash-es';
import { action, computed, observable } from 'mobx';
import { RootStore } from 'stores/RootStore';
import omsApi, { camelizeKeys, decamelizeKeys } from 'utils/axios';

import { TemplatesStore } from './TemplatesStore';

interface FetchPayload {
  documents: MailerAttach[];
  tempDocuments: MailerAttach[];
  attachments: MailerFile[];
  templates: MailerTemplate[];
  clientLocale: string;
}

interface UploadAttachmentResponse {
  attachedFile: {
    id: string;
    name: string;
    size: number;
  };
}

type FileTypes = 'documents' | 'tempDocuments' | 'attachments';

class MailerStore {
  rootStore: RootStore;
  templatesStore: TemplatesStore;

  @observable screen: MailerScreen = MailerScreen.MAIN;

  @observable documents: MailerAttach[] = [];

  initialSelectedFilesSizes = {
    documents: 0,
    tempDocuments: 0,
    attachments: 0,
  };

  @observable selectedFilesSizes: Record<FileTypes, number> = this.initialSelectedFilesSizes;

  @observable tempDocuments: MailerAttach[] = [];

  @observable attachments: MailerAttach[] = [];

  @observable templates: MailerTemplate[] = [];

  @observable isFetching: string | undefined = undefined;

  @observable isUploadInProcess = false;

  /** Use to set recipient email addresses directly instead of from templates */
  @observable forceRecipientEmails: null | string[] = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.templatesStore = new TemplatesStore();
  }

  @computed
  get currentTemplate() {
    return this.templatesStore.currentTemplate;
  }

  @action reset = () => {
    this.documents = [];
    this.tempDocuments = [];
    this.attachments = [];
    this.screen = MailerScreen.MAIN;
    this.templates = [];
    this.templatesStore.reset();
    this.selectedFilesSizes = this.initialSelectedFilesSizes;
    this.resetForceRecipientEmails();
  };

  @action setForceRecipientEmails = (emails: string[]) => {
    this.forceRecipientEmails = emails;
  };

  @action resetForceRecipientEmails = () => {
    this.forceRecipientEmails = null;
  };

  @action setIsFetching = (value: string | undefined): void => {
    this.isFetching = value;
  };

  @action setScreen = (value: MailerScreen): void => {
    this.screen = value;
  };

  @action setUploadProcess = (value: boolean): void => {
    this.isUploadInProcess = value;
  };

  @action fetch = async (url: string, params?: any): Promise<AxiosResponse> => {
    this.setIsFetching('Fetch email templates...');
    this.reset();

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response = await omsApi.get<{ payload: FetchPayload }>(url, { params });
      const payload = camelizeKeys(response.data?.payload);

      this.assignAttributes(payload);
      this.setIsFetching(undefined);

      return response;
    } catch (e) {
      this.setIsFetching(undefined);

      throw e;
    }
  };

  @action send = async (url: string, values: any): Promise<AxiosResponse> => {
    this.setIsFetching('Sending in process. Please wait.');

    try {
      const response = await omsApi.post(url, {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        mailer: decamelizeKeys(values),
      });

      this.setIsFetching(undefined);
      this.setScreen(MailerScreen.SUCCESS);

      this.rootStore.activityRecordStore.refetch();

      return response;
    } catch (e) {
      this.setIsFetching(undefined);
      this.setScreen(MailerScreen.FAILURE);
      this.rootStore.activityRecordStore.refetch();

      throw e;
    }
  };

  @action uploadAttachment = async (url: string, file: File): Promise<AxiosResponse> => {
    this.setUploadProcess(true);

    const fd = new FormData();

    fd.append('file', file);

    try {
      const response = await omsApi.post<{ payload: UploadAttachmentResponse }>(url, fd);

      const { payload } = response.data;
      const f = camelizeKeys(payload);

      this.appendFile({
        value: f.attachedFile.id,
        label: f.attachedFile.name,
        size: f.attachedFile.size,
      });

      this.setUploadProcess(false);

      return response;
    } catch (e) {
      this.setUploadProcess(false);

      throw e;
    }
  };

  @action appendFile = (attachment: MailerAttach): void => {
    this.attachments = [...this.attachments, attachment];
  };

  @action assignAttributes = (payload: FetchPayload): void => {
    this.documents = payload?.documents || [];
    this.tempDocuments = payload?.tempDocuments || [];
    this.attachments = this.prependFiles(payload?.attachments || []);
    this.templates = payload.templates || [];

    this.templatesStore.setClientLocale(payload.clientLocale || 'en');
    this.templatesStore.setTemplates(payload.templates || []);
  };

  @action setTemplate = (templateId: number) => {
    const target: MailerTemplate =
      this.templates.find((template) => template.id === templateId) || ({} as MailerTemplate);

    this.templatesStore.setCurrentTemplate(target.constantName);

    return this.currentTemplate;
  };

  @action prependFiles = (files: MailerFile[]) =>
    files.map(
      (f: MailerFile): MailerAttach => ({
        value: String(f.id),
        label: f.name,
        size: f.size,
      }),
    );

  /** Used to calculate the size of selected files */
  @action setSelectedFiles = (fileType: FileTypes, checkedValue: CheckboxValueType[]) => {
    const documentsSize = this[fileType].reduce<number>((prev, curr) => {
      if (checkedValue.includes(curr.value)) {
        const size = curr.size === undefined ? 0 : curr.size;

        return prev + size;
      }

      return prev;
    }, 0);

    this.selectedFilesSizes[fileType] = documentsSize;
  };

  @computed get recipients() {
    return this.forceRecipientEmails || this.currentTemplate?.recipient || [];
  }

  @computed get carbonCopy(): string[] {
    return uniq(this.currentTemplate?.carbonCopy);
  }

  @computed get blindCarbonCopy(): string[] {
    return uniq(this.currentTemplate?.blindCarbonCopy);
  }
}

export default MailerStore;
