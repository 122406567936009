import styled from '@emotion/styled';
import { bc, colors } from 'components/UI/variables';

const headerColor = '#b4c5d6';
const selectionColor = 'rgb(220, 237, 254)';

interface TableContainerProps {
  columnHeaderHeight?: number;
  relative?: boolean;
}

export const TableContainer = styled.div<TableContainerProps>`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  min-width: 100%;
  overflow: auto;

  .table {
    display: grid;
    font-size: 13px;
    grid-auto-rows: min-content;
    overflow: auto;
    ${({ relative }) => (relative ? '' : 'height: 100%;')}
    margin: 0;
    width: 100%;

    .table-column-header {
      grid-row: 1;
      position: sticky;
      top: 0;
      z-index: 100;

      align-items: center;
      background: ${bc.tableHeader};
      border: 1px solid ${colors.gray5};
      border-right: 1px solid ${colors.gray0};
      display: flex;
      flex-direction: row;
      height: ${({ columnHeaderHeight }) => columnHeaderHeight ?? '36'}px;
      justify-content: start;
      padding: 0 8px;
      text-align: center;
      user-select: none;
      white-space: nowrap;
      font-weight: 600;
      color: ${bc.tableHeaderTextColor};

      &:first-of-type {
        border-left: none;
      }

      .column-dragger {
        cursor: col-resize;
        height: 100%;
        min-width: 4px;
        width: 4px;
        position: absolute;
        right: -1px;

        &:hover {
          background-color: ${colors.gray7};
        }
      }

      .column-title {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;

        &:hover {
          cursor: not-allowed;
        }
      }

      .sortable-column-title {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;

        &:hover {
          cursor: pointer;
        }

        .item:not(.centered) {
          flex-basis: 0%;
          flex-grow: 1;
        }

        .item:last-child {
          min-width: 12px;
          padding: 0;
          text-align: right;
        }
      }
    }

    .table-row {
      display: contents;

      .table-cell {
        align-items: center;
        border: 1px solid ${colors.gray5};
        border-top: none;
        border-right: none;
        cursor: pointer;
        display: flex;
        min-height: 26px;
        line-height: 0.93rem;
        padding: 4px 8px;
        background-color: #ffffff;

        &:first-of-type {
          border-left: none;
        }

        &.nowrap {
          white-space: nowrap;
          hyphens: none;
        }

        .nowrap {
          white-space: nowrap;
          hyphens: none;
        }
      }

      &:hover > .table-cell {
        background-color: ${selectionColor};
      }

      .table-cell.selected {
        background-color: ${selectionColor};
      }

      &.expanded > div:first-of-type {
        border-left: 4px solid ${headerColor};
      }
    }

    .row-expansion-container {
      border-left: 4px solid ${headerColor};
    }
  }
`;
