import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  Operation,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useMonitoringClient } from '@xometry/ui';
import { Modal, notification } from 'antd';
import { defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { useAccessErrorNotification } from 'components/shared/AccessError/useAccessErrorNotification';
import { Maintenance } from 'components/shared/Maintenance';
import { NotFound } from 'components/shared/NotFound';
import { ENV_API_ENDPOINT } from 'constants/env';
import stringify from 'fast-safe-stringify';
import { GraphQLError } from 'graphql';
import { get } from 'lodash-es';
import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { useStore } from 'stores/RootStore';
import { AddressFragment } from 'utils/graphql/fragments/__generated__/address';
import { MessengerDealSubscriptionFragmentFragment } from 'utils/graphql/fragments/__generated__/messengerDealSubscriptionFragment';
import { getAuthToken } from 'utils/graphql/requests';
import { handleUnauthorizedError } from 'utils/unauthorizedUtils';

interface Props {
  children: ReactNode;
}

export const GQLProvider: FC<Props> = (props) => {
  const { children } = props;
  const monitoringClient = useMonitoringClient();
  const accessErrorNotification = useAccessErrorNotification();

  const client = useMemo(() => {
    const cache = new InMemoryCache({
      addTypename: true,
      dataIdFromObject: (object) => {
        switch (object.__typename) {
          case 'MessageSource': {
            const o = object as MessengerDealSubscriptionFragmentFragment;

            return `MessageSource:${o.sourceType || ''}:${o.sourceId || ''}`;
          }

          case 'DealShippingAddressType': {
            const keysForJoin: Array<keyof AddressFragment> = [
              'address',
              'city',
              'company',
              'country',
              'id',
              'name',
              'phone',
              'zip',
            ];
            const dealShippingAddress = object as AddressFragment;
            const key = keysForJoin.map((k) => dealShippingAddress[k] || '').join('_');

            return `DealShippingAddressType:${key}`;
          }

          default:
            return defaultDataIdFromObject(object) || undefined;
        }
      },
    });

    const networkErrorHandler = (operation: Operation, networkError: ServerError | ServerParseError) => {
      const statusCode = networkError.statusCode;

      if (statusCode === 401) {
        handleUnauthorizedError();

        return;
      }

      if (statusCode === 403) {
        // Show error only on unauthorized mutation
        // For authorization errors in query, notification should be
        // shown only when it's manually requested
        const operationData = String(get(operation, 'query.definitions.0.operation'));

        if (operationData === 'mutation') {
          const error = String(get(networkError, 'result.error'));

          accessErrorNotification(error);
        }

        return;
      }

      if (statusCode === 404) {
        const operationName = operation.operationName;
        const url = networkError.response.url;
        const variables = stringify(operation.variables);

        Modal.info({
          title: 'Not found',
          content: NotFound({
            operationName,
            url,
            variables,
          }),
          width: 800,
        });

        return;
      }

      switch (statusCode) {
        case 500:
          notification.error({ message: 'Something went wrong!' });

          break;
        case 503:
          Modal.info({
            title: 'Attention',
            content: Maintenance,
            width: 800,
          });

          break;
        default: {
          notification.error({ message: networkError.toString() });
        }
      }

      monitoringClient.captureError(networkError, {
        level: 'warning',
        extras: {
          networkError: stringify(networkError),
        },
      });
    };

    const graphqlErrorHandler = (rawErrors: readonly GraphQLError[]) => {
      rawErrors.forEach((rawError) => {
        notification.error(rawError);

        try {
          const message =
            rawError?.message && Array.isArray(rawError?.path)
              ? `[${rawError.path.join('/')}]: ${rawError.message}`
              : rawError?.originalError?.message;

          // Do not log login attempt with incorrect password
          if (message && message.includes('usersEmailLogin') && message.includes('Account not found')) {
            return;
          }

          monitoringClient.captureError(message ? new Error(message) : rawError, {
            level: 'warning',
            extras: {
              rawError: stringify(rawError),
            },
          });
        } catch (e: unknown) {
          monitoringClient.captureError(e instanceof Error ? e : new Error(String(e)), {
            level: 'warning',
            extras: {
              customError: e,
              rawError: stringify(rawError),
            },
          });
        }
      });
    };

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        console.error(`graphql errors [${operation.operationName}]: `, graphQLErrors);
        graphqlErrorHandler(graphQLErrors);
      }

      if (networkError && 'statusCode' in networkError) {
        console.error(`graphql network error [${operation.operationName}]: `, networkError);
        networkErrorHandler(operation, networkError);
      }
    });
    const httpLink = new HttpLink({
      uri: (operation) => `${ENV_API_ENDPOINT}/oms/graphql?${operation.operationName}`,
    });
    const authLink = setContext((_, { headers }) => {
      const token = getAuthToken();

      return {
        // Why it is typed as any, apollo?
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          ...headers,
          accept: 'application/json',
          authorization: token,
        },
        credentials: 'include',
      };
    });

    const link = from([errorLink, authLink.concat(httpLink)]);

    return new ApolloClient({ cache, link, connectToDevTools: process.env.NODE_ENV === 'development' });
  }, [accessErrorNotification, monitoringClient]);

  const store = useStore();

  useEffect(() => {
    store.setGraphlQLClient(client);
  }, [client, store]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
