import { gql } from 'apollo-boost';
import { ExistingWorkspacePreset } from 'interfaces/graphql/workspacePreset';

import { WORKSPACE_PRESET_FRAGMENT } from './fragments';

export interface IWorkspacePresetsResponse {
  workspacePresets: ExistingWorkspacePreset[];
}

export const GET_WORKSPACE_PRESETS = gql`
  query workspacePresets($workspaceName: String!) {
    workspacePresets(workspaceName: $workspaceName) {
      ...workspacePresetFragment
    }
  }
  ${WORKSPACE_PRESET_FRAGMENT}
`;
