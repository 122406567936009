import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { routesApi } from 'routes/api';
import { useStore } from 'stores/RootStore';

const SmartRouter = observer(() => {
  const { smartSearchStore } = useStore();

  useEffect(() => {
    const searchedItem = new URLSearchParams(window.location.search).get('search');

    if (searchedItem) {
      smartSearchStore.fetch(routesApi.smartSearchPath(searchedItem));
    }
  }, [smartSearchStore]);

  return <></>;
});

export default SmartRouter;
