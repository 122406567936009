import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { ErpPermissionFragmentFragmentDoc } from './erpPermissionFragment';
export type RoleWithPermissionsFragment = { id: number, name: string, label?: string | null, erpPermissions: Array<{ id: number, group: string, action: string, name: string, label?: string | null }> };

export const RoleWithPermissionsFragmentDoc = gql`
    fragment roleWithPermissions on Role {
  id
  name
  label
  erpPermissions {
    ...erpPermissionFragment
  }
}
    ${ErpPermissionFragmentFragmentDoc}`;