import { map } from 'lodash-es';

export const stateMapping = {
  draft: { label: 'Draft', color: '#F1830D' },
  active: { label: 'Active', color: '#219653' },
  closed: { label: 'Closed', color: '#DC3545' },
  canceled: { label: 'Canceled', color: '#DC3545' },
  expired: { label: 'Expired', color: '#DC3545' },
};

export const stateOptions = map(stateMapping, (stateData, stateName) => {
  return { value: stateName, label: stateData.label };
});
