import { Row, Space } from 'antd';
import { Notice } from 'components/UI/Notice/styled';
import { observer } from 'mobx-react-lite';
import React from 'react';

interface Props {
  title: React.ReactNode;
  toggle?: React.ReactNode;
  notice?: React.ReactNode;
}

const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

const CollapseHeader = observer<Props>(({ title, notice, toggle }) => (
  <Row justify="space-between">
    <b>{title}</b>

    <Space>
      {toggle}

      {notice && <Notice onClick={stopPropagation}>{notice}</Notice>}
    </Space>
  </Row>
));

export default CollapseHeader;
