import { EMessengerAppName, EMessengerEventBusEvents, TMessengerAppParams } from '@xometry/ui';
import { amplitudeLogger } from 'amplitude';
import clsx from 'clsx';
import { MicroFrontend } from 'components/MicroFrontend';
import PreviewModal from 'components/UI/Preview/Modal';
import { ENV_MESSENGER_URL } from 'constants/env';
import { throttle } from 'lodash-es';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef } from 'react';
import { useStore } from 'stores/RootStore';
import { ESideOverlayType } from 'stores/SideOverlay/SideOverlayStore';
import { getMessengerAppConfig } from 'utils/getMessengerAppConfig';
import { DeviceTypes, useDeviceType } from 'utils/hooks/useDeviceType';
import { useMessengerEventBus } from 'utils/hooks/useMessengerEventBus';
import { useMessengerInit } from 'utils/hooks/useMessengerInit';

import { HeaderControls } from './HeaderControls';
import { ContentContainer } from './styled';

const THROTTLE_DELAY = 2000;
const DISABLE_OUTSIDE_CLICK = true;

function isNode(e: EventTarget | null): e is Node {
  if (!e || !('nodeType' in e)) {
    return false;
  }

  return true;
}

const SideOverlayContainer = observer(() => {
  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const { sideOverlayStore } = useStore();

  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  useMessengerInit();

  const { isOpen, side, context, closeSideOverlay: closeSideOverlayStore, toggleSide } = sideOverlayStore;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { setEventBusRef, previewVisibility, openPreview, closePreview, previewUrl, sendEvent } =
    useMessengerEventBus();

  const closeSideOverlay = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    sendEvent?.(EMessengerEventBusEvents.CLOSE_MESSENGER, {});
    closePreview();
    closeSideOverlayStore();
  }, [closeSideOverlayStore, closePreview, sendEvent]);

  useEffect(() => {
    if (DISABLE_OUTSIDE_CLICK) {
      return;
    }

    const handleClickOutside = throttle(
      (e: MouseEvent) => {
        const eventTarget = e.target;

        const exceptionsClassNames = ['ant-image-preview-root', 'ant-modal-root'];

        if (isOpen && isNode(eventTarget)) {
          let prevent = false;

          exceptionsClassNames.forEach((exceptionClassName) => {
            let eventTargetParentElement = eventTarget.parentElement;

            while (eventTargetParentElement) {
              if (eventTargetParentElement.classList.contains(exceptionClassName)) {
                prevent = true;

                return;
              }

              eventTargetParentElement = eventTargetParentElement.parentElement;
            }
          });

          if (!prevent && !contentContainerRef.current?.contains(eventTarget)) {
            closeSideOverlay();
          }
        }
      },
      THROTTLE_DELAY,
      { trailing: false },
    );

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => document.removeEventListener('click', handleClickOutside);
  }, [closeSideOverlay, closePreview, isOpen]);

  const handleOpenModal = () => {
    openPreview();
  };

  const previewModalComponent = (
    <PreviewModal
      visible={previewVisibility}
      openModal={handleOpenModal}
      closeModal={closePreview}
      url={previewUrl}
      skipFetch={true}
    />
  );

  if (!isOpen) {
    return previewModalComponent;
  }

  let messengerAppParams: TMessengerAppParams<EMessengerAppName.ERP_MAIN> | null = null;

  if (context.type === ESideOverlayType.Messenger) {
    const messengerAppConfig = getMessengerAppConfig();

    if (context.initialScreen) {
      messengerAppParams = {
        appName: EMessengerAppName.ERP_MAIN,
        config: messengerAppConfig,
        initialScreen: toJS(context.initialScreen),
        setEventBusRef,
        amplitudeLogger,
        isMobile,
      };
    } else {
      messengerAppParams = {
        appName: EMessengerAppName.ERP_MAIN,
        config: messengerAppConfig,
        initialMessageId: context.initialMessageId,
        setEventBusRef,
        amplitudeLogger,
        isMobile,
      };
    }
  }

  return (
    <>
      <ContentContainer className={clsx({ [side]: true, isMobile })} ref={contentContainerRef}>
        {!isMobile ? (
          <HeaderControls
            onClose={() => {
              closeSideOverlay();
            }}
            onToggleSide={toggleSide}
            side={side}
          />
        ) : null}
        {context.type === ESideOverlayType.Messenger && messengerAppParams ? (
          <MicroFrontend
            name="Messenger"
            containerId="MessengerContainer"
            host={ENV_MESSENGER_URL}
            appParams={messengerAppParams}
          />
        ) : null}
      </ContentContainer>
      {previewModalComponent}
    </>
  );
});

SideOverlayContainer.displayName = 'SideOverlay';

export { SideOverlayContainer };
