export const wait = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));

interface WithAttemptsParams<T> {
  attempts?: number;
  retryDelayMs?: number;
  shouldRetry?: (config: {
    attempt: number;
    params?: WithAttemptsParams<T>;
    // Note(pkocherov@xometry.de) you can throw not an "Error" or even "undefined" and be with it.
    error?: unknown;
  }) => boolean;
}
const DEFAULT_SHOULD_RETRY_FN: WithAttemptsParams<unknown>['shouldRetry'] = () => true;

export const withAttempts = <T>(request: () => Promise<T>, params?: WithAttemptsParams<T>): (() => Promise<T>) => {
  const { attempts = 3, retryDelayMs = 300, shouldRetry = DEFAULT_SHOULD_RETRY_FN } = params || {};

  return async () => {
    for (let attempt = 1; attempt <= attempts; attempt++) {
      try {
        return await request();
      } catch (error: unknown) {
        if (attempt >= attempts || !shouldRetry({ attempt, error, params })) {
          throw error;
        }

        if (retryDelayMs) {
          await wait(retryDelayMs);
        }
      }
    }

    // Note(pkocherov@xometry.de) Won't be called, but makes typescript happy.
    return await request();
  };
};
