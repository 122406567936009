import { FormInstance } from 'antd/lib/form';
import { IProviderOrder } from 'interfaces';
import { action, observable } from 'mobx';
import { RootStore } from 'stores/RootStore';

interface POProviderInfo {
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  state?: string;
  comment?: string;
}

class DealProviderOrderStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  form: FormInstance | undefined;
  providerInfo: POProviderInfo | undefined = undefined;

  @observable loading = false;
  @observable currentProviderOrder: IProviderOrder | undefined;

  @action reset = () => {
    this.providerInfo = undefined;
    this.currentProviderOrder = undefined;
  };

  @action setProviderInfo = (providerInfo: POProviderInfo) => {
    this.providerInfo = providerInfo;
  };

  @action setCurrentProviderOrder = (value: IProviderOrder): void => {
    this.currentProviderOrder = value;
  };
}

export default DealProviderOrderStore;
