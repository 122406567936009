import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import {
  Column,
  ColumnHeaderDecorator,
  ISortParam,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { columnSortable } from 'components/Workspaces/General/shared/GeneralWorkspace/utils';
import { decamelize } from 'humps';
import { find, includes } from 'lodash-es';
import React from 'react';

interface Props {
  column: Column;
  index: number;
  onColumnHeader?: ColumnHeaderDecorator;
  onColumnSort: (sortBy: string) => void;
  onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  sortedColumns: ISortParam[];
}

const ColumnHeader: React.FC<Props> = ({ column, index, onColumnHeader, onColumnSort, onMouseDown, sortedColumns }) => {
  let title = column.settings?.title ?? column.title;

  const decamelizedSortBy: string | undefined = (() => {
    if (column.settings?.sortBy != null) return decamelize(column.settings.sortBy);

    if (column.settings?.decamelizedName != null) return column.settings.decamelizedName;

    if (column.key != null && !column.synthetic) return decamelize(column.key);

    return undefined;
  })();

  // If the column is synthetic, add * to the and of the title
  if (column.synthetic && !column.settings?.skipAsterisk) {
    title += ' *';
  }

  let titleContent: React.ReactNode;

  let notSortable = false;

  if (column.settings?.sortBy == null) {
    notSortable =
      column.synthetic || includes(column.field?.description, 'custom') || !columnSortable(column.settings?.visibility);
  }

  const titleInternalContent = column.settings?.thTitle
    ? column.settings?.thTitle
    : onColumnHeader?.(column.key, { title }) ?? title;

  const tooltipContent = column.settings?.thTitle ? title : decamelizedSortBy;

  if (decamelizedSortBy == null || notSortable) {
    titleContent = <div className="column-title">{titleInternalContent}</div>;
  } else {
    const sortRule = find(sortedColumns, (sort) => sort.sortBy === decamelizedSortBy);

    titleContent = (
      <Tooltip title={tooltipContent}>
        <div
          className="sortable-column-title"
          role="button"
          onClick={() => decamelizedSortBy && onColumnSort(decamelizedSortBy)}
          onKeyPress={() => decamelizedSortBy && onColumnSort(decamelizedSortBy)}
          tabIndex={-1}
        >
          {/* Is required for centering the header */}
          <div className="item" />
          <div className="item centered">{titleInternalContent}</div>
          <div className="item">
            {sortRule != null &&
              (sortRule.direction === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
          </div>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className="table-column-header" key={column.key} style={{ gridColumn: index + 1 }}>
      {titleContent}
      <div
        aria-label="Resize column"
        className="column-dragger"
        onMouseDown={onMouseDown}
        role="button"
        tabIndex={-1}
      />
    </div>
  );
};

export default ColumnHeader;
