import { Form } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import {
  Column,
  GraphQLScalarType,
  ITopFilter,
  NativeColumn,
  WorkspaceGraphQLField,
  WorkspaceGraphQLObjectField,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import ValueInputs from 'components/Workspaces/General/shared/GeneralWorkspace/EditFiltersModal/Filters/ValueInputs';
import { FilterItem } from 'components/Workspaces/General/shared/GeneralWorkspace/FiltersStore';
import humanizeString from 'humanize-string';
import { find, flatMap, map } from 'lodash-es';
import React from 'react';

type LabelPosition = 'label' | 'placeholder';

interface Props {
  attributes: WorkspaceGraphQLField[];
  columns: Column[];
  filterSettings: ITopFilter;
  inputSize?: SizeType;
  inputWidth?: number;
  labelPosition?: LabelPosition;
  objectAttributes: WorkspaceGraphQLObjectField[];
  onFiltersUpdate?: (filters: FilterItem) => any;
}

const TopFilter: React.FC<Props> = ({
  attributes,
  columns,
  filterSettings,
  inputSize,
  inputWidth,
  labelPosition = 'placeholder',
  objectAttributes,
}) => {
  const column = find(columns, { key: filterSettings.attribute }) as NativeColumn;
  const filterLabel = column?.title ?? filterSettings.attribute;
  const valueOptions = column?.settings?.options;
  const multipleOptions = !column?.settings?.synthetic && column?.settings?.multipleOptions;

  const attribute = filterSettings.attribute?.includes('__')
    ? find(
        flatMap(objectAttributes, (objectAttribute) =>
          map(objectAttribute.type.fields, (field) => ({ ...field, name: `${objectAttribute.name}__${field.name}` })),
        ),
        { name: filterSettings.attribute },
      )
    : find(attributes, (x) => x.name === filterSettings.attribute);

  let attributeType = attribute?.type?.name as GraphQLScalarType;

  if (attributeType == null) attributeType = GraphQLScalarType.String;

  const label = `${filterLabel} ${humanizeString(filterSettings.pattern).toLowerCase()}`;

  return (
    <Form.Item
      className="top-filter"
      name={filterSettings.attribute}
      label={labelPosition === 'label' ? label : undefined}
      valuePropName="values"
    >
      <ValueInputs
        options={valueOptions}
        multipleOptions={multipleOptions}
        pattern={filterSettings.pattern}
        placeholder={labelPosition === 'placeholder' ? label : undefined}
        prefix={filterSettings.inputPrefix}
        size={inputSize}
        type={attributeType}
        width={inputWidth}
      />
    </Form.Item>
  );
};

export default TopFilter;
