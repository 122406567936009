import { Button, Checkbox, Form, Input, Popconfirm, Space, Tooltip } from 'antd';
import { ValidateErrorEntity } from 'collections/form';
import { Notification } from 'components/UI';
import { QueryNameKey } from 'components/Workspaces/collections';
import { Column } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { PresetOptionData } from 'components/Workspaces/General/shared/GeneralWorkspace/withPresets';
import { ExistingWorkspacePreset } from 'interfaces/graphql/workspacePreset';
import React from 'react';
import { useDefaultWorkspacePresetQuery } from 'utils/graphql/queries/__generated__/defaultWorkspacePreset';
import { trimmedRequired } from 'validators/rules';

import s from './EditPresetForm.module.less';
import { IPresetParams } from './interfaces';
import { SetAsGlobalDefaultButton } from './SetAsGlobalDefaultButton';

interface Props {
  currentColumns: Column[];
  currentPreset: ExistingWorkspacePreset;
  onPresetUpdate: (preset: ExistingWorkspacePreset) => void;
  onPresetDelete: (preset: ExistingWorkspacePreset) => void;
  onPresetCopy: (preset: ExistingWorkspacePreset) => void;
  queryName: QueryNameKey;
  presetsList: PresetOptionData[];
}

const unpublishErrorMessage = 'It is not allowed to unpublish a default preset';
const deletePresetErrorMessage = 'It is not allowed to delete a default preset';
const presetNameError = 'This name already exist';

const EditPresetForm: React.FC<Props> = ({
  currentPreset,
  currentColumns,
  queryName,
  presetsList,
  onPresetUpdate,
  onPresetDelete,
  onPresetCopy,
}) => {
  const [form] = Form.useForm<IPresetParams>();

  const { data: defaultPresetData, loading: defaultPresetLoading } = useDefaultWorkspacePresetQuery({
    variables: { workspaceName: queryName },
  });

  const currentPresetIsDefault =
    currentPreset.id === defaultPresetData?.defaultWorkspacePreset?.globalDefaultPreset?.id;

  const allowToDeletePreset = !currentPresetIsDefault && !defaultPresetLoading;

  const handleUpdatePresetClick = () => {
    form
      .validateFields()
      .then(() => {
        onPresetUpdate({
          ...currentPreset,
          ...form.getFieldsValue(),
        });
      })
      .catch((e) => {
        const error = e as ValidateErrorEntity;
        Notification.Error(error.errorFields[0].errors[0]);
      });
  };

  const handleCreateNewPreset = () => {
    void form.validateFields(['name']).then(() => {
      const values = form.getFieldsValue();
      const columns = currentColumns.filter((c) => c.enabled).map((column) => column.key);

      onPresetCopy({
        ...currentPreset,
        ...values,
        columns,
      });
    });
  };

  const handleDeletePresetClick = () => {
    onPresetDelete(currentPreset);
  };

  return (
    <div className={s.root}>
      <Form className={s.presetRow} form={form} initialValues={currentPreset} layout="horizontal">
        <Space direction="horizontal" size="middle" align="start">
          <Form.Item
            name="name"
            label="Name:"
            rules={[
              trimmedRequired(true),
              {
                message: presetNameError,
                validator: (rule, value) => {
                  const nameAlreadyExist = Boolean(
                    presetsList
                      .filter((preset) => preset.value !== currentPreset.id)
                      .find((preset) => preset.name === value),
                  );

                  if (nameAlreadyExist) {
                    return Promise.reject(presetNameError);
                  }

                  return Promise.resolve();
                },
              },
            ]}
            className={s.presetNameField}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="public"
            valuePropName="checked"
            className={s.checkbox}
            rules={[
              {
                validator: (rule, value) => {
                  if (currentPresetIsDefault && value === false) {
                    return Promise.reject(unpublishErrorMessage);
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Checkbox>Public</Checkbox>
          </Form.Item>
        </Space>

        <div className={s.actions}>
          <Space>
            <Button htmlType="button" onClick={handleUpdatePresetClick} type="primary">
              Update
            </Button>
            <Button htmlType="button" onClick={handleCreateNewPreset} type="primary">
              Create new
            </Button>
          </Space>
          <Space>
            <SetAsGlobalDefaultButton currentPreset={currentPreset} queryName={queryName} />

            <Popconfirm onConfirm={handleDeletePresetClick} title="Are you sure you want to delete the preset?">
              <Tooltip title={!allowToDeletePreset ? deletePresetErrorMessage : undefined}>
                <Button danger disabled={!allowToDeletePreset}>
                  Delete preset
                </Button>
              </Tooltip>
            </Popconfirm>
          </Space>
        </div>
      </Form>
    </div>
  );
};

export default EditPresetForm;
