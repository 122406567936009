/* eslint-disable react/jsx-props-no-spreading */
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { routes } from 'routes';
import { useStore } from 'stores/RootStore';

interface Props extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
}

const PrivateRoute = observer((props: Props) => {
  const { userStore } = useStore();
  const { component: Component, ...rest } = props;

  const { isAuthenticated } = userStore;

  if (!isAuthenticated) userStore.setReturnTo();

  return (
    <Route
      {...rest}
      render={(routeProps) => (isAuthenticated ? <Component {...routeProps} /> : <Redirect to={routes.signInPath()} />)}
    />
  );
});

export default PrivateRoute;
