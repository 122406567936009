import { UserLocStore } from 'interfaces';
import { routes } from 'routes';

export const clearAuthLocalStorage = () => {
  localStorage.setItem(UserLocStore.isAuth, 'false');
  localStorage.removeItem(UserLocStore.name);
  localStorage.removeItem(UserLocStore.id);
  localStorage.removeItem(UserLocStore.email);

  return {
    isAuthenticated: false,
    name: null,
    id: null,
    email: null,
  };
};

export const handleUnauthorizedError = () => {
  clearAuthLocalStorage();

  localStorage.setItem(UserLocStore.returnTo, window.location.href);

  window.location.href = routes.signInPath();
};
