import { RuleObject } from 'antd/lib/form';

import { TemplateModalFormFieldEnum } from './TemplatesManagerModal.types';

export const toRules = [
  {
    validator: (_: RuleObject, value: string[]) => {
      if (!value || value.length === 0) {
        return Promise.reject(new Error('Recipients is required'));
      }

      return Promise.resolve();
    },
  },
];

export const getContentRules = (field: TemplateModalFormFieldEnum) => [
  {
    validator: (_: RuleObject, value: string) => {
      if (!value || !value.trim()) {
        return Promise.reject(new Error(`${field} is required`));
      }

      return Promise.resolve();
    },
  },
];
