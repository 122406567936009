import { gql } from 'apollo-boost';

import { ORGANIZATION_FRAGMENT } from '../fragments';

export const ORGANIZATION_UPDATE_PM = gql`
  mutation organizationsUpdateProductionManager($id: ID!, $attributes: OmsInputsOrganizationUpdatePmCascade!) {
    organizationsUpdateProductionManager(input: { id: $id, attributes: $attributes }) {
      organization {
        ...organizationFragment
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
