import { action, observable } from 'mobx';

export class ModalStore {
  @observable open = false;

  @action
  openModal = () => {
    this.open = true;
  };

  @action
  closeModal = () => {
    this.open = false;
  };

  @action
  toggleModal = () => {
    this.open = !this.open;
  };
}
