import { TemplateDirectionEnum, TemplateLocaleEnum, TemplateTypeEnum } from '__generated__/types';

export enum TemplateCategoryEnum {
  CUSTOM = 'Custom',
  ERP_TEMPLATES = 'ERP Templates',
  WHATS_APP = 'WhatsApp',
}

export enum TemplateModalFormFieldEnum {
  TemplateName = 'templateName',
  Category = 'category',
  RelatedType = 'relatedType',
  Direction = 'direction',
  Locale = 'locale',
  Subject = 'subject',
  Content = 'content',
  To = 'to',
  Cc = 'cc',
  Bcc = 'bcc',
  Shared = 'shared',
}

export interface TemplateModalFormValues {
  [TemplateModalFormFieldEnum.TemplateName]: string;
  [TemplateModalFormFieldEnum.Category]: string;
  [TemplateModalFormFieldEnum.RelatedType]: TemplateTypeEnum | string;
  [TemplateModalFormFieldEnum.Direction]: TemplateDirectionEnum | string;
  [TemplateModalFormFieldEnum.Locale]: TemplateLocaleEnum[];
  [TemplateModalFormFieldEnum.Subject]: string;
  [TemplateModalFormFieldEnum.Content]: string;
  [TemplateModalFormFieldEnum.To]: string[];
  [TemplateModalFormFieldEnum.Cc]: string[];
  [TemplateModalFormFieldEnum.Bcc]: string[];
  [TemplateModalFormFieldEnum.Shared]: boolean;
}
