import { Notification } from 'components/UI';
import { IAddress } from 'interfaces/stores/Shipping';

interface AddressData {
  phone?: string | null;
  email?: string | null;
  name?: string | null;
}

export const addDataToAddress = (data?: AddressData | null, address?: IAddress) => {
  if (!data || !address) return { newAddress: address, addedData: {} };

  const newAddress = { ...address };
  const addedData: AddressData = {};

  if (!newAddress.name && data.name) {
    const personName = data.name;
    newAddress.name = personName;
    addedData.name = personName;
  }

  if (!newAddress.phone && data.phone) {
    const personPhone = data.phone;
    newAddress.phone = personPhone;
    addedData.phone = personPhone;
  }

  if (!newAddress.email && data.email) {
    const personEmail = data.email;
    newAddress.email = personEmail;
    addedData.email = personEmail;
  }

  return { newAddress, addedData };
};

export const notifyAboutAddressUpdate = (addedData: AddressData, addressType: string) => {
  const { name, phone, email } = addedData;

  if (name || phone || email) {
    const addedDataString = Object.entries({ name, phone, email })
      .map(([key, value]) => {
        return `${key}: ${value as string}`;
      })
      .join('\n');

    Notification.Info(`${addressType} address was updated from deal's person`, addedDataString);
  }
};

interface AddDataArgs {
  address?: IAddress;
  data?: AddressData | null;
  addressType: string;
}

export const addDataToAddressAndNotify = ({ address, data, addressType }: AddDataArgs) => {
  if (!address || !data) return address;

  const { newAddress, addedData } = addDataToAddress(data, address);

  notifyAboutAddressUpdate(addedData, addressType);

  return newAddress;
};
