import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageCreateFromTemplateMutationVariables = Types.Exact<{
  input: Types.MessengerMessageCreateFromTemplateInput;
}>;


export type MessageCreateFromTemplateMutation = { messengerMessageCreateFromTemplate?: { message: { id: string } } | null };


export const MessageCreateFromTemplateDocument = gql`
    mutation messageCreateFromTemplate($input: MessengerMessageCreateFromTemplateInput!) {
  messengerMessageCreateFromTemplate(input: $input) {
    message {
      id
    }
  }
}
    `;
export type MessageCreateFromTemplateMutationFn = Apollo.MutationFunction<MessageCreateFromTemplateMutation, MessageCreateFromTemplateMutationVariables>;

/**
 * __useMessageCreateFromTemplateMutation__
 *
 * To run a mutation, you first call `useMessageCreateFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageCreateFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageCreateFromTemplateMutation, { data, loading, error }] = useMessageCreateFromTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageCreateFromTemplateMutation(baseOptions?: Apollo.MutationHookOptions<MessageCreateFromTemplateMutation, MessageCreateFromTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageCreateFromTemplateMutation, MessageCreateFromTemplateMutationVariables>(MessageCreateFromTemplateDocument, options);
      }
export type MessageCreateFromTemplateMutationHookResult = ReturnType<typeof useMessageCreateFromTemplateMutation>;
export type MessageCreateFromTemplateMutationResult = Apollo.MutationResult<MessageCreateFromTemplateMutation>;
export type MessageCreateFromTemplateMutationOptions = Apollo.BaseMutationOptions<MessageCreateFromTemplateMutation, MessageCreateFromTemplateMutationVariables>;