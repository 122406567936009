import { ErpPermissionGroup } from 'collections/authorization';
import useCurrentUserPermissions from 'utils/hooks/useCurrentUserPermissions';

export const useAllowedToUpdatePresets = () => {
  const { allPermissions } = useCurrentUserPermissions();

  const workspacesPermissions =
    allPermissions?.[ErpPermissionGroup.Workspaces]?.map((permission) => permission.name) || [];

  return workspacesPermissions.includes('update_presets');
};
