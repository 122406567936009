import { TrackingEventsPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

const BackofficeController = () => (
  <Route path={routes.backofficeTrackingEventsPath} component={TrackingEventsPage} exact />
);

export default BackofficeController;
