import { Button, Tooltip } from 'antd';
import { Notification } from 'components/UI';
import { ExistingWorkspacePreset } from 'interfaces/graphql/workspacePreset';
import React, { useMemo } from 'react';
import { useWorkspacePresetsAssignGlobalDefaultPresetMutation } from 'utils/graphql/mutations/__generated__/workspacePresetsAssignGlobalDefaultPreset';
import {
  DefaultWorkspacePresetDocument,
  useDefaultWorkspacePresetQuery,
} from 'utils/graphql/queries/__generated__/defaultWorkspacePreset';

interface Props {
  queryName: string;
  currentPreset: ExistingWorkspacePreset;
}

export const SetAsGlobalDefaultButton: React.FC<Props> = (props) => {
  const { queryName, currentPreset } = props;

  const { data: defaultPresetData, loading: defaultPresetLoading } = useDefaultWorkspacePresetQuery({
    variables: { workspaceName: queryName },
  });

  const isDefaultGlobalPreset = currentPreset.id === defaultPresetData?.defaultWorkspacePreset?.globalDefaultPreset?.id;

  const [assignDefaultPreset, { loading: assignDefaultPresetLoading }] =
    useWorkspacePresetsAssignGlobalDefaultPresetMutation({
      onCompleted: () => Notification.Success('Default global preset successfully updated'),
      refetchQueries: [
        {
          query: DefaultWorkspacePresetDocument,
          variables: { workspaceName: queryName },
        },
      ],
    });

  const handleMakeDefaultClick = () => {
    void assignDefaultPreset({
      variables: {
        workspaceName: queryName,
        presetId: String(currentPreset.id),
      },
    });
  };

  const { tooltipText, isDisabled } = useMemo(() => {
    let tooltipText: string | undefined = undefined;

    if (isDefaultGlobalPreset) {
      tooltipText = 'Preset is already global';
    }

    if (!currentPreset.public) {
      tooltipText = 'Preset is not public';
    }

    const isDisabled = isDefaultGlobalPreset || !currentPreset.public;

    return { tooltipText, isDisabled };
  }, [currentPreset.public, isDefaultGlobalPreset]);

  if (defaultPresetLoading) {
    return null;
  }

  return (
    <Tooltip title={tooltipText}>
      <Button loading={assignDefaultPresetLoading} disabled={isDisabled} onClick={handleMakeDefaultClick}>
        Set as global default
      </Button>
    </Tooltip>
  );
};
