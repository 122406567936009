import { CreateDealFormValues, ObjectType } from 'interfaces';

export const mapCreateValues = (values: CreateDealFormValues) => ({
  create_empty: values.createEmpty,
  attachments: values.attachments,
  person_id: values.personId,
});

export const sortByType = (type: ObjectType, name: string): number => {
  switch (type) {
    case ObjectType.Q:
      return 100;
    case ObjectType.E:
      return 90;
    case ObjectType.OC:
      return 80;
    case ObjectType.J:
      return 70;
    case ObjectType.PO:
      return 60;
    case ObjectType.CL:
      return 50;
    case ObjectType.DL:
      return 40;
    case ObjectType.SH:
      return 39;
    case ObjectType.QC:
      return 38;
    case ObjectType.SU:
      return 37;
    case ObjectType.OI:
      return name.includes('G') || name.includes('Credit') ? 30 : 35;
    case ObjectType.CreditNote:
      return 20;
    case ObjectType.Payment:
      return name.includes('RF') ? 10 : 15;

    default:
      return 0;
  }
};
