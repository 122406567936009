import { Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { FC, ReactElement } from 'react';
import { REQUIRED } from 'validators';

import { RecipientItem } from '../styled';

const { Option } = Select;

interface Props {
  name: string[] | string;
  label?: string;
  options: string[];
  required?: boolean;
  form: FormInstance;
}

const Recipient: FC<Props> = ({ name, options, required, label = '' }): ReactElement => (
  <RecipientItem label={label} name={name} rules={[{ required, message: REQUIRED }]}>
    <Select mode="tags">
      {options.map((item, index) => (
        <Option
          // eslint-disable-next-line react/no-array-index-key
          key={`${item}-${index}`}
          value={item}
        >
          {item}
        </Option>
      ))}
    </Select>
  </RecipientItem>
);

export default Recipient;
