import { useMutation, useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { Notification, PageLoader } from 'components/UI';
import { ColumnsMap, QueryNameKey } from 'components/Workspaces/collections';
import { filterByLabel } from 'helpers/filterOption';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import { filter, find, orderBy, pickBy } from 'lodash-es';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useWorkspacePresetsUpdateMutation } from 'utils/graphql/mutations/__generated__/workspacePresetsUpdate';
import { useDefaultWorkspacePresetQuery } from 'utils/graphql/queries/__generated__/defaultWorkspacePreset';

import { ISortParam } from './collections';
import {
  IWorkspacePresetsCreateResponse,
  IWorkspacePresetsDeleteResponse,
  WORKSPACE_PRESETS_CREATE,
  WORKSPACE_PRESETS_DELETE,
} from './graphql/mutations';
import { GET_WORKSPACE_PRESETS, IWorkspacePresetsResponse } from './graphql/queries';
import s from './preset.module.less';

export interface PresetOptionData {
  label: string;
  name: string;
  value: number;
}

export interface IPresetAttributes {
  name: string;
  isPublic: boolean;
  columns: string[];
  query: string;
  sort?: ISortParam;
}

interface WithPresetsProps {
  predefinedPreset?: number;
  columns?: ColumnsMap<any>;
  queryName: QueryNameKey;
  embed?: boolean;
}

interface PresettableProps {
  preset: WorkspacePreset;
  presetSelector: React.ReactElement<SelectProps<number>>;
  onPresetSelectorFilter?: (filter: (option: PresetOptionData) => boolean) => any;
  onPresetCreate: (attributes: IPresetAttributes) => any;
  onPresetUpdate: (preset: ExistingWorkspacePreset) => any;
  onPresetDelete: (preset: ExistingWorkspacePreset) => any;
}

function withPresets<T extends PresettableProps>(Component: React.ComponentType<T>) {
  const InnerComponent: FC<Omit<T, keyof PresettableProps> & WithPresetsProps> = ({
    columns,
    queryName,
    predefinedPreset,
    embed,
    ...restProps
  }) => {
    const enabledColumns = Object.keys(pickBy(columns, { enabled: true }));

    const defaultPreset = {
      columns: enabledColumns,
      public: false,
      workspaceName: queryName,
    };

    const [currentPreset, setCurrentPreset] = useState<WorkspacePreset>();
    const [presetFilter, setPresetFilter] = useState<(option: PresetOptionData) => boolean>();

    const { data: userDefaultPresetData, loading: userDefaultPresetLoading } = useDefaultWorkspacePresetQuery({
      variables: { workspaceName: queryName },
      skip: embed,
    });

    const userDefaultPreset =
      userDefaultPresetData?.defaultWorkspacePreset.personalDefaultPreset ||
      userDefaultPresetData?.defaultWorkspacePreset.globalDefaultPreset;

    const isCurrentPresetDefault = Boolean(
      currentPreset && userDefaultPreset && currentPreset.id === userDefaultPreset.id,
    );

    const getWorkspacePresetsVariables = { workspaceName: queryName };
    const { data: workspacePresetsData, loading: workspacePresetsLoading } = useQuery<IWorkspacePresetsResponse>(
      GET_WORKSPACE_PRESETS,
      {
        skip: userDefaultPresetLoading,
        variables: getWorkspacePresetsVariables,
      },
    );

    const { workspacePresets } = workspacePresetsData || {};

    let workspacePresetOptions: PresetOptionData[] = useMemo(() => {
      let presets: ExistingWorkspacePreset[] = orderBy(workspacePresets || [], 'public');

      if (userDefaultPreset) {
        presets = presets.filter((p) => p.id !== userDefaultPreset.id);
        presets.unshift(userDefaultPreset as ExistingWorkspacePreset);
      }

      return presets.map(presetToOption);
    }, [userDefaultPreset, workspacePresets]);

    if (presetFilter != null) {
      workspacePresetOptions = filter(workspacePresetOptions, presetFilter);
    }

    const handleCurrentPresetChange = (presetId?: number | string) => {
      const preset = find(workspacePresetsData?.workspacePresets, (wp) => wp.id.toString() === presetId?.toString());
      const newPreset = (preset || userDefaultPreset || defaultPreset) as WorkspacePreset;
      setCurrentPreset(newPreset);
    };

    const [createWorkspacePreset] = useMutation<IWorkspacePresetsCreateResponse>(WORKSPACE_PRESETS_CREATE, {
      onCompleted: ({ workspacePresetsCreate: { workspacePreset } }) => handleCurrentPresetChange(workspacePreset.id),
      refetchQueries: [
        {
          query: GET_WORKSPACE_PRESETS,
          variables: getWorkspacePresetsVariables,
        },
      ],
      awaitRefetchQueries: true,
    });

    const [updateWorkspacePreset] = useWorkspacePresetsUpdateMutation({
      onCompleted: ({ workspacePresetsUpdate }) => {
        handleCurrentPresetChange(workspacePresetsUpdate?.workspacePreset.id);
      },
    });

    const [deleteWorkspacePreset] = useMutation<IWorkspacePresetsDeleteResponse>(WORKSPACE_PRESETS_DELETE, {
      onCompleted: (_: IWorkspacePresetsDeleteResponse) => {
        handleCurrentPresetChange(undefined);
      },
      refetchQueries: [
        {
          query: GET_WORKSPACE_PRESETS,
          variables: getWorkspacePresetsVariables,
        },
      ],
    });

    useEffect(() => {
      if (workspacePresetsLoading) return;

      if (workspacePresetsData == null) return;

      // 1. Determine preset_id by priority:
      //   * URL parameter
      //   * User's default preset
      //   * Predefined preset for current workspace
      // 2. If there's no preset_id - no preset requested, use plain workspace
      // 3. If preset_id exists - try to find preset by id
      // 4. If preset is found - use it,
      //    if not found - use dummy and show error

      const query = new URLSearchParams(window.location.search);
      const urlPreset = query.get('preset') ?? undefined;
      const presetId = urlPreset ?? userDefaultPreset?.id.toString() ?? predefinedPreset?.toString();

      if (presetId == null) {
        setCurrentPreset(defaultPreset);

        return;
      }

      const preset = find(workspacePresetsData.workspacePresets, (wp) => wp.id.toString() === presetId);

      if (preset != null) {
        setCurrentPreset(preset);
      } else {
        setCurrentPreset(defaultPreset);
        Notification.Error(`Preset "${presetId}" not found`);
      }
      // TODO: Infinite rerendering if fix dependencies
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspacePresetsLoading, workspacePresetsData == null, predefinedPreset]);

    const handlePresetCreate = ({ name, query, columns, isPublic, sort }: IPresetAttributes) => {
      void createWorkspacePreset({
        variables: {
          attributes: {
            name,
            query,
            columns,
            workspaceName: queryName,
            public: isPublic,
            sortColumn: sort?.sortBy,
            sortDirection: sort?.direction,
          },
        },
      });
    };

    const handlePresetUpdate = (preset: ExistingWorkspacePreset) => {
      void updateWorkspacePreset({
        variables: {
          input: {
            attributes: {
              name: preset.name,
              query: preset.query,
              public: preset.public,
              workspaceName: preset.workspaceName,
              columns: preset.columns,
              exportColumns: preset.exportColumns,
              sortColumn: preset.sortColumn,
              sortDirection: preset.sortDirection,
            },
            id: preset.id,
          },
        },
      });
    };

    const handlePresetDelete = (preset: ExistingWorkspacePreset) => {
      void deleteWorkspacePreset({
        variables: { id: preset.id },
      }).then(() => {
        const personalPresetId = userDefaultPresetData?.defaultWorkspacePreset.personalDefaultPreset?.id;
        const globalPresetId = userDefaultPresetData?.defaultWorkspacePreset.globalDefaultPreset?.id;
        const isPersonalPresetDeleted = personalPresetId === preset.id;
        const isGlobalPresetDeleted = globalPresetId === preset.id;

        if (isPersonalPresetDeleted) {
          handleCurrentPresetChange(globalPresetId);
        } else if (isGlobalPresetDeleted) {
          handleCurrentPresetChange(personalPresetId);
        } else {
          handleCurrentPresetChange();
        }
      });
    };

    const presetSelector = (
      <Select
        onChange={handleCurrentPresetChange}
        options={workspacePresetOptions}
        placeholder="Custom"
        showSearch
        allowClear={!isCurrentPresetDefault}
        filterOption={filterByLabel}
        className={s.presetSelector}
        value={currentPreset?.name}
      />
    );

    if (workspacePresetsLoading || currentPreset == null) {
      return <PageLoader title="Loading presets data..." isVisible />;
    }

    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(restProps as any)}
        columns={columns}
        queryName={queryName}
        preset={currentPreset}
        presetSelector={presetSelector}
        presetsList={workspacePresetOptions}
        onPresetSelectorFilter={(filter) => setPresetFilter(() => filter)}
        onPresetCreate={handlePresetCreate}
        onPresetUpdate={handlePresetUpdate}
        onPresetDelete={handlePresetDelete}
      />
    );
  };

  return InnerComponent;
}

function presetToOption(preset: ExistingWorkspacePreset): PresetOptionData {
  return {
    label: `${!preset.public ? '🔒 ' : ''}${preset.name}`,
    name: preset.name,
    value: preset.id,
  };
}

export default withPresets;
