import { dateFormatToBackend } from 'components/utils/formatters';
import { OutInvoicePublishedFormValues, ServerUpdatePublishedInvoice } from 'interfaces';
import { decamelizeKeys } from 'utils/axios';

export const mapServerPublishedInvoice = (data: OutInvoicePublishedFormValues): ServerUpdatePublishedInvoice => ({
  cash_collection_comment: data.cashCollectionComment,
  cash_collection_status: data.cashCollectionStatus,
  datev_comment: data.datevComment,
  intacct_comment: data.intacctComment,
  is_published_in_ca: data.isPublishedInCA,
  negotiated_due_date: data.negotiatedDueDate && dateFormatToBackend(data.negotiatedDueDate),
  paid_at: data.paidAt && dateFormatToBackend(data.paidAt),
  payment_relations: decamelizeKeys(data.paymentRelations),
  payment_terms_date: data.paymentTermsDate && dateFormatToBackend(data.paymentTermsDate),
  registered_at: data.registeredAt && dateFormatToBackend(data.registeredAt),
  send_reminders: data.sendReminders,
});

export const initialValuesOutInvoicePublished: OutInvoicePublishedFormValues = {
  datevComment: '',
  intacctComment: '',
  isPublishedInCA: false,
  negotiatedDueDate: null,
  paidAt: undefined,
  paymentTermsDate: null,
  registeredAt: undefined,
  sendReminders: false,
};
