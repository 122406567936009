import {
  Money,
  ProviderOrdersSamplesConfirmationStatusEnum,
  ProviderOrdersSamplesProviderOrderTypeEnum,
} from '__generated__/types';
import { TransactionTypes } from 'collections/paymentConstants';
import { ObjectType } from 'interfaces';

import { IncomingInvoiceState } from './IncomingInvoiceStore';

export interface IDealSidebarItem {
  id: number;
  name: string;
  type: SidebarItemType;
  status: string;

  // TODO: payment only move to meta
  isRefund?: boolean;
  state: string;
  net: number;
  brut: number;

  operationType: SidebarItemOperationType;
  path: string;
  _url?: string;
  url?: string;
  createdAt: string;
  updatedAt: string;
  meta: any;
}

export interface SidebarItem {
  id: number;
  objectType: ObjectType;
  createdAt: string;
  updatedAt: string;
  name: string;
  visible: boolean;
}

export interface SidebarItemRFQ extends Pick<SidebarItem, 'id' | 'objectType' | 'name' | 'visible'> {
  state: string;
  publicationEnd: string;
}

export interface SidebarItemE extends SidebarItem {
  state: string;
  canceled: boolean;
  currency?: string;
  totalAmountNet?: number;
  totalAmountBrut?: number;
}

export interface SidebarItemJ extends SidebarItem {
  state: string;
  publicationEnd?: string;
  value: number;
  valueCurrency: string;
  providerOrder?: {
    id: number;
    number?: string;
  };
}

export interface SidebarItemOC extends SidebarItem {
  currency?: string;
  number?: string;
  state?: string;
  publishedAt?: string;
  totalAmountNet?: number;
  totalAmountBrut?: number;
}

export interface SidebarItemP extends SidebarItem {
  children?: SidebarItemP[];
  amountBrut?: number;
  amountNet?: number;
  currency?: string;
  isRefund: boolean;
  paidAt?: string;
  positions: SidebarItemPPosition[];
  sourceId?: number;
  sourceType?: string;
  state: string;
  transactionType?: TransactionTypes;
}

export interface SidebarItemPO extends SidebarItem {
  children?: SidebarItemPO[] | SidebarItemII[] | SidebarItemPZ[];
  conformityCheck?: string;
  deadline?: string;
  fullyPaidAt?: string;
  partsValueNetto?: number;
  servicesValueNetto?: number;
  state: string;
  currency?: string;
  samplesPoType?: ProviderOrdersSamplesProviderOrderTypeEnum;
  samplesConfirmationStatus?: ProviderOrdersSamplesConfirmationStatusEnum;
  samplesProviderOrder?: SidebarItemPO;
}

export interface SidebarItemDL extends SidebarItem {
  state: string;
  url: string;
  providerOrderId?: number;
  direction?: string;
}

export interface SidebarItemSH extends SidebarItem {
  state: string;
  receivedProcessingStatus: string;
  direction?: string;
  relatedProviderOrders: {
    id: number | string;
    number: string;
  }[];
}

export interface SidebarItemQC extends SidebarItem {
  id: number;
  state: string;
  kind: string;
  relatedProviderOrders?:
    | {
        id: number;
        number: string;
      }[]
    | null;
  positions?:
    | {
        id: number;
        techVerdict?: string | null;
        businessVerdict?: string | null;
      }[]
    | null;
}

export interface SidebarItemSU extends SidebarItem {
  storageType: string;
  processingState: string;
  quantityLeft: number;
  quantityInitial: number;
}

export interface SidebarItemCL extends SidebarItem {
  state: string;
}

export interface SidebarItemPZ extends SidebarItem {
  children?: SidebarItemPZ[];
  state: string;
  number: string;
  amount: number;
  amountMoney: Money;
  isRefund: boolean;
  sourceId: number | string;
  sourceType: ObjectType.PO | ObjectType.II | ObjectType.PZ;
  paidAt?: string;
}

export interface SidebarItemOI extends SidebarItem {
  children?: SidebarItemOI[];
  isStorno: boolean;
  invoiceNeedFix?: {
    by: string;
    at: string;
  };
  invoiceReady?: {
    by: string;
    at: string;
  };
  invoiceChecked: {
    by: string;
    at: string;
  };
  refInvoice?: {
    id: number;
    number: number;
    uiNumber: string;
  };
  currency?: string;
  creditNote: boolean;
  state: string;
  paidAt?: string;
  registeredAt?: string;
  totalAmountNetto?: number;
  totalAmountBrutto?: number;
}

export interface SidebarItemII extends SidebarItem {
  children?: SidebarItemPZ[];
  isPartial: boolean;
  providerOrderId?: number | string;
  refInvoice?: {
    id: number;
    number: number;
    selfBilling: boolean;
    creditNote?: boolean;
    state: IncomingInvoiceState;
  };
  validity?: 'partner_need_fix' | 'partner_ready' | 'manager_need_fix' | 'manager_ready' | 'finance_ready';
  creditNote: boolean;
  state: string;
  paidAt?: string;
  number: string;
  selfBilling: boolean;
  registeredAt?: string;
  totalAmountNetto?: number;
  totalAmountNettoMoney: Money;
  totalAmountBrutto?: number;
  totalAmountBruttoMoney: Money;
}

interface SidebarItemPPosition {
  totalNet?: number;
  totalBrut?: number;
}

export interface SidebarItemQ extends SidebarItem {
  url: string;
}

export type SidebarItemOperationType = 'canceled' | 'sales' | 'finance' | 'logistic' | 'operations';

export type SidebarItemType =
  | ObjectType.OC
  | ObjectType.E
  | ObjectType.Payment
  | ObjectType.PO
  | ObjectType.CL
  | ObjectType.DL
  | ObjectType.CreditNote
  | ObjectType.OI
  | ObjectType.II
  | ObjectType.Q
  | ObjectType.J
  | ObjectType.QC
  | ObjectType.SH
  | ObjectType.SU;

export enum SortOptions {
  TYPE_ASC = 'Type asc',
  TYPE_DESC = 'Type desc',
  CREATED_ASC = 'Created asc',
  CREATED_DESC = 'Created desc',
  UPDATED_ASC = 'Updated asc',
  UPDATED_DESC = 'Updated desc',
}

export enum FilterPreset {
  ALL = 'All',
  NONE = 'None',
  SALES = 'Sales',
  LOGISTIC = 'Logistic',
  FINANCE = 'Finance',
  PRODUCTION = 'Production',
}
