import { WorkspacesRfqOffer } from '__generated__/types';
import { stateMapping } from 'collections/rfqs';
import React from 'react';

export const rfqStateDecorator = (rfqOffer: WorkspacesRfqOffer) => {
  const state = rfqOffer.rfqState as keyof typeof stateMapping;

  return (
    <>
      {stateMapping[state] && state !== 'draft' ? (
        <>
          <b style={{ color: stateMapping[state].color }}>{stateMapping[state].label}</b>
        </>
      ) : (
        <>&mdash;</>
      )}
    </>
  );
};
