import { FilterPreset } from 'interfaces';
import { filterObjects, objectsByFilterPreset } from 'stores/Deal/DealSidebarStore/filterObjects';

export const getObjectsByPreset = (preset: FilterPreset, canceled: boolean) => {
  const result: Record<string, boolean> = {};
  filterObjects.forEach((option) => {
    result[option.objectType] = false;
  });

  result.canceled = canceled;

  switch (preset) {
    case FilterPreset.ALL:
      objectsByFilterPreset.All.forEach((objectType) => {
        result[objectType] = true;
      });
      break;
    case FilterPreset.NONE:
      break;
    case FilterPreset.FINANCE:
      objectsByFilterPreset.Finance.forEach((objectType) => {
        result[objectType] = true;
      });
      break;
    case FilterPreset.LOGISTIC:
      objectsByFilterPreset.Logistic.forEach((objectType) => {
        result[objectType] = true;
      });
      break;
    case FilterPreset.PRODUCTION:
      objectsByFilterPreset.Production.forEach((objectType) => {
        result[objectType] = true;
      });
      break;
    case FilterPreset.SALES:
      objectsByFilterPreset.Sales.forEach((objectType) => {
        result[objectType] = true;
      });
      break;
    default:
      break;
  }

  return result;
};

const comparePresetArrays = (presetArray: string[], objectsArray: string[]) => {
  return (
    presetArray.every((objectType) => objectsArray.includes(objectType)) && presetArray.length === objectsArray.length
  );
};

export const getFilterPresetByObjects = (objects: Record<string, boolean>) => {
  const objectsArray = Object.keys(objects).filter((objectType) => {
    if (objectType === 'canceled') {
      return false;
    }

    return objects[objectType];
  });

  if (objectsArray.length === 0) {
    return null;
  }

  if (objectsArray.length === filterObjects.length) {
    return FilterPreset.ALL;
  }

  if (comparePresetArrays(objectsByFilterPreset.Finance, objectsArray)) {
    return FilterPreset.FINANCE;
  }

  if (comparePresetArrays(objectsByFilterPreset.Logistic, objectsArray)) {
    return FilterPreset.LOGISTIC;
  }

  if (comparePresetArrays(objectsByFilterPreset.Production, objectsArray)) {
    return FilterPreset.PRODUCTION;
  }

  if (comparePresetArrays(objectsByFilterPreset.Sales, objectsArray)) {
    return FilterPreset.SALES;
  }

  return null;
};
