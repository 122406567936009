import { Money } from '__generated__/types';
import { PayoutState } from 'interfaces/graphql/payout';
import { ObjectType } from 'interfaces/shared';

import { IWorkspaceDealAttributes } from './shared/dealAttributes';

export enum PayoutTransferTypeCol {
  Wire = 'Wire',
  ACH = 'ach_sepa',
  ACHBuks = 'ach_bucs',
}

export type PayoutTransferType = PayoutTransferTypeCol.ACH | PayoutTransferTypeCol.Wire | PayoutTransferTypeCol.ACHBuks;

export interface IWorkspacePayout extends IWorkspaceDealAttributes {
  id: string;
  number: string;
  paidAt?: string;
  isRefund: boolean;
  state: PayoutState;
  amount: number;
  amountCents: number;
  amountMoney: Money;
  sourceType: ObjectType.PO | ObjectType.II | ObjectType.PZ;
  sourceId: string;
  datevComment?: string;
  providerId: string;
  approvedAt?: string;
  approvedBy?: {
    id: string;
    name: string;
  };
  paymentDetails?: string;
  providerOrderNumber?: string;
  approvedById: string;
  deletedAt?: string;
  fastPay: boolean;
  frameworkAgreement: boolean;
  isForSoftwareTesting?: boolean;
  instantPay: boolean;
  internalComment: string;
  invoiceNumber: string;
  methodId: string;
  methodType: string;
  prepayment: boolean;
  reference?: {
    id: string;
    number: string;
  };
  partnerLegalNameLower: string;
  partnerSegments: number[];
  provider?: {
    id: string;
    name: string;
    legalName: string;
    payoutTransferType: PayoutTransferType;
  };
  importantDeal?: boolean;
  createdAt: string;
  updatedAt: string;
}
