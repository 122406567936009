import { useMutation } from '@apollo/react-hooks';
import Notification from 'components/UI/Notification';
import { QueryNameKey } from 'components/Workspaces/collections';
import { map } from 'lodash-es';
import { useMemo, useState } from 'react';
import useAsyncJobStatus from 'utils/hooks/useAsyncJobStatus';

import { buildExportMutation, ExportMutationReponse } from './utils';

export interface ExportParams {
  filename: string;
  format: string;
  timezoneOffset: number;
  filterQuery: string;
  queryOptions?: Record<string, unknown>;
  columns: { key: string; title: string }[];

  sortParams: {
    sortBy: string;
    direction: string;
  }[];

  paginationParams: {
    page: number;
    pageSize: number;
  };

  selectedRecords?: (string | number)[];
}

export interface LazyExportProps {
  queryName: QueryNameKey;
  onCompleted: (data: string) => any;
}

export type LazyExportReturnType = [(params: ExportParams) => Promise<void>, boolean];

const useLazyExport = ({ queryName, onCompleted }: LazyExportProps) => {
  const [loading, setLoading] = useState(false);

  const { exportMutationName: exportQueryName, exportMutation: exportQuery } = useMemo(
    () => buildExportMutation(queryName),
    [queryName],
  );

  const [startExport] = useMutation<ExportMutationReponse>(exportQuery, {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/no-unsafe-return
    onCompleted: (data) => startExportPolling(data[exportQueryName].jobId),
  });

  const [startExportPolling] = useAsyncJobStatus({
    keys: ['file_url'],
    onCompleted: (status, { file_url: fileUrl }) => {
      setLoading(false);

      if (status === 'failed') {
        Notification.Error('Failed to export to CSV. Please try again later.');

        return;
      }

      onCompleted(fileUrl);
    },
  });

  const triggerExport = async (params: ExportParams) => {
    setLoading(true);

    const variables = {
      ...params,
      selectedRecords: map(params.selectedRecords, (r) => (typeof r === 'number' ? r : Number.parseInt(r, 10))),
    };

    await startExport({ variables });
  };

  return [triggerExport, loading] as LazyExportReturnType;
};

export default useLazyExport;
