import {
  AccountingExportBillingAccountsPage,
  AccountingExportBillingAccountsShowPage,
  AccountingExportIncomingsPage,
  AccountingExportIncomingsShowPage,
  AccountingExportOutcomingPage,
  AccountingExportOutcomingShowPage,
  AccountingExportProvidersPage,
  AccountingExportProvidersShowPage,
} from 'pages';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

const AccountingController: React.FC = () => (
  <Switch>
    <Route path={routes.accountingExportsProvidersPath()} component={AccountingExportProvidersPage} exact />
    <Route path={routes.accountingExportProvidersPath(':id')} component={AccountingExportProvidersShowPage} exact />
    <Route path={routes.accountingExportsBillingAccountsPath()} component={AccountingExportBillingAccountsPage} exact />
    <Route
      path={routes.accountingExportBillingAccountsPath(':id')}
      component={AccountingExportBillingAccountsShowPage}
      exact
    />
    <Route path={routes.accountingExportOutcomingsPath()} component={AccountingExportOutcomingPage} exact />
    <Route path={routes.accountingExportOutcomingPath(':id')} component={AccountingExportOutcomingShowPage} exact />
    <Route path={routes.accountingExportIncomingsPath()} component={AccountingExportIncomingsPage} exact />
    <Route path={routes.accountingExportIncomingPath(':id')} component={AccountingExportIncomingsShowPage} exact />
  </Switch>
);

export default AccountingController;
