import { UserLocStore } from 'interfaces';
import { action, observable } from 'mobx';
import { routesApi } from 'routes/api';
import omsApi from 'utils/axios';
import { redirectToRoot } from 'utils/graphql/requests';
import { clearAuthLocalStorage } from 'utils/unauthorizedUtils';

class UserStore {
  @observable isAuthenticated = localStorage.getItem(UserLocStore.isAuth) === 'true';

  @observable id = localStorage.getItem(UserLocStore.id);

  @observable name = localStorage.getItem(UserLocStore.name);

  @observable email = localStorage.getItem(UserLocStore.email);

  @action deauthenticate = () => {
    void omsApi.delete(routesApi.logoutAuthPath()).finally(() => this.webDeauthenticate());
  };

  @action onAuthSuccess = (name: string, email: string, payload: { access_token: string; id: string }) => {
    localStorage.setItem('token', payload.access_token);
    localStorage.setItem(UserLocStore.isAuth, 'true');
    localStorage.setItem(UserLocStore.name, name);
    localStorage.setItem(UserLocStore.id, payload.id);
    localStorage.setItem(UserLocStore.email, email);

    this.isAuthenticated = true;
    this.name = name;
    this.id = payload.id;
    this.email = email;

    this.redirect();
  };

  @action webDeauthenticate = () => {
    const { isAuthenticated, name, id, email } = clearAuthLocalStorage();

    this.isAuthenticated = isAuthenticated;
    this.name = name;
    this.id = id;
    this.email = email;
  };

  setReturnTo = () => {
    localStorage.setItem(UserLocStore.returnTo, window.location.href);
  };

  private redirect = () => {
    const returnTo = localStorage.getItem(UserLocStore.returnTo);

    if (!returnTo) {
      redirectToRoot();

      return;
    }

    localStorage.setItem(UserLocStore.returnTo, '');

    window.location.href = returnTo;
  };
}

export default UserStore;
