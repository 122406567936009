import { useQuery } from '@apollo/react-hooks';
import { Layout, Row } from 'antd';
import lookers from 'collections/lookers';
import PartnerHeader from 'components/Partner/Header';
import { RfqOffers } from 'components/Workspaces/General/RfqOffers';
import { IProvider } from 'interfaces/graphql';
import {
  IncomingInvoicesPage,
  JobOffersListPage,
  LookerPage,
  PartnerBalancePage,
  ProviderAvailabilityCalendarPage,
  ProviderContactsPage,
  ProviderEditPage,
  ProviderOrdersPage,
  ShippingsListPage,
} from 'pages';
import React, { FC } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { routes } from 'routes';
import { GET_PROVIDER } from 'utils/graphql/queries/providers';

import { Sidebar } from './Sidebar';
import SidebarRightProvider from './SidebarRightProvider';
import { ColCenterContent, ColLeftSidebar, ColRightSidebar } from './styled';

interface IProviderResponse {
  provider: IProvider;
}

interface Params {
  providerId: string;
}

const ProviderController: FC = () => {
  const { providerId } = useParams<Params>();

  const { loading, data } = useQuery<IProviderResponse>(GET_PROVIDER, {
    variables: { id: providerId },
  });

  if (loading || !data?.provider) {
    return <></>;
  }

  const { provider } = data;

  document.title = `PR-${provider.id} | ERP`;

  return (
    <Layout.Content style={{ height: '100vh' }}>
      <PartnerHeader provider={provider} />
      <Row style={{ height: '100%' }}>
        <ColLeftSidebar xs={24} sm={4} xxl={3}>
          <Sidebar provider={provider} />
        </ColLeftSidebar>

        <ColCenterContent xs={24} sm={17} xxl={18}>
          <div className="inner-content">
            <Switch>
              <Route
                path={`${routes.providerPath(':providerId')}/edit`}
                render={(props) => <ProviderEditPage {...props} provider={provider} />}
              />
              <Route
                path={`${routes.providerPath(':providerId')}/contacts`}
                render={(props) => <ProviderContactsPage {...props} provider={provider} />}
              />
              <Route
                path={`${routes.providerPath(':providerId')}/production_orders`}
                exact
                render={() => <ProviderOrdersPage initialFilter={`provider_id = '${providerId}'`} embed />}
              />
              <Route
                path={`${routes.providerPath(':providerId')}/invoices`}
                render={() => <IncomingInvoicesPage initialFilter={`partner_id = '${providerId}'`} embed />}
              />
              <Route
                path={`${routes.providerPath(':providerId')}/rating_by_parts`}
                render={(props) => (
                  <LookerPage
                    {...props}
                    url={lookers.partners.ratingByParts(providerId)}
                    title="ERP | Partners | Rating by parts"
                  />
                )}
              />
              <Route
                path={`${routes.providerPath(':providerId')}/shippings`}
                render={() => <ShippingsListPage initialFilter={`related_providers CONTAINS '${providerId}'`} embed />}
              />
              <Route
                path={`${routes.providerPath(':providerId')}/job_offers`}
                exact
                render={() => (
                  <JobOffersListPage
                    embed
                    initialFilter={`provider_id = '${providerId}' AND (response_state = 'application' OR response_state = 'counteroffer')`}
                  />
                )}
              />
              <Route
                path={`${routes.providerPath(':providerId')}/rfq_offers`}
                exact
                render={() => <RfqOffers initialFilter={`provider_id = '${providerId}'`} embed />}
              />
              <Route
                path={`${routes.providerPath(':providerId')}/availability_calendar`}
                exact
                render={(props) => <ProviderAvailabilityCalendarPage {...props} provider={provider} />}
              />

              <Route
                path={routes.providerBalancePath(':providerId')}
                render={() => (
                  <Switch>
                    <Route
                      path={`${routes.providerBalancePath(':providerId')}/total`}
                      exact
                      render={(props) => <PartnerBalancePage {...props} currentTab="total" provider={provider} />}
                    />
                    <Route
                      path={`${routes.providerBalancePath(':providerId')}/transactions`}
                      exact
                      render={(props) => (
                        <PartnerBalancePage {...props} currentTab="transactions" provider={provider} />
                      )}
                    />
                    <Route
                      path={`${routes.providerBalancePath(':providerId')}/statement`}
                      exact
                      render={(props) => <PartnerBalancePage {...props} currentTab="statement" provider={provider} />}
                    />
                    <Redirect to={`${routes.providerBalancePath(':providerId')}/total`} />
                  </Switch>
                )}
              />

              <Redirect to={`${routes.providerPath(':providerId')}/edit`} />
            </Switch>
          </div>
        </ColCenterContent>

        <ColRightSidebar xs={24} sm={3} xxl={3}>
          <SidebarRightProvider />
        </ColRightSidebar>
      </Row>
    </Layout.Content>
  );
};

export default ProviderController;
