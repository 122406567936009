import { Form, Spin, TreeSelect } from 'antd';
import { Tag } from 'interfaces';
import { groupBy, map, sortBy } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { Key, useEffect, useState } from 'react';
import { useStore } from 'stores/RootStore';

interface Props {
  name: string | string[];
  label: string;
  includeContexts: string[];
  onChange?: (value: any[]) => void;
}

const TagsSelector = observer<Props>(({ name, label, includeContexts, onChange }) => {
  const { tagStore } = useStore();
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  const {
    fetchStatus: { isFetching },
    tags,
  } = tagStore;

  const toggleExpandNode = (key: string | number) => {
    setExpandedKeys((prev) => {
      const outArr = [...prev];

      if (outArr.includes(key)) {
        return outArr.filter((e) => e !== key);
      }

      outArr.push(key);

      return outArr;
    });
  };

  const treeCollection = (allTags: Tag[]) => {
    const groupedTags = groupBy(allTags, 'contextName');
    const sortedGroups = sortBy(Object.keys(groupedTags));

    return map(sortedGroups, (contextName: string, index: number) => {
      const sortedGroupItems = sortBy(groupedTags[contextName], 'name');
      const groupCollection = map(sortedGroupItems, (tag) => ({
        title: tag.name,
        value: tag.id,
        key: tag.id,
      }));

      return {
        title: (
          <span tabIndex={0} onClick={() => toggleExpandNode(`group-${index}`)} role="button">
            {contextName}
          </span>
        ),
        key: `group-${index}`,
        value: `group-${index}`,
        selectable: false,
        children: groupCollection,
        expand: true,
      };
    });
  };

  useEffect(() => {
    tagStore.fetchTags(includeContexts);
  }, [tagStore, includeContexts]);

  if (isFetching) {
    return <Spin size="small" />;
  }

  return (
    <Form.Item label={label} name={name}>
      <TreeSelect
        treeData={treeCollection(tags)}
        treeNodeFilterProp="title"
        onChange={onChange}
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        placeholder="Please select"
        onTreeExpand={setExpandedKeys}
        treeExpandedKeys={expandedKeys}
        multiple
      />
    </Form.Item>
  );
});

export default TagsSelector;
