/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Modal } from 'antd';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { accessErrorNotification } from 'components/shared/AccessError/accessErrorNotification';
import { Maintenance } from 'components/shared/Maintenance';
import { Notification } from 'components/UI';
import { ENV_API_ENDPOINT } from 'constants/env';
import humps from 'humps';
import { get, isEmpty, isObject } from 'lodash-es';
import qs from 'qs';
import { handleUnauthorizedError } from 'utils/unauthorizedUtils';

interface ErrorsResponse {
  source: Record<string, string[]> | string;
  status: number;
  title: string;
}

const apiEndpoint = ENV_API_ENDPOINT;

// TODO: Correct typescript utilities
export const decamelizeKeys = <O = any, T = O>(obj: O): T =>
  humps.decamelizeKeys(obj as unknown as object, (key, convert, options) =>
    /^[A-Z0-9_]+$/.test(key) ? key : convert(key, options),
  ) as unknown as T;

// TODO: Correct typescript utilities
export const camelizeKeys = <O = any, T = O>(obj: O): T =>
  humps.camelizeKeys(obj as unknown as object, (key, convert) =>
    /^[A-Z0-9_]+$/.test(key) ? key : convert(key),
  ) as unknown as T;

const buildFormData = (formData: FormData, data: object, parentKey?: string) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      // @ts-ignore
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    // @ts-ignore
    if (parentKey) formData.append(parentKey, value);
  }
};

export const jsonToFormData = (data: any): FormData => {
  const formData = new FormData();
  buildFormData(formData, data);

  return formData;
};

const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    console.error('Failed to get a token from the local storage');

    return '';
  }

  return token;
};

const omsApi = axios.create({
  baseURL: apiEndpoint,
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${getTokenFromLocalStorage()}`,
  },
});

export const requestBuilder = (params: Partial<AxiosRequestConfig>): AxiosInstance =>
  axios.create({
    baseURL: apiEndpoint,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
    ...params,
  });

const errorResponse = (error: any) => {
  const status = get(error, 'response.status') as number;
  const dataError = get(error, 'response.data.error') as string;
  const dataErrors = get(error, 'response.data.errors') as ErrorsResponse;
  const source = get(dataErrors, 'source');
  const errorsKeys = isObject(source) ? Object.keys(source) : [];
  const errorMessage = get(error, 'message') as string | undefined;

  switch (status) {
    case 401:
      handleUnauthorizedError();

      return true;
    case 422:
      if (typeof dataErrors.source === 'string') {
        Notification.Error(dataErrors.source);
      }

      if (errorsKeys.length > 0) {
        errorsKeys.forEach((key) => {
          if (typeof dataErrors.source !== 'string') {
            const errorMessages = Array.isArray(dataErrors.source[key])
              ? dataErrors.source[key][0]
              : dataErrors.source[key];

            if (typeof errorMessages === 'string' || Array.isArray(errorMessages)) Notification.Error(errorMessages);
          }
        });
      }

      return Promise.reject(error);
    case 403:
      accessErrorNotification(dataError);

      return Promise.reject(error);
    case 500:
      Notification.Error('Sorry, something went wrong.', errorMessage);

      return Promise.reject(error);
    case 503:
      Modal.info({
        title: 'Attention',
        content: Maintenance,
        width: 800,
      });

      return Promise.reject(error);
    default:
      return Promise.reject(error);
  }
};

const successResponse = (response: AxiosResponse) => response;

omsApi.interceptors.response.use(successResponse, errorResponse);

export const handleErrorMessage = (err: any): string => {
  const { message, source, title } = get(err, 'response.data.errors') || {};
  let errors = {};

  if (source && typeof source !== 'string') {
    // eslint-disable-next-line no-useless-escape
    errors = JSON.stringify(source).replace(/[[\[\]]|[\{\}]|['"]+/gi, '');
  }

  if (isEmpty(errors) && typeof err === 'string') {
    errors = err;
  }

  if (isEmpty(errors) && (message || title)) {
    errors = message || title;
  }

  if (isEmpty(errors)) {
    errors = 'Sorry, something went wrong.';
  }

  return String(errors);
};

export const omsGet = async (url: string, query: any) =>
  omsApi.get(url, {
    params: query,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
  });

export const omsPost = async (url: string, params: any) => omsApi.post(url, params);

export default omsApi;
