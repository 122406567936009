export const ENV_CUSTOM_ENV = String(
  import.meta.env.REACT_APP_CUSTOM_NODE_ENV || process.env['REACT_APP_CUSTOM_NODE_ENV'] || 'development',
);
export const ENV_DEDICATED_STAGING = String(
  import.meta.env.REACT_APP_DEDICATED_STAGING || process.env['REACT_APP_DEDICATED_STAGING'] || 'false',
);

export const SENTRY_DSN = String(import.meta.env.REACT_APP_SENTRY_DSN || process.env['REACT_APP_SENTRY_DSN'] || '');
export const RELEASE = String(
  import.meta.env.REACT_APP_SENTRY_RELEASE || process.env['REACT_APP_SENTRY_RELEASE'] || 'development',
);

export const ENV_API_ENDPOINT = String(
  import.meta.env.REACT_APP_API_ENDPOINT || process.env['REACT_APP_API_ENDPOINT'] || '',
);
export const ENV_WS_ENDPOINT = String(
  import.meta.env.REACT_APP_WS_ENDPOINT || process.env['REACT_APP_WS_ENDPOINT'] || '',
);
export const ENV_MESSENGER_URL = String(
  import.meta.env.REACT_APP_MSG_ENDPOINT || process.env['REACT_APP_MSG_ENDPOINT'] || '',
);
export const ENV_APP_REGION = String(
  import.meta.env.REACT_APP_APP_REGION || process.env['REACT_APP_APP_REGION'] || 'EU',
);
export const ENV_SUPPORTED_LOCALES = String(
  import.meta.env.REACT_APP_SUPPORTED_LOCALES || process.env['REACT_APP_SUPPORTED_LOCALES'] || 'en',
);
export const ENV_BASE_URL = String(import.meta.env.REACT_APP_BASE_URL || process.env['REACT_APP_BASE_URL'] || '');

export const ENV_APP_HOST_EU = String(
  import.meta.env.REACT_APP_APP_HOST_EU || process.env['REACT_APP_APP_HOST_EU'] || '',
);
export const ENV_APP_HOST_UK = String(
  import.meta.env.REACT_APP_APP_HOST_UK || process.env['REACT_APP_APP_HOST_UK'] || '',
);
export const ENV_APP_HOST_TR = String(
  import.meta.env.REACT_APP_APP_HOST_TR || process.env['REACT_APP_APP_HOST_TR'] || '',
);

export const ENV_CUSTOMERS_APP_HOST_EU = String(
  import.meta.env.REACT_APP_CUSTOMERS_APP_HOST_EU || process.env['REACT_APP_CUSTOMERS_APP_HOST_EU'] || '',
);
export const ENV_CUSTOMERS_APP_HOST_UK = String(
  import.meta.env.REACT_APP_CUSTOMERS_APP_HOST_UK || process.env['REACT_APP_CUSTOMERS_APP_HOST_UK'] || '',
);
export const ENV_CUSTOMERS_APP_HOST_TR = String(
  import.meta.env.REACT_APP_CUSTOMERS_APP_HOST_TR || process.env['REACT_APP_CUSTOMERS_APP_HOST_TR'] || '',
);

export const ENV_PARTNERS_APP_HOST_EU = String(
  import.meta.env.REACT_APP_PARTNERS_APP_HOST_EU || process.env['REACT_APP_PARTNERS_APP_HOST_EU'] || '',
);
export const ENV_PARTNERS_APP_HOST_UK = String(
  import.meta.env.REACT_APP_PARTNERS_APP_HOST_UK || process.env['REACT_APP_PARTNERS_APP_HOST_UK'] || '',
);
export const ENV_PARTNERS_APP_HOST_TR = String(
  import.meta.env.REACT_APP_PARTNERS_APP_HOST_TR || process.env['REACT_APP_PARTNERS_APP_HOST_TR'] || '',
);
