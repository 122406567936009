import { Money as MoneyType } from '__generated__/types';
import { getMoneyFabric, IMoneyProps } from '@xometry/ui/dist/components/common/Money';
import {
  getCurrencySymbolByCode,
  getFormattedMoneyFabric,
  getXMoneyFabric,
  IXMoney,
} from '@xometry/ui/dist/utils/money';
import { ReactComponent as IconEU } from 'assets/images/icon-eu.svg';
import { ReactComponent as IconEUR } from 'assets/images/icon-EUR.svg';
import { ReactComponent as IconGBP } from 'assets/images/icon-GBP.svg';
import { ReactComponent as IconTR } from 'assets/images/icon-tr.svg';
import { ReactComponent as IconTRY } from 'assets/images/icon-TRY.svg';
import { ReactComponent as IconUK } from 'assets/images/icon-uk.svg';
import { ReactComponent as IconUS } from 'assets/images/icon-us.svg';
import { ReactComponent as IconUSD } from 'assets/images/icon-USD.svg';
import { getRegionCurrency, getRegionCurrencySymbol } from 'config/regions/utils';
import { forwardRef, FunctionComponent, SVGProps } from 'react';

const CurrencyToIcon: Record<string, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  TRY: IconTRY,
  GBP: IconGBP,
  EUR: IconEUR,
  USD: IconUSD,
};

const CurrencyFlagIconByCurrency: Record<string, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  TRY: IconTR,
  GBP: IconUK,
  EUR: IconEU,
  USD: IconUS,
};

export const getCurrencySymbol = (code?: string | null) => {
  const FALLBACK = getRegionCurrencySymbol();

  return getCurrencySymbolByCode(code, FALLBACK);
};

/**
 * Component for money values.
 * Outputs it according to currency rules.
 */
const MoneyComponent = getMoneyFabric({ fallbackCurrencyCode: getRegionCurrency() });
export const Money = MoneyComponent as ReturnType<typeof forwardRef<HTMLSpanElement, IMoneyProps>>;

/**
 * Class for math operations with money.
 */
export const XMoney = getXMoneyFabric({ fallbackCurrencyCode: getRegionCurrency() });
/**
 * Plan utility to format money values, according to currency rules.
 * Note: use it only when Money component isn't suitable.
 */
export const formatMoney = getFormattedMoneyFabric({ fallbackCurrencyCode: getRegionCurrency() });

export const getCurrencyIcon = (currencyCode: string): FunctionComponent<SVGProps<SVGSVGElement>> | undefined =>
  CurrencyToIcon[currencyCode];

export const getCurrencyFlagIconByCurrency = (
  currencyCode: string,
): FunctionComponent<SVGProps<SVGSVGElement>> | undefined => CurrencyFlagIconByCurrency[currencyCode];

export const toIMoney = (value: IXMoney | null | undefined) => {
  if (!value) {
    return null;
  }

  return value.toIMoney() as MoneyType;
};
