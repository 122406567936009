import { FilterPreset, ObjectType } from 'interfaces';

export const filterObjects = [
  {
    label: 'Quotation',
    objectType: ObjectType.Q,
  },
  {
    label: 'Enquiry',
    objectType: ObjectType.E,
  },
  {
    label: 'Order Confirmation',
    objectType: ObjectType.OC,
  },
  {
    label: 'Payments & Ref.',
    objectType: ObjectType.Payment,
  },
  {
    label: 'Jobs',
    objectType: ObjectType.J,
  },
  {
    label: 'Job Offers',
    objectType: ObjectType.JO,
  },
  {
    label: 'Delivery',
    objectType: ObjectType.DL,
  },
  {
    label: 'Shippings',
    objectType: ObjectType.SH,
  },
  {
    label: 'Quality Controls',
    objectType: ObjectType.QC,
  },
  {
    label: 'Storage/Utilization',
    objectType: ObjectType.SU,
  },
  {
    label: 'Out. Invoices & CN',
    objectType: ObjectType.OI,
  },
  {
    label: 'Production Orders',
    objectType: ObjectType.PO,
  },
  {
    label: 'Inc. Invoices & CN ',
    objectType: ObjectType.II,
    sub: true,
  },
  {
    label: 'Payouts & Ref.',
    objectType: ObjectType.PZ,
    sub: true,
  },
  {
    label: 'Claims',
    objectType: ObjectType.CL,
  },
  {
    label: 'RFQ',
    objectType: ObjectType.RFQ,
  },
];

export const objectsByFilterPreset = {
  [FilterPreset.FINANCE]: [
    ObjectType.OC,
    ObjectType.Payment,
    ObjectType.OI,
    ObjectType.PO,
    ObjectType.II,
    ObjectType.CL,
    ObjectType.PZ,
  ],
  [FilterPreset.LOGISTIC]: [ObjectType.SH, ObjectType.DL, ObjectType.SU, ObjectType.QC],
  [FilterPreset.PRODUCTION]: [
    ObjectType.OC,
    ObjectType.PO,
    ObjectType.SH,
    ObjectType.QC,
    ObjectType.SU,
    ObjectType.DL,
    ObjectType.II,
    ObjectType.CL,
  ],
  [FilterPreset.SALES]: [
    ObjectType.E,
    ObjectType.Q,
    ObjectType.OC,
    ObjectType.Payment,
    ObjectType.PZ,
    ObjectType.PO,
    ObjectType.OI,
    ObjectType.CL,
  ],
  [FilterPreset.ALL]: filterObjects.map(({ objectType }) => objectType),
  [FilterPreset.NONE]: [],
};

export const filterPresetList = [
  FilterPreset.ALL,
  FilterPreset.SALES,
  FilterPreset.LOGISTIC,
  FilterPreset.FINANCE,
  FilterPreset.PRODUCTION,
];
