import { PlusOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Button, Input, Space, Tooltip } from 'antd';
import { colors } from 'components/UI/colors';
import { FilterSettings } from 'components/Workspaces/collections';
import {
  Column,
  PermissionsSettings,
  WorkspaceGraphQLField,
  WorkspaceGraphQLObjectField,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { FilterItem } from 'components/Workspaces/General/shared/GeneralWorkspace/FiltersStore';
import { useFiltersEditPermission } from 'components/Workspaces/General/shared/GeneralWorkspace/useFiltersEditPermission';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { routes } from 'routes';

import FilterNode from './FilterNode';
import s from './Filters.module.less';
import { AttributesFiltersContainer } from './styled';

interface Props {
  attributes: WorkspaceGraphQLField[];
  columns: Column[];
  customFilters?: FilterSettings[];
  filters: FilterItem;
  hideUndefinedColumns?: boolean;
  objectAttributes: WorkspaceGraphQLObjectField[];
  permissionsSettings: PermissionsSettings;
}

const Filters = observer<Props>(
  ({ attributes, hideUndefinedColumns, columns, customFilters, filters, objectAttributes, permissionsSettings }) => {
    const [customQuery, setCustomQuery] = useState('');
    const [inlineEdit, setInlineEdit] = useState(false);

    const { isFiltersChangingAllowed } = useFiltersEditPermission(permissionsSettings);

    const permissionGroup = permissionsSettings.group;

    const isEditingAllowed = permissionGroup ? isFiltersChangingAllowed : !inlineEdit;

    const handleCustomQueryChange = (query: string) => {
      setCustomQuery(query);
    };

    const handleEditInlineClick = () => {
      setCustomQuery(filters.toQuery);
      setInlineEdit(true);
    };

    const handleSubmitInlineQueryClick = () => {
      setInlineEdit(false);
      const parsed = FilterItem.fromQuery(customQuery) ?? new FilterItem({ root: true });
      filters.assignRoot(parsed);
    };

    return (
      <AttributesFiltersContainer>
        <div className={s.filterWrapper}>
          <FilterNode
            attributes={attributes}
            objectAttributes={objectAttributes}
            columns={columns}
            customFilters={customFilters}
            hideUndefinedColumns={hideUndefinedColumns}
            node={filters}
            readonly={!isEditingAllowed}
            remove={undefined}
          />
        </div>

        <div className={s.addCondition}>
          {filters.lastOperator !== 'AND' && (
            <Button
              disabled={!isEditingAllowed}
              icon={<PlusOutlined />}
              size="small"
              type="link"
              onClick={() => filters.addChild('AND')}
            >
              Add AND condition
            </Button>
          )}

          {filters.value.kind === 'group' && filters.lastOperator !== 'OR' && (
            <Button
              disabled={!isEditingAllowed}
              icon={<PlusOutlined />}
              onClick={() => filters.addChild('OR')}
              size="small"
              style={{ marginLeft: '10px' }}
              type="link"
            >
              Add OR condition
            </Button>
          )}
        </div>

        <div>
          {inlineEdit ? (
            <Input
              style={{
                color: 'black',
                flexGrow: 1,
                fontFamily: 'Monospace',
              }}
              value={customQuery}
              onChange={(e) => handleCustomQueryChange(e.target.value)}
            />
          ) : (
            <Input
              disabled
              style={{
                color: 'black',
                flexGrow: 1,
                fontFamily: 'Monospace',
              }}
              value={filters.toQuery}
            />
          )}
        </div>

        <Space>
          {inlineEdit ? (
            <Button onClick={handleSubmitInlineQueryClick} size="small" type="link" className="p-none">
              Save query
            </Button>
          ) : (
            <Button
              disabled={!isEditingAllowed}
              onClick={handleEditInlineClick}
              size="small"
              type="link"
              className="p-none"
            >
              Edit query inline
            </Button>
          )}
          <Tooltip title="Query language help">
            <a href={routes.external.workspacesQuerySpec()} target="blank" rel="noreferrer noopener">
              <QuestionCircleFilled style={{ color: colors.gray4 }} />
            </a>
          </Tooltip>
        </Space>
      </AttributesFiltersContainer>
    );
  },
);

export default Filters;
