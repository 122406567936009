import { WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import { compact } from 'lodash-es';
import React, { FC, useEffect } from 'react';

export interface WithPageTitleProps {
  header: string;
  pageTitle?: string;
  preset: WorkspacePreset;
}

function withPageTitle<T extends WithPageTitleProps>(Component: React.ComponentType<T>) {
  const NewComponent: FC<T & WithPageTitleProps> = ({ header, pageTitle, preset, ...restProps }) => {
    useEffect(() => {
      if (pageTitle != null) {
        window.document.title = pageTitle;

        return;
      }

      const blocks = compact([header, preset.name, 'Workspace']);
      window.document.title = blocks.join(' | ');
    }, [header, pageTitle, preset.name]);

    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(restProps as any)}
        header={header}
        preset={preset}
      />
    );
  };

  return NewComponent;
}

export default withPageTitle;
