import {
  ColIndexMap,
  Column,
  ColumnHeaderDecorator,
  IDataItem,
  ISortParam,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { map } from 'lodash-es';
import React, { FC, RefObject, useState } from 'react';

import { TableContainer } from './styled';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

interface BaseProps {
  columnHeaderHeight?: number;
  columns: Column[];
  dataSource: IDataItem[];
  expandRows?: boolean;
  hideRowNumbers?: boolean;
  relative?: boolean;
  rowExpansion?: React.ReactNode;
  sortedColumns: ISortParam[];
  tableRef: RefObject<HTMLDivElement>;
  disabledRows?: string[];
  onColumnHeader?: ColumnHeaderDecorator;
  onColumnSort: (sortBy: string) => any;
  onCurrentRowChange?: (record: IDataItem) => void;
}

type PropsWithoutCheckboxes = BaseProps & {
  showCheckboxes?: false;
  selectedRows?: undefined;
  onRowSelect?: undefined;
  onRowDeselect?: undefined;
  onRowSelectAll?: undefined;
  onRowDeselectAll?: undefined;
};

type PropsWithCheckboxes = BaseProps & {
  showCheckboxes: true;
  selectedRows: IDataItem[];
  onRowSelect: (record: IDataItem) => void;
  onRowDeselect: (record: IDataItem) => void;
  onRowSelectAll: (records: IDataItem[]) => void;
  onRowDeselectAll: () => void;
};

type Props = PropsWithoutCheckboxes | PropsWithCheckboxes;

const getColumnsWidth = (columns: Column[]) => {
  const columnsWidth: Record<string, number> = {};

  columns.forEach((c) => {
    if (c.settings?.width !== undefined) {
      columnsWidth[c.key] = c.settings?.width;
    }
  });

  return columnsWidth;
};

const RecordsTable: FC<Props> = ({
  columnHeaderHeight,
  columns,
  dataSource,
  expandRows,
  hideRowNumbers,
  relative,
  rowExpansion,
  sortedColumns,
  showCheckboxes,
  selectedRows,
  tableRef,
  disabledRows,
  onColumnHeader,
  onColumnSort,
  onCurrentRowChange,
  onRowSelect,
  onRowDeselect,
  onRowSelectAll,
  onRowDeselectAll,
}) => {
  const [columnsWidth, setColumnsWidth] = useState<Record<string, number>>(getColumnsWidth(columns));

  const handleColumnResize = (columnKey: string, diff: number) => {
    setColumnsWidth((prevColumnWidth) => {
      const original = prevColumnWidth[columnKey] || 0;
      let width = original + diff;

      if (width <= 80) width = 80;

      return {
        ...prevColumnWidth,
        [columnKey]: width,
      };
    });
  };

  const handleColumnWidthUpdate = (columnKey: string, value: number) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnKey]: value,
    });
  };

  let gridColumns = map(columns, (c) => (columnsWidth[c.key] ? `${columnsWidth[c.key]}px` : 'auto')).join(' ');

  const colIndexMap: ColIndexMap = {
    rowNumber: 0,
    checkbox: 0,
    expandButton: 0,
  };

  if (expandRows) {
    gridColumns = `30px ${gridColumns}`;
    colIndexMap.expandButton += 1;
  }

  if (showCheckboxes) {
    gridColumns = `35px ${gridColumns}`;
    colIndexMap.expandButton += 1;
    colIndexMap.rowNumber += 1;
  }

  if (!hideRowNumbers) {
    gridColumns = `30px ${gridColumns}`;
    colIndexMap.expandButton += 1;
    colIndexMap.checkbox += 1;
    colIndexMap.rowNumber += 1;
  }

  let colIndexOffset = hideRowNumbers ? 0 : 1;

  if (showCheckboxes) colIndexOffset += 1;

  if (expandRows) colIndexOffset += 1;

  return (
    <TableContainer columnHeaderHeight={columnHeaderHeight} relative={relative}>
      <div className="table" ref={tableRef} style={{ gridTemplateColumns: gridColumns }}>
        <TableHeader
          colIndexMap={colIndexMap}
          colIndexOffset={colIndexOffset}
          columns={columns}
          columnsWidth={columnsWidth}
          dataSource={dataSource}
          expandRows={expandRows}
          hideRowNumbers={hideRowNumbers}
          selectedRows={selectedRows}
          showCheckboxes={showCheckboxes ?? false}
          sortedColumns={sortedColumns}
          onColumnHeader={onColumnHeader}
          onColumnResize={handleColumnResize}
          onColumnSort={onColumnSort}
          onColumnWidthUpdate={handleColumnWidthUpdate}
          onRowDeselectAll={onRowDeselectAll}
          onRowSelectAll={onRowSelectAll}
        />

        <TableBody
          colIndexMap={colIndexMap}
          colIndexOffset={colIndexOffset}
          columns={columns}
          dataSource={dataSource}
          expandRows={expandRows}
          hideRowNumbers={hideRowNumbers}
          rowExpansion={rowExpansion}
          showCheckboxes={showCheckboxes}
          selectedRows={selectedRows}
          tableRef={tableRef}
          onCurrentRowChange={onCurrentRowChange}
          onRowSelect={onRowSelect}
          onRowDeselect={onRowDeselect}
          disabledRows={disabledRows}
        />
      </div>
    </TableContainer>
  );
};

export default RecordsTable;
