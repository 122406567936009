import * as PAGES from 'pages';

export const preloadRoutes = () => {
  const keys = Object.keys(PAGES) as Array<keyof typeof PAGES>;

  for (const key of keys) {
    const page = PAGES[key];

    if ('preload' in page) {
      page.preload();
    }
  }
};
