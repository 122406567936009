import { Button, Checkbox, Form, Input, Tooltip } from 'antd';
import { Column } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { useAllowedToUpdatePresets } from 'components/Workspaces/General/shared/GeneralWorkspace/useAllowedToUpdatePresets';
import React from 'react';
import { required } from 'validators/rules';

import { IPresetParams } from './interfaces';
import s from './NewPresetForm.module.less';

interface Props {
  currentColumns: Column[];
  onPresetCreate: (name: string, isPublic: boolean, columns: Column[]) => void;
}

const NewPresetForm: React.FC<Props> = ({ currentColumns, onPresetCreate }) => {
  const [form] = Form.useForm<IPresetParams>();

  const handleFormSubmit = (values: IPresetParams) => onPresetCreate(values.name, values.public, currentColumns);

  const isAllowedToUpdatePresets = useAllowedToUpdatePresets();

  return (
    <div className={s.root}>
      <Form
        className={s.presetRow}
        form={form}
        initialValues={{ public: false }}
        layout="horizontal"
        onFinish={handleFormSubmit}
      >
        <Form.Item name="name" label="Preset name:" rules={[required(true)]}>
          <Input />
        </Form.Item>

        <Tooltip
          title={
            isAllowedToUpdatePresets
              ? undefined
              : "You don't have permissions to create public presets in this workspace"
          }
        >
          <Form.Item name="public" valuePropName="checked">
            <Checkbox disabled={!isAllowedToUpdatePresets}>Public</Checkbox>
          </Form.Item>
        </Tooltip>

        <Button htmlType="submit" type="primary" style={{ minWidth: 120 }}>
          Save
        </Button>
      </Form>
    </div>
  );
};

export default NewPresetForm;
