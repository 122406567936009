import { ENV_SUPPORTED_LOCALES } from './env';

export enum LocaleEnum {
  EN = 'en',
}

export const FALLBACK_LOCALE = LocaleEnum.EN;
export const DEFAULT_LOCALE = LocaleEnum.EN;

export const SUPPORTED_LOCALES = ENV_SUPPORTED_LOCALES
  ? (ENV_SUPPORTED_LOCALES.split(';') as LocaleEnum[])
  : [LocaleEnum.EN];

export const languagesOptions = [{ value: LocaleEnum.EN, label: LocaleEnum.EN.toUpperCase() }];
