import { SortDirectionEnum } from '__generated__/types';
import { ErpPermissionGroup, RfqOffersActions } from 'collections/authorization';
import { PageLoader } from 'components/UI';
import { QueryName } from 'components/Workspaces/collections';
import GeneralWorkspace from 'components/Workspaces/General/shared/GeneralWorkspace';
import { PermissionsSettings } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import React, { FC } from 'react';

import { useColumns } from './useColumns';

const permissionsSettings: PermissionsSettings = {
  view: RfqOffersActions.RfqOffersListView,
  filter: RfqOffersActions.RfqOffersListFilter,
  group: ErpPermissionGroup.RfqOffers,
};

interface RfqOffersProps {
  initialFilter: string;
  embed?: boolean;
}

/** @deprecated import the page from 'src/pages' */
export const RfqOffers: FC<RfqOffersProps> = ({ initialFilter, embed }) => {
  const { loading, columns } = useColumns();

  if (loading) {
    return <PageLoader title="Loading..." isVisible />;
  }

  return (
    <GeneralWorkspace
      header="Local RFQ Offers"
      selectiveFieldsEnabled
      queryName={QueryName.RfqOffers}
      columns={columns}
      initialFilter={initialFilter}
      embed={embed}
      sortParams={[
        {
          sortBy: 'response_state_sorted',
          direction: SortDirectionEnum.Asc,
        },
      ]}
      permissionsSettings={permissionsSettings}
    />
  );
};
