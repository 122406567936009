import { useCallback, useMemo } from 'react';
import { UserFragmentFragment } from 'utils/graphql/fragments/__generated__/userFragment';
import { useGetUsersQuery } from 'utils/graphql/queries/__generated__/users';

export const useUsers = () => {
  const { data } = useGetUsersQuery();

  const realUsers = useMemo(() => {
    const users: Record<string, UserFragmentFragment> = {};

    data?.users?.forEach((user) => {
      const { id, isRobot } = user;

      if (!isRobot) {
        users[id] = user;
      }
    });

    return users;
  }, [data?.users]);

  const getUserById = useCallback(
    (id: string): UserFragmentFragment | undefined => {
      return realUsers[id];
    },
    [realUsers],
  );

  return { realUsers, getUserById };
};
