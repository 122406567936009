import { FormInstance } from 'antd';
import { RootStore } from 'stores/RootStore';

class ClaimStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  form: FormInstance | undefined;
}

export default ClaimStore;
