import { Payment } from 'interfaces';
import { routesApi } from 'routes/api';
import api, { camelizeKeys, decamelizeKeys } from 'utils/axios';

interface CompleteBankRefundResponse {
  payload: Payment;
}

export const completeBankRefund = async (paymentId: number, params: Payment) => {
  const response = await api.post<CompleteBankRefundResponse>(
    routesApi.paymentBankRefundPath(paymentId),
    decamelizeKeys(params),
  );
  const payment = camelizeKeys(response.data.payload);

  return payment;
};
