import { GlobalOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import Mailer from 'components/Mailer';
import { UpdatePnmModal } from 'components/Modals/UpdatePnmModal';
import { currency, shortName } from 'components/utils/formatters';
import { IProvider } from 'interfaces/graphql';
import { find } from 'lodash-es';
import React, { FC, useState } from 'react';
import { routesApi } from 'routes/api';
import useRedirect from 'utils/hooks/useRedirect';

import { InfoButton, InfoItem, InfoLine, InfoTag } from './styled';

interface IProps {
  provider: IProvider;
}

const Info: FC<IProps> = ({ provider }) => {
  const redirect = useRedirect();
  const [updatePnmModalVisible, setUpdatePnmModalVisible] = useState<boolean>(false);

  const [mailerVisible, setMailerVisible] = useState<boolean>(false);

  const primaryContact = find(provider.contacts, {
    id: provider.primaryContactId,
  });

  const impersonate = () => {
    if (!primaryContact) {
      return;
    }

    void redirect(routesApi.providerContactImpersonatePath(provider.id, primaryContact.id));
  };

  return (
    <>
      <InfoLine>
        <InfoItem>
          Company:&nbsp;
          <b>{provider.legalName}</b>
          &nbsp;
          {provider.website && (
            <a
              href={provider.website.indexOf('//') ? provider.website : `//${provider.website}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GlobalOutlined />
            </a>
          )}
        </InfoItem>
      </InfoLine>
      <InfoLine>
        <InfoItem>
          Contact:&nbsp;
          <b>
            {shortName(
              (primaryContact ? `${primaryContact.firstName} ${primaryContact.lastName}` : provider.name) || '',
              24,
            )}
          </b>
        </InfoItem>
        {(primaryContact?.phone || provider.phone) && (
          <InfoItem>
            <PhoneOutlined />
            &nbsp;
            {primaryContact?.phone || provider.phone}
          </InfoItem>
        )}
        <InfoItem>
          <MailOutlined />
          &nbsp;
          <InfoButton type="link" htmlType="button" onClick={() => setMailerVisible(true)}>
            Send Email
          </InfoButton>
        </InfoItem>
        {primaryContact && (
          <InfoItem>
            <InfoButton onClick={impersonate} type="link">
              Emulate PA
            </InfoButton>
            &nbsp; &nbsp;
            {provider.locale && <InfoTag>{provider.locale}</InfoTag>}
          </InfoItem>
        )}
      </InfoLine>
      <Row className="totals-info">
        <Col className="totals-info-col">
          PO total:&nbsp;
          <b>
            {provider.performance?.totalOrdersCount}
            &nbsp;/&nbsp;
            {currency(provider.performance?.totalOrdersValue)}
          </b>
        </Col>
        <Col className="totals-info-col">
          In Production:&nbsp;
          <b>
            {provider.performance?.ordersInProductionCount}
            &nbsp;/&nbsp;
            {currency(provider.performance?.ordersInProductionValue)}
          </b>
          <br />
          Delay POs:&nbsp;
          <b>{provider.rank?.delayedProviderOrdersQuantity}</b>
        </Col>
        <Col>
          To be Paid:&nbsp;
          <b>
            {provider.performance?.ordersBePaidCount}
            &nbsp;/&nbsp;
            {currency(provider.performance?.ordersBePaidValue)}
          </b>
          <br />
          Paid, Total:&nbsp;
          <b>
            {provider.performance?.ordersPaidCount}
            &nbsp;/&nbsp;
            {currency(provider.performance?.ordersPaidValue)}
          </b>
        </Col>
      </Row>
      <div className="m-t-xs">
        <span>PNM:&nbsp;</span>
        <Button type="link" onClick={() => setUpdatePnmModalVisible(true)} className="p-none" size="small">
          {provider.partnerNetworkManager?.name || 'No PNM'}
        </Button>
      </div>
      <Mailer
        title="General mailer"
        visible={mailerVisible}
        requests={{
          fetch: routesApi.providerEmailTemplates(String(provider.id)),
          submit: routesApi.providerSendEmail(String(provider.id)),
          directSubmit: routesApi.providerSendDirectEmail(String(provider.id)),
          uploadFile: routesApi.entityAttachmentsUploadPath('providers', provider.id),
        }}
        onCancel={() => setMailerVisible(false)}
      />
      <UpdatePnmModal
        provider={provider}
        visible={updatePnmModalVisible}
        onCancel={() => setUpdatePnmModalVisible(false)}
      />
    </>
  );
};

export default Info;
