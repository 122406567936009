import { IncomingInvoiceState } from 'interfaces/stores/IncomingInvoiceStore';

import { IProviderOrder } from './providerOrder';

export enum IncomingInvoiceStateEnum {
  /** canceled */
  Canceled = 'canceled',
  /** draft */
  Draft = 'draft',
  /** published */
  Published = 'published',
  /** rejected */
  Rejected = 'rejected',
}

export enum IncomingInvoiceValidityEnum {
  /** finance_ready */
  FinanceReady = 'finance_ready',
  /** manager_need_fix */
  ManagerNeedFix = 'manager_need_fix',
  /** manager_ready */
  ManagerReady = 'manager_ready',
  /** partner_need_fix */
  PartnerNeedFix = 'partner_need_fix',
  /** partner_ready */
  PartnerReady = 'partner_ready',
  /** rejected_by_pm */
  RejectedByPm = 'rejected_by_pm',
}

export interface IIncomingInvoice {
  id: number;
  dealId: number;
  state: IncomingInvoiceState;
  number?: string;
  validity?: IncomingInvoiceValidityEnum;

  isPartial: boolean;
  creditNote: boolean;

  totalAmountNetto?: number;
  totalAmountBrutto?: number;

  refInvoice?: IIncomingInvoice;
  holdUntil?: string;
  paymentDueDate?: string;
  issuedAt?: string;
  registeredAt?: string;
  paidAt?: string;

  providerOrderId?: number;
  providerOrder?: IProviderOrder;

  createdAt: string;
  updatedAt: string;
}
