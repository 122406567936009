import { FileExcelOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { ExportOutlined } from 'components/UI/Icons/ExportOutlined';
import { ExportColumnsMap, QueryNameKey } from 'components/Workspaces/collections';
import {
  Column,
  IDataItem,
  ISortParam,
  PermissionsSettings,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { ExportModal } from 'components/Workspaces/General/shared/GeneralWorkspace/ExportModal';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import React, { useState } from 'react';
import useModal from 'utils/hooks/useModal';

interface Props {
  columns: Column[];
  exportColumns?: ExportColumnsMap;
  header: string;
  queryName: QueryNameKey;
  queryOptions?: Record<string, unknown>;
  preset?: WorkspacePreset;
  selectedRecords: IDataItem[];
  sortParams: ISortParam[];
  timezoneOffset: number;
  total: number;
  permissionsSettings: PermissionsSettings;

  onPresetUpdate: (preset: ExistingWorkspacePreset) => any;
}

export const ExportActions: React.FC<Props> = ({
  columns,
  exportColumns,
  header,
  queryName,
  queryOptions,
  preset,
  selectedRecords,
  sortParams,
  timezoneOffset,
  total,
  permissionsSettings,
  onPresetUpdate,
}) => {
  const [exportFormat, setExportFormat] = useState<string>('csv');

  const { modalVisible: exportModalVisible, openModal: openExportModal, closeModal: closeExportModal } = useModal();

  const handleExportCSVClick = () => {
    setExportFormat('csv');
    openExportModal();
  };

  const handleExportXSLXClick = () => {
    setExportFormat('xlsx');
    openExportModal();
  };

  const exportMenu = [
    {
      key: 'exportXLSX',
      onClick: handleExportXSLXClick,
      icon: <FileExcelOutlined />,
      label: 'Export to XLSX',
    },
    {
      key: 'exportCSV',
      onClick: handleExportCSVClick,
      icon: <FileTextOutlined />,
      label: 'Export to CSV',
    },
  ];

  return (
    <>
      <Dropdown
        menu={{
          items: exportMenu,
        }}
      >
        <Button type="link" icon={<ExportOutlined />} />
      </Dropdown>

      {exportModalVisible && (
        <ExportModal
          timezoneOffset={timezoneOffset}
          format={exportFormat}
          columns={columns}
          exportColumns={exportColumns}
          onCancel={closeExportModal}
          queryName={queryName}
          queryOptions={queryOptions}
          preset={preset}
          selectedRecords={selectedRecords}
          sortParams={sortParams}
          title={header}
          total={total}
          visible
          onPresetUpdate={onPresetUpdate}
          permissionsSettings={permissionsSettings}
        />
      )}
    </>
  );
};
