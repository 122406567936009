import { EAttachmentStatus } from '@xometry/ui';
import { FormInstance } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { TemplateModalFormFieldEnum, TemplateModalFormValues } from 'components/TemplatesModal/TemplateModal.types';
import { Notification } from 'components/UI';
import { useEffect, useState } from 'react';
import { AttachmentFragmentFragment } from 'utils/graphql/fragments/__generated__/attachmentsFragment';
import { useErpMessengerAttachmentsUploadMutation } from 'utils/graphql/mutations/__generated__/attachments';

export type Attachment = AttachmentFragmentFragment & {
  status: EAttachmentStatus;
};

interface Attachments {
  initialAttachments: Attachment[];
  form: FormInstance<TemplateModalFormValues>;
  isMobile: boolean;
}

const MAX_VISIBLE_ATTACHMENTS = 12;

const cleanBase64 = (base64: string) => base64.replace(/^data:(.*,)?/, '');

export const useAttachments = ({ initialAttachments, form, isMobile }: Attachments) => {
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [attachments, setAttachments] = useState(initialAttachments);

  const totalAttachments = attachments.length;

  const [upload, { loading: uploadLoading }] = useErpMessengerAttachmentsUploadMutation({
    onCompleted: (response) => {
      const files = response.messengerAttachmentsUpload?.attachments || [];

      setAttachments((attachments) => [
        ...files.map((file) => ({ ...file, status: EAttachmentStatus.PREVIEW_SUCCESS })),
        ...attachments,
      ]);
    },
  });

  useEffect(() => {
    setAttachments(initialAttachments);
  }, [initialAttachments]);

  useEffect(() => {
    form.setFieldValue(
      TemplateModalFormFieldEnum.AttachmentIds,
      attachments.map((attachment) => attachment.id),
    );
  }, [attachments, form]);

  const handlePrevClick = () => {
    if (currentStartIndex > 0) {
      setCurrentStartIndex(currentStartIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentStartIndex < totalAttachments - MAX_VISIBLE_ATTACHMENTS) {
      setCurrentStartIndex(currentStartIndex + 1);
    }
  };

  const handleRemoveAttachment = (id: string) => {
    const updatedAttachments = attachments.filter((attachment) => attachment.id !== id);
    setAttachments(updatedAttachments);

    if (currentStartIndex >= updatedAttachments.length - MAX_VISIBLE_ATTACHMENTS && currentStartIndex > 0) {
      setCurrentStartIndex(currentStartIndex - 1);
    }
  };

  const handleBeforeUpload = async (file: RcFile) => {
    setAttachments((attachments) => [
      {
        id: file.uid,
        uuidId: file.uid,
        name: file.name,
        status: EAttachmentStatus.UPLOADING,
      },
      ...attachments,
    ]);

    const toBase64 = (file: RcFile): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(cleanBase64(reader.result as string));
        reader.onerror = (error) => reject(error);
      });
    };

    try {
      const base64File = await toBase64(file);

      await upload({
        variables: {
          attachments: [
            {
              name: file.name,
              fileBase64Encode: base64File,
            },
          ],
        },
      }).then((response) => {
        // Removing of the temporary file item if success
        setAttachments((attachments) => {
          return attachments.filter(
            (current) =>
              response.data?.messengerAttachmentsUpload?.attachments.find(({ id }) => id === current.id) ||
              current.status !== EAttachmentStatus.UPLOADING, // save already proceeded files
          );
        });
      });
      Notification.Success(`Attachment ${file.name} has been added`);
    } catch (error) {
      Notification.Error('Failed to upload attachments');
    }

    return false; // Prevent the upload from triggering default behavior
  };

  const visibleAttachments = attachments.slice(currentStartIndex, currentStartIndex + MAX_VISIBLE_ATTACHMENTS);
  const showCarousel = !isMobile && totalAttachments > MAX_VISIBLE_ATTACHMENTS;
  const disabledRow = currentStartIndex >= totalAttachments - MAX_VISIBLE_ATTACHMENTS;

  return {
    currentStartIndex,
    disabledRow,
    handleBeforeUpload,
    handleNextClick,
    handlePrevClick,
    handleRemoveAttachment,
    showCarousel,
    totalAttachments,
    uploadLoading,
    visibleAttachments,
  };
};
