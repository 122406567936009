import { Form as AntdForm, Modal as AntdModal } from 'antd';
import { If, Notification } from 'components/UI';
import { MailerScreen } from 'interfaces/stores/MailerStore';
import { compact } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactElement, useCallback, useEffect, useRef } from 'react';
import { useStore } from 'stores/RootStore';

import { Failure, Form, Process, Success } from './shared';
import { Container, Modal, StyledForm } from './styled';
import { MailerFormValues } from './types';

export interface MailerProps {
  title?: string;
  visible: boolean;
  requests: {
    fetch: string;
    submit: string;
    directSubmit: string;
    uploadFile?: string;
  };
  forceRecipientEmails?: string[];
  onCancel: () => void;
}

const { confirm } = AntdModal;

const Mailer: FC<MailerProps> = ({ title, visible, requests, forceRecipientEmails, onCancel }): ReactElement => {
  const { mailerStore } = useStore();
  const [form] = AntdForm.useForm<MailerFormValues>();
  const mailerTitle = compact(['ERP Mailer', title]).join(' | ');
  const changesMadeRef = useRef(false);

  useEffect(() => {
    if (visible) {
      mailerStore.fetch(requests.fetch).then(
        () => form.resetFields(),
        (e) => console.info(e),
      );
    }
  }, [form, mailerStore, requests.fetch, visible]);

  useEffect(() => {
    if (visible && forceRecipientEmails?.length && forceRecipientEmails.length > 0) {
      mailerStore.setForceRecipientEmails(forceRecipientEmails);
    }
  }, [forceRecipientEmails, form, mailerStore, visible]);

  const handleSubmit = (values: MailerFormValues) => {
    mailerStore
      .send(requests.submit, values)
      .then(() => (changesMadeRef.current = false))
      .catch(() => {
        Notification.Error('Failed to send email');
      });
  };

  const handleDirectSubmit = async () => {
    const values = await form.validateFields();

    mailerStore.send(requests.directSubmit, values).catch(() => {
      Notification.Error('Failed to send email');
    });
  };

  const handleRetry = () => {
    mailerStore.setScreen(MailerScreen.MAIN);
  };

  const handleUpload = (file: File) => {
    if (!requests.uploadFile) return;

    mailerStore.uploadAttachment(requests.uploadFile, file).catch(() => {
      Notification.Error('Failed to upload file');
    });
  };

  const selectedDocuments = mailerStore.documents.filter((doc) => doc.selected).map((doc) => doc.value);

  const handleValuesChange = useCallback((values: Record<string, string>) => {
    if (changesMadeRef.current) {
      return;
    } else {
      if (!values.name) {
        changesMadeRef.current = true;
      }
    }
  }, []);

  const handleCancel = useCallback(() => {
    if (changesMadeRef.current) {
      confirm({
        title: 'Close Mailer?',
        content: 'Changes you made may not be saved',
        onOk() {
          changesMadeRef.current = false;
          onCancel();
        },
        onCancel() {
          return;
        },
      });
    } else {
      onCancel();
    }
  }, [onCancel]);

  return (
    <Modal
      title={mailerTitle}
      open={visible}
      onCancel={handleCancel}
      width={1000}
      footer={null}
      destroyOnClose
      centered
    >
      <If condition={!!mailerStore.isFetching}>
        <Process message={mailerStore.isFetching} />
      </If>

      <Container>
        <If condition={mailerStore.screen === MailerScreen.MAIN}>
          {!mailerStore.isFetching && (
            <StyledForm
              form={form}
              onFinish={(values) => handleSubmit(values as MailerFormValues)}
              initialValues={{
                ...mailerStore.currentTemplate,
                recipient: mailerStore.recipients,
                carbonCopy: mailerStore.carbonCopy,
                blindCarbonCopy: mailerStore.blindCarbonCopy,
                documents: selectedDocuments,
                attachments: [],
              }}
              onValuesChange={handleValuesChange}
            >
              <Form
                changesMadeRef={changesMadeRef}
                form={form}
                onDirectSubmit={() => void handleDirectSubmit()}
                onUpload={handleUpload}
                withUploads={!!requests.uploadFile}
                textAreaRows={12}
              />
            </StyledForm>
          )}
        </If>

        <If condition={mailerStore.screen === MailerScreen.SUCCESS}>
          <Success onCancel={onCancel} />
        </If>

        <If condition={mailerStore.screen === MailerScreen.FAILURE}>
          <Failure onCancel={onCancel} onRetry={handleRetry} />
        </If>
      </Container>
    </Modal>
  );
};

const ObservableMailer = observer(Mailer);

export default ObservableMailer;
