import countries from 'collections/countries';
import { dealStagesById } from 'collections/deal';
import { map, orderBy } from 'lodash-es';

export const dealStageOptions = orderBy(
  map(Object.entries(dealStagesById), ([value, label]) => ({ label, value })),
  ({ label }) => Number.parseFloat((label.match(/\d+\.\d+/) ?? ['0'])[0]),
);

export const countryOptions = countries.map(({ name }) => ({ value: name, label: name }));

export const techAuditStatusArr = ['New⭕️', 'N/Inf🤔', 'OK✅', 'Skip🤖️', 'Risk🔴'];
export const techAuditStatusOptions = techAuditStatusArr.map((status) => ({ label: status, value: status }));

export const drawingCleanupStatusArr = ['🧹 ✅', '🧹 ❌', '🤖️', '✍️ ✅', '✍️ ❌'];
export const drawingCleanupStatusOptions = drawingCleanupStatusArr.map((status) => ({ label: status, value: status }));

export const pricingMethodArr = ['RFQ 🤝', 'IQE 💚', 'Man 🔴'];
export const pricingMethodOptions = pricingMethodArr.map((method) => ({ label: method, value: method }));

export const techTagsArr = [
  'Casting/Injection Molding',
  'CNC',
  'Sheet',
  '3D',
  'Die Casting',
  'Vacuum Casting',
  'Compression Molding',
  'Other methods',
];

export const techTagsOptions = techTagsArr.map((tag) => ({ label: tag, value: tag }));
