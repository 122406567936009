import { Column } from '../../collections';

interface PrepareFilterValuesProps {
  column?: Column;
  values: any[];
}

export const wrapFilterValues = ({ column, values }: PrepareFilterValuesProps): any[] => {
  if (column == null) return values;

  if (column.synthetic) return values;

  if (column.settings?.filterPrepareValues == null) return values;

  return column.settings.filterPrepareValues.wrap(values);
};

export const unwrapFilterValues = ({ column, values }: PrepareFilterValuesProps): any[] => {
  if (column == null) return values;

  if (column.synthetic) return values;

  if (column.settings?.filterPrepareValues == null) return values;

  return column.settings.filterPrepareValues.unwrap(values);
};
