import { useMonitoringClient } from '@xometry/ui';
import React, { FC, ReactNode, useMemo } from 'react';
import { RootStore, StoreContext } from 'stores/RootStore';

interface Props {
  children: ReactNode;
}

export const RootStoreProvider: FC<Props> = (props) => {
  const { children } = props;
  const monitoringClient = useMonitoringClient();

  const store = useMemo(() => {
    return new RootStore({
      monitoring: monitoringClient,
    });
  }, [monitoringClient]);

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
