import styled from '@emotion/styled';
import { Button } from 'antd';
import { colors } from 'components/UI/colors';
import { Modal as UIModal } from 'components/UI/Modal/styled';

export const FileContainer = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
  word-break: break-all;

  .name {
    font-size: 0.8rem;
    width: 90px;
  }
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 14px;
`;

export const FileItemContainer = styled.div`
  height: 130px;
  margin-right: 20px;
  position: relative;
  width: auto;
`;

export const FileListPreview = styled(Button)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 140px;
  margin-bottom: 10px;
  padding: 16px 8px;
  width: 100%;

  &.active {
    border: 1px solid;
  }

  &:hover:not(.active) {
    border: 1px solid ${colors.gray4};
  }

  .preview-container {
    position: relative;
    width: 100%;
  }

  .right-button {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const Modal = styled(UIModal)`
  .ant-modal-content {
    height: calc(100vh - 80px);
  }

  iframe {
    top: 55px !important;
  }
`;

export const PdfViewerWrapper = styled.div`
  width: 100%;

  .pdf-viewer {
    min-height: 80vh;
    position: relative;
    width: 100%;

    iframe {
      position: absolute;
      top: 0 !important;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
`;

export const Preview = styled.div`
  align-items: center;
  background: white;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1px #ccc, inset 0 0 0 6px #fff;
  clip-path: polygon(0 0, 75% 0, 100% 20%, 100% 100%, 0% 100%);
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  max-height: 80px;
  min-height: 80px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 60px;

  &:before {
    content: '';
    background: #ccc;
    width: 25%;
    height: 20%;
    position: absolute;
    top: 0;
    right: 0;
  }

  &:hover {
    &:after {
      background: #009dff;
      content: '';
      display: inline;
      height: 100%;
      left: 0;
      opacity: 0.1;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:before {
      background: #aaa;
    }
  }

  .preview-image {
    width: 100%;
  }
`;

export const PreviewModalContainer = styled.div`
  align-items: center;
  display: flex;

  .preview-container {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 80vh;
    justify-content: center;
    object-fit: contain;
  }

  .sidebar {
    align-items: flex-start;
    align-self: normal;
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: flex-start;
    max-height: 80vh;
    overflow: auto;
    padding-left: 10px;
    width: 300px;
  }
`;

export const RemoveFileButton = styled(Button)`
  align-items: center;
  border: none;
  color: ${colors.redDarker};
  display: flex;
  height: 20px;
  justify-content: center;
  left: calc(50% + 30px);
  position: absolute;
  top: 0;
  width: 20px;
`;
