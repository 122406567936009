import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessengerTemplatePlaceholdersQueryVariables = Types.Exact<{
  type: Types.TemplateTypeEnum;
}>;


export type MessengerTemplatePlaceholdersQuery = { messengerTemplatePlaceholders: { content: Array<{ label?: string | null, value?: string | null }>, recipients: Array<{ label?: string | null, value?: string | null }> } };


export const MessengerTemplatePlaceholdersDocument = gql`
    query messengerTemplatePlaceholders($type: TemplateTypeEnum!) {
  messengerTemplatePlaceholders(type: $type) {
    content {
      label
      value
    }
    recipients {
      label
      value
    }
  }
}
    `;

/**
 * __useMessengerTemplatePlaceholdersQuery__
 *
 * To run a query within a React component, call `useMessengerTemplatePlaceholdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerTemplatePlaceholdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerTemplatePlaceholdersQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMessengerTemplatePlaceholdersQuery(baseOptions: Apollo.QueryHookOptions<MessengerTemplatePlaceholdersQuery, MessengerTemplatePlaceholdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessengerTemplatePlaceholdersQuery, MessengerTemplatePlaceholdersQueryVariables>(MessengerTemplatePlaceholdersDocument, options);
      }
export function useMessengerTemplatePlaceholdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessengerTemplatePlaceholdersQuery, MessengerTemplatePlaceholdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessengerTemplatePlaceholdersQuery, MessengerTemplatePlaceholdersQueryVariables>(MessengerTemplatePlaceholdersDocument, options);
        }
export function useMessengerTemplatePlaceholdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MessengerTemplatePlaceholdersQuery, MessengerTemplatePlaceholdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessengerTemplatePlaceholdersQuery, MessengerTemplatePlaceholdersQueryVariables>(MessengerTemplatePlaceholdersDocument, options);
        }
export type MessengerTemplatePlaceholdersQueryHookResult = ReturnType<typeof useMessengerTemplatePlaceholdersQuery>;
export type MessengerTemplatePlaceholdersLazyQueryHookResult = ReturnType<typeof useMessengerTemplatePlaceholdersLazyQuery>;
export type MessengerTemplatePlaceholdersSuspenseQueryHookResult = ReturnType<typeof useMessengerTemplatePlaceholdersSuspenseQuery>;
export type MessengerTemplatePlaceholdersQueryResult = Apollo.QueryResult<MessengerTemplatePlaceholdersQuery, MessengerTemplatePlaceholdersQueryVariables>;