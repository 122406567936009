import { ObjectType, SmartSearchClassName, SmartSearchPayload } from 'interfaces';
import { routes } from 'routes';

export const erpPath = (payload: SmartSearchPayload): string | undefined => {
  let path;

  switch (payload.className) {
    case SmartSearchClassName.OutcomingInvoice:
      path = routes.editOutcomingInvoicePath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.IncomingInvoice:
      path = routes.editDealIncomingInvoicePath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.Job:
      path = routes.jobPath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.CL:
      path = routes.editDealClaimPath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.QualityControl:
      path = routes.editDealQualityControlPath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.BA:
      path = routes.billingAccountPath(payload.id);
      break;
    case SmartSearchClassName.SA:
      if (payload.personId) {
        path = routes.saPath(payload.personId, payload.id);
      }

      break;
    case SmartSearchClassName.Event:
      switch (payload.sourceType) {
        case ObjectType.PO:
          path = `${routes.editProviderOrderPath(payload.dealId, String(payload.sourceId))}?event_id=${payload.id}`;

          break;
        case ObjectType.OC:
          path = `${routes.orderConfirmationPath(payload.dealId)}?event_id=${payload.id}`;

          break;
        case ObjectType.E:
          path = `${routes.enquiryEditPath(payload.dealId, String(payload.sourceId))}?event_id=${payload.id}`;

          break;
        case ObjectType.CL:
          path = `${routes.editDealClaimPath(payload.dealId, String(payload.sourceId))}?event_id=${payload.id}`;

          break;
        default:
          break;
      }

      break;
    case SmartSearchClassName.PO:
      path = routes.editProviderOrderPath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.Part:
      path = `${routes.dealPartsPath(payload.dealId)}?part_id=${payload.id}`;
      break;
    case SmartSearchClassName.JobOffer:
      path = routes.jobOfferPath(payload.dealId, payload.jobId || '', payload.id);
      break;
    case SmartSearchClassName.Deal:
      path = routes.dealPath(payload.id);
      break;
    case SmartSearchClassName.Payment:
      path = routes.paymentPath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.OrderConfirmation:
      path = routes.orderConfirmationPath(payload.dealId);
      break;
    case SmartSearchClassName.Delivery:
      path = routes.deliveryPath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.Shipping:
      path = routes.editShippingPath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.Storage:
      path = routes.editStoragePath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.Payout:
      path = routes.editPayoutPath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.Enquiry:
      path = routes.enquiryEditPath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.Provider:
      path = routes.providerPath(payload.id);
      break;
    case SmartSearchClassName.Organization:
      path = routes.orgPath(payload.id);
      break;
    case SmartSearchClassName.Person:
      path = routes.personPath(payload.id);
      break;
    case SmartSearchClassName.EnquiryPart:
      path = `${routes.enquiryEditPath(payload.dealId, String(payload.enquiryId))}?part_id=${payload.id}`;
      break;
    case SmartSearchClassName.Quotation:
      path = routes.dealQuotationPath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.MasterShipping:
      path = routes.editMasterShippingPath(payload.id);
      break;
    case SmartSearchClassName.Rfq:
      path = routes.rfqEditPath(payload.dealId, payload.id);
      break;
    case SmartSearchClassName.RfqOffer:
      path = routes.rfqOfferPath(payload.dealId, payload.rfqId, payload.id);
      break;
    default:
      break;
  }

  return path;
};
