export enum MailerScreen {
  MAIN,
  SUCCESS,
  FAILURE,
}

export interface MailerAddress {
  value: string;
  label: string;
}

export interface MailerAttach {
  label: string;
  value: string;
  size: number;
  selected?: boolean;
}

export interface MailerFile {
  id: number;
  name: string;
  size: number;
}

export interface MailerTemplate {
  id: number;
  name: string;
  constantName: string;
  type: string;
  locale: string;
  sender: string;
  replyTo: string;
  recipient: string[];
  carbonCopy: string[];
  blindCarbonCopy: string[];
  subject: string;
  content: string;
}
