import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { RenderedTemplateFragmentDoc } from '../../fragments/__generated__/renderedTemplate';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RenderedTemplateQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  sourceId: Types.Scalars['ID']['input'];
  sourceType: Types.Scalars['String']['input'];
}>;


export type RenderedTemplateQuery = { messengerRenderTemplate?: { content: string, readOnly: boolean, blindCarbonCopy?: Array<string> | null, carbonCopy?: Array<string> | null, recipients?: Array<string> | null, id: string, name?: string | null, constantName?: string | null, locale: Types.TemplateLocaleEnum, attachments: Array<{ id: string, uuidId: string, name: string, previewUrl?: string | null, previewLargeUrl?: string | null }> } | null };


export const RenderedTemplateDocument = gql`
    query renderedTemplate($id: ID!, $sourceId: ID!, $sourceType: String!) {
  messengerRenderTemplate(id: $id, sourceId: $sourceId, sourceType: $sourceType) {
    ...renderedTemplate
  }
}
    ${RenderedTemplateFragmentDoc}`;

/**
 * __useRenderedTemplateQuery__
 *
 * To run a query within a React component, call `useRenderedTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRenderedTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRenderedTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sourceId: // value for 'sourceId'
 *      sourceType: // value for 'sourceType'
 *   },
 * });
 */
export function useRenderedTemplateQuery(baseOptions: Apollo.QueryHookOptions<RenderedTemplateQuery, RenderedTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RenderedTemplateQuery, RenderedTemplateQueryVariables>(RenderedTemplateDocument, options);
      }
export function useRenderedTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RenderedTemplateQuery, RenderedTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RenderedTemplateQuery, RenderedTemplateQueryVariables>(RenderedTemplateDocument, options);
        }
export function useRenderedTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RenderedTemplateQuery, RenderedTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RenderedTemplateQuery, RenderedTemplateQueryVariables>(RenderedTemplateDocument, options);
        }
export type RenderedTemplateQueryHookResult = ReturnType<typeof useRenderedTemplateQuery>;
export type RenderedTemplateLazyQueryHookResult = ReturnType<typeof useRenderedTemplateLazyQuery>;
export type RenderedTemplateSuspenseQueryHookResult = ReturnType<typeof useRenderedTemplateSuspenseQuery>;
export type RenderedTemplateQueryResult = Apollo.QueryResult<RenderedTemplateQuery, RenderedTemplateQueryVariables>;