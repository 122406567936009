import { Button, Col, Form, Input, Modal as AntdModal, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormItemCache from 'components/UI/Form/FormItemCache';
import If from 'components/UI/If';
import { observer } from 'mobx-react-lite';
import React, { FC, MutableRefObject, ReactElement, useEffect, useState } from 'react';
import { useStore } from 'stores/RootStore';
import { REQUIRED } from 'validators';

interface Props {
  changesMadeRef?: MutableRefObject<boolean>;
  form: FormInstance;
}

const { confirm } = AntdModal;

const Template: FC<Props> = observer(({ changesMadeRef, form }): ReactElement => {
  const [senderVisible, setSenderVisible] = useState<boolean>(false);
  const { mailerStore } = useStore();
  const { getCurrentTemplateLocaleOptions, currentTemplate, templateOptions, setCurrentTemplate, setLocale } =
    mailerStore.templatesStore;

  useEffect(() => {
    if (currentTemplate) {
      form.setFieldsValue({
        ...currentTemplate,
        recipient: mailerStore.recipients,
        carbonCopy: mailerStore.carbonCopy,
        blindCarbonCopy: mailerStore.blindCarbonCopy,
      });
    }
  }, [currentTemplate, form, mailerStore.blindCarbonCopy, mailerStore.carbonCopy, mailerStore.recipients]);

  const handleChangeTemplatesLocale = (locale: string) => {
    if (changesMadeRef?.current) {
      confirm({
        title: 'Change locale?',
        content: 'Changes you made may not be saved',
        onOk() {
          changesMadeRef.current = false;
          setLocale(locale);
        },
        onCancel() {
          return;
        },
      });
    } else {
      setLocale(locale);
    }
  };

  const handleChangeTemplate = (templateName: string) => {
    if (changesMadeRef?.current) {
      confirm({
        title: 'Change template?',
        content: 'Changes you made may not be saved',
        onOk() {
          changesMadeRef.current = false;

          setCurrentTemplate(templateName);
        },
        onCancel() {
          return;
        },
      });
    } else {
      setCurrentTemplate(templateName);
    }
  };

  const handleChangeSender = (): void => {
    setSenderVisible(false);
  };

  return (
    <>
      <Row justify="space-between" gutter={20}>
        <Col xs={9}>
          <Form.Item name="name">
            <Select<string>
              onSelect={handleChangeTemplate}
              defaultValue={currentTemplate?.constantName}
              options={templateOptions}
              value={currentTemplate?.constantName}
            />
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item>
            <Select
              onChange={handleChangeTemplatesLocale}
              defaultValue={currentTemplate?.locale}
              value={currentTemplate?.locale}
              options={getCurrentTemplateLocaleOptions}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Row justify="center">
            <Form.Item
              name="sender"
              style={{ textAlign: 'center' }}
              rules={[
                {
                  validator: () => {
                    const sender = form.getFieldValue('sender') as string;
                    const replyTo = form.getFieldValue('replyTo') as string;

                    if (!sender && !replyTo) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject('Sender Name and Email required!');
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Button type="link" onClick={() => setSenderVisible(true)} disabled={senderVisible}>
                {`${(form.getFieldValue('sender') as string) || 'None'} <${
                  (form.getFieldValue('replyTo') as string) || 'None'
                }>`}
              </Button>
            </Form.Item>
          </Row>
        </Col>
      </Row>

      <If condition={senderVisible}>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item name="sender" rules={[{ required: true, message: REQUIRED }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Row justify="space-between">
              <Form.Item name="replyTo" rules={[{ required: true, message: REQUIRED }]}>
                <Input style={{ width: '400px' }} />
              </Form.Item>
              <Button onClick={handleChangeSender}>OK</Button>
            </Row>
          </Col>
        </Row>
      </If>

      <If condition={!senderVisible}>
        <FormItemCache name={['sender']} />
        <FormItemCache name={['replyTo']} />
      </If>
    </>
  );
});

export default Template;
