import lookers from 'collections/lookers';
import { observer } from 'mobx-react-lite';
import { LookerPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

const CrmController = observer(() => (
  <>
    <Route
      path={routes.lookers.crm.deals.today()}
      exact
      render={(props) => <LookerPage {...props} url={lookers.crm.deals.today} title="ERP | Deals | Today" />}
    />

    <Route
      path={routes.lookers.crm.deals.last7Days()}
      exact
      render={(props) => <LookerPage {...props} url={lookers.crm.deals.last7Days} title="ERP | Deals | Last 7 days" />}
    />

    <Route
      path={routes.lookers.crm.deals.todayOrders()}
      exact
      render={(props) => (
        <LookerPage {...props} url={lookers.crm.deals.todayOrders} title="ERP | Deals | Today orders" />
      )}
    />

    <Route
      path={routes.lookers.crm.deals.last7DaysOrders()}
      exact
      render={(props) => (
        <LookerPage {...props} url={lookers.crm.deals.last7DaysOrders} title="ERP | Deals | Last 7 days orders" />
      )}
    />

    <Route
      path={routes.lookers.crm.deals.won2021()}
      exact
      render={(props) => <LookerPage {...props} url={lookers.crm.deals.won2021} title="ERP | Deals | Won 2021" />}
    />

    <Route
      path={routes.lookers.crm.orgsPath()}
      exact
      render={(props) => <LookerPage {...props} url={lookers.crm.orgs} title="ERP | Organizations" />}
    />

    <Route
      path={routes.lookers.crm.personsPath()}
      exact
      render={(props) => <LookerPage {...props} url={lookers.crm.persons} title="ERP | Persons" />}
    />

    <Route
      path={routes.lookers.crm.crossPath()}
      exact
      render={(props) => <LookerPage {...props} url={lookers.crm.cross} title="ERP | Deal Person Org" />}
    />
  </>
));

export default CrmController;
