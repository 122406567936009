import { Part } from 'interfaces';
import { routesApi } from 'routes/api';
import api, { camelizeKeys, decamelizeKeys } from 'utils/axios';

export interface Values {
  hsCode: string | null | undefined;
  hsCodeName: string | null | undefined;
  hsCodePurpose: string | null | undefined;
  hsCodeState: string | null | undefined;
  hsCodeConfirmedAt: string | null | undefined;
  hsCodeConfirmedByName: string | null | undefined;
}

export const updateHsCodesForPart = async (partId: string | number, values: Values): Promise<Part> => {
  const url = routesApi.partPath(partId);
  const form = new FormData();
  const decamelizedValues = decamelizeKeys(values);
  Object.entries(decamelizedValues).forEach(([key, value]) => {
    form.append(`part[${key}]`, JSON.stringify(value));
  });

  return api.patch<{ payload: Part }>(url, form).then((response) => {
    return camelizeKeys(response.data.payload);
  });
};
