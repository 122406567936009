import { FALLBACK_LOCALE, LocaleEnum, SUPPORTED_LOCALES } from 'constants/locales';
import i18n from 'i18next';
import { noop } from 'lodash-es';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';

const resources = {
  en: { translation: en },
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: FALLBACK_LOCALE,
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(noop);

export const currentLocale = (): LocaleEnum => {
  if (SUPPORTED_LOCALES.includes(i18n.language as LocaleEnum)) {
    return i18n.language as LocaleEnum;
  }

  return LocaleEnum.EN;
};
