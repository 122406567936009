import {
  CustomFilterPattern,
  FilterNextOperator,
  FilterPatternName,
  filterPatterns,
  IFilter,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { camelize } from 'humps';
import { each, last } from 'lodash-es';

import FilterItem from './FilterItem';

type Lexeme = [string, string, string?];

export const arithmeticExpressionPattern = /^\{[^{}]+\}/;

interface IPattern {
  kind: string;
  value: RegExp;
  process?: (value: string) => string | undefined;
}

const tokenize = (input: string, patterns: IPattern[]) => {
  let index = 0;
  const lexemes: Lexeme[] = [];

  while (index < input.length) {
    let lastMatch: Lexeme | undefined;

    // eslint-disable-next-line no-loop-func
    each(patterns, (pattern) => {
      if (lastMatch != null) return;

      const match = input.slice(index).match(pattern.value);

      if (match == null) return;

      let value: string | undefined = match[0].trim();

      if (pattern.process != null) value = pattern.process(value);

      lastMatch = [pattern.kind, match[0], value];
    });

    if (lastMatch == null) return lexemes;

    if (lastMatch[2] != null) lexemes.push(lastMatch);

    index += lastMatch[1].length;
  }

  return lexemes;
};

const convertCustomFilter = (filter: IFilter) => {
  if (filter.pattern === FilterPatternName.Equal && filter.values[0] === 'null') {
    return {
      ...filter,
      pattern: CustomFilterPattern.IsNull,
      values: [],
    };
  }

  if (filter.pattern === FilterPatternName.NotEqual && filter.values[0] === 'null') {
    return {
      ...filter,
      pattern: CustomFilterPattern.IsNotNull,
      values: [],
    };
  }

  if (filter.pattern === FilterPatternName.Equal && filter.values[0] === 'true') {
    return {
      ...filter,
      pattern: CustomFilterPattern.IsTrue,
      values: [],
    };
  }

  if (filter.pattern === FilterPatternName.Equal && filter.values[0] === 'false') {
    return {
      ...filter,
      pattern: CustomFilterPattern.IsFalse,
      values: [],
    };
  }

  return filter;
};

const parseExpression: (input: string) => IFilter = (input) => {
  const patterns: IPattern[] = [
    {
      kind: 'column',
      value: /^\b[a-zA-Z][a-zA-Z0-9_]+/,
    },
    {
      kind: 'value',
      value: /^\d+/,
    },
    {
      kind: 'value',
      value: /^'[^']+'/,
      process: (value) => value.replaceAll("'", ''),
    },
    {
      kind: 'arithmetic_expression',
      value: arithmeticExpressionPattern,
    },
    {
      kind: 'space',
      value: /^\s+/,
      process: () => undefined,
    },
  ];

  each(filterPatterns, (pattern) =>
    patterns.push({
      kind: 'operator',
      value: new RegExp(`^${pattern.value}`, 'i'),
    }),
  );

  const lexemes = tokenize(input, patterns);
  const [column, operator, value]: Lexeme[] = lexemes;

  let attribute = column && column[2];
  attribute = attribute && attribute.split('__').map(camelize).join('__');

  let filter: IFilter = {
    attribute,
    pattern: operator && (operator[2] as FilterPatternName),
    values: value != null ? (value[2] || '').split(',') : [],
  };

  filter = convertCustomFilter(filter);

  return filter;
};

export const parseFilters: (input: string) => FilterItem | undefined = (input) => {
  const patterns: IPattern[] = [
    {
      kind: 'expression',
      value: /^\b(?!\s+)(?:(?!(?:\)|\(|\band\b|\bor\b)(?=(?:[^']*'[^']*')*[^']*$)).)+/i,
    },
    {
      kind: '(',
      value: /^\(/,
    },
    {
      kind: ')',
      value: /^\)/,
    },
    {
      kind: 'operator',
      value: /^(?:and|or)/i,
      process: (value) => value.toUpperCase(),
    },
    {
      kind: 'space',
      value: /^\s+/,
      process: () => undefined,
    },
  ];

  const lexemes = tokenize(input, patterns);
  const parents: FilterItem[] = [];

  each(lexemes, (lex) => {
    const parent = last(parents);

    // eslint-disable-next-line default-case
    switch (lex[0]) {
      case 'expression': {
        const filter = parseExpression(lex[1]);
        const filterItem = new FilterItem({ filter });

        if (parent != null) {
          if (parent.value.kind === 'group') {
            parent.value.children.push(filterItem);
          }
        } else {
          parents.push(filterItem);
        }

        break;
      }

      case 'operator': {
        if (parent == null) {
          return;
        }

        if (parent.value.kind === 'filter') {
          const oldParent = parents.pop() as FilterItem;
          oldParent.operator = lex[2] as FilterNextOperator;

          parents.push(
            new FilterItem({
              children: [oldParent],
            }),
          );
        } else {
          (last(parent.value.children) as FilterItem).operator = lex[2] as FilterNextOperator;
        }

        break;
      }

      case '(': {
        const newGroup = new FilterItem({ children: [] });
        parents.push(newGroup);
        break;
      }

      case ')': {
        const oldParent = parents.pop();

        if (oldParent == null) {
          return;
        }

        let newParent = last(parents);

        if (newParent == null) {
          newParent = new FilterItem({ children: [] });
          parents.push(newParent);
        }

        if (newParent.value.kind === 'filter') {
          return;
        }

        newParent.value.children.push(oldParent);
        break;
      }
    }
  });

  return last(parents);
};
