import { AxiosError } from 'axios';
import { Notification } from 'components/UI';

interface ValidationError {
  errors: {
    source: {
      base: string;
    };
    status: number;
    title: string;
  };
}
const showDefaultError = () => Notification.Error('Cant download file :(');

export const handleDownloadError = async (e: AxiosError<Blob, Blob>) => {
  if (e?.response != null && e.response.status === 422) {
    try {
      const rawResponse = await e.response.data.text();
      const parsedResponse = JSON.parse(rawResponse) as ValidationError;
      Notification.Error(parsedResponse.errors.source.base);
    } catch {
      showDefaultError();
    }
  } else {
    showDefaultError();
  }
};
