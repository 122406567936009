import { CurrencyEnum } from '__generated__/types';
import { LoadPositionsOptions, Position, ServerPosition } from 'interfaces';
import { get, last, map } from 'lodash-es';
import { action, observable } from 'mobx';
import { routesApi } from 'routes/api';
import FetchableStore from 'stores/shared/FetchableStore';
import uniqid from 'uniqid';
import { camelizeKeys } from 'utils/axios';

class DealSourcedPositionsStore extends FetchableStore {
  deliveryId: string | null = null;

  @observable positions: Position[] = [];

  @action assignOnFetch = (payload: ServerPosition[]) => {
    this.positions = map(payload, (position: ServerPosition) => ({
      categoryName: position.accounting_category,
      quantity: position.quantity || 1,
      price: position.price_per_piece || 0,
      priceMoney: camelizeKeys(position.price_per_piece_money) || {
        amount: '0',
        amountCents: '0',
        currencyCode: CurrencyEnum.Try,
      },
      providerPrice: position.provider_price,
      providerPriceMoney: camelizeKeys(position.provider_price_money) || {
        amount: '0',
        amountCents: '0',
        currencyCode: CurrencyEnum.Try,
      },
      pricePerPieceInProviderPriceCurrencyMoney: camelizeKeys(
        position.price_per_piece_in_provider_price_currency_money,
      ),
      name: position.name,
      vat: position.vat_rate,
      position: position.position || this.rootStore.dealOutInvoicePositionsStore.incCurrentPosition(),
      refId: position.ref_id,
      refType: position.ref_type,
      key: uniqid(),
      files: position.files || [],
      refSource: position.ref_source,
      deliveryDate: position.delivery_date,
      id: position.id,
    }));

    this.rootStore.dealOutInvoicePositionsStore.addPositions(this.positions);
    this.rootStore.dealOutInvoicePositionsStore.setCurrentPosition(
      get(last(this.positions), 'position') || this.rootStore.dealOutInvoicePositionsStore.incCurrentPosition(),
    );
    this.rootStore.dealOutInvoicePositionsStore.setTableLoading(false);
  };

  @action loadPositions = async (dealId: string, options?: LoadPositionsOptions): Promise<void> => {
    const source = this.rootStore.dealOutInvoicePositionsSourcesStore.selected;

    if (source) {
      this.rootStore.dealOutInvoicePositionsStore.setTableLoading(true);
      const withFiles = options && options.withFiles ? '&with_files=1' : '';
      const partsOnly = options && options.partsOnly ? '&parts_only=1' : '';
      await this.rootStore.dealSourcedPositionsStore.fetch(
        `${routesApi.dealInvoicePositionsSourcedPath(dealId)}?source_id=${source.sourceId}&source_type=${
          source.sourceType
        }${withFiles}${partsOnly}`,
      );
    }
  };

  @action preloadDeliveryPositions = (dealId: string, sourceId: string, sourceType: string) => {
    this.rootStore.dealOutInvoicePositionsStore.setTableLoading(true);
    void this.rootStore.dealSourcedPositionsStore.fetch(
      `${routesApi.dealInvoicePositionsSourcedPath(dealId)}?source_id=${sourceId}&source_type=${sourceType}`,
    );
  };
}

export default DealSourcedPositionsStore;
