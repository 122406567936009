import { SortDirectionEnum } from '__generated__/types';
import { Radio, RadioChangeEvent, Row, Select, Space, Typography } from 'antd';
import React from 'react';

const directionOptions = [
  { label: 'Asc', value: SortDirectionEnum.Asc },
  { label: 'Desc', value: SortDirectionEnum.Desc },
];

interface Props {
  sortOptions: Array<{ label: string; value: string }>;
  sortColumn?: string;
  sortDirection?: SortDirectionEnum;
  onChange: (column?: string, direction?: SortDirectionEnum) => void;
}

export const SortColumn = (props: Props) => {
  const { sortOptions, onChange, sortColumn, sortDirection } = props;

  const handleChangeColumn = (value: string) => {
    if (!value) {
      onChange();
    } else {
      onChange(value, sortDirection || SortDirectionEnum.Asc);
    }
  };

  const handleChangeDirection = ({ target: { value } }: RadioChangeEvent) => {
    if (sortColumn) {
      onChange(sortColumn, value as SortDirectionEnum);
    }
  };

  return (
    <Space direction="vertical" className="w-100">
      <div>
        <Typography.Text>Sort column</Typography.Text>
        <Row>
          <Select
            allowClear
            value={sortColumn}
            options={sortOptions}
            onChange={handleChangeColumn}
            style={{ flexGrow: 1 }}
          />
        </Row>
      </div>

      <Row align="middle">
        <Typography.Text>Direction</Typography.Text>
        <Radio.Group
          options={directionOptions}
          onChange={handleChangeDirection}
          value={sortDirection}
          optionType="button"
          buttonStyle="solid"
          style={{ marginLeft: 10, flexGrow: 1 }}
        />
      </Row>
    </Space>
  );
};
