/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { TEventBusEventCallback } from '@xometry/event-bus';
import {
  EMessengerEventBusEvents,
  IEventBusMessengerAttachment,
  IEventBusMessengerEvents,
  MessengerEventBus,
} from '@xometry/ui';
import { AppContext } from 'contexts/AppContext';
import { PreviewsSchema } from 'interfaces';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useStore } from 'stores/RootStore';

export const useMessengerEventBus = () => {
  const { messengerEventBus, setMessengerEventBus } = useContext(AppContext);

  const setEventBusRef = useCallback(
    (eventBus: MessengerEventBus) => {
      if (messengerEventBus) {
        return messengerEventBus;
      }

      setMessengerEventBus(eventBus);

      return eventBus;
    },
    [messengerEventBus, setMessengerEventBus],
  );

  const sendEvent = useMemo(() => {
    return messengerEventBus?.sendEvent;
  }, [messengerEventBus]);

  const {
    previewsStore: { loadPreview, setSchema },
  } = useStore();
  const [previewVisibility, setPreviewVisiblity] = useState(false);

  const previewUrl = '';

  const closePreview = useCallback(() => {
    setPreviewVisiblity(false);
  }, []);

  const openPreview = useCallback(
    (params?: { current: number; attachments: IEventBusMessengerAttachment[] }) => {
      const { current = 0, attachments } = params || {};

      try {
        const schema: PreviewsSchema = {
          documents: [
            {
              bucketName: 'attachments',
              items: (attachments || []).map((item) => ({
                id: +item.fileId,
                name: item.fileName,
                context: 'attachments   ',
                isCleaned: false,
                url: item.preview || '',
              })),
            },
          ],
          drawings: [],
        };

        loadPreview((attachments || [])[current].fileId)
          .then(() => {
            setSchema(schema);
            setPreviewVisiblity(true);
          })
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {});
      } catch (e) {
        console.error(e);
      }
    },
    [loadPreview, setSchema],
  );

  const handleOpenPreview: TEventBusEventCallback<
    IEventBusMessengerEvents,
    EMessengerEventBusEvents.OPEN_FILE_PREVIEW
  > = useCallback(
    ({ current, files }) => {
      if (typeof current === 'number' && files.length > 0) {
        openPreview({ current, attachments: files });
      }
    },
    [openPreview],
  );

  const addEventListeners = useCallback(
    (eb: MessengerEventBus) => {
      eb.addEventListener(EMessengerEventBusEvents.OPEN_FILE_PREVIEW, handleOpenPreview);
    },
    [handleOpenPreview],
  );

  const removeEventListeners = useCallback(
    (eb: MessengerEventBus) => {
      eb.removeEventListener(EMessengerEventBusEvents.OPEN_FILE_PREVIEW, handleOpenPreview);
    },
    [handleOpenPreview],
  );

  useEffect(() => {
    if (messengerEventBus) {
      addEventListeners(messengerEventBus);

      return () => {
        removeEventListeners(messengerEventBus);
      };
    }
  }, [addEventListeners, messengerEventBus, removeEventListeners]);

  return {
    setEventBusRef,
    previewVisibility,
    openPreview,
    closePreview,
    previewUrl,
    sendEvent,
  };
};
