import styled from '@emotion/styled';
import { Empty as AntEmpty, Row } from 'antd';
import { Modal as UIModal } from 'components/UI/Modal/styled';
import { bc } from 'components/UI/variables';

export const Modal = styled(UIModal)`
  min-width: 90vw;

  .ant-modal-body {
    padding: 16px 24px;
  }

  .ant-tabs-nav-list {
    text-transform: capitalize;
  }
`;

export const Divider = styled.div`
  border-right: 1px solid ${bc.border};
  margin: auto;
  width: 1px;
  height: 100%;
`;

export const BucketName = styled.div`
  background-color: ${bc.modal.header};
`;

interface BucketFilenameProps {
  active: boolean;
}

export const BucketFilename = styled.div<BucketFilenameProps>`
  margin-top: 5px;
  word-break: break-all;

  ${({ active }) =>
    active &&
    `
  color: ${bc.link};
`}
`;

export const ImgPreviewBig = styled.img`
  width: auto;
  height: auto;

  max-width: 100%;
  max-height: 100%;
`;

export const RowPreviewBig = styled(Row)`
  position: relative;
  height: 100%;
  border: 1px solid ${bc.border};
  align-items: center;
  flex-direction: column;
  min-height: 0;

  .anticon.anticon-warning svg {
    font-size: 150px;
  }
`;

export const Empty = styled(AntEmpty)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PdfViewerWrapper = styled.div`
  .pdf-viewer {
    min-height: 80vh;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
`;

export const ScsViewerWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
