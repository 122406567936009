import Icon from '@ant-design/icons/lib/components/Icon';
import { ReactComponent as ExportOutlinedIcon } from 'assets/images/icons/ExportOutlined.svg';
import React from 'react';

type IconProps = React.ComponentProps<typeof Icon>;

// https://github.com/ant-design/ant-design-icons/pull/604
export function ExportOutlined(props: IconProps) {
  return <Icon component={ExportOutlinedIcon} {...props} />;
}
