import { amplitudeLogger } from 'amplitude';
import { AmplitudeAttributeKey, AmplitudeMessengerEvent } from 'collections/amplitudeEvents';
import React, { FC } from 'react';
import { ESide } from 'stores/SideOverlay/SideOverlayStore';

import { CrossIcon, HeaderControlsBlock, SwapIcon } from './styled';

interface IProps {
  onClose: () => void;
  onToggleSide?: () => void;
  side: ESide;
}

export const HeaderControls: FC<IProps> = ({ onClose, onToggleSide, side }) => {
  const handleCloseClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    amplitudeLogger({ event: AmplitudeMessengerEvent.MESSENGER_CLOSED });

    onClose();
  };

  const handleToggleSideClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    amplitudeLogger({
      event: AmplitudeMessengerEvent.MESSENGER_SIDE_SWAPPED,
      attrs: {
        [AmplitudeAttributeKey.SIDE]: side,
      },
    });

    onToggleSide?.();
  };

  return (
    <HeaderControlsBlock>
      {onToggleSide ? <SwapIcon onClick={handleToggleSideClick} size="18px" /> : null}
      <CrossIcon onClick={handleCloseClick} size="18px" />
    </HeaderControlsBlock>
  );
};
