import { action, observable } from 'mobx';
import { RootStore } from 'stores/RootStore';

const DEFAULT_TEXT = 'Loading...';

class PageLoaderStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable isVisible = false;

  @observable text = DEFAULT_TEXT;

  @action enable = (text = DEFAULT_TEXT) => {
    this.text = text;
    this.isVisible = true;
  };

  @action disable = () => {
    this.isVisible = false;
  };
}

export default PageLoaderStore;
