import { noop } from 'lodash-es';
import { action, observable } from 'mobx';
import { RootStore } from 'stores/RootStore';

class OrgStore {
  rootStore: RootStore;

  refetch = noop as () => unknown;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable isEditing = false;

  @action setIsEditing = (isEditing: boolean) => {
    this.isEditing = isEditing;
  };
}

export default OrgStore;
