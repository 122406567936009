import lookers from 'collections/lookers';
import { observer } from 'mobx-react-lite';
import { LookerPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

const LogisticsController = observer(() => (
  <>
    <Route
      path={routes.lookers.logistics.warehousePath()}
      exact
      render={(props) => <LookerPage {...props} url={lookers.logistics.warehouse} title="ERP | Warehouse" />}
    />
  </>
));

export default LogisticsController;
