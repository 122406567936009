import { gql } from 'apollo-boost';

import { CURRENT_REGION } from '../../../config/regions/config';
import { Regions } from '../../../config/regions/types';

const BILLING_ACCOUNT_FRAGMENT_CONFIG: Record<Regions, ReturnType<typeof gql>> = {
  [Regions.EU]: gql`
    fragment billingAccountFragment on BillingAccount {
      id
      type
      accountType
      datevId
      name
      country
      zip
      city
      address
      vatId
      taxId
      internalComment
      vatValidationStatus
      vatRegistered
      vatRegistrationCountry
      badPayer
      paymentTermsUpdatedAt
      paymentTermsUpdatedBy {
        name
      }
    }
  `,
  [Regions.UK]: gql`
    fragment billingAccountFragment on BillingAccount {
      id
      type
      accountType
      intacctId
      name
      country
      zip
      city
      address
      vatId
      taxId
      internalComment
      vatValidationStatus
      badPayer
      paymentTermsUpdatedAt
      paymentTermsUpdatedBy {
        name
      }
    }
  `,
  [Regions.TR]: gql`
    fragment billingAccountFragment on BillingAccount {
      id
      type
      accountType
      intacctId
      name
      country
      zip
      city
      address
      vatId
      vatExempt
      taxId
      internalComment
      vatValidationStatus
      badPayer
      paymentTermsUpdatedAt
      paymentTermsUpdatedBy {
        name
      }
    }
  `,
};

export const BILLING_ACCOUNT_FRAGMENT = BILLING_ACCOUNT_FRAGMENT_CONFIG[CURRENT_REGION];
