import styled from '@emotion/styled';
import { Button as AntButton } from 'antd';
import { bc, colors } from 'components/UI/colors';

export const Button = styled(AntButton)`
  &[disabled] {
    color: ${bc.disabled};
    border-color: ${bc.disabled};
  }

  &.ant-btn-outlined-danger {
    background-color: transparent;
    border-color: ${colors.red6};
    color: ${colors.red6};

    &:hover {
      background-color: transparent;
      border-color: ${colors.red7};
      color: ${colors.red7};
    }
  }

  &.ant-btn-outlined-primary {
    background-color: transparent;
    border-color: ${bc.primary};
    color: ${bc.primary};

    &:hover {
      background-color: transparent;
      border-color: ${bc.primaryHover};
      color: ${bc.primaryHover};
    }
  }

  &.ant-btn-link:not[disabled] {
    color: ${bc.link};

    &:hover {
      color: ${bc.hover};
    }
  }

  &.ant-btn-dangerous:not([disabled]) {
    border-color: ${colors.red4};
    color: ${colors.red7};

    &:hover {
      border-color: ${colors.red8};
      color: ${colors.red8};
    }
  }

  &.ant-btn-dangerous.ant-btn-text:not([disabled]) {
    border-color: transparent;
    color: ${colors.red7};

    &:hover {
      color: ${colors.red8};
    }

    &[disabled] {
      border-color: transparent;
    }
  }

  &.ant-btn-dangerous.ant-btn-link:not([disabled]) {
    &:hover {
      color: ${colors.red8};
    }
  }

  &.ant-btn-primary {
    background: ${bc.primary};
    border-color: ${bc.primary};

    &.ant-btn-background-ghost {
      color: ${bc.primary};
      border-color: #d1d6dc;
      box-shadow: none;
    }

    &:hover {
      background: ${bc.primaryHover};
      border-color: ${bc.primaryHover};
    }

    &.ant-btn-dangerous:not([disabled]) {
      background: ${colors.red7};
      border-color: ${colors.red7};
      color: ${colors.white};

      &:hover {
        background: ${colors.red};
        border-color: ${colors.red};
        color: ${colors.white};
      }
    }
  }

  &.ant-btn-default {
    color: ${colors.blue7bg};
  }

  &.ant-btn-promoted {
    background: ${colors.orange};
    border-color: ${colors.orange};
    color: white;

    &:hover {
      background: ${colors.orange3};
      border-color: ${colors.orange3};
    }
  }

  &.ant-btn-link {
    border: none;
  }

  svg {
    margin-right: 5px;
  }
  .anticon {
    svg {
      margin-right: initial;
    }
  }
`;
