import React from 'react';

import { AccessRequestLink } from './AccessRequestLink';

interface Props {
  message?: string;
  group?: string;
  action?: string;
}

export const NotAuthorized: React.FC<Props> = ({ message, group, action }) => (
  <div style={{ width: '100%', textAlign: 'center' }}>
    <h1>You have no access to this page</h1>
    <h3>{message}</h3>
    <AccessRequestLink group={group} action={action} />
  </div>
);
