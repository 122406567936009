import { TemplateLocaleEnum } from '__generated__/types';

export const LOCALES_LIST = [
  TemplateLocaleEnum.En,
  TemplateLocaleEnum.De,
  TemplateLocaleEnum.Fr,
  TemplateLocaleEnum.It,
  TemplateLocaleEnum.Es,
  TemplateLocaleEnum.Pl,
  TemplateLocaleEnum.Pt,
  TemplateLocaleEnum.Nl,
  TemplateLocaleEnum.Nb,
  TemplateLocaleEnum.Sv,
  TemplateLocaleEnum.Fi,
  TemplateLocaleEnum.Da,
  TemplateLocaleEnum.Tr,
  TemplateLocaleEnum.Cs,
];
