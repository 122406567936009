/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { message } from 'antd';
import { erpPath } from 'components/Header/shared/Search/erpPath';
import { GROUP_LINKS } from 'components/SearchPage/constants';
import { get } from 'lodash-es';
import { action, observable } from 'mobx';
import { routes } from 'routes';
import { camelizeKeys } from 'utils/axios';

import FetchableStore from './shared/FetchableStore';

type MessageType = 'success' | 'error' | 'info' | 'warning' | 'warn' | 'loading';

const BACK_NOT_FOUND = 'Not found';
const INTERNAL_ERROR = 'Something went wrong';

const DURATION = 2;

const notification = (text: string, type: MessageType) => {
  void message[type](text, DURATION);
};

class SmartSearchStore extends FetchableStore {
  @observable query = '';

  @action assignOnFetch = (payload: any): void => {
    if (get(payload, 'value') === BACK_NOT_FOUND) {
      this.rootStore.pageLoaderStore.disable();
      if (this.query) {
        window.location.assign(`${routes.searchPagePath(GROUP_LINKS.Deal)}?query=${this.query}`);
      }
    }

    const url = get(payload, 'url');

    if (url) {
      window.location.href = url;

      return;
    }

    const erpUrl = erpPath(camelizeKeys(payload));

    if (erpUrl) {
      window.location.href = erpUrl;
    }
  };

  @action setQuery = (query: string) => {
    this.query = query;
  };

  @action onFetchError(error: any) {
    super.onFetchError(error);

    const receivedMessage = get(error, 'response.data.errors.source');
    notification(receivedMessage || INTERNAL_ERROR, 'error');
  }
}

export default SmartSearchStore;
