import { GroupAndAction } from 'collections/authorization';
import AuthorizeVisibility from 'components/shared/AuthorizeVisibility';
import { NotAuthorized } from 'components/shared/NotAuthorized';
import React, { PropsWithChildren } from 'react';
import { Route, RouteProps } from 'react-router-dom';

type Props = Omit<RouteProps, 'component'> & GroupAndAction;

export const AuthorizedRoute: React.FC<PropsWithChildren<Props>> = ({ group, action, children, ...routeProps }) => {
  const authorizedComponent = (
    <AuthorizeVisibility
      group={group}
      action={action}
      placeholder={
        <NotAuthorized
          group={group}
          action={action}
          message={`Permission ${group}.${action} is required to access the page`}
        />
      }
    >
      {children}
    </AuthorizeVisibility>
  );

  return <Route {...routeProps}>{authorizedComponent}</Route>;
};
