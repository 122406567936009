import { filteredUtilizationStates, inventoryStates, utilizationStates } from 'collections/storage';
import { Notification } from 'components/UI';
import {
  Storage,
  StorageCreateShippingParams,
  StorageCreateStorageParams,
  StorageLot,
  StoragePart,
  StorageRelatedDoc,
  StorageRelatedQC,
  StorageSource,
  StorageStateOptions,
  StorageSummary,
  StorageType,
} from 'interfaces';
import { concat, get, map, pick, sum } from 'lodash-es';
import { action, computed, observable } from 'mobx';
import { ReactText } from 'react';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import uniqid from 'uniqid';
import api, { camelizeKeys, decamelizeKeys } from 'utils/axios';
import { openFile } from 'utils/hooks/useFileOpener';
import { XMoney } from 'utils/money';

class StorageStore {
  public rootStore: RootStore;

  @observable isFetching = false;
  @observable isSourcesFetching = false;
  @observable sources: StorageSource[] = [];
  @observable selectedSource: StorageSource = {} as StorageSource;
  @observable tempLots: StorageLot[] = [];
  @observable parts: StoragePart[] = [];
  @observable lots: StorageLot[] = [];
  @observable currentStorage: Storage = {} as Storage;
  @observable inventoryNoteHtml = '';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  stateOptions = (type: StorageType, testDeal: boolean): StorageStateOptions[] => {
    let states: { label: string; value: string; final: boolean }[] = [];

    if (type === StorageType.Inventory) states = inventoryStates;

    if (type === StorageType.Utilization) states = filteredUtilizationStates(testDeal);

    return map(states, (state) => pick(state, 'label', 'value'));
  };

  get sourceName() {
    return this.selectedSource.sourceName;
  }

  get dealId() {
    return this.currentStorage.dealId;
  }

  @computed get relatedDocs() {
    if (!this.currentStorage.id) return [];

    const documents = map(this.currentStorage.relatedDocuments, (doc) => this.buildRelatedObj(doc));

    const qcs = map(this.currentStorage.relatedQualityControls, (qc) => this.buildRelatedObj(qc));

    return concat(documents, ...qcs);
  }

  @computed get referringDocs() {
    if (!this.currentStorage.id) return [];

    const documents = map(this.currentStorage.referringDocuments, (doc) => this.buildRelatedObj(doc));

    const qcs = map(this.currentStorage.referringQualityControls, (qc) => this.buildRelatedObj(qc));

    return concat(documents, ...qcs);
  }

  private buildRelatedObj = (obj: StorageRelatedDoc | StorageRelatedQC) => ({
    label: obj.name,
    link: this.getLinkByType(obj.objectType, obj.objectId),
    canceled: obj.canceled,
  });

  public getLinkByType = (sourceType: string, sourceId: number): string => {
    switch (sourceType) {
      case 'Deal::Delivery':
        return routes.deliveryPath(this.dealId, sourceId);
      case 'Deal::QualityControl':
        return routes.editDealQualityControlPath(this.dealId, sourceId);
      case 'Deal::Storage':
        return routes.editStoragePath(this.dealId, sourceId);
      case 'Deal::Shipping':
        return routes.editShippingPath(this.dealId, sourceId);
      default:
        return '';
    }
  };

  @computed get summary(): StorageSummary {
    const totalQty = sum(map(this.lots, (l) => l.quantity));
    const totalCustomerPriceMoney = this.lots
      .reduce((acc, cur) => {
        const curSum = XMoney(cur.customerPriceMoney).mult(cur.quantity);

        acc = acc.plus(curSum);

        return acc;
      }, XMoney(0))
      .toIMoney();

    const totalProviderPriceMoney = this.lots
      .reduce((acc, cur) => {
        const curSum = XMoney(cur.providerPriceMoney).mult(cur.quantity);

        acc = acc.plus(curSum);

        return acc;
      }, XMoney(0))
      .toIMoney();

    const totalWeight = sum(map(this.lots, (l) => (l.weightKgPerPiece ?? 0) * l.quantity));

    return {
      positions: this.lots.length,
      count: totalQty,
      weight: totalWeight,
      totalCustomerPriceMoney,
      totalProviderPriceMoney,
    };
  }

  @computed get storageState(): string {
    const states = this.currentStorage.storageType === StorageType.Inventory ? inventoryStates : utilizationStates;

    const state = states.find((state) => state.value === this.currentStorage.processingState);

    return state?.label || '';
  }

  @action private setIsFetching = (value: boolean) => {
    this.isFetching = value;
  };

  @action setSelectedSource = (value: string) => {
    const source = this.sources.find((source: StorageSource) => source.sourceName === value);
    this.selectedSource = source || ({} as StorageSource);
  };

  @action private setSourcesFetching = (value: boolean) => {
    this.isSourcesFetching = value;
  };

  @action private setSources = (sources: StorageSource[]) => {
    this.sources = sources;
  };

  @action private setTempLots = (lots: StorageLot[]) => {
    this.tempLots = lots;
  };

  @action resetTempLots = () => {
    this.tempLots = [];
  };

  @action setLots = (lots: StorageLot[]) => {
    const lotsWithKeys = lots.map((lot) => ({ ...lot, _key: uniqid() }));

    this.lots = [...this.lots, ...lotsWithKeys];
  };

  @action setCurrentStorage = (storage: Storage) => {
    this.currentStorage = {
      ...storage,
      lots: [],
    };

    this.setLots(storage.lots);
  };

  @action setInventoryNoteHtml = (html: string) => {
    this.inventoryNoteHtml = html;
  };

  @action updateLot = (editedLot: StorageLot) => {
    this.lots = this.lots.map((lot) => {
      if (lot._key === editedLot._key) {
        return editedLot;
      }

      return lot;
    });
  };

  @action deleteLot = (deletedLot: StorageLot) => {
    this.lots = this.lots.filter((lot: StorageLot) => lot._key !== deletedLot._key);
  };

  @action reset = () => {
    this.parts = [];
    this.lots = [];
    this.tempLots = [];
    this.sources = [];
    this.selectedSource = {} as StorageSource;
    this.currentStorage = {} as Storage;
  };

  @action fetchLotSources = async (dealId: ReactText) => {
    this.setSourcesFetching(true);

    try {
      const response = await api.get<{ payload: StorageSource[] }>(routesApi.storageSourcesPath(Number(dealId)));
      const sources = response.data.payload;
      this.setSources(camelizeKeys(sources));

      return response;
    } catch (e: unknown) {
      console.error(e);

      return e;
    } finally {
      this.setSourcesFetching(false);
    }
  };

  @action fetchLots = async (dealId: ReactText) => {
    this.setIsFetching(true);
    try {
      const url = routesApi.storageSourcePositionsPath(
        dealId,
        this.selectedSource.sourceType,
        this.selectedSource.sourceId,
      );

      const response = await api.get<{ payload: StorageLot[] }>(url);
      const lots = response.data.payload;

      this.setTempLots(camelizeKeys(lots));

      return response;
    } catch (e: unknown) {
      console.error(e);

      return e;
    } finally {
      this.setIsFetching(false);
    }
  };

  @action fetch = async (dealId: ReactText, storageId: ReactText) => {
    this.reset();
    this.setIsFetching(true);

    try {
      const response = await api.get<{ payload: Storage }>(
        routesApi.dealStoragePath(Number(dealId), Number(storageId)),
      );
      const { payload } = response.data;

      this.setCurrentStorage(camelizeKeys(payload));

      return response;
    } catch (e: unknown) {
      const status = Number(get(e, 'response.status'));

      if (status === 404) {
        Notification.Error(`Can't find Storage with ID: ${storageId}`);
        this.rootStore.history.push(routes.newStoragePath(dealId));
      }

      return e;
    } finally {
      this.setIsFetching(false);
    }
  };

  @action fetchInventoryNote = async (storageId: number) => {
    this.setIsFetching(true);

    try {
      const response = await api.get<{ html: string }>(routesApi.storageInventoryNotePath(storageId));
      const { html } = response.data;

      // TODO: WTF camelize?
      this.setInventoryNoteHtml(camelizeKeys(html));

      return response;
    } catch (e: unknown) {
      return (e as { response?: unknown })?.response;
    } finally {
      this.setIsFetching(false);
    }
  };

  @action create = async (dealId: ReactText, params: any) => {
    this.setIsFetching(true);

    try {
      const response = await api.post<{ payload: string }>(
        routesApi.dealStoragesPath(Number(dealId)),
        decamelizeKeys(params),
      );
      const id = response.data.payload;

      this.rootStore.history.push(routes.editStoragePath(dealId, id));

      return response;
    } catch (e: unknown) {
      console.error(e);

      return e;
    } finally {
      this.setIsFetching(false);
    }
  };

  @action createShipping = async (params: StorageCreateShippingParams) => {
    this.setIsFetching(true);

    try {
      const decamelized = decamelizeKeys(params);
      const response = await api.post<{ payload: { shippingId: string } }>(
        routesApi.createShippingFromStoragePath(this.currentStorage.id),
        decamelized,
      );

      const { shippingId } = camelizeKeys(response.data.payload);
      const href = routes.editShippingPath(this.dealId, shippingId);
      this.rootStore.history.push(href);

      void openFile(routesApi.shippingDownloadInventoryNotePath(shippingId), { print: true });

      return response;
    } catch (e: unknown) {
      return (e as { response?: unknown })?.response;
    } finally {
      this.setIsFetching(false);
    }
  };

  @action createStorage = async (params: StorageCreateStorageParams) => {
    this.setIsFetching(true);

    try {
      const decamelized = decamelizeKeys(params);
      const response = await api.post(
        routesApi.dealStorageRepackPath(this.dealId, this.currentStorage.id),
        decamelized,
      );

      const repackedId = String(get(response, 'data.repacked_id'));
      const href = routes.editStoragePath(this.dealId, repackedId);
      this.rootStore.history.push(href);

      const inventoryNoteHref = routes.storageInventoryNotePath(repackedId);
      window.open(inventoryNoteHref);

      return response;
    } catch (e: unknown) {
      return (e as { response?: unknown })?.response;
    } finally {
      this.setIsFetching(false);
    }
  };

  @action createQC = async () => {
    this.setIsFetching(true);

    try {
      const response = await api.post<{ payload: { storage: Storage } }>(
        routesApi.createQCFromStoragePath(this.currentStorage.id),
      );

      const { storage } = camelizeKeys(response.data.payload);
      this.reset();
      this.setCurrentStorage(storage);

      return response;
    } catch (e: unknown) {
      return (e as { response?: unknown })?.response;
    } finally {
      this.setIsFetching(false);
    }
  };

  @action update = async (params: any) => {
    this.setIsFetching(true);

    try {
      const response = await api.patch<{ payload: Storage }>(
        routesApi.dealStoragePath(this.currentStorage.dealId, this.currentStorage.id),
        decamelizeKeys(params),
      );

      const { payload } = response.data;

      this.reset();
      this.setCurrentStorage(camelizeKeys(payload));

      return response;
    } catch (e: unknown) {
      console.error(e);

      return e;
    } finally {
      this.setIsFetching(false);
    }
  };

  @action destroy = async () => {
    this.setIsFetching(true);
    const { dealId } = this.currentStorage;

    try {
      const response = await api.delete(routesApi.dealStoragePath(dealId, this.currentStorage.id));

      this.reset();
      this.rootStore.history.push(routes.newStoragePath(dealId));

      return response;
    } catch (e: unknown) {
      console.error(e);

      return e;
    } finally {
      this.setIsFetching(false);
    }
  };
}

export default StorageStore;
