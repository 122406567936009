import { Spin as AntSpin } from 'antd';
import React from 'react';

import { SpinWrapper } from './styled';

type Size = 'small' | 'large' | 'default' | undefined;

interface Props {
  size?: Size;
  style?: React.CSSProperties;
}

const Spin: React.FC<Props> = ({ size = 'default', style }) => (
  <SpinWrapper>
    <AntSpin size={size} style={style} />
  </SpinWrapper>
);

export default Spin;
