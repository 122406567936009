import lookers from 'collections/lookers';
import { observer } from 'mobx-react-lite';
import { LookerPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

const ProductionController = observer(() => (
  <>
    <Route
      path={routes.lookers.production.daily.indicatorsPath()}
      exact
      render={(props) => <LookerPage {...props} url={lookers.production.daily.indicators} />}
    />

    <Route
      path={routes.lookers.production.warehouseOverviewPath()}
      exact
      render={(props) => <LookerPage {...props} url={lookers.production.warehouseOverview} />}
    />

    <Route
      path={routes.lookers.production.dfmDrawingsOverviewPath()}
      exact
      render={(props) => <LookerPage {...props} url={lookers.production.dfmDrawingsOverview} />}
    />

    <Route
      path={routes.lookers.production.pm.workloadPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="PM Workload | Production | ERP" url={lookers.production.pm.workload} />
      )}
    />

    <Route
      path={routes.lookers.production.differenceAnalysis()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="RF Difference analysis | Production | ERP"
          url={lookers.production.differenceAnalysis}
        />
      )}
    />

    <Route
      path={routes.lookers.production.po.noHsPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="POs without HS code | Production | ERP" url={lookers.production.po.noHs} />
      )}
    />

    <Route
      path={routes.lookers.production.jobs.listPath()}
      exact
      render={(props) => <LookerPage {...props} title="Jobs | Production | ERP" url={lookers.production.jobs.list} />}
    />

    <Route
      path={routes.lookers.production.jobs.offers.allPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Job Offers | Production | ERP"
          url={lookers.production.jobs.offers.navigationAll}
        />
      )}
    />

    <Route
      path={routes.lookers.production.productionOrdersPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Production Orders | Production | ERP" url={lookers.production.productionOrders} />
      )}
    />

    <Route
      path={routes.lookers.production.jobs.offers.decisionNeededPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Decision Needed | Job Offers | Production | ERP"
          url={`${lookers.production.jobs.offers.decisionNeeded}?Partner%20Response=application,counteroffer&Xometry%20Decision=pending`}
        />
      )}
    />

    <Route
      path={routes.lookers.production.jobs.generalJobBoardPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="General Job Borad | Job Offers | Production | ERP "
          url={lookers.production.jobs.generalBoard}
        />
      )}
    />

    <Route
      path={routes.lookers.production.jobs.lackOfGraphic()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Lack of Graphic / Non Graphic In Preview | Job Offers | Production | ERP "
          url={lookers.production.jobs.lackOfGraphic}
        />
      )}
    />

    <Route
      path={routes.lookers.production.jobs.infoPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Jobs Info | Jobs | Production | ERP " url={lookers.production.jobs.info} />
      )}
    />
  </>
));

export default ProductionController;
