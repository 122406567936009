import { gql } from 'apollo-boost';

export const SHIPPING_ADDRESS_FRAGMENT = gql`
  fragment shippingAddressFragment on ShippingAddress {
    id
    sourceType
    sourceId
    companyName
    contactName
    contactPhone
    country
    zip
    city
    address
    comment
    internalComment
  }
`;
