import React, { FC, ReactElement, ReactNode } from 'react';

interface Props {
  condition: boolean | undefined | null;
  children: ReactNode;
}

const If: FC<Props> = ({ condition, children }): ReactElement | null => {
  if (!condition) return null;

  return <>{children}</>;
};

export default If;
