import { OmsInputsMessengerTemplatedMessageType, TemplateEntryPointEnum } from '__generated__/types';
import { TEventBusEventCallback } from '@xometry/event-bus';
import {
  EMessengerChatSourceType,
  EMessengerEventBusEvents,
  IEventBusMessengerEvents,
  MessengerEventBus,
} from '@xometry/ui';
import { FormInstance } from 'antd';
import Notification from 'components/UI/Notification';
import { AppContext } from 'contexts/AppContext';
import { useCallback, useContext, useEffect } from 'react';
import { useStore } from 'stores/RootStore';
import { ESideOverlayType } from 'stores/SideOverlay/SideOverlayStore';
import { TemplateFragment } from 'utils/graphql/fragments/__generated__/template';
import { useMessageCreateFromTemplateMutation } from 'utils/graphql/mutations/__generated__/messageCreateFromTemplate';
import { useTemplateModalQuery } from 'utils/graphql/queries/__generated__/templateModal';
import { useMessengerEventBus } from 'utils/hooks/useMessengerEventBus';

import { TemplateModalFormValues } from './TemplateModal.types';

export const useTemplatesModal = ({
  form,
  selectedTemplate,
}: {
  form: FormInstance<TemplateModalFormValues>;
  selectedTemplate: Omit<TemplateFragment, 'content'> | null;
}) => {
  const { messengerEventBus } = useContext(AppContext);
  const { sendEvent } = useMessengerEventBus();
  const { sideOverlayStore, templatesModalStore } = useStore();

  const { data: templateModalData } = useTemplateModalQuery({
    variables: {
      ...(templatesModalStore.categoriesRequestParams as NonNullable<
        typeof templatesModalStore.categoriesRequestParams
      >),
    },
    fetchPolicy: 'network-only',
    skip: !templatesModalStore.isOpen || !templatesModalStore.categoriesRequestParams,
  });

  const [createMessageFromTemplate] = useMessageCreateFromTemplateMutation();

  /** EventBus event handler */
  const handleOpenTemplatesModal: TEventBusEventCallback<
    IEventBusMessengerEvents,
    EMessengerEventBusEvents.OPEN_TEMPLATES_MODAL
  > = useCallback(
    ({ chatType, sourceId, sourceType, isVisibleToClient, isVisibleToPartner }) => {
      let entryPoint: TemplateEntryPointEnum | null = null;

      if (chatType === 'document') {
        entryPoint = TemplateEntryPointEnum.DocumentMessenger;
      } else if (chatType === 'general') {
        if (sourceType === EMessengerChatSourceType.CUSTOMER) {
          entryPoint = TemplateEntryPointEnum.GeneralMessengerPerson;
        } else if (sourceType === EMessengerChatSourceType.PARTNER) {
          entryPoint = TemplateEntryPointEnum.GeneralMessengerProvider;
        }
      }

      if (!entryPoint) {
        console.error('Unable to open templates modal');

        return;
      }

      templatesModalStore.openTemplatesModal({
        entryPoint,
        messageSourceForTemplates: { sourceId, sourceType },
        isVisibleToClient,
        isVisibleToPartner,
      });
    },
    [templatesModalStore],
  );

  const handleCloseTemplatesModal = () => {
    templatesModalStore.closeTemplatesModal();

    form.resetFields();
  };

  const handleSendMessage = () => {
    const formValues = form.getFieldsValue();
    const { messageSourceForMessage: messageSource, messageVisibilityAttributes } = templatesModalStore;

    if (!selectedTemplate || !messageSource || !messageVisibilityAttributes) {
      return;
    }

    const attributes: Required<OmsInputsMessengerTemplatedMessageType> = {
      content: formValues.content,
      mentions: formValues.mentions,
      sourceId: messageSource.sourceId,
      sourceType: messageSource.sourceType,
      isVisibleToClient: messageVisibilityAttributes.isVisibleToClient,
      isVisibleToPartner: messageVisibilityAttributes.isVisibleToPartner,
      to: formValues.to,
      cc: formValues.cc,
      bcc: formValues.bcc,
      attachmentIds: formValues.attachmentIds,
    };

    void createMessageFromTemplate({ variables: { input: { attributes, templateId: selectedTemplate.id } } }).then(
      () => {
        if (sideOverlayStore.context.type == ESideOverlayType.Messenger) {
          sendEvent?.(EMessengerEventBusEvents.REFETCH_MESSAGES, {
            sourceId: messageSource.sourceId,
            sourceType: messageSource.sourceType,
          });
        }

        Notification.Success('Message has been successfully sent from template.');

        handleCloseTemplatesModal();
      },
    );
  };

  const addEventListeners = useCallback(
    (eb: MessengerEventBus) => {
      eb.addEventListener(EMessengerEventBusEvents.OPEN_TEMPLATES_MODAL, handleOpenTemplatesModal);
    },
    [handleOpenTemplatesModal],
  );

  const removeEventListeners = useCallback(
    (eb: MessengerEventBus) => {
      eb.removeEventListener(EMessengerEventBusEvents.OPEN_TEMPLATES_MODAL, handleOpenTemplatesModal);
    },
    [handleOpenTemplatesModal],
  );

  useEffect(() => {
    if (messengerEventBus) {
      addEventListeners(messengerEventBus);

      return () => {
        removeEventListeners(messengerEventBus);
      };
    }
  }, [addEventListeners, messengerEventBus, removeEventListeners]);

  return {
    handleCloseTemplatesModal,
    handleSendMessage,
    templateModalData,
    templatesModalVisibility: templatesModalStore.isOpen,
    isVisibleToClient: Boolean(templatesModalStore.messageVisibilityAttributes?.isVisibleToClient),
    isVisibleToPartner: Boolean(templatesModalStore.messageVisibilityAttributes?.isVisibleToPartner),
  };
};
