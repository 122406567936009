import ProviderController from 'components/Partner/ProviderController';
import { ProviderNewPage } from 'pages';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

const PartnersController = () => (
  <Switch>
    <Route path={`${routes.providersPath()}/new`} component={ProviderNewPage} />

    <Route path={routes.providerPath(':providerId')} component={ProviderController} />
  </Switch>
);

export default PartnersController;
