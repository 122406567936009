import { EyeOutlined } from '@ant-design/icons';
import { LocaleIcon, TagView } from '@xometry/ui';
import { Alert, Button, Checkbox, Form, Input, Popconfirm, Row, Select, SelectProps, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import clsx from 'clsx';
import { DIRECTION_OPTIONS } from 'components/TemplatesPage/utils';
import { Button as DeleteButton, Modal } from 'components/UI';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { LOCALES_LIST } from './TemplatesManagerModal.const';
import s from './TemplatesManagerModal.module.less';
import { TemplateModalFormFieldEnum } from './TemplatesManagerModal.types';
import { useTemplatesManagerModal } from './useTemplatesManagerModal';
import { useTemplatesPlaceholders } from './useTemplatesPlaceholders';
import { getContentRules, toRules } from './utils';

const renderEmailTag: SelectProps['tagRender'] = ({ onClose, value }) => (
  <TagView key={String(value)} onCrossClick={onClose}>
    {value}
  </TagView>
);

const TemplatesManagerModalComponent: React.FC = () => {
  const {
    form,
    buttonName,
    createdTitle,
    updatedTitle,
    categoryOptions,
    contentVariableOptions,
    emailsVariableOptions,
    relatedTypeOptions,
    selectedLocale,
    isOpen,
    readOnly,
    hasContent,
    isCreateModal,
    isCustom,
    isERPTemplates,
    isWhatsApp,
    shareChecked,
    relatedTypeDisabled,
    handleCategoryChange,
    handleRelatedTypeChange,
    handleTemplateNameChange,
    handleDirectionChange,
    handleEmails,
    handleLocaleChange,
    handleSubjectChange,
    handleContentChange,
    handleChangeShared,
    handleCloseTemplatesModal,
    handleCreateOrUpdateTemplate,
    handleDeleteTemplateGroup,
  } = useTemplatesManagerModal();

  const { setRef, handleSelectChange, updateCursorPosition } = useTemplatesPlaceholders({
    form,
    handleContentChange,
    handleSubjectChange,
  });

  return (
    <Modal
      title="New Template"
      open={isOpen}
      onCancel={handleCloseTemplatesModal}
      width={1000}
      footer={
        <div className={s.footer}>
          <Popconfirm
            title="Are you sure?"
            onConfirm={handleDeleteTemplateGroup}
            okButtonProps={{ size: 'middle' }}
            cancelButtonProps={{ size: 'middle' }}
            okText="Yes, Delete"
            cancelText="Cancel"
          >
            <DeleteButton className={clsx(s.deleteButton, { [s.hide]: isCreateModal || readOnly })} key="delete" danger>
              Delete
            </DeleteButton>
          </Popconfirm>
          {isCustom ? (
            <Checkbox className={s.shared} checked={shareChecked} onChange={handleChangeShared} disabled={readOnly}>
              Share template
            </Checkbox>
          ) : null}
          <Button className={s.previewButton} key="preview" icon={<EyeOutlined className={s.eyeOutlined} />} />
          <Button className={s.cancelButton} key="cancel" onClick={handleCloseTemplatesModal}>
            Cancel
          </Button>
          <Button key="create" type="primary" onClick={handleCreateOrUpdateTemplate} disabled={readOnly}>
            {buttonName}
          </Button>
        </div>
      }
      destroyOnClose
      centered
    >
      <Form form={form} requiredMark={false}>
        {readOnly ? (
          <Alert
            className={s.alert}
            // TODO: add username to description
            description="This is a shared template. You are not able to make any changes or delete it."
            type="warning"
            showIcon
          />
        ) : null}
        <div className={s.selectRow}>
          <Form.Item
            className={s.selectItem}
            name={TemplateModalFormFieldEnum.Category}
            rules={[{ required: true, message: 'Template category is required' }]}
            label="Template category:"
          >
            <Select
              placeholder="Select"
              onChange={handleCategoryChange}
              getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
              options={categoryOptions}
              disabled={readOnly}
            />
          </Form.Item>
          <Form.Item
            className={s.selectItem}
            name={TemplateModalFormFieldEnum.RelatedType}
            rules={[{ required: !relatedTypeDisabled, message: 'Related document is required' }]}
            label="Related Document:"
          >
            <Select
              placeholder="Select"
              onChange={handleRelatedTypeChange}
              getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
              options={relatedTypeOptions}
              disabled={readOnly || relatedTypeDisabled}
            />
          </Form.Item>
          <Form.Item
            className={s.selectItem}
            name={TemplateModalFormFieldEnum.TemplateName}
            rules={[{ required: true, message: 'Template name is required' }]}
            label="Template name:"
          >
            <Input placeholder="Type template name" onChange={handleTemplateNameChange} disabled={readOnly} />
          </Form.Item>
          <Form.Item
            className={s.selectItem}
            name={TemplateModalFormFieldEnum.Direction}
            rules={[{ required: true, message: 'Direction is required' }]}
            label="Direction:"
          >
            <Select
              placeholder="Select"
              onChange={handleDirectionChange}
              getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
              options={DIRECTION_OPTIONS}
              disabled={readOnly}
            />
          </Form.Item>
        </div>
        <div className={s.locales}>
          <Form.Item name={TemplateModalFormFieldEnum.Locale}>
            <div className={s.localesTabs}>
              {LOCALES_LIST.map((locale) => {
                const isActive = locale === selectedLocale;
                const unfilled = !isActive && !hasContent(locale);

                return (
                  <Button
                    key={locale}
                    className={clsx({ [s.isActive]: isActive })}
                    block
                    onClick={() => handleLocaleChange(locale)}
                  >
                    <LocaleIcon unfilled={unfilled} locale={locale} />
                    {locale}
                  </Button>
                );
              })}
            </div>
          </Form.Item>
          {isERPTemplates ? (
            <div className={s.subject}>
              <Form.Item
                className={clsx(s.localesItem, s.localesSubject)}
                name={TemplateModalFormFieldEnum.Subject}
                rules={getContentRules(TemplateModalFormFieldEnum.Subject)}
                label="Message title:"
              >
                <Input placeholder="Goes to email subject" onChange={handleSubjectChange} />
              </Form.Item>
              <Select
                value="Add variable"
                className={s.subjectPlaceholders}
                popupClassName={s.selectDropdown}
                options={contentVariableOptions}
                onChange={handleSelectChange(TemplateModalFormFieldEnum.Subject)}
                getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
                placement="bottomRight"
                bordered={false}
              />
            </div>
          ) : null}
          <Form.Item
            className={clsx(s.localesItem, s.localesContent)}
            rules={getContentRules(TemplateModalFormFieldEnum.Content)}
            name={TemplateModalFormFieldEnum.Content}
            label="Main content:"
          >
            <TextArea
              ref={setRef}
              placeholder="Message content here"
              rows={9}
              onChange={(e) => {
                handleContentChange(e);
                updateCursorPosition(e);
              }}
            />
          </Form.Item>
          {isERPTemplates || isWhatsApp ? (
            <Row className={s.contentPlaceholders}>
              <Select
                value="Add variable to the content"
                popupClassName={s.selectDropdown}
                options={contentVariableOptions}
                onChange={handleSelectChange(TemplateModalFormFieldEnum.Content)}
                getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
                placement="topLeft"
                bordered={false}
              />
            </Row>
          ) : null}
        </div>
        {isERPTemplates ? (
          <div className={s.emails}>
            <Form.Item className={s.emailsItem} rules={toRules} name={TemplateModalFormFieldEnum.To} label="To:">
              <Select
                allowClear
                className={s.emailsSelector}
                popupClassName={s.selectDropdown}
                tagRender={renderEmailTag}
                options={emailsVariableOptions}
                onChange={handleEmails(TemplateModalFormFieldEnum.To)}
                getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
                mode="tags"
                disabled={readOnly}
              />
            </Form.Item>
            <Form.Item className={s.emailsItem} name={TemplateModalFormFieldEnum.Cc} label="CC:">
              <Select
                allowClear
                className={s.emailsSelector}
                popupClassName={s.selectDropdown}
                tagRender={renderEmailTag}
                options={emailsVariableOptions}
                onChange={handleEmails(TemplateModalFormFieldEnum.Cc)}
                getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
                mode="tags"
                disabled={readOnly}
              />
            </Form.Item>
            <Form.Item className={s.emailsItem} name={TemplateModalFormFieldEnum.Bcc} label="BCC:">
              <Select
                allowClear
                className={s.emailsSelector}
                popupClassName={s.selectDropdown}
                tagRender={renderEmailTag}
                options={emailsVariableOptions}
                onChange={handleEmails(TemplateModalFormFieldEnum.Bcc)}
                getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
                mode="tags"
                disabled={readOnly}
              />
            </Form.Item>
          </div>
        ) : null}
        <Typography.Text className={s.created} type="secondary" italic>
          {createdTitle} {updatedTitle}
        </Typography.Text>
      </Form>
    </Modal>
  );
};

export const TemplatesManagerModal = observer(TemplatesManagerModalComponent);
