import { CurrencyEnum } from '__generated__/types';
import { RegionData, RegionLocales, Regions } from 'config/regions/types';
import { ENV_APP_HOST_TR, ENV_CUSTOMERS_APP_HOST_TR, ENV_PARTNERS_APP_HOST_TR } from 'constants/env';

import { ReactComponent as Icon } from './assets/tr.svg';

export const TRRegionConfig: RegionData<Regions.TR> = {
  region: Regions.TR,
  orgName: 'Xometry Türkiye',
  name: 'Türkiye',
  shortName: 'TR',
  locale: RegionLocales.TR,
  currency: CurrencyEnum.Try,
  icon: Icon,
  host: ENV_APP_HOST_TR,
  customersHost: ENV_CUSTOMERS_APP_HOST_TR,
  partnersHost: ENV_PARTNERS_APP_HOST_TR,
  headerColor: '#072F4F',
};
