import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

import CrmController from './CrmController';
import { DrawingsController } from './DrawingsController';
import FinanceController from './FinanceController';
import LogisticsController from './LogisticsController';
import PartnersController from './PartnersController';
import ProductionController from './ProductionController';
import SalesController from './SalesController';

const LookersController = observer(() => (
  <>
    <Route path={routes.lookers.crm.rootPath()} component={CrmController} />

    <Route path={routes.lookers.sales.rootPath()} component={SalesController} />

    <Route path={routes.lookers.logistics.rootPath()} component={LogisticsController} />

    <Route path={routes.lookers.finance.rootPath()} component={FinanceController} />

    <Route path={routes.lookers.production.rootPath()} component={ProductionController} />

    <Route path={routes.lookers.partners.rootPath()} component={PartnersController} />

    <Route path={routes.lookers.drawings.rootPath()} component={DrawingsController} />
  </>
));

export default LookersController;
