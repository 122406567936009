import { Checkbox } from 'antd';
import { ColIndexMap, Column, IDataItem } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { map, some } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useStore } from 'stores/RootStore';

import ExpansionButton from './ExpansionButton';
import RawTableCell from './RawTableCell';
import TableCell from './TableCell';

const OFFSET_TOP = 134; // page header + table header

const scrollToElement = (elementTop: number, elementHeight: number, container?: HTMLElement | null) => {
  if (container == null) return;

  const containerTop = container.scrollTop;
  const containerHeight = container.offsetHeight - 34; // 34px - table header height

  const offset = elementTop - containerTop;
  const elementBottom = offset + elementHeight;

  // Cursor moved up, above the visible part of screen
  if (offset < 0) {
    container.scrollTo({ top: elementTop });

    return;
  }

  // Cursor moved up from first to last record
  if (offset > containerHeight) {
    container.scrollTo({ top: container.scrollHeight - containerHeight });

    return;
  }

  if (elementBottom > containerHeight) {
    if (elementHeight > containerHeight) {
      // Element doesn't fit into container, show as much as possible
      container.scrollTo({ top: elementTop });
    } else {
      // Cursor moved down below the visible part of screen
      const bottomOffset = elementBottom - containerHeight;
      container.scrollBy({ top: bottomOffset + 10 }); // add little space on page bottom
    }
  }
};

interface Props {
  colIndexMap: ColIndexMap;
  colIndexOffset: number;
  columns: Column[];
  dataSource: IDataItem[];
  expandRows?: boolean;
  hideRowNumbers?: boolean;
  rowExpansion?: React.ReactNode;
  showCheckboxes?: boolean;
  selectedRows?: IDataItem[];
  tableRef: RefObject<HTMLDivElement>;
  disabledRows?: string[];
  onCurrentRowChange?: (record: IDataItem) => void;
  onRowSelect?: (record: IDataItem) => void;
  onRowDeselect?: (record: IDataItem) => void;
}

const TableBody = observer(
  ({
    colIndexMap,
    colIndexOffset,
    columns,
    dataSource,
    expandRows,
    hideRowNumbers,
    rowExpansion,
    showCheckboxes,
    selectedRows,
    tableRef,
    disabledRows = [],
    onCurrentRowChange,
    onRowSelect,
    onRowDeselect,
  }: Props) => {
    const { sideOverlayStore } = useStore();
    const [currentRow, setCurrentRowValue] = useState<{ index: number; item: IDataItem }>();
    const [currentRowExpanded, setCurrentRowExpanded] = useState(false);
    const rowsRef = useRef<HTMLDivElement[]>(new Array<HTMLDivElement>());
    const rowExpansionRef = useRef<HTMLDivElement>(null);
    const disabledRowsSet = new Set(disabledRows);

    const setCurrentRow = useCallback(
      ({ item, index, expand = false }: { item: IDataItem; index: number; expand?: boolean }) => {
        setCurrentRowValue({ item, index });
        setCurrentRowExpanded(expand);
        if (onCurrentRowChange != null) onCurrentRowChange(item);
      },
      [onCurrentRowChange],
    );

    const toggleCurrentRowExpanded = () => setCurrentRowExpanded((prev) => !prev);

    const handleRowClick = (item: IDataItem, index: number) =>
      currentRow?.index === index ? toggleCurrentRowExpanded() : setCurrentRow({ item, index, expand: true });

    const selectedRowKeys = map(selectedRows, 'key');

    // Scroll table if row expansion doesn't fit into screen
    useLayoutEffect(() => {
      if (currentRow == null || rowExpansionRef.current == null || !currentRowExpanded) return;

      const rowEl = rowsRef.current[currentRow.index];
      const elementTop = rowEl.offsetTop - OFFSET_TOP;
      const elementHeight = rowEl.offsetHeight + rowExpansionRef.current.offsetHeight;
      scrollToElement(elementTop, elementHeight, tableRef.current);
    }, [currentRow, currentRowExpanded, tableRef]);

    useEffect(() => {
      const updateRow = (index: number) => {
        const dataItem = dataSource[index];
        setCurrentRow({ index, item: dataItem });

        const rowEl = rowsRef.current[index];
        const elementTop = rowEl.offsetTop - OFFSET_TOP;
        const elementHeight = rowEl.offsetHeight;
        scrollToElement(elementTop, elementHeight, tableRef.current);
      };

      const moveDown = () => {
        let newIndex = (currentRow?.index ?? -1) + 1;

        if (newIndex > dataSource.length - 1) newIndex = 0;

        updateRow(newIndex);
      };

      const moveUp = () => {
        let newIndex = (currentRow?.index ?? dataSource.length) - 1;

        if (newIndex < 0) newIndex = dataSource.length - 1;

        updateRow(newIndex);
      };

      const keyDownHandler = (e: KeyboardEvent) => {
        if (e.target instanceof HTMLInputElement || sideOverlayStore.isOpen) return;

        // eslint-disable-next-line default-case
        switch (e.key) {
          case 'ArrowUp':
            e.preventDefault();
            if (!e.repeat) moveUp();

            break;
          case 'ArrowDown':
            e.preventDefault();
            if (!e.repeat) moveDown();

            break;
          case 'Tab':
            e.preventDefault();
            if (!e.repeat) e.shiftKey ? moveUp() : moveDown();

            break;
          case ' ':
            if (!expandRows || currentRow == null) break;

            e.preventDefault();
            toggleCurrentRowExpanded();
            break;
          case 'Enter':
            if (!showCheckboxes || onRowSelect == null || onRowDeselect == null) break;

            e.preventDefault();

            if (!e.repeat && currentRow != null) {
              if (some(selectedRows, { key: currentRow.item.key })) {
                onRowDeselect(currentRow.item);
              } else {
                onRowSelect(currentRow.item);
              }
            }

            break;
        }
      };

      document.addEventListener('keydown', keyDownHandler);

      return () => document.removeEventListener('keydown', keyDownHandler);
    }, [
      currentRow,
      currentRow?.index,
      dataSource,
      expandRows,
      onRowDeselect,
      onRowSelect,
      selectedRows,
      setCurrentRow,
      showCheckboxes,
      sideOverlayStore.isOpen,
      tableRef,
    ]);

    return (
      <>
        {map(dataSource, (item, recordIndex) => {
          const isCurrentRow = recordIndex === currentRow?.index;

          let rowIndexOffset = 2;

          if (rowExpansion != null && currentRow != null && currentRow.index < recordIndex) {
            rowIndexOffset += 1;
          }

          const gridRowIndex = recordIndex + rowIndexOffset;
          const rowClass = currentRowExpanded && isCurrentRow ? 'expanded' : '';
          const disabled = disabledRowsSet.has(item.key);

          return (
            <React.Fragment key={item.key}>
              <div className={`table-row ${rowClass}`}>
                {showCheckboxes && selectedRows != null && onRowSelect != null && onRowDeselect != null && (
                  <RawTableCell
                    align="center"
                    colIndex={colIndexMap.checkbox}
                    justify="center"
                    recordIndex={recordIndex}
                    rowIndex={gridRowIndex}
                    selected={isCurrentRow}
                  >
                    <Checkbox
                      checked={disabled || selectedRowKeys.includes(item.key)}
                      disabled={disabled}
                      onChange={(e) => (e.target.checked ? onRowSelect(item) : onRowDeselect(item))}
                    />
                  </RawTableCell>
                )}

                {hideRowNumbers || (
                  <RawTableCell
                    align="center"
                    colIndex={colIndexMap.rowNumber}
                    justify="flex-end"
                    recordIndex={recordIndex}
                    rowIndex={gridRowIndex}
                    selected={isCurrentRow}
                  >
                    {recordIndex + 1}
                  </RawTableCell>
                )}
                {expandRows && (
                  <RawTableCell
                    align="center"
                    colIndex={colIndexMap.expandButton}
                    justify="center"
                    recordIndex={recordIndex}
                    rowIndex={gridRowIndex}
                    selected={isCurrentRow}
                  >
                    <ExpansionButton
                      expanded={isCurrentRow && currentRowExpanded}
                      onCollapse={() => setCurrentRowExpanded(false)}
                      onExpand={() => setCurrentRow({ item, index: recordIndex, expand: true })}
                    />
                  </RawTableCell>
                )}

                {map(columns, (column, colIndex) => (
                  <TableCell
                    key={item.key + column.key}
                    cellRef={colIndex === 0 ? (element) => element && rowsRef.current.push(element) : undefined}
                    colIndex={colIndex + colIndexOffset}
                    column={column}
                    item={item}
                    recordIndex={recordIndex}
                    rowIndex={gridRowIndex}
                    selected={isCurrentRow}
                    onClick={() => handleRowClick(item, recordIndex)}
                  />
                ))}
              </div>

              {isCurrentRow && currentRowExpanded && rowExpansion != null && (
                <div
                  className="row-expansion-container"
                  ref={rowExpansionRef}
                  style={{
                    gridRow: gridRowIndex + 1,
                    gridColumn: `1 / ${columns.length + colIndexOffset + 1}`,
                  }}
                >
                  {rowExpansion}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  },
);

export default TableBody;
