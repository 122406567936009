import { gql } from 'apollo-boost';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';

export const CONTACT_FRAGMENT = gql`
  fragment contactFragment on Contact {
    id
    firstName
    lastName
    email
    position
    phone
    viber
    whatsapp
    telegram
    wechat
    preferredContactType
    openPassword
    disallowToPool
    verifiedAt
    verifyKey
  }
`;

const PROVIDER_FRAGMENT_CONFIG: Record<Regions, ReturnType<typeof gql>> = {
  [Regions.EU]: gql`
    fragment providerFragment on Provider {
      id
      state
      publicComment
      primaryContactId
      name
      legalName
      email
      vatId
      notVatPayer
      datevKonto
      phone
      website
      pincode
      locale
      bankAccount
      bankAccountHolderName
      bankCountry
      bankName
      bankCode
      isEuropean
      lastVisitAt
      createdAt
      poNumberLimit
      poNumberLimitFreezeUntil
      poValueLimit
      poValueLimitFreezeUntil
      limitsComment
      payoutTransferType
      acceptSelfBilling
      salesforceId
      partnerNetworkManagerFreezeUntil
      testPartner
      partnerNetworkManager {
        id
        name
      }
      productionHoliday
      publicHoliday
      allowAutoacceptOffers
      allowAutoacceptOffersFreezeUntil
      allowDirectshipOrders
      allowDirectshipOrdersFreezeUntil
      autoacceptPoNumberLimit
      autoacceptPoNumberLimitFreezeUntil
      autoacceptPoValueLimit
      autoacceptPoValueLimitFreezeUntil
      freeCapacity
      freeCapacityFreezeUntil
      onImprovement
      onImprovementComment
      onImprovementFreezeUntil
      onImprovementStartedAt
      performance {
        totalOrdersCount
        totalOrdersValue
        ordersInProductionCount
        ordersInProductionValue
        ordersBePaidCount
        ordersBePaidValue
        ordersPaidCount
        ordersPaidValue
      }

      rank {
        total
        timing
        overdueRatio
        quality
        packing
        communication
        poQuantity
        qualityRatio
        cancelRatio
        delayedProviderOrdersQuantity
      }
      tags {
        id
        name
        context
        approved
      }
      billingAddress {
        city
        country
        zip
        address
      }
      shippingAddress {
        city
        country
        zip
        address
      }
      contacts {
        ...contactFragment
      }
    }
    ${CONTACT_FRAGMENT}
  `,
  [Regions.UK]: gql`
    fragment providerFragment on Provider {
      id
      state
      publicComment
      primaryContactId
      name
      legalName
      email
      vatId
      intacctId
      phone
      website
      pincode
      locale
      bankAccount
      bankAccountHolderName
      bankCountry
      bankName
      bankCode
      isEuropean
      lastVisitAt
      createdAt
      poNumberLimit
      poNumberLimitFreezeUntil
      poValueLimit
      poValueLimitFreezeUntil
      limitsComment
      payoutTransferType
      acceptSelfBilling
      salesforceId
      partnerNetworkManagerFreezeUntil
      testPartner
      partnerNetworkManager {
        id
        name
      }
      productionHoliday
      publicHoliday
      allowAutoacceptOffers
      allowAutoacceptOffersFreezeUntil
      allowDirectshipOrders
      allowDirectshipOrdersFreezeUntil
      autoacceptPoNumberLimit
      autoacceptPoNumberLimitFreezeUntil
      autoacceptPoValueLimit
      autoacceptPoValueLimitFreezeUntil
      freeCapacity
      freeCapacityFreezeUntil
      onImprovement
      onImprovementComment
      onImprovementFreezeUntil
      onImprovementStartedAt
      performance {
        totalOrdersCount
        totalOrdersValue
        ordersInProductionCount
        ordersInProductionValue
        ordersBePaidCount
        ordersBePaidValue
        ordersPaidCount
        ordersPaidValue
      }

      rank {
        total
        timing
        overdueRatio
        quality
        packing
        communication
        poQuantity
        qualityRatio
        cancelRatio
        delayedProviderOrdersQuantity
      }
      tags {
        id
        name
        context
        approved
      }
      billingAddress {
        city
        country
        zip
        address
      }
      shippingAddress {
        city
        country
        zip
        address
      }
      contacts {
        ...contactFragment
      }
    }
    ${CONTACT_FRAGMENT}
  `,
  [Regions.TR]: gql`
    fragment providerFragment on Provider {
      id
      state
      publicComment
      primaryContactId
      currency
      name
      legalName
      email
      vatId
      intacctId
      phone
      website
      pincode
      locale
      bankAccount
      bankAccountHolderName
      bankCountry
      bankName
      bankCode
      isEuropean
      lastVisitAt
      createdAt
      poNumberLimit
      poNumberLimitFreezeUntil
      poValueLimit
      poValueLimitFreezeUntil
      limitsComment
      payoutTransferType
      acceptSelfBilling
      salesforceId
      partnerNetworkManagerFreezeUntil
      testPartner
      partnerNetworkManager {
        id
        name
      }
      productionHoliday
      publicHoliday
      allowAutoacceptOffers
      allowAutoacceptOffersFreezeUntil
      allowDirectshipOrders
      allowDirectshipOrdersFreezeUntil
      autoacceptPoNumberLimit
      autoacceptPoNumberLimitFreezeUntil
      autoacceptPoValueLimit
      autoacceptPoValueLimitFreezeUntil
      freeCapacity
      freeCapacityFreezeUntil
      onImprovement
      onImprovementComment
      onImprovementFreezeUntil
      onImprovementStartedAt
      performance {
        totalOrdersCount
        totalOrdersValue
        ordersInProductionCount
        ordersInProductionValue
        ordersBePaidCount
        ordersBePaidValue
        ordersPaidCount
        ordersPaidValue
      }

      rank {
        total
        timing
        overdueRatio
        quality
        packing
        communication
        poQuantity
        qualityRatio
        cancelRatio
        delayedProviderOrdersQuantity
      }
      tags {
        id
        name
        context
        approved
      }
      billingAddress {
        city
        country
        zip
        address
      }
      shippingAddress {
        city
        country
        zip
        address
      }
      contacts {
        ...contactFragment
      }
    }
    ${CONTACT_FRAGMENT}
  `,
};

export const PROVIDER_FRAGMENT = PROVIDER_FRAGMENT_CONFIG[CURRENT_REGION];
