import { gql } from 'apollo-boost';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';

const INTERCOMPANY_OBJECT_FRAGMENT = gql`
  fragment intercompanyObjectPerson on IntercompanyObject {
    createdAt
    objectId
    objectType
    id
    foreignObjectId
    foreignObjectType
    foreignRegion
    foreignDealId
    updatedAt
    role
  }
`;

const PERSON_FRAGMENT_CONFIG: Record<Regions, ReturnType<typeof gql>> = {
  [Regions.EU]: gql`
    fragment personFragment on Person {
      id
      createdAt
      updatedAt
      deletedAt
      deleted
      pipedriveId
      pipedriveUrl
      externalCrmId
      externalCrmUrl
      name
      email
      carbonCopyEmails
      invoiceEmails
      logisticEmails
      ocEmails
      paymentReminderEmails
      technicalEmails
      locale
      phone
      mobile
      whatsappNumber
      notes
      jobTitle
      birthday
      nps
      mailingsSubscription
      mailingSuspendedTill
      isRobot
      inLoyaltyProgram
      movedTo {
        foreignObjectId
      }
      movedToRegion
      tpoEnabled

      owner {
        id
        name
      }

      contact {
        id
        email
        active
        createdAt
        verifiedAt
        lastVisitedAt
      }

      defaultBillingAccount {
        creditLimitMoney {
          amount
          amountCents
          currencyCode
        }
        id
        type
        accountType
        datevId
        deletedAt
        name
        country
        zip
        city
        address
        registeredAt
        vatId
        taxId
        internalComment
        vatRegistered
        vatRegistrationCountry
        vatValidationStatus
      }

      billingAccounts {
        creditLimitMoney {
          amount
          amountCents
          currencyCode
        }
        id
        type
        accountType
        datevId
        deletedAt
        name
        country
        zip
        city
        address
        registeredAt
        vatId
        taxId
        internalComment
        vatRegistered
        vatRegistrationCountry
        vatValidationStatus
      }

      defaultShippingAddress {
        id
        sourceType
        sourceId
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      shippingAddresses {
        id
        sourceType
        sourceId
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      organization {
        id
        isImportant
        name
        createdAt
        deletedAt
        pipedriveId
        pipedriveUrl
        customerOrderGrade
        defaultShippingAddressId
        notes

        productionManager {
          id
          name
        }

        kam {
          id
          name
        }
        owner {
          id
          name
        }
        persons {
          id
          name
          createdAt
        }
      }

      deals {
        id
        createdAt
        currency
        stage
        enquiry {
          id
          canceledAt
        }
        pipedriveId
        pipedriveUrl
        pipedriveStatus
        pipedrivePipeline
        pipedriveOrderValue

        intercompanyObjects {
          ...intercompanyObjectPerson
        }
      }
    }
    ${INTERCOMPANY_OBJECT_FRAGMENT}
  `,
  [Regions.UK]: gql`
    fragment personFragment on Person {
      id
      createdAt
      updatedAt
      deletedAt
      deleted
      pipedriveId
      pipedriveUrl
      externalCrmId
      externalCrmUrl
      name
      email
      carbonCopyEmails
      invoiceEmails
      logisticEmails
      ocEmails
      paymentReminderEmails
      technicalEmails
      locale
      phone
      mobile
      whatsappNumber
      notes
      jobTitle
      birthday
      nps
      mailingsSubscription
      mailingSuspendedTill
      isRobot
      inLoyaltyProgram
      movedTo {
        foreignObjectId
      }
      movedToRegion
      tpoEnabled

      owner {
        id
        name
      }

      contact {
        id
        email
        active
        createdAt
        verifiedAt
        lastVisitedAt
      }

      defaultBillingAccount {
        creditLimitMoney {
          amount
          amountCents
          currencyCode
        }
        id
        type
        accountType
        intacctId
        deletedAt
        registeredAt
        name
        country
        zip
        city
        address
        vatId
        taxId
        internalComment
        vatValidationStatus
      }

      billingAccounts {
        creditLimitMoney {
          amount
          amountCents
          currencyCode
        }
        id
        type
        accountType
        intacctId
        deletedAt
        registeredAt
        name
        country
        zip
        city
        address
        vatId
        taxId
        internalComment
        vatValidationStatus
      }

      defaultShippingAddress {
        id
        sourceType
        sourceId
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      shippingAddresses {
        id
        sourceType
        sourceId
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      organization {
        id
        name
        createdAt
        deletedAt
        pipedriveId
        pipedriveUrl
        customerOrderGrade
        defaultShippingAddressId
        notes

        productionManager {
          id
          name
        }

        kam {
          id
          name
        }
        owner {
          id
          name
        }
        persons {
          id
          name
          createdAt
        }
      }

      deals {
        id
        createdAt
        currency
        stage
        enquiry {
          id
          canceledAt
        }
        pipedriveId
        pipedriveUrl
        pipedriveStatus
        pipedrivePipeline
        pipedriveOrderValue

        intercompanyObjects {
          ...intercompanyObjectPerson
        }
      }
    }

    ${INTERCOMPANY_OBJECT_FRAGMENT}
  `,
  [Regions.TR]: gql`
    fragment personFragment on Person {
      id
      createdAt
      updatedAt
      deletedAt
      deleted
      pipedriveId
      pipedriveUrl
      externalCrmId
      externalCrmUrl
      name
      email
      carbonCopyEmails
      invoiceEmails
      logisticEmails
      ocEmails
      paymentReminderEmails
      technicalEmails
      locale
      phone
      mobile
      whatsappNumber
      notes
      jobTitle
      birthday
      nps
      mailingsSubscription
      mailingSuspendedTill
      isRobot
      inLoyaltyProgram
      movedTo {
        foreignObjectId
      }
      movedToRegion
      tpoEnabled

      owner {
        id
        name
      }

      contact {
        id
        email
        active
        createdAt
        verifiedAt
        lastVisitedAt
      }

      defaultBillingAccount {
        creditLimitMoney {
          amount
          amountCents
          currencyCode
        }
        id
        type
        accountType
        intacctId
        deletedAt
        name
        country
        zip
        city
        address
        vatExempt
        vatId
        taxId
        internalComment
        vatValidationStatus
      }

      billingAccounts {
        creditLimitMoney {
          amount
          amountCents
          currencyCode
        }
        id
        type
        accountType
        intacctId
        deletedAt
        name
        country
        zip
        city
        address
        vatId
        taxId
        internalComment
        vatValidationStatus
      }

      defaultShippingAddress {
        id
        sourceType
        sourceId
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      shippingAddresses {
        id
        sourceType
        sourceId
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      organization {
        id
        name
        createdAt
        deletedAt
        pipedriveId
        pipedriveUrl
        customerOrderGrade
        defaultShippingAddressId
        notes

        productionManager {
          id
          name
        }

        kam {
          id
          name
        }
        owner {
          id
          name
        }
        persons {
          id
          name
          createdAt
        }
      }

      deals {
        id
        createdAt
        currency
        stage
        enquiry {
          id
          canceledAt
        }
        pipedriveId
        pipedriveUrl
        pipedriveStatus
        pipedrivePipeline
        pipedriveOrderValue

        intercompanyObjects {
          ...intercompanyObjectPerson
        }
      }
    }

    ${INTERCOMPANY_OBJECT_FRAGMENT}
  `,
};

export const PERSON_FRAGMENT = PERSON_FRAGMENT_CONFIG[CURRENT_REGION];

const PERSON_DEAL_HEADER_FRAGMENT_CONFIG: Record<Regions, ReturnType<typeof gql>> = {
  [Regions.EU]: gql`
    fragment personDealHeaderFragment on Person {
      id
      createdAt
      updatedAt
      deletedAt
      deleted
      pipedriveId
      pipedriveUrl
      name
      email
      carbonCopyEmails
      invoiceEmails
      logisticEmails
      ocEmails
      paymentReminderEmails
      technicalEmails
      locale
      phone
      notes
      jobTitle
      birthday
      nps
      mailingsSubscription
      mailingSuspendedTill
      isRobot

      defaultShippingAddress {
        id
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      contact {
        id
        active
        createdAt
        verifiedAt
        lastVisitedAt
      }

      defaultBillingAccount {
        id
        type
        accountType
        datevId
        deletedAt
        name
        country
        zip
        city
        address
        vatId
        taxId
        internalComment
        vatValidationStatus
        deletedAt
        vatRegistered
        vatRegistrationCountry
      }

      owner {
        id
        name
      }

      organization {
        id
        name
      }
    }
  `,
  [Regions.UK]: gql`
    fragment personDealHeaderFragment on Person {
      id
      createdAt
      updatedAt
      deletedAt
      deleted
      pipedriveId
      pipedriveUrl
      name
      email
      carbonCopyEmails
      invoiceEmails
      logisticEmails
      ocEmails
      paymentReminderEmails
      technicalEmails
      locale
      phone
      notes
      jobTitle
      birthday
      nps
      mailingsSubscription
      mailingSuspendedTill
      isRobot

      defaultShippingAddress {
        id
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      contact {
        id
        active
        createdAt
        verifiedAt
        lastVisitedAt
      }

      defaultBillingAccount {
        id
        type
        accountType
        intacctId
        deletedAt
        name
        country
        zip
        city
        address
        vatId
        taxId
        internalComment
        vatValidationStatus
        deletedAt
      }

      owner {
        id
        name
      }

      organization {
        id
        name
      }
    }
  `,
  [Regions.TR]: gql`
    fragment personDealHeaderFragment on Person {
      id
      createdAt
      updatedAt
      deletedAt
      deleted
      pipedriveId
      pipedriveUrl
      name
      email
      carbonCopyEmails
      invoiceEmails
      logisticEmails
      ocEmails
      paymentReminderEmails
      technicalEmails
      locale
      phone
      notes
      jobTitle
      birthday
      nps
      mailingsSubscription
      mailingSuspendedTill
      isRobot

      defaultShippingAddress {
        id
        companyName
        contactName
        contactPhone
        country
        zip
        city
        address
        comment
        internalComment
      }

      contact {
        id
        active
        createdAt
        verifiedAt
        lastVisitedAt
      }

      defaultBillingAccount {
        id
        type
        accountType
        intacctId
        deletedAt
        name
        country
        zip
        city
        address
        vatId
        taxId
        internalComment
        vatValidationStatus
        deletedAt
      }

      owner {
        id
        name
      }

      organization {
        id
        name
      }
    }
  `,
};

/** @deprecated use personDealHeader.graphql instead */
export const PERSON_DEAL_HEADER_FRAGMENT = PERSON_DEAL_HEADER_FRAGMENT_CONFIG[CURRENT_REGION];
