import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Popconfirm, Tooltip } from 'antd';
import { Notification, PageLoader } from 'components/UI';
import { BulkAction } from 'components/Workspaces/collections';
import { IDataItem } from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { map, size } from 'lodash-es';
import React, { useState } from 'react';
import useAsyncJobStatus from 'utils/hooks/useAsyncJobStatus';

interface Props {
  bulkActions?: BulkAction<any>[];
  selectedRecords: IDataItem[];
}

export const BulkActions: React.FC<Props> = ({ bulkActions, selectedRecords }) => {
  const showSuccessNotification = () => Notification.Success('Action completed successfully');

  const [currentAction, setCurrentAction] = useState<BulkAction<any> | null>(null);
  const [startActionStatusPolling, actionStatusLoading] = useAsyncJobStatus({
    onCompleted: (status, _) => {
      if (status === 'complete') showSuccessNotification();

      setCurrentAction(null);
    },
  });

  const handleBulkActionRun = async (action: BulkAction<any>) => {
    const recordsData = map(selectedRecords, 'data');

    if (action.async) {
      setCurrentAction(action);
      const jobId = await action.handler(recordsData);
      startActionStatusPolling(jobId);
    } else {
      await action.handler(recordsData);
    }
  };

  if (actionStatusLoading) {
    return <PageLoader title={`Running ${currentAction?.name ?? ''}...`} isVisible />;
  }

  if (bulkActions == null || size(bulkActions) === 0) return null;

  const items = bulkActions.map((action) => ({
    key: action.name,
    label: (
      <Tooltip title={action.tooltip}>
        {action.skipConfirm ? (
          <Button
            disabled={selectedRecords.length === 0 || action.disabled}
            type="link"
            onClick={() => void handleBulkActionRun(action)}
          >
            {action.label}
          </Button>
        ) : (
          <Popconfirm
            title={`Proceed with "${action.label}" bulk action for selected items?`}
            onConfirm={() => void handleBulkActionRun(action)}
          >
            <Button disabled={selectedRecords.length === 0 || action.disabled} type="link">
              {action.label}
            </Button>
          </Popconfirm>
        )}
      </Tooltip>
    ),
    onClick: () => void handleBulkActionRun(action),
  }));

  return (
    <Dropdown disabled={size(selectedRecords) === 0} menu={{ items }}>
      <Button type="link" style={{ paddingLeft: 0 }}>
        Bulk actions&nbsp;
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};
