import styled from '@emotion/styled';
import { Checkbox } from 'antd';
import { colors } from 'components/UI/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 10px 0;
`;

export const FilesGroup = styled(Checkbox.Group)`
  display: flex;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  display: flex;
  height: 35px;
  background: red;
  align-items: center;
  padding: 0 10px;
  border-radius: 2px;
  background: ${colors.gray3};
  margin: 0 5px 5px 0;

  .ant-checkbox-wrapper {
    margin-right: 5px;
  }
`;
