import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { MessengerTemplateCategoryFragmentDoc } from '../../fragments/__generated__/messengerTemplateCategory';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessengerTemplateCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MessengerTemplateCategoriesQuery = { messengerTemplateCategories: Array<{ id: string, title: string, channels: Array<Types.TemplateChannelEnum>, templatesGroups: Array<{ id: string, constantName?: string | null, name?: string | null, templates: Array<{ id: string, locale: Types.TemplateLocaleEnum }> }> }> };


export const MessengerTemplateCategoriesDocument = gql`
    query messengerTemplateCategories {
  messengerTemplateCategories {
    ...messengerTemplateCategory
  }
}
    ${MessengerTemplateCategoryFragmentDoc}`;

/**
 * __useMessengerTemplateCategoriesQuery__
 *
 * To run a query within a React component, call `useMessengerTemplateCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerTemplateCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerTemplateCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessengerTemplateCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<MessengerTemplateCategoriesQuery, MessengerTemplateCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessengerTemplateCategoriesQuery, MessengerTemplateCategoriesQueryVariables>(MessengerTemplateCategoriesDocument, options);
      }
export function useMessengerTemplateCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessengerTemplateCategoriesQuery, MessengerTemplateCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessengerTemplateCategoriesQuery, MessengerTemplateCategoriesQueryVariables>(MessengerTemplateCategoriesDocument, options);
        }
export function useMessengerTemplateCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MessengerTemplateCategoriesQuery, MessengerTemplateCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessengerTemplateCategoriesQuery, MessengerTemplateCategoriesQueryVariables>(MessengerTemplateCategoriesDocument, options);
        }
export type MessengerTemplateCategoriesQueryHookResult = ReturnType<typeof useMessengerTemplateCategoriesQuery>;
export type MessengerTemplateCategoriesLazyQueryHookResult = ReturnType<typeof useMessengerTemplateCategoriesLazyQuery>;
export type MessengerTemplateCategoriesSuspenseQueryHookResult = ReturnType<typeof useMessengerTemplateCategoriesSuspenseQuery>;
export type MessengerTemplateCategoriesQueryResult = Apollo.QueryResult<MessengerTemplateCategoriesQuery, MessengerTemplateCategoriesQueryVariables>;