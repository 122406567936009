import { observer } from 'mobx-react-lite';
import React, { MouseEvent } from 'react';

import Content from './Content';
import { Modal } from './styled';

interface Props {
  visible: boolean;
  openModal: (e: MouseEvent<HTMLElement>) => void;
  closeModal: () => void;
  url: string;
  skipFetch?: boolean;
  onSkipCleaningComplete?: () => void;
}

const PreviewModal = observer<Props>(({ visible, openModal, closeModal, url, skipFetch, onSkipCleaningComplete }) => (
  <Modal
    open={visible}
    title="Attached Files"
    centered
    onOk={openModal}
    onCancel={closeModal}
    footer={null}
    destroyOnClose
    isFullHeight
  >
    <Content url={url} skipFetch={skipFetch} onSkipCleaningComplete={onSkipCleaningComplete} />
  </Modal>
));

export default PreviewModal;
