import {
  BillingAccountsAttachedFilesContextEnumType,
  BillingAccountsVatValidationStatusEnumType,
} from '__generated__/types';
import { colors } from 'components/UI/colors';
import { TAX_ID_IS_MAIN_TAX_FIELD } from 'config/regions/features';

export const defaultPaymentTermsDays = 30;

export const mainTaxFieldName = TAX_ID_IS_MAIN_TAX_FIELD ? 'TAX ID' : 'VAT ID';

export const billingAccountVatStatusOptions = Object.values(BillingAccountsVatValidationStatusEnumType).map(
  (status) => {
    return {
      value: status,
      label: status,
    };
  },
);

export const filesContext = [
  BillingAccountsAttachedFilesContextEnumType.Default,
  BillingAccountsAttachedFilesContextEnumType.ManualVatValidation,
  BillingAccountsAttachedFilesContextEnumType.PaymentTerms,
];

export const vatValidationColors: Record<string, string> = {
  Failed: colors.redDarker,
  Invalid: colors.redDarker,
  NeedCheck: colors.yellow8,
  NeedPrint: colors.yellow8,
  NeedReview: colors.yellow8,
  Pending: colors.gray6,
  Success: colors.greenMain,
  SuccessManual: colors.greenMain,
  Valid: colors.greenMain,
};
