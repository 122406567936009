import { TemplateDirectionEnum } from '__generated__/types';
import { ReactComponent as PartnerIcon } from 'assets/images/icons/messenger/partner.svg';
import { ReactComponent as CustomerIcon } from 'assets/images/icons/messenger/person.svg';
import { ReactComponent as AnyIcon } from 'assets/images/icons/people.svg';
import { ReactComponent as InternalIcon } from 'assets/images/icons/team.svg';

export const DIRECTION_LABEL_MAP: Record<TemplateDirectionEnum, string> = {
  [TemplateDirectionEnum.Any]: 'Any',
  [TemplateDirectionEnum.X2c]: 'Customer',
  [TemplateDirectionEnum.X2p]: 'Partner',
  [TemplateDirectionEnum.X2x]: 'Internal',
};

export const DIRECTION_ICON_MAP: Record<
  TemplateDirectionEnum,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >
> = {
  [TemplateDirectionEnum.Any]: AnyIcon,
  [TemplateDirectionEnum.X2c]: CustomerIcon,
  [TemplateDirectionEnum.X2p]: PartnerIcon,
  [TemplateDirectionEnum.X2x]: InternalIcon,
};
