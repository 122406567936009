import { gql } from 'apollo-boost';

export const ORGANIZATION_BALANCE_RECORD_FRAGMENT = gql`
  fragment organizationBalanceRecordFragment on OrganizationBalanceRecord {
    id
    organizationId
    date

    invoicedBrutto
    paidBrutto
    balanceBrutto

    prepayments
    currentDue

    overdueBrutto
    overdueBelow30Days
    overdueAbove30Days
  }
`;
