import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkspacePresetsRemoveDefaultPresetMutationVariables = Types.Exact<{
  workspaceName: Types.Scalars['String']['input'];
}>;


export type WorkspacePresetsRemoveDefaultPresetMutation = { workspacePresetsRemoveDefaultPreset?: { success: boolean } | null };


export const WorkspacePresetsRemoveDefaultPresetDocument = gql`
    mutation workspacePresetsRemoveDefaultPreset($workspaceName: String!) {
  workspacePresetsRemoveDefaultPreset(input: {workspaceName: $workspaceName}) {
    success
  }
}
    `;
export type WorkspacePresetsRemoveDefaultPresetMutationFn = Apollo.MutationFunction<WorkspacePresetsRemoveDefaultPresetMutation, WorkspacePresetsRemoveDefaultPresetMutationVariables>;

/**
 * __useWorkspacePresetsRemoveDefaultPresetMutation__
 *
 * To run a mutation, you first call `useWorkspacePresetsRemoveDefaultPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePresetsRemoveDefaultPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspacePresetsRemoveDefaultPresetMutation, { data, loading, error }] = useWorkspacePresetsRemoveDefaultPresetMutation({
 *   variables: {
 *      workspaceName: // value for 'workspaceName'
 *   },
 * });
 */
export function useWorkspacePresetsRemoveDefaultPresetMutation(baseOptions?: Apollo.MutationHookOptions<WorkspacePresetsRemoveDefaultPresetMutation, WorkspacePresetsRemoveDefaultPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkspacePresetsRemoveDefaultPresetMutation, WorkspacePresetsRemoveDefaultPresetMutationVariables>(WorkspacePresetsRemoveDefaultPresetDocument, options);
      }
export type WorkspacePresetsRemoveDefaultPresetMutationHookResult = ReturnType<typeof useWorkspacePresetsRemoveDefaultPresetMutation>;
export type WorkspacePresetsRemoveDefaultPresetMutationResult = Apollo.MutationResult<WorkspacePresetsRemoveDefaultPresetMutation>;
export type WorkspacePresetsRemoveDefaultPresetMutationOptions = Apollo.BaseMutationOptions<WorkspacePresetsRemoveDefaultPresetMutation, WorkspacePresetsRemoveDefaultPresetMutationVariables>;