import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessengerTemplateGroupDeleteMutationVariables = Types.Exact<{
  input: Types.MessengerTemplateGroupDeleteInput;
}>;


export type MessengerTemplateGroupDeleteMutation = { messengerTemplateGroupDelete?: { success: boolean } | null };


export const MessengerTemplateGroupDeleteDocument = gql`
    mutation messengerTemplateGroupDelete($input: MessengerTemplateGroupDeleteInput!) {
  messengerTemplateGroupDelete(input: $input) {
    success
  }
}
    `;
export type MessengerTemplateGroupDeleteMutationFn = Apollo.MutationFunction<MessengerTemplateGroupDeleteMutation, MessengerTemplateGroupDeleteMutationVariables>;

/**
 * __useMessengerTemplateGroupDeleteMutation__
 *
 * To run a mutation, you first call `useMessengerTemplateGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessengerTemplateGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messengerTemplateGroupDeleteMutation, { data, loading, error }] = useMessengerTemplateGroupDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessengerTemplateGroupDeleteMutation(baseOptions?: Apollo.MutationHookOptions<MessengerTemplateGroupDeleteMutation, MessengerTemplateGroupDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessengerTemplateGroupDeleteMutation, MessengerTemplateGroupDeleteMutationVariables>(MessengerTemplateGroupDeleteDocument, options);
      }
export type MessengerTemplateGroupDeleteMutationHookResult = ReturnType<typeof useMessengerTemplateGroupDeleteMutation>;
export type MessengerTemplateGroupDeleteMutationResult = Apollo.MutationResult<MessengerTemplateGroupDeleteMutation>;
export type MessengerTemplateGroupDeleteMutationOptions = Apollo.BaseMutationOptions<MessengerTemplateGroupDeleteMutation, MessengerTemplateGroupDeleteMutationVariables>;