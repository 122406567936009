import { CodeSandboxOutlined } from '@ant-design/icons';
import { bc } from 'components/UI/variables';
import React from 'react';

const ScsNoPreview: React.FC = () => (
  <>
    <CodeSandboxOutlined style={{ color: bc.border }} />
    <span className="no-preview-text">View in 3D</span>
  </>
);

export default ScsNoPreview;
