import { accessErrorNotification } from 'components/shared/AccessError/accessErrorNotification';
import { useCallback } from 'react';
import { useStore } from 'stores/RootStore';

export const useAccessErrorNotification = () => {
  const rootStore = useStore();

  return useCallback(
    (message?: string) => {
      accessErrorNotification(message, rootStore);
    },
    [rootStore],
  );
};
