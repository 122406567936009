import styled from '@emotion/styled';
import { Button, Col, Row, Tag } from 'antd';
import { colors } from 'components/UI/colors';
import { H1 } from 'components/UI/H/styled';
import { bc, sizes } from 'components/UI/variables';

export const HeaderContainer = styled(Row)`
  border-bottom: 1px solid ${bc.border};

  .rating {
    color: ${colors.gray9};
    font-size: 12px;
    line-height: 20px;
    padding-top: 20px;
    display: block;
    white-space: nowrap;

    .rating-name {
      line-height: 20px;
      margin-right: 8px;
    }

    .rating-title {
      text-align: center;
      line-height: 20px;
      position: absolute;
      bottom: 100%;
      width: 100%;
    }

    .rating-progress {
      width: 150px;
      margin-right: 4px;
      position: relative;
    }

    .rating-total {
      margin-right: 20px;
    }

    .rating-circle {
      padding-top: 3px;

      .ant-progress-text {
        font-size: 18px;
        color: ${colors.gray8};
        font-weight: 600;
      }
      .rating-circle-not-available {
        color: ${colors.gray7};
      }
    }
  }

  .totals-info {
    font-size: 13px;
    margin-top: 10px;
    color: ${colors.gray8};

    & b {
      font-weight: 600;
    }

    .totals-info-col {
      margin-right: 15px;
    }

    .limits-area {
      border-left: 1px solid #c4c4c4;
      padding-left: 18px;
      margin-left: -14px;
    }
  }
`;

export const HeaderCol = styled(Col)`
  border-right: 1px solid ${bc.border};
  padding: 9px ${sizes.mainPadding} 15px ${sizes.mainPadding};
`;

export const PartnerHeaderCol = styled(Col)`
  padding: 15px ${sizes.mainPadding} 15px 12px;
`;

export const Links = styled(Row)`
  font-size: 20px;
  margin: 5px 0;

  svg {
    margin-right: 10px;
  }
`;

export const MainInfo = styled.div`
  margin-bottom: 15px;

  .holiday-tag {
    margin-top: 8px;
    border-radius: 24px;
  }

  .production-holiday-tag {
    border-color: var(--x-color-background-red-darker);
    background-color: var(--x-color-background-red);
    color: var(--x-color-action-red);
  }

  .public-holiday-tag {
    border-color: var(--x-color-background-green-darker);
    background-color: var(--x-color-background-green);
    color: var(--x-color-action-green);
  }
`;

export const IdAndStatus = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
`;

export const PartnerId = styled(H1)`
  color: #262626;
  margin: 0;
  margin-right: 10px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const PartnerHeaderDiv = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const RowPipeline = styled(Row)`
  font-size: 13px;
`;

export const PartnerTag = styled(Tag)`
  border-radius: 16px;
  text-transform: uppercase;
  height: 18px;
  line-height: 16px;
  font-size: 13px;
  padding: 0 10px;
  font-weight: 600;
  margin-right: 0;
`;

export const InfoLine = styled.div`
  line-height: 20px;
  display: flex;
  font-size: 15px;
  flex-wrap: wrap;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  & b {
    font-weight: 600;
  }
`;

export const DotDivider = styled.span`
  width: 5px;
  height: 5px;
  display: inline-block;
  background-color: ${colors.gray6};
  border-radius: 5px;
  margin: 0 7px 3px;
`;

export const InfoItem = styled.div`
  position: relative;
  white-space: nowrap;

  &:not(:last-of-type) {
    margin-right: 18px;

    &:after {
      content: '';
      width: 5px;
      height: 5px;
      display: block;
      position: absolute;
      background-color: ${colors.gray6};
      border-radius: 5px;
      top: 50%;
      right: -11px;
      transform: translateY(-3px);
    }
  }
`;

export const InfoButton = styled(Button)`
  padding: 0;
  height: auto;
  line-height: 18px;
`;

export const InfoTag = styled.div`
  display: inline-block;
  background-color: ${colors.greyDark};
  color: ${colors.white};
  padding: 0 4px 1px;
  font-weight: 600;
  border-radius: 3px;
  font-size: 13px;
  line-height: 1.4em;
`;
