import { StorageInventoryNotePage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

const StoragesController = () => (
  <Route path={routes.storageInventoryNotePath(':id')} component={StorageInventoryNotePage} exact />
);

export default StoragesController;
