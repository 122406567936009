import { ClaimStatusState } from 'components/DealHeader/DealHeaderPrimary/claimStatusTypes';
import { getRegionCurrencySymbol } from 'config/regions/utils';
import { DealPipedriveStatus, IDeal, PartsOverview } from 'interfaces';
import { noop } from 'lodash-es';
import { action, computed, observable } from 'mobx';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import FetchableStore from 'stores/shared/FetchableStore';
import omsApi from 'utils/axios';
import { dealPipelineStage } from 'utils/deal/dealPipelineStage';
import { getClaimStatus } from 'utils/deal/getClaimStatus';

export type DealLayout = 'default' | 'fullScreen' | 'expandRight';

class DealStore extends FetchableStore {
  refetch = noop;

  @observable isEditing = false;
  @observable layout: DealLayout = 'default';
  @observable partsOverview: PartsOverview | undefined = undefined;

  @observable data: IDeal = {
    id: 0,
    name: '',
    number: 'D-#',
    vat: 0,

    currency: getRegionCurrencySymbol(),

    grade: undefined,

    testDeal: false,

    pmId: undefined,
    pipedriveId: undefined,
    pipedriveStatus: DealPipedriveStatus.deleted,
    pipedrivePipeline: '',
    stage: '',

    isNpg: false,

    pipedriveUrl: '',
    googleDriveUrl: '',
    slackUrl: '',
    slackUrlRequested: '',

    isExpress: undefined,
    isForSoftwareTesting: false,
    isSamplesNeeded: undefined,
    measurementProtocols: [],
    isPreQuoted: undefined,
    isImportant: false,
    isOnHold: false,
    isHighVolume: false,

    manualProcessingFinance: false,
    manualProcessingEmails: false,
    manualProcessingOutcomingInvoices: false,
    manualProcessingJobs: false,
    manualProcessingOrderConfirmation: false,
    manualProcessingShippings: false,
    manualProcessingStages: false,

    createdAt: undefined,

    customerOrderDate: undefined,
    deliveryDeadline: undefined,
    pipedriveOrderValue: undefined,
    totalPaidNett: undefined,
    invoiced: undefined,
    reasonOfLose: '',

    customerOrderCode: undefined,
    frameworkAgreement: false,

    productionManager: {
      id: undefined,
      name: undefined,
    },

    owner: {
      name: '',
      id: undefined,
    },

    utm: {
      campaign: '',
      medium: '',
      term: '',
    },

    claims: [],

    outcomingInvoices: [],

    payments: [],

    personId: 0,
    organizationId: 0,
  };

  @action assignOnFetch = (payload: IDeal) => {
    this.data = payload;
  };

  @action create = (values: FormData) => {
    omsApi
      .post(routesApi.dealsPath(), values)
      .then((response) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        window.location.href = routes.dealPath(String(response.data.payload.id));
      })
      .catch((error: unknown) => error);
  };

  @action setIsEditing = (isEditing: boolean): void => {
    this.isEditing = isEditing;
  };

  @action setLayout = (newLayout: DealLayout): void => {
    this.layout = newLayout;
  };

  @action setPartsOverview = (partsOverview: PartsOverview) => {
    this.partsOverview = partsOverview;
  };

  @computed
  get dealName(): string {
    return this.data.pipedriveId ? `D-${this.data.pipedriveId}` : `DealID-${this.data.id}`;
  }

  @computed
  get claimStatus(): ClaimStatusState {
    return getClaimStatus(this.data.claims);
  }

  @computed
  get dealPipelineStage(): string {
    return dealPipelineStage(this.data.pipedrivePipeline, this.data.stage);
  }
}

export default DealStore;
