import { CurrencyEnum } from '__generated__/types';
import { RegionData, RegionLocales, Regions } from 'config/regions/types';
import { ENV_APP_HOST_EU, ENV_CUSTOMERS_APP_HOST_EU, ENV_PARTNERS_APP_HOST_EU } from 'constants/env';

import { ReactComponent as Icon } from './assets/eu.svg';

export const EURegionConfig: RegionData<Regions.EU> = {
  region: Regions.EU,
  orgName: 'Xometry Europe',
  name: 'Europe',
  shortName: 'EU',
  locale: RegionLocales.EN,
  currency: CurrencyEnum.Eur,
  icon: Icon,
  host: ENV_APP_HOST_EU,
  customersHost: ENV_CUSTOMERS_APP_HOST_EU,
  partnersHost: ENV_PARTNERS_APP_HOST_EU,
  headerColor: '#4C6985',
};
