import { gql } from 'apollo-boost';

export const CORRECTION_REFERENCE_FRAGMENT = gql`
  fragment correctionReferenceFragment on CorrectionReference {
    id
    value
  }
`;

export const DOCUMENT_REFERENCES_FRAGMENT = gql`
  fragment documentReferencesFragment on DocumentReferenceGroup {
    in {
      objectType
      objectId
      count
    }
    out {
      objectType
      objectId
      count
    }
  }
`;

export const PART_STATE_RECORD_FRAGMENT = gql`
  fragment partStateRecordFragment on PartStateRecord {
    id
    partId

    orderedActual
    orderedHold
    prodPlacing
    prodPlaced
    prodShipped2x
    prodWarehouse
    prodShipped2c
    prodDelivered
    qaIssues
    qaClaims
    invCustomer
    invPartner
    documentReferences {
      orderedActual {
        ...documentReferencesFragment
      }
      orderedHold {
        ...documentReferencesFragment
      }
      prodPlacing {
        ...documentReferencesFragment
      }
      prodPlaced {
        ...documentReferencesFragment
      }
      prodShipped2x {
        ...documentReferencesFragment
      }
      prodWarehouse {
        ...documentReferencesFragment
      }
      prodShipped2c {
        ...documentReferencesFragment
      }
      prodDelivered {
        ...documentReferencesFragment
      }
      qaIssues {
        ...documentReferencesFragment
      }
      qaClaims {
        ...documentReferencesFragment
      }
      invCustomer {
        ...documentReferencesFragment
      }
      invPartner {
        ...documentReferencesFragment
      }
    }
    corrections {
      orderedActual {
        ...correctionReferenceFragment
      }
      orderedHold {
        ...correctionReferenceFragment
      }
      prodPlacing {
        ...correctionReferenceFragment
      }
      prodPlaced {
        ...correctionReferenceFragment
      }
      prodShipped2x {
        ...correctionReferenceFragment
      }
      prodWarehouse {
        ...correctionReferenceFragment
      }
      prodShipped2c {
        ...correctionReferenceFragment
      }
      prodDelivered {
        ...correctionReferenceFragment
      }
      qaIssues {
        ...correctionReferenceFragment
      }
      qaClaims {
        ...correctionReferenceFragment
      }
      invCustomer {
        ...correctionReferenceFragment
      }
      invPartner {
        ...correctionReferenceFragment
      }
    }

    createdAt
    updatedAt
  }
  ${DOCUMENT_REFERENCES_FRAGMENT}
  ${CORRECTION_REFERENCE_FRAGMENT}
`;
