import { compact, filter, flatMap, groupBy, map, uniqBy } from 'lodash-es';
import moment from 'moment';
import { useMemo } from 'react';
import { useStore } from 'stores/RootStore';
import { useRolesAndPermissionsQuery } from 'utils/graphql/queries/__generated__/rolesAndPermissions';

interface UseCurrentUserPermissionsParams {
  skip?: boolean;
}

function useCurrentUserPermissions({ skip }: UseCurrentUserPermissionsParams = {}) {
  const { userStore } = useStore();
  const userId = userStore.id;

  const { data, loading } = useRolesAndPermissionsQuery({
    variables: {
      userId: userId ?? '',
    },
    skip,
  });

  const allPermissions = useMemo(
    () =>
      data == null
        ? undefined
        : groupBy(
            uniqBy(
              [
                // All permissions granted by roles
                ...compact(flatMap(data.rolesForUser, (role) => role.erpPermissions)),

                // All individual not expired permissions
                ...map(
                  filter(data.userPermissions, (up) => up.expiresAt == null || moment(up.expiresAt) > moment()),
                  (up) => up.erpPermission,
                ),
              ],
              (p) => p.id,
            ),
            (p) => p.group,
          ),
    [data],
  );

  return { loading, allPermissions };
}

export default useCurrentUserPermissions;
