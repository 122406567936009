import { CheckCircleFilled } from '@ant-design/icons';
import { Row } from 'antd';
import { Button } from 'components/UI';
import React, { FC, ReactElement } from 'react';

import { Inner, ScreenContainer } from '../styled';

interface Props {
  onCancel: () => void;
  onRetry: () => void;
}

const Failure: FC<Props> = ({ onRetry, onCancel }): ReactElement => (
  <ScreenContainer>
    <Inner failure>
      <Row justify="center">
        <CheckCircleFilled />
      </Row>

      <Row justify="center">
        <h2>Sending Failed!</h2>
      </Row>

      <Row justify="center">
        <Button onClick={onCancel}>Cancel</Button>

        <Button type="primary" onClick={onRetry} style={{ marginLeft: '10px' }}>
          Try again
        </Button>
      </Row>
    </Inner>
  </ScreenContainer>
);

export default Failure;
