import styled from '@emotion/styled';

enum Justify {
  start = 'flex-start',
  end = 'flex-end',
  center = 'center',
  'spece-around' = 'space-around',
  'space-between' = 'space-between',
}

interface ButtonGroupRowProps {
  justify?: keyof typeof Justify;
  gutter?: number;
}

export const ButtonGroupRow = styled('div')<ButtonGroupRowProps>`
  display: flex;
  flex-direction: row;
  ${({ justify }) => justify && `justify-content: ${Justify[justify]};`}

  & > button {
    ${({ justify, gutter }) => gutter && justify && Justify[justify] === Justify.start && `margin-right: ${gutter}px;`}
    ${({ justify, gutter }) => gutter && justify && Justify[justify] === Justify.end && `margin-left: ${gutter}px;`}
  }
`;
