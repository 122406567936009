import {
  BankPaymentsExportPage,
  BankPaymentsExportsPage,
  BankPaymentsImportPage,
  BankPaymentsImportsPage,
} from 'pages';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

const BankPaymentsController: React.FC = () => (
  <Switch>
    <Route path={routes.bankPaymentsImportsPath()} component={BankPaymentsImportsPage} exact />

    <Route path={routes.bankPaymentsImportPath(':id')} component={BankPaymentsImportPage} exact />

    <Route path={routes.bankPaymentsExportsPath()} component={BankPaymentsExportsPage} exact />

    <Route path={routes.bankPaymentsExportPath(':id')} component={BankPaymentsExportPage} exact />
  </Switch>
);

export default BankPaymentsController;
