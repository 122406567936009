type Value = string | number | null | undefined;

interface QueryObject {
  [key: string]: Value | QueryObject | null | undefined;
}

/**
 * Creates query string from nested query object
 *
 * @example createQuery({ preset: 1, filter: { test: 1 } }) => 'preset=1&filter=test=1'
 * @param query QueryObject
 * @returns Query string
 */
export function createQuery(query: QueryObject): string {
  const filters: string[] = [];

  Object.entries(query).forEach(([key, value]) => {
    if (value != null) {
      if (typeof value === 'object') {
        const nestedValue = createQuery(value);

        if (nestedValue) {
          filters.push(`${key}=${createQuery(value)}`);
        }
      } else {
        filters.push(`${key}=${value}`);
      }
    }
  });

  return filters.join('&');
}
