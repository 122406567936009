import styled from '@emotion/styled';
import { Form } from 'antd';
import { Button } from 'components/UI';
import { Modal as UIModal } from 'components/UI/Modal/styled';
import { colors } from 'components/UI/variables';

const { Item } = Form;

const theme = {
  header: {
    background: '#F0F2F5',
    color: '#595959',
  },
  recipient: {
    color: '#262626',
    border: '#E8E8E8',
  },
  success: {
    icon: colors.green,
  },
  failure: {
    icon: colors.redDarker,
  },
};

export const Modal = styled(UIModal)`
  padding: 0;

  .ant-modal-title {
    color: ${theme.header.color};
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
  }

  .ant-modal-header {
    background: ${theme.header.background};
  }

  .ant-form {
    display: flex;
    flex-direction: column;
  }
`;

export const RecipientItem = styled(Item)`
  margin-bottom: 0px;
  border-bottom: 1px solid ${theme.recipient.border};

  .ant-form-item-label > label {
    width: 80px;
    font-weight: 600;
    color: ${theme.recipient.color};
  }

  .ant-select-selector {
    border: none;
  }

  .ant-input {
    border: none;
    outline: none;
    box-shadow: none;
  }

  .ant-form {
    height: 100%;
  }
`;

export const MailerFormGroup = styled('div')`
  & > :first-child /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */ {
    border-top: 1px solid ${theme.recipient.border};
  }
`;

export const ScreenContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
`;

interface InnerProps {
  success?: boolean;
  failure?: boolean;
}

export const Inner = styled('div')<InnerProps>`
  margin-top: -50px;
  text-align: center;

  & svg {
    font-size: 70px;
    margin: 0;
    ${({ success }) => success && `color: ${theme.success.icon};`}
    ${({ failure }) => failure && `color: ${theme.failure.icon};`}
  }

  & h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    margin: 0 0 8px 0;
  }

  & > div > span {
    margin-bottom: 10px;
    color: ${colors.gray8};
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProcessText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.blue};
`;

export const SendDirectEmailButton = styled(Button)`
  color: ${colors.gray4} !important;

  &:hover {
    color: ${colors.blue} !important;
  }
`;

export const StyledForm = styled(Form)`
  height: 100%;
`;

export const StyledTextAreaItem = styled(Item)`
  flex-grow: 1;

  .ant-form-item-control,
  .ant-form-item-control-input,
  .ant-form-item-control-input-content,
  .ant-form-item-control-input-content textarea {
    height: 100%;
  }
  .ant-form-item-control-input-content textarea {
    min-height: 200px;
  }
`;
