import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganizationPersonsEmailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  searchByRelatedEmail?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type OrganizationPersonsEmailsQuery = { organizationPersons: { totalCount: number, persons: Array<{ id: number, name: string, email: string, carbonCopyEmails: Array<string>, invoiceEmails: Array<string>, logisticEmails: Array<string>, ocEmails: Array<string>, paymentReminderEmails: Array<string>, technicalEmails: Array<string> }> } };


export const OrganizationPersonsEmailsDocument = gql`
    query organizationPersonsEmails($id: ID!, $limit: Int, $searchByRelatedEmail: String) {
  organizationPersons(
    id: $id
    limit: $limit
    searchByRelatedEmail: $searchByRelatedEmail
  ) {
    persons {
      id
      name
      email
      carbonCopyEmails
      invoiceEmails
      logisticEmails
      ocEmails
      paymentReminderEmails
      technicalEmails
    }
    totalCount
  }
}
    `;

/**
 * __useOrganizationPersonsEmailsQuery__
 *
 * To run a query within a React component, call `useOrganizationPersonsEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationPersonsEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationPersonsEmailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      searchByRelatedEmail: // value for 'searchByRelatedEmail'
 *   },
 * });
 */
export function useOrganizationPersonsEmailsQuery(baseOptions: Apollo.QueryHookOptions<OrganizationPersonsEmailsQuery, OrganizationPersonsEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationPersonsEmailsQuery, OrganizationPersonsEmailsQueryVariables>(OrganizationPersonsEmailsDocument, options);
      }
export function useOrganizationPersonsEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationPersonsEmailsQuery, OrganizationPersonsEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationPersonsEmailsQuery, OrganizationPersonsEmailsQueryVariables>(OrganizationPersonsEmailsDocument, options);
        }
export function useOrganizationPersonsEmailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationPersonsEmailsQuery, OrganizationPersonsEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationPersonsEmailsQuery, OrganizationPersonsEmailsQueryVariables>(OrganizationPersonsEmailsDocument, options);
        }
export type OrganizationPersonsEmailsQueryHookResult = ReturnType<typeof useOrganizationPersonsEmailsQuery>;
export type OrganizationPersonsEmailsLazyQueryHookResult = ReturnType<typeof useOrganizationPersonsEmailsLazyQuery>;
export type OrganizationPersonsEmailsSuspenseQueryHookResult = ReturnType<typeof useOrganizationPersonsEmailsSuspenseQuery>;
export type OrganizationPersonsEmailsQueryResult = Apollo.QueryResult<OrganizationPersonsEmailsQuery, OrganizationPersonsEmailsQueryVariables>;