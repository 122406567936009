import { Checkbox, Form, Modal, Popconfirm, Radio } from 'antd';
import { Autocomplete, Button, SemiBold } from 'components/UI';
import { FormWrapper } from 'components/UI/Form/styled';
import { Notice } from 'components/UI/Notice/styled';
import { get } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import { useStore } from 'stores/RootStore';
import { REQUIRED } from 'validators';

import { CloneToDeal } from './styled';

export enum PartCloneTo {
  Deal = 'deal',
  NewDeal = 'newDeal',
}

interface CloneToPart {
  cloneTo: string;
}

const CloneToDealForm = observer(() => {
  const { partsStore, dealStore } = useStore();

  const [isPersonOnlyDeals, setIsPersonOnlyDeals] = useState<boolean>(true);

  const [form] = Form.useForm();

  const onFinish = (values: { dealId: string; cloneTo: PartCloneTo }) => {
    const fn = async () => {
      const response = await partsStore.cloneToDeal({
        dealId: values.dealId,
        cloneTo: values.cloneTo,
      });

      const dealPipedriveId = String(get(response, 'data.payload'));

      if (!isPersonOnlyDeals || form.getFieldValue('cloneTo') === PartCloneTo.NewDeal) {
        Modal.success({
          title: `Parts will appears in deal D-${dealPipedriveId} `,
          content: (
            <Button type="link" onClick={() => window.open(routes.pDealPath(dealPipedriveId), '_blank')}>
              Click to open the deal&lsquo;s parts page
            </Button>
          ),
        });
      } else {
        window.location.reload();
      }
    };

    void fn();
  };

  return (
    <FormWrapper>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          cloneTo: PartCloneTo.Deal,
        }}
        preserve={false}
      >
        <SemiBold className="m-b-xxs">Select destination:</SemiBold>

        <Form.Item name="cloneTo">
          <Radio.Group>
            <Radio value={PartCloneTo.Deal}>
              <div style={{ display: 'inline-flex' }}>
                Clone to another Deal
                <Notice style={{ fontSize: 'inherit' }}>&nbsp;(owned by same Person)</Notice>
              </div>
            </Radio>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues: CloneToPart, currentValues: CloneToPart) =>
                prevValues.cloneTo !== currentValues.cloneTo
              }
            >
              {({ getFieldValue }) => (
                <CloneToDeal>
                  {!isPersonOnlyDeals && (
                    <Autocomplete
                      prefetch
                      label="Select Deal:"
                      name="dealId"
                      disabled={getFieldValue('cloneTo') === PartCloneTo.NewDeal}
                      endpoint={routesApi.autocompleteDeals()}
                      rules={[
                        {
                          required: getFieldValue('cloneTo') !== PartCloneTo.NewDeal,
                          message: REQUIRED,
                        },
                      ]}
                      form={form}
                    />
                  )}

                  {isPersonOnlyDeals && (
                    <Autocomplete
                      prefetch
                      label="Select Deal:"
                      name="dealId"
                      disabled={getFieldValue('cloneTo') === PartCloneTo.NewDeal}
                      endpoint={`${routesApi.autocompleteDeals()}?person_id=${dealStore.data?.personId}`}
                      rules={[
                        {
                          required: getFieldValue('cloneTo') !== PartCloneTo.NewDeal,
                          message: REQUIRED,
                        },
                      ]}
                      form={form}
                    />
                  )}

                  <Checkbox
                    disabled={getFieldValue('cloneTo') === PartCloneTo.NewDeal}
                    onChange={() => setIsPersonOnlyDeals((prevState) => !prevState)}
                  >
                    Allow cloning to other Person’s quotes
                  </Checkbox>
                </CloneToDeal>
              )}
            </Form.Item>

            <Radio value={PartCloneTo.NewDeal} className="m-t-md">
              Create new Deal owned by the same Person
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item style={{ textAlign: 'center' }}>
          <Popconfirm title="Are you sure?" onConfirm={form.submit} okText="Yes" cancelText="No">
            <Button className="m-t-md" type="primary">
              Clone quote
            </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
});

export default CloneToDealForm;
