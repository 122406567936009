/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { Notification } from 'components/UI';
import { camelizeKeys } from 'humps';
import { CurrentPreview, PreviewsPayload, PreviewsSchema } from 'interfaces';
import { action, computed, observable } from 'mobx';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import { requestStatus } from 'stores/utils';
import omsApi from 'utils/axios';

class PreviewsStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  openingPreviewId?: number = undefined;

  @observable isLoadingPreview = false;

  @observable currentPreview?: CurrentPreview = undefined;

  @observable schema: PreviewsSchema = {};

  @observable fetchStatus = requestStatus();

  @action loadPreview = async (id: number | string) => {
    this.isLoadingPreview = true;
    this.openingPreviewId = undefined;

    await omsApi
      .get(routesApi.attachedFilePreviewPath(String(id)))
      .then((response) => {
        const currentPreview = response.data.payload as CurrentPreview;

        this.setCurrentPreview(currentPreview);
      })
      .finally(() => {
        this.setIsLoadingPreview(false);
      });
  };

  @action skipCleaning = async (id: number, refetchUrl: string) => {
    this.setIsLoadingPreview(true);

    try {
      const response = await omsApi.get(routesApi.attachedFileSkipCleaningPath(String(id)));
      const currentPreview = response.data.payload as CurrentPreview;

      await this.fetch(refetchUrl);
      await this.loadPreview(currentPreview.id);
    } catch {
      this.setIsLoadingPreview(false);
    }
  };

  @action setCurrentPreview = (currentPreview: CurrentPreview): void => {
    this.currentPreview = currentPreview;
  };

  @action setIsLoadingPreview = (isLoadingPreview: boolean): void => {
    this.isLoadingPreview = isLoadingPreview;
  };

  @action reset = (): void => {
    this.isLoadingPreview = false;
    this.currentPreview = undefined;
  };

  @action fetch = async (url: string) => {
    this.fetchStatus.isFinished = false;
    this.fetchStatus.isProcessing = true;

    try {
      const response = await omsApi.get(url);
      this.onFetchSuccess(response);

      return response;
    } catch (err) {
      this.onFetchError();

      Notification.Error('Failed to fetch attachments');

      return err as AxiosError;
    }
  };

  @action setSchema = (data: PreviewsSchema): void => {
    this.fetchStatus.isFinished = true;
    this.fetchStatus.isProcessing = false;
    this.schema = camelizeKeys(data);
  };

  @action onFetchSuccess = (response: AxiosResponse<any>): void => {
    const payload = response.data.payload as PreviewsPayload;

    this.fetchStatus.isFinished = true;
    this.fetchStatus.isProcessing = false;

    this.schema = camelizeKeys(payload);
  };

  @action onFetchError = (): void => {
    this.fetchStatus.error = true;
    this.fetchStatus.isProcessing = false;
  };

  @computed({ keepAlive: true })
  get isOriginal(): boolean {
    if (this.currentPreview?.parentId) {
      return false;
    }

    if (this.currentPreview?.cleanedId) {
      return true;
    }

    if (!this.currentPreview?.parentId && this.currentPreview?.cleanedId) {
      return true;
    }

    return true;
  }
}

export default PreviewsStore;
