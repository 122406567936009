import { OrdersPage, QuotesPage } from 'pages';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

export const DrawingsWorkspacesController = () => (
  <Switch>
    <Redirect from={routes.workspacesDraftingDealPartsPath()} to={routes.workspacesDrawingsOrdersPath()} />
    <Route path={routes.workspacesDrawingsOrdersPath()} component={OrdersPage} exact />

    <Redirect from={routes.workspacesDraftingEnquiryPartsPath()} to={routes.workspacesDrawingsQuotesPath()} />
    <Route path={routes.workspacesDrawingsQuotesPath()} component={QuotesPage} exact />
  </Switch>
);
