import { gql } from 'apollo-boost';
import { ShippingAddress } from 'interfaces/graphql/person';
import { SHIPPING_ADDRESS_FRAGMENT } from 'utils/graphql/fragments';

interface ShippingAddressInput {
  address: string;
  city: string;
  comment?: string | null;
  companyName?: string;
  contactName?: string;
  contactPhone?: string;
  country: string;
  id?: number;
  isDefault?: boolean;
  sourceId?: number;
  sourceType?: string;
  zip: string;
}

export interface ShippingAddressesUpdateInput {
  id: number;
  attributes: ShippingAddressInput;
}

export interface ShippingAddressesUpdateResponse {
  shippingAddressesUpdate: ShippingAddress;
}

export const SHIPPING_ADDRESSES_UPDATE = gql`
  mutation shippingAddressesUpdate($id: ID!, $attributes: OmsInputsShippingAddressType!) {
    shippingAddressesUpdate(input: { id: $id, attributes: $attributes }) {
      shippingAddress {
        ...shippingAddressFragment
      }
    }
  }
  ${SHIPPING_ADDRESS_FRAGMENT}
`;

export interface ShippingAddressesCreateInput {
  attributes: ShippingAddressInput;
}

export interface ShippingAddressesCreateResponse {
  shippingAddressesCreate: ShippingAddress;
}

export const SHIPPING_ADDRESSES_CREATE = gql`
  mutation shippingAddressesCreate($attributes: OmsInputsShippingAddressType!) {
    shippingAddressesCreate(input: { attributes: $attributes }) {
      shippingAddress {
        ...shippingAddressFragment
      }
    }
  }
  ${SHIPPING_ADDRESS_FRAGMENT}
`;

export interface ShippingAddressesDeleteInput {
  id: number;
}

export interface ShippingAddressesDeleteResponse {
  shippingAddressesDelete: ShippingAddress;
}

export const SHIPPING_ADDRESSES_DELETE = gql`
  mutation shippingAddressesDelete($id: ID!) {
    shippingAddressesDelete(input: { id: $id }) {
      shippingAddress {
        ...shippingAddressFragment
      }
    }
  }
  ${SHIPPING_ADDRESS_FRAGMENT}
`;

export const SHIPPING_ADDRESSES_UPDATE_CASCADE = gql`
  mutation shippingAddressesUpdateCascade($id: ID!, $attributes: OmsInputsShippingAddressUpdateCascadeType!) {
    shippingAddressesUpdateCascade(input: { id: $id, attributes: $attributes }) {
      shippingAddress {
        ...shippingAddressFragment
      }
    }
  }
  ${SHIPPING_ADDRESS_FRAGMENT}
`;
