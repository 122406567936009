export enum OrgType {
  Private = 'Private Person',
  Corporate = 'Corporate',
}

export enum OrgGrade {
  A = 'Class A',
  B = 'Class B',
  C = 'Class C',
  G = 'Class G',
}

export interface OrgPerson {
  id: number;
  name: string;
}
