import styled from '@emotion/styled';
import { Tag as AntdTag } from 'antd';

export const BlackLink = styled.a`
  color: black;
`;

export const BlueSpan = styled.span`
  color: blue;
`;

export const GrayLink = styled.a`
  color: dimgray;
`;

export const GraySpan = styled.span`
  color: dimgray;
`;

export const GreenSpan = styled.span`
  color: green;
`;

export const RedSpan = styled.span`
  color: red;
`;

export const Tag = styled(AntdTag)`
  background: white;
  border-radius: 12px;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 4px;
  margin-right: 4px;
  padding: 0 10px;
  border-color: #8c99a6;
  color: #5f5f5f;
`;
