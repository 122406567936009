import { OutInvoice, ServerGetInvoice } from 'interfaces/stores/DealOutInvoiceStore';
import { find, get, last } from 'lodash-es';
import { action, observable } from 'mobx';
import { routesApi } from 'routes/api';
import FetchableStore from 'stores/shared/FetchableStore';

class DealOutInvoicesStore extends FetchableStore {
  @observable invoices: OutInvoice[] = [];

  @observable selected: OutInvoice | null = null;

  @action select = (id: number) => {
    const outInvoice = find(this.invoices, { id });

    if (outInvoice) {
      this.selected = outInvoice;
    }
  };

  @action reset = () => {
    this.rootStore.dealOutInvoicesStore = new DealOutInvoicesStore(this.rootStore);
  };

  @action addPositions = (id: string) => {
    this.rootStore.dealOutInvoicePositionsStore.setTableLoading(true);
    void this.rootStore.dealReferencePositionsStore.fetch(routesApi.outcomingInvoicesPositionsPath(id)).then(() => {
      this.rootStore.dealOutInvoicePositionsStore.addPositions(this.rootStore.dealReferencePositionsStore.positions);
      const lastPosition = get(last(this.rootStore.dealReferencePositionsStore.positions), 'position');
      this.rootStore.dealOutInvoicePositionsStore.currentPosition =
        lastPosition || this.rootStore.dealOutInvoicePositionsStore.currentPosition;

      this.rootStore.dealOutInvoicePositionsStore.setTableLoading(false);
    });
  };

  @action assignOnFetch = (payload: ServerGetInvoice[]): void => {
    this.invoices = payload.map((invoice) => ({
      id: invoice.id,
      number: invoice.number,
      issuedAt: invoice.issued_at,
      paidAt: invoice.paid_at,
      totalAmountNetto: Number(invoice.total_amount_netto_money?.amount),
      totalAmountBrutto: Number(invoice.total_amount_brutto_money?.amount),
      vatAvg: invoice.vat_avg,
    }));
  };
}

export default DealOutInvoicesStore;
