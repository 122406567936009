import { TemplateEntryPointEnum } from '__generated__/types';
import { EMessengerChatSourceType } from '@xometry/ui';
import { action, computed, observable } from 'mobx';
import { TemplateModalQueryVariables } from 'utils/graphql/queries/__generated__/templateModal';

interface Source {
  sourceId: string;
  sourceType: EMessengerChatSourceType;
}

class TemplatesModalStore {
  @observable isOpen = false;

  @observable entryPoint: TemplateEntryPointEnum | null = null;

  @observable messageSourceForTemplates: Source | null = null;

  @observable messageSourceForMessage: Source | null = null;

  @observable messageVisibilityAttributes: {
    isVisibleToClient: boolean;
    isVisibleToPartner: boolean;
  } | null = null;

  @computed get categoriesRequestParams(): TemplateModalQueryVariables | null {
    if (!this.entryPoint || !this.messageSourceForTemplates || !this.messageSourceForMessage) {
      return null;
    }

    return {
      entryPoint: this.entryPoint,
      templateSourceId: this.messageSourceForTemplates.sourceId,
      templateSourceType: this.messageSourceForTemplates.sourceType,
      messageSourceId: this.messageSourceForMessage.sourceId,
      messageSourceType: this.messageSourceForMessage.sourceType,
    };
  }

  @action openTemplatesModal = ({
    entryPoint,
    messageSourceForTemplates,
    messageSourceForMessage = messageSourceForTemplates,
    isVisibleToClient = false,
    isVisibleToPartner = false,
  }: {
    entryPoint: TemplateEntryPointEnum;
    messageSourceForTemplates: Source;
    messageSourceForMessage?: Source;
    isVisibleToClient?: boolean;
    isVisibleToPartner?: boolean;
  }) => {
    this.entryPoint = entryPoint;
    this.messageSourceForTemplates = messageSourceForTemplates;
    this.messageSourceForMessage = messageSourceForMessage;
    this.messageVisibilityAttributes = { isVisibleToClient, isVisibleToPartner };
    this.isOpen = true;
  };

  @action closeTemplatesModal = () => {
    this.isOpen = false;
    this.entryPoint = null;
    this.messageSourceForTemplates = null;
    this.messageSourceForMessage = null;
    this.messageVisibilityAttributes = null;
  };
}

export default TemplatesModalStore;
