export enum ClaimState {
  New = 'new',
  Discovered = 'discovered',
  SolutionSelected = 'solution_selected',
  CashCollection = 'cash_collection',
  ReturnDelivery = 'return_delivery',
  NeedInfo = 'need_info',
  ResolvedWithCustomer = 'resolved_with_customer',
  ResolvedWithProvider = 'resolved_with_provider',
  Closed = 'closed',
  Declined = 'declined',
  Placed = 'placed',
}

export const activeClaimStates = [
  ClaimState.ReturnDelivery,
  ClaimState.Placed,
  ClaimState.ResolvedWithProvider,
  ClaimState.Discovered,
  ClaimState.SolutionSelected,
  ClaimState.ResolvedWithCustomer,
  ClaimState.New,
];

export const closedClaimStates = [ClaimState.Closed, ClaimState.Declined];
