import { notification } from 'antd';
import { action, observable } from 'mobx';
import { routesApi } from 'routes/api';
import FetchableStore from 'stores/shared/FetchableStore';

interface FetchCommentParams {
  isStorno: boolean;
  creditNote: boolean;
  refInvoiceId: number;
  paidAt?: string;
  paymentDue: number;
  discountDueDays?: number;
  discountPercentage?: number;
  sourceShippingId?: number;
}

class DealOutInvoiceCommentStore extends FetchableStore {
  @observable comment: string | undefined = undefined;

  @action assignOnFetch = (payload: { comment: string }): void => {
    this.comment = payload.comment;

    this.rootStore.pageLoaderStore.disable();
  };

  @action reset = (): void => {
    this.setFetchStatus({ ...this.fetchStatus, isFetched: false });

    this.comment = '';
  };

  @action fetchComment = (dealId: string, invoiceId: string, params: FetchCommentParams): void => {
    this.rootStore.pageLoaderStore.enable('Fetching comments...');

    const parsedParams = {
      is_credit_note: params.creditNote,
      is_storno: params.isStorno,
      ref_invoice_id: params.refInvoiceId,
      payment_terms_days: params.paymentDue,
      paid_at: params.paidAt,
      payment_terms_discount_days: params.discountDueDays,
      payment_terms_discount_percentage: params.discountPercentage,
      source_shipping_id: params.sourceShippingId,
    };

    void this.fetch(routesApi.dealOutcomingInvoicesGenerageCommentPath(dealId), {
      ...parsedParams,
      invoice_id: invoiceId,
    }).finally(() => {
      this.setFetchStatus({
        ...this.fetchStatus,
        isFetched: true,
        isFetching: false,
      });
    });
  };

  @action onFetchError() {
    this.rootStore.pageLoaderStore.disable();

    this.setFetchStatus({
      ...this.fetchStatus,
      fetchError: true,
      isFetching: false,
    });

    notification.error({
      message: 'You might have forgotten to select Reference Invoice',
    });
  }
}

export default DealOutInvoiceCommentStore;
