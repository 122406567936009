import { debounce } from 'lodash-es';
import { useEffect, useState } from 'react';
import styles from 'styles/utils/responsive.module.less';

export enum DeviceTypes {
  MOBILE = 'Mobile',
  TABLET = 'Tablet',
  DESKTOP = 'Desktop',
}

export const getDeviceType = () => {
  if (typeof window !== 'undefined') {
    const windowWidth = window.innerWidth;

    return windowWidth >= parseInt(styles.desktopWidth, 10)
      ? DeviceTypes.DESKTOP
      : windowWidth >= parseInt(styles.tabletWidth, 10)
        ? DeviceTypes.TABLET
        : DeviceTypes.MOBILE;
  }

  return DeviceTypes.DESKTOP;
};

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState(getDeviceType());

  useEffect(() => {
    const handleResize = debounce(() => setDeviceType(getDeviceType()), 100);
    window?.addEventListener('resize', handleResize);
    handleResize();

    return () => window?.removeEventListener('resize', handleResize);
  }, []);

  return deviceType;
};
