import styled from '@emotion/styled';
import { Row } from 'antd';
import { colors } from 'components/UI/colors';

export const ModalTitle = styled(Row)`
  margin-top: 25px;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.orange3};
`;

export const ModalSubtitle = styled(Row)`
  font-size: 16px;
  line-height: 24px;
`;
