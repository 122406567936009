import { activeClaimStates, ClaimState, closedClaimStates } from 'collections/claims';
import { ClaimStatusState } from 'components/DealHeader/DealHeaderPrimary/claimStatusTypes';

interface Claim {
  state?: ClaimState | string | null | undefined;
}

export const getClaimStatus = (claims: Claim[] | null | undefined = []): ClaimStatusState => {
  if (claims) {
    if (claims.length === 0) {
      return ClaimStatusState.None;
    }

    if (claims.some((claim) => activeClaimStates.includes(claim.state as ClaimState))) {
      return ClaimStatusState.Active;
    }

    if (claims.filter((claim) => closedClaimStates.includes(claim.state as ClaimState)).length > 0) {
      return ClaimStatusState.Closed;
    }
  }

  return ClaimStatusState.None;
};
