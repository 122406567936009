import { MessengerEventBus } from '@xometry/ui';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { AppContext, DEFAULT_APP_CONTEXT, defaultSetter, IAppContext } from './AppContext';

interface Props {
  children: ReactNode;
}

export const AppContextProvider: FC<Props> = ({ children }) => {
  const setMessengerEventBusRef = useRef<IAppContext['setMessengerEventBus']>(defaultSetter);

  const [contextValue, setContextValue] = useState<IAppContext>({
    ...DEFAULT_APP_CONTEXT,
    setMessengerEventBus: (...args) => setMessengerEventBusRef.current(...args),
  });

  useEffect(() => {
    setMessengerEventBusRef.current = (eventBus: MessengerEventBus) => {
      setContextValue((contextValue) => ({ ...contextValue, messengerEventBus: eventBus }));
    };
  }, []);

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
