import certificatesCollection from 'collections/certificates';
import { packagingTranslated } from 'collections/services';
import { XOMETRY_VAT_ENABLED } from 'config/regions/features';
import { getRegionCurrency } from 'config/regions/utils';
import { Certificates, Position, PositionsByKey, PositionsByShipping, ServerPosition, ServiceType } from 'interfaces';
import { OIShippingDetails } from 'interfaces/graphql';
import { each, find, get, groupBy, last, map } from 'lodash-es';
import { action, computed, observable } from 'mobx';
import { RootStore } from 'stores/RootStore';
import uniqid from 'uniqid';
import { camelizeKeys } from 'utils/axios';
import { XMoney } from 'utils/money';

class DealOutInvoicePositionsStore {
  rootStore: RootStore;

  currentPosition = 0;

  @observable tableLoading = false;

  @observable tablePositions: PositionsByKey = {};

  @observable positionsByShipping: PositionsByShipping = {};

  @observable sourceShipping?: OIShippingDetails;

  @observable vatRate?: number;

  parsePositions = (positions: ServerPosition[]): Position[] => {
    const newPositions: Position[] = positions.map((position) => ({
      categoryName: position.accounting_category,
      quantity: position.quantity || 1,
      price: position.price_per_piece,
      priceMoney: camelizeKeys(position.price_per_piece_money),
      providerPrice: position.provider_price,
      name: position.name,
      vat: XOMETRY_VAT_ENABLED ? this.vatRate || position.vat_rate : position.vat_rate,
      position: position.position,
      partId: position.part_id,
      refId: position.ref_id,
      refType: position.ref_type,
      refSource: position.ref_source,
      deliveryDate: position.delivery_date,
      id: position.id,
      key: uniqid(),
    }));

    this.currentPosition = get(last(newPositions), 'position') || this.currentPosition;

    return newPositions;
  };

  incCurrentPosition = (inc = 10): number => {
    this.currentPosition += inc;

    return this.currentPosition;
  };

  @action setVatRate = (vatRate: number) => {
    this.vatRate = vatRate;
  };

  @action setCurrentPosition = (position: number): void => {
    this.currentPosition = position;
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed({ keepAlive: true })
  get tablePositionsArray(): Position[] {
    return Object.values(this.tablePositions);
  }

  @action addPositionsFromObject = (positions: Position[]) => {
    const result: PositionsByKey = {};

    each(positions, (position: Position) => {
      const { key } = position;

      const vatNumber = Number(position.vat);

      result[key] = {
        ...position,
        part: camelizeKeys(position.part),
        // NOTICE: temp hack due to changes in TR VAT https://xometry.atlassian.net/browse/XTR-856
        vat: vatNumber != 20 && vatNumber != 0 ? 20 : position.vat,
      };
    });

    this.tablePositions = {
      ...this.tablePositions,
      ...result,
    };
  };

  @computed({ keepAlive: true })
  get positionsByShippingArray(): Position[] {
    return Object.keys(this.positionsByShipping).reduce((acc: Position[], cur) => {
      return [...acc, ...this.positionsByShipping[cur]];
    }, []);
  }

  @computed({ keepAlive: true })
  get nonDestroyedPositions(): Position[] {
    return this.positionsByShippingArray.filter((pos) => !pos._destroy);
  }

  @computed
  get positionsByShippingByKey() {
    return this.positionsByShippingArray.reduce((acc: Record<string, Position>, position) => {
      acc[position.key] = position;

      return acc;
    }, {});
  }

  @action addPositions = (positions: Position[]) => {
    const positionsByShipping = groupBy(positions, 'refSource');

    const newPositionsByShipping = Object.keys(positionsByShipping).reduce((acc: PositionsByShipping, source) => {
      if (['null', 'Deal::OrderConfirmation'].includes(source) || !source.startsWith('SH')) {
        const currentNullables = acc?.['null'] || [];
        const nullableObjs = positionsByShipping[source].map((pos) => ({ ...pos, refSource: 'null' }));

        return { ...acc, null: [...currentNullables, ...nullableObjs] };
      }

      return { ...acc, [source]: positionsByShipping[source] };
    }, {}) as PositionsByShipping;

    const firstSource = Object.keys(newPositionsByShipping)[0];

    if (this.positionsByShipping[firstSource]) {
      this.positionsByShipping[firstSource] = [
        ...this.positionsByShipping[firstSource],
        ...newPositionsByShipping[firstSource],
      ];
    } else {
      this.positionsByShipping = {
        ...this.positionsByShipping,
        ...newPositionsByShipping,
      };
    }

    const newPositions = positions.reduce((acc: Record<string, Position>, position) => {
      acc[position.key] = {
        ...position,
        part: camelizeKeys(position.part),
      };

      return acc;
    }, {});

    this.tablePositions = {
      ...this.tablePositions,
      ...newPositions,
    };
  };

  @action setPositions = (positions: Position[]) => {
    this.positionsByShipping = groupBy(positions, 'refSource');

    this.tablePositions = positions.reduce((acc: Record<string, Position>, position) => {
      acc[position.key] = {
        ...position,
        part: camelizeKeys(position.part),
      };

      return acc;
    }, {});
  };

  @action add = (): Position => {
    const currency = this.tablePositionsArray[0]?.priceMoney?.currencyCode;
    const key = uniqid();

    const newPosition = {
      categoryName: ServiceType.Packaging,
      name: packagingTranslated,
      quantity: 1,
      price: 0,
      priceMoney: {
        amount: '0',
        amountCents: '0',
        currencyCode: currency || getRegionCurrency(),
      },
      providerPrice: 0,
      vat: XOMETRY_VAT_ENABLED ? this.vatRate : this.rootStore.dealStore.data.vat,
      position: this.incCurrentPosition(),
      refSource: 'null',
      key,
    };

    this.tablePositions[key] = newPosition;

    if (this.positionsByShipping['null'] && this.positionsByShipping['null'].length > 0) {
      this.positionsByShipping['null'] = [...this.positionsByShipping['null'], newPosition];
    } else {
      this.positionsByShipping.null = [newPosition];
    }

    return newPosition;
  };

  @action addCertificates = (certificates: Certificates): Position[] =>
    map(certificates, (data, certificate) => {
      const key = uniqid();

      const quantity = data?.positions || 1;
      const price = Number(((data?.amount || 0) / quantity).toFixed(2));

      const newPosition = {
        categoryName: ServiceType.Certificates,
        name: find(certificatesCollection.children, { value: certificate })?.defaultName || '',
        quantity,
        price,
        priceMoney: XMoney(0).toIMoney(),
        providerPrice: 0,
        vat: XOMETRY_VAT_ENABLED ? this.vatRate : this.rootStore.dealStore.data.vat,
        position: this.incCurrentPosition(),
        key,
      };

      this.tablePositions[key] = newPosition;

      return newPosition;
    });

  @action delete = (positionId: string, source?: string | null): void => {
    if (source || source === null) {
      this.positionsByShipping[String(source)] =
        this.positionsByShipping[String(source)]?.map((position) => {
          if (position.key === positionId) {
            return {
              ...position,
              _destroy: true,
            };
          }

          return position;
        }) ?? [];
    }
  };

  @action setTableLoading = (isLoading: boolean): void => {
    this.tableLoading = isLoading;
  };

  @action updatePositionAndCloseEdit = (key: string, position: Position): void => {
    const replaceObject = (replacement: Position) => {
      Object.keys(this.positionsByShipping).map((prop) => {
        this.positionsByShipping[prop] = this.positionsByShipping[prop].map((item) => {
          if (item.key === replacement.key) {
            return replacement;
          } else {
            return item;
          }
        });
      });
    };

    replaceObject(position);
    this.tablePositions[key] = position;
  };

  @action reset = (): void => {
    this.rootStore.dealOutInvoicePositionsStore = new DealOutInvoicePositionsStore(this.rootStore);
  };

  @action setSourceShipping = (shipping: OIShippingDetails) => {
    this.sourceShipping = shipping;
  };

  @action updateVat = () => {
    const updatedData: PositionsByShipping = {};

    Object.keys(this.positionsByShipping).forEach((key) => {
      updatedData[key] = this.positionsByShipping[key].map((item) => ({ ...item, vat: this.vatRate }));
    });

    this.positionsByShipping = updatedData;
  };
}

export default DealOutInvoicePositionsStore;
