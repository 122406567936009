import { TemplateChannelEnum } from '__generated__/types';
import { Tooltip } from 'antd';
import { ReactComponent as ChatIcon } from 'assets/images/icons/chat.svg';
import { ReactComponent as EmailIcon } from 'assets/images/icons/email.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/images/socials/whatsapp.svg';
import React, { FC } from 'react';

import s from './ChannelMarker.module.less';

interface Props {
  channel: TemplateChannelEnum;
}

const ICONS_MAP: Record<TemplateChannelEnum, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
  [TemplateChannelEnum.Messenger]: ChatIcon,
  [TemplateChannelEnum.Email]: EmailIcon,
  [TemplateChannelEnum.Whatsapp]: WhatsAppIcon,
};

const TITLE_MAP: Record<TemplateChannelEnum, string> = {
  [TemplateChannelEnum.Messenger]: 'Message Center message',
  [TemplateChannelEnum.Email]: 'Email notification with a message content',
  [TemplateChannelEnum.Whatsapp]: 'WhatsApp message',
};

export const ChannelMarker: FC<Props> = ({ channel }) => {
  const Icon = ICONS_MAP[channel];

  return (
    <Tooltip title={TITLE_MAP[channel]}>
      <Icon className={s.icon} />
    </Tooltip>
  );
};
