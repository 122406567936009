import { IJobStrategy } from 'interfaces';
import { action, observable } from 'mobx';
import { routesApi } from 'routes/api';
import FetchableStore from 'stores/shared/FetchableStore';
import { camelizeKeys } from 'utils/axios';

class JobStrategyStore extends FetchableStore {
  @observable strategies: IJobStrategy[] = [];

  @action assignOnFetch = (payload: IJobStrategy[]): void => {
    this.strategies = camelizeKeys(payload);
  };

  @action fetchStrategies = (): void => {
    this.fetch(routesApi.jobStrategiesPath());
  };
}

export default JobStrategyStore;
