import lookers from 'collections/lookers';
import { ACCOUNTING_ID_NAME, CURRENT_REGION } from 'config/regions/config';
import { observer } from 'mobx-react-lite';
import { LookerPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';
import { useStore } from 'stores/RootStore';

const FinanceController = observer(() => {
  const { userStore } = useStore();

  const userName = userStore.name || '';

  return (
    <>
      <Route
        path={routes.lookers.finance.outcomingInvoices.allPath()}
        exact
        render={(props) => <LookerPage {...props} url={lookers.finance.outcomingInvoices.all} title="ERP | OI" />}
      />

      <Route
        path={routes.lookers.finance.outcomingInvoices.my.allPath()}
        exact
        render={(props) => (
          <LookerPage {...props} url={lookers.finance.outcomingInvoices.my.all(userName)} title="ERP | OI | My" />
        )}
      />

      <Route
        path={routes.lookers.finance.outcomingInvoices.my.needFixesPath()}
        exact
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.outcomingInvoices.my.needFixes(userName)}
            title='ERP | OI | My "need fixes"'
          />
        )}
      />

      <Route
        path={routes.lookers.finance.outcomingInvoices.my.notYetPaidPath()}
        exact
        render={(props) => (
          <LookerPage
            {...props}
            title='ERP | OI | My "not yet paid"'
            url={lookers.finance.outcomingInvoices.my.notYetPaid(userName)}
          />
        )}
      />

      <Route
        path={routes.lookers.finance.outcomingInvoices.readyForCheckPath()}
        exact
        render={(props) => (
          <LookerPage
            {...props}
            title="ERP | OI | Ready for check"
            url={lookers.finance.outcomingInvoices.readyForCheck}
          />
        )}
      />

      <Route
        path={routes.lookers.finance.paymentBalance.overviewPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.paymentBalance.overview}
            title="ERP | Payment Balance | Overview"
          />
        )}
      />

      <Route
        path={routes.lookers.finance.paymentBalance.detailedPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.paymentBalance.detailed}
            title="ERP | Payment Balance | Detailed"
          />
        )}
      />

      <Route
        path={routes.lookers.finance.customersBalancesPath()}
        render={(props) => (
          <LookerPage {...props} url={lookers.finance.customerBalances} title="ERP | Customers Balances" />
        )}
      />

      <Route
        path={routes.lookers.finance.partnersBalancesPath()}
        render={(props) => (
          <LookerPage {...props} url={lookers.finance.partnerBalances} title="ERP | Partners Balances" />
        )}
      />

      <Route
        path={routes.lookers.finance.customersCashPath()}
        render={(props) => <LookerPage {...props} url={lookers.finance.customersCash} title="ERP | Customers Cash" />}
      />

      {/* partnerInvoices */}
      <Route
        path={routes.lookers.finance.partnerInvoices.old.allPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.old.all}
            title="ERP | Partner Invoices (old) | All"
          />
        )}
      />

      <Route
        path={routes.lookers.finance.partnerInvoices.old.dashPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.old.dash}
            title="ERP | Partner Invoices (old) | Dashboard"
          />
        )}
      />

      <Route
        path={routes.lookers.finance.partnerInvoices.old.missingOrMismatchPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.old.missingOrMismatch}
            title="ERP | Partner Invoices (old) | Missing or mismatch"
          />
        )}
      />

      <Route
        path={routes.lookers.finance.partnerInvoices.old.myToProcessPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.old.myToProcess(userName)}
            title="ERP | Partner Invoices (old) | My to process"
          />
        )}
      />

      <Route
        path={routes.lookers.finance.partnerInvoices.old.toBePaidPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.old.toBePaid}
            title="ERP | Partner Invoices (old) | To be paid"
          />
        )}
      />

      <Route
        path={routes.lookers.finance.partnerInvoices.old.toEnterToAccountingIdPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.old.toEnterToAccountingId}
            title={`ERP | Partner Invoices (old) | To enter to ${ACCOUNTING_ID_NAME[CURRENT_REGION]}`}
          />
        )}
      />

      <Route
        path={routes.lookers.finance.partnerInvoices.new.allPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.new.all}
            title="ERP | Partner Invoices (new) | All"
          />
        )}
      />

      <Route
        path={routes.lookers.finance.partnerInvoices.new.myToProcessPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.new.myToProcess(userName)}
            title="ERP | Partner Invoices (new) | My (check & fix)"
          />
        )}
      />

      <Route
        path={routes.lookers.finance.partnerInvoices.new.toCheckPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.new.toCheck}
            title="ERP | Partner Invoices (new) | For financial check"
          />
        )}
      />

      <Route
        path={routes.lookers.finance.partnerInvoices.new.toEnterToAccountingIdPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.new.toEnterToAccountingId}
            title={`ERP | Partner Invoices (new) | For registration in ${ACCOUNTING_ID_NAME[CURRENT_REGION]}`}
          />
        )}
      />

      <Route
        path={routes.lookers.finance.partnerInvoices.new.toBePaidPath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.new.toBePaid}
            title="ERP | Partner Invoices (new) | To be paid"
          />
        )}
      />

      <Route
        path={routes.lookers.finance.partnerInvoices.new.partnersSidePath()}
        render={(props) => (
          <LookerPage
            {...props}
            url={lookers.finance.partnerInvoices.new.partnersSide}
            title="ERP | Partner Invoices (new) | Invoices on the Partner's side"
          />
        )}
      />
    </>
  );
});

export default FinanceController;
