import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { UserFragmentFragmentDoc } from '../../fragments/__generated__/userFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUsersQueryVariables = Types.Exact<{
  roleName?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type GetUsersQuery = { users?: Array<{ id: number, email: string, name: string, imageUrl?: string | null, pipedriveId?: string | null, salesforceId?: string | null, jobTitle?: string | null, podId?: number | null, active: boolean, isRobot: boolean, phone?: string | null, birthday?: string | null, comment?: string | null, deputyUserId?: number | null, rfqLimit?: number | null, region?: Types.UserRegionEnum | null, autoAssignmentSettings?: { customerOrderGrade?: Array<Types.CustomerOrderGradeEnum> | null, languages?: Array<Types.LanguageEnum> | null, maxDeals?: number | null, overloadPossible?: boolean | null, technology?: Array<number> | null } | null, deputyUser?: { id: number, name: string, email: string } | null }> | null };

export type GetUserQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  isPipedriveId?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetUserQuery = { user?: { id: number, email: string, name: string, imageUrl?: string | null, pipedriveId?: string | null, salesforceId?: string | null, jobTitle?: string | null, podId?: number | null, active: boolean, isRobot: boolean, phone?: string | null, birthday?: string | null, comment?: string | null, deputyUserId?: number | null, rfqLimit?: number | null, region?: Types.UserRegionEnum | null, autoAssignmentSettings?: { customerOrderGrade?: Array<Types.CustomerOrderGradeEnum> | null, languages?: Array<Types.LanguageEnum> | null, maxDeals?: number | null, overloadPossible?: boolean | null, technology?: Array<number> | null } | null, deputyUser?: { id: number, name: string, email: string } | null } | null };


export const GetUsersDocument = gql`
    query getUsers($roleName: [String!]) {
  users(roleName: $roleName) {
    ...userFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      roleName: // value for 'roleName'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: ID!, $isPipedriveId: Boolean) {
  user(id: $id, isPipedriveId: $isPipedriveId) {
    ...userFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isPipedriveId: // value for 'isPipedriveId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;