import { ENV_BASE_URL } from 'constants/env';

import { TypeRouteId } from './api';

const OMS = ENV_BASE_URL || '/';

export const root = () => {
  const result = OMS;

  if (result[result.length - 1] !== '/') {
    return `${result}/`;
  }

  return result;
};

const dealPath = (dealId: string) => `${root()}deals/${dealId}`;
const providerPath = (providerId: string) => `${root()}providers/${providerId}`;

const dealPartPath = (dealId: string, partId: string) => `${root()}deals/${dealId}?part_id=${partId}`;

const deliveryPath = (dealId: TypeRouteId, id: TypeRouteId) => `${root()}deals/${dealId}/deliveries/${id}/edit`;

const dealDeliveriesNewPath = (dealId: string) => `${root()}deals/${dealId}/deliveries/new`;

const dealOrderConfirmationsNewPath = (dealId: string) => `${root()}deals/${dealId}/order_confirmations/new`;

const claimsPath = () => `${root()}claims`;
const claimPath = (dealId: TypeRouteId, id: TypeRouteId) => `${root()}deals/${dealId}/claims/${id}/edit`;

const dealClaimsNewPath = (dealId: string) => `${root()}deals/${dealId}/claims/new`;

const partnersPath = () => `${root()}providers`;

const dealPartsPath = (dealId: string | number) => `${root()}deals/${dealId}`;

const enquiriesPath = () => `${root()}enquiries`;

const enquiryPath = (enquiryId: string) => `${root()}enquiries/${enquiryId}`;

const enquiryPromotionsPath = () => `${root()}enquiry_promotions`;
const enquiryPromotionPath = (id: TypeRouteId) => `${enquiryPromotionsPath()}/${id}/edit`;

export default {
  root,
  dealPath,

  enquiriesPath,
  enquiryPath,
  enquiryPromotionsPath,
  enquiryPromotionPath,

  claimsPath,
  claimPath,

  dealClaimsNewPath,

  partnersPath,

  deliveryPath,
  dealDeliveriesNewPath,

  dealPartPath,
  dealPartsPath,

  dealOrderConfirmationsNewPath,

  providerPath,
};
