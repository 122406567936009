import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { TemplateGroupFragmentDoc } from '../../../../components/TemplatesPage/TemplatesPageTable/graphql/fragments/__generated__/templateGroup';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessengerTemplateGroupCreateMutationVariables = Types.Exact<{
  input: Types.MessengerTemplateGroupCreateInput;
}>;


export type MessengerTemplateGroupCreateMutation = { messengerTemplateGroupCreate?: { templateGroup: { id: string, constantName?: string | null, name?: string | null, type: Types.TemplateTypeEnum, direction: Types.TemplateDirectionEnum, readOnly: boolean, shared: boolean, createdBy?: string | null, createdAt?: string | null, updatedBy?: string | null, updatedAt?: string | null, blindCarbonCopy?: Array<string> | null, carbonCopy?: Array<string> | null, recipients?: Array<string> | null, category?: { id: string, title: string } | null, templates: Array<{ id: string, locale: Types.TemplateLocaleEnum, content: string, subject?: string | null }> } } | null };


export const MessengerTemplateGroupCreateDocument = gql`
    mutation messengerTemplateGroupCreate($input: MessengerTemplateGroupCreateInput!) {
  messengerTemplateGroupCreate(input: $input) {
    templateGroup {
      ...templateGroup
    }
  }
}
    ${TemplateGroupFragmentDoc}`;
export type MessengerTemplateGroupCreateMutationFn = Apollo.MutationFunction<MessengerTemplateGroupCreateMutation, MessengerTemplateGroupCreateMutationVariables>;

/**
 * __useMessengerTemplateGroupCreateMutation__
 *
 * To run a mutation, you first call `useMessengerTemplateGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessengerTemplateGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messengerTemplateGroupCreateMutation, { data, loading, error }] = useMessengerTemplateGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessengerTemplateGroupCreateMutation(baseOptions?: Apollo.MutationHookOptions<MessengerTemplateGroupCreateMutation, MessengerTemplateGroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessengerTemplateGroupCreateMutation, MessengerTemplateGroupCreateMutationVariables>(MessengerTemplateGroupCreateDocument, options);
      }
export type MessengerTemplateGroupCreateMutationHookResult = ReturnType<typeof useMessengerTemplateGroupCreateMutation>;
export type MessengerTemplateGroupCreateMutationResult = Apollo.MutationResult<MessengerTemplateGroupCreateMutation>;
export type MessengerTemplateGroupCreateMutationOptions = Apollo.BaseMutationOptions<MessengerTemplateGroupCreateMutation, MessengerTemplateGroupCreateMutationVariables>;