import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type WorkspacePresetFragment = { id: number, name: string, workspaceName: string, public: boolean, query?: string | null, columns?: Array<string> | null, exportColumns?: Array<string> | null, createdAt: string, updatedAt: string, sortColumn?: string | null, sortDirection?: string | null, owner: { id: number, name: string }, lastUpdatedBy?: { id: number, name: string } | null };

export const WorkspacePresetFragmentDoc = gql`
    fragment workspacePreset on WorkspacePreset {
  id
  name
  workspaceName
  public
  query
  columns
  exportColumns
  createdAt
  updatedAt
  sortColumn
  sortDirection
  owner {
    id
    name
  }
  lastUpdatedBy {
    id
    name
  }
}
    `;