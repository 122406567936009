/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Money } from '__generated__/types';
import { getInitialValues } from 'components/Deal/OrderConfirmation/shared/ocFormHelpers';
import { OCFormValues } from 'components/Deal/OrderConfirmation/shared/ocFormValues';
import Notification from 'components/UI/Notification';
import { dateFormat } from 'components/utils/formatters';
import { XOMETRY_VAT_ENABLED } from 'config/regions/features';
import { CommentRequest, OCLot, OrderConfirmation } from 'interfaces/stores/OrderConfirmation';
import { IAddress } from 'interfaces/stores/Shipping';
import { get, isEmpty, omit, sortBy } from 'lodash-es';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import uniqid from 'uniqid';
import api, { camelizeKeys, decamelizeKeys, omsGet } from 'utils/axios';
import { DealOrderConfirmationVatQuery } from 'utils/graphql/queries/__generated__/dealOrderConfirmationVat';

interface CreditOverflow {
  creditLimit: number | Money;
  currentDebt: number | Money;
  currentOrder: number | Money;
  currentOpenOrders: number | Money;
  total: number | Money;
  org?: 'noaccess';
  person?: 'noaccess';
  ba?: 'noaccess';
}

class OrderConfirmationStore {
  public rootStore: RootStore;

  @observable isFetching = true;
  @observable dealId = 0;
  @observable isExist = false;
  @observable creditOverflow: CreditOverflow | undefined = undefined;
  @observable reloadOnUpdate = true;
  @observable orderConfirmation: OrderConfirmation = {} as OrderConfirmation;
  @observable automationJobCreating = false;
  @observable billingAccountCountry: string | undefined = undefined;
  @observable billingAccountId: string | undefined = undefined;
  @observable billingAccountVatCountry: string | undefined = undefined;
  @observable billingAccountType: string | undefined = undefined;
  @observable shippingAddressCountry: string | undefined = undefined;
  @observable vatData: DealOrderConfirmationVatQuery['dealOrderConfirmationVat'] | null = null;
  @observable initialValues: OCFormValues = {} as OCFormValues;
  @observable forceUpdateBillingAccountCache = false;
  @observable baNotValid = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action setBANotValid = (baNotValid: boolean) => {
    this.baNotValid = baNotValid;
  };

  @action setBillingAccountId = (billingAccountId: string | undefined) => {
    this.billingAccountId = billingAccountId;
  };

  @action updateInitialValues = () => {
    if (!this.vatData) return;

    this.initialValues = {
      ...this.initialValues,
      lots: this.initialValues.lots.map((lot) => ({
        ...lot,
        vatRate: this.vatData?.vatRate || lot.vatRate || 0,
      })),
      services: this.initialValues.services.map((service) => ({
        ...service,
        vatRate: this.vatData?.vatRate || service.vatRate || 0,
      })),
    };
  };

  @action setForceUpdateBillingAccountCache = (forceUpdateBillingAccountCache: boolean) => {
    this.forceUpdateBillingAccountCache = forceUpdateBillingAccountCache;
  };

  @action setVatData = (vatData: DealOrderConfirmationVatQuery['dealOrderConfirmationVat'] | null) => {
    this.vatData = vatData;
  };

  @action setBillingAccountCountry = (billingAccountCountry: string | undefined) => {
    this.billingAccountCountry = billingAccountCountry;
  };

  @action setBillingAccountVatCountry = (billingAccountVatCountry: string | undefined) => {
    this.billingAccountVatCountry = billingAccountVatCountry;
  };

  @action setBillingAccountType = (billingAccountType: string | undefined) => {
    this.billingAccountType = billingAccountType;
  };

  @action setShippingAddressCountry = (shippingAddressCountry: string | undefined) => {
    this.shippingAddressCountry = shippingAddressCountry;
  };

  @action setDealId = (dealId: number | string): void => {
    this.dealId = Number(dealId);
  };

  @action clearCache = () => {
    this.orderConfirmation = {} as OrderConfirmation;
  };

  @action setIsExist = (value: boolean): void => {
    this.isExist = value;
  };

  @action fetch = async () => {
    this.setIsFetching(true);

    try {
      const response = await api.get(routesApi.dealOrderConfirmation(this.dealId));
      const payload = get(response, 'data.payload');

      if (payload && payload.id) this.setIsExist(true);

      this.assignAttributes(payload);

      this.setIsFetching(false);
    } catch (e) {
      this.setIsFetching(false);
    }
  };

  @action create = async (values: any) => {
    this.setIsFetching(true);

    const attributes = this.prependAttributes(values);

    try {
      await api.post(routesApi.dealOrderConfirmation(this.dealId), attributes);

      this.clearCache();

      window.location.reload();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      this.setIsFetching(false);

      const errors = get(e, 'response.data.errors.source') as unknown as Record<string, string> | null;
      const message = get(e, 'response.data.errors.title') as unknown as string | null;

      if (isEmpty(errors)) {
        if (message) {
          Notification.Error(message);
        } else {
          Notification.Error('Something went wrong, please reload the page and try again.');
        }

        return;
      }

      errors &&
        Object.keys(errors).forEach((key) => {
          Notification.Error(`${key !== 'base' ? `${key}: ` : ''} ${errors[key]}`);
        });
    }
  };

  @action update = async (values: any) => {
    this.rootStore.pageLoaderStore.enable('Updating OC');

    const defaultAttributes = {
      ...this.prependAttributes(values),
    };

    const attributesWithVat = {
      ...defaultAttributes,
      xometry_vat_number_id: this.vatData?.xomVatNumber?.id,
    };

    const attributes = XOMETRY_VAT_ENABLED ? attributesWithVat : defaultAttributes;

    try {
      if (values.billingAccount?.deletedAt) {
        Notification.Error('Error', `You are about to update OC using Deleted BA-${values.billingAccount.id}`);

        return;
      }

      await api.patch(routesApi.dealOrderConfirmation(this.dealId), attributes);
    } catch (e: any) {
      this.rootStore.pageLoaderStore.disable();

      Notification.Error('Error', 'Something went wrong. Please try again later.');

      return e;
    }

    if (this.reloadOnUpdate) {
      window.location.assign(window.location.href);
    }

    return null;
  };

  @action updateCurrency = async (currency: string) => {
    this.rootStore.pageLoaderStore.enable('Updating OC');

    try {
      const response = await api.patch(routesApi.orderConfirmationUpdateCurrencyPath(this.dealId), { currency });
      this.assignAttributes(response.data.payload);
    } catch (e: unknown) {
      this.rootStore.pageLoaderStore.disable();

      return e;
    }

    if (this.reloadOnUpdate) {
      window.location.reload();
    }

    return null;
  };

  @action publish = (values: any) => {
    this.rootStore.pageLoaderStore.enable('Updating & Publishing...');

    const defaultAttributes = {
      ...this.prependAttributes(values),
      published_at: moment().format('DD.MM.YYYY'),
    };

    const attributesWithVat = {
      ...defaultAttributes,
      xometry_vat_number_id: this.vatData?.xomVatNumber?.id,
    };

    const attributes = XOMETRY_VAT_ENABLED ? attributesWithVat : defaultAttributes;

    return api
      .patch(routesApi.dealOrderConfirmation(this.dealId), attributes)
      .then((response) => {
        const payload = get(response, 'data.payload');
        this.assignAttributes(payload);

        Notification.Success('OC has been published in CA');
      })
      .catch((e: any) => e)
      .finally(() => {
        this.rootStore.pageLoaderStore.disable();
      });
  };

  @action destroy = async (): Promise<void> => {
    this.setIsFetching(true);

    try {
      await api.delete(routesApi.dealOrderConfirmation(this.dealId));

      this.clearCache();

      window.location.reload();

      this.setIsFetching(false);
    } catch (e) {
      this.setIsFetching(false);
    }
  };

  @action fetchLots = async () => {
    this.rootStore.pageLoaderStore.enable('Fetching Parts and Lots...');

    try {
      const response = await api.get(routesApi.orderConfirmationPositionsPath(this.dealId));

      const payload = get(response, 'data.payload');
      const lots = camelizeKeys(payload);

      this.setLots(lots);
      this.rootStore.pageLoaderStore.disable();

      return this.orderConfirmation.lots;
    } catch (e) {
      this.rootStore.pageLoaderStore.disable();
    }
  };

  @action fetchComment = async (values: CommentRequest): Promise<string> => {
    try {
      const response = await omsGet(routesApi.orderConfirmationCommentPath(this.dealId), decamelizeKeys(values));
      const payload: string = get(response, 'data.payload');

      return payload || '';
    } catch (e) {
      // throw new Error(e);
      // eslint-disable-next-line no-console
      console.log(e);

      return '';
    }
  };

  private prependAttributes = (values: any) => {
    const valuesWithoutBilling = omit(values, ['billingAccount']);

    return decamelizeKeys({
      ...valuesWithoutBilling,
      billingAccountId: values.billingAccount?.id,
      dealId: this.dealId,
      confirmDate: values.confirmDate ? dateFormat(values.confirmDate) : undefined,
      deliveryDate: values.deliveryDate ? dateFormat(values.deliveryDate) : undefined,
      forceUpdateBillingAccountCache: this.forceUpdateBillingAccountCache,
      publishedAt: values.publishedAt ? dateFormat(values.publishedAt) : null,
    });
  };

  @action private setIsFetching = (value: boolean): boolean => {
    this.isFetching = value;

    return this.isFetching;
  };

  @action setCreditOverflow = (creditOverflow?: CreditOverflow) => {
    this.creditOverflow = creditOverflow;
  };

  @action setReloadOnUpdate = (reloadOnUpdate: boolean) => {
    this.reloadOnUpdate = reloadOnUpdate;
  };

  @action private setLots = (lots: OCLot[]) => {
    const vat = this.rootStore.dealStore.data.vat || 0;

    const lotsWithKeys = sortBy(
      camelizeKeys(lots).map((lot: OCLot) => ({
        ...lot,
        _key: uniqid(),
        vatRate: this.vatData?.vatRate || lot.vatRate || vat || 0,
      })),
      (lot: OCLot) => lot.partPosition,
    );

    this.orderConfirmation = {
      ...this.orderConfirmation,
      lots: lotsWithKeys,
    };
  };

  @action private assignAttributes = (oc: any) => {
    const orderConfirmation = camelizeKeys(oc);

    try {
      this.orderConfirmation = {
        ...camelizeKeys(orderConfirmation),
        lots: this.preparePositions(orderConfirmation.lots),
        services: this.preparePositions(orderConfirmation.services),
        shippingAddress: this.convertShippingAddress(orderConfirmation.shippingAddress),
      };

      this.billingAccountCountry = orderConfirmation.billingAccount.country;
      this.billingAccountId = orderConfirmation.billingAccount.id;
      this.billingAccountVatCountry = orderConfirmation.billingAccount.vatRegistrationCountry;
      this.billingAccountType = orderConfirmation.billingAccount.accountType;
      this.shippingAddressCountry = orderConfirmation.shippingAddress?.country;

      this.initialValues = getInitialValues(this.orderConfirmation);
    } catch (e) {
      console.error(e);
    }
  };

  @computed get customerAddress(): IAddress | undefined {
    const ocShippingAddress = this.orderConfirmation.shippingAddress;

    if (!ocShippingAddress) return undefined;

    const address: IAddress = {
      id: ocShippingAddress.id ? String(ocShippingAddress.id) : undefined,
      address: ocShippingAddress.address || '',
      city: ocShippingAddress.city || '',
      zip: ocShippingAddress.zip || '',
      country: ocShippingAddress.country || '',
      company: ocShippingAddress.companyName || '',
      name: ocShippingAddress.name || '',
      phone: ocShippingAddress.phone || '',
      comment: ocShippingAddress.comment || '',
      internalComment: ocShippingAddress.internalComment || '',
    };

    return address;
  }

  get isProdIntercompanyRole(): boolean {
    return this.orderConfirmation.intercompanyRole === 'prod';
  }

  private preparePositions = <T>(positions: T[]): T[] =>
    camelizeKeys(positions).map((position: T) => {
      const defaultPosition = {
        ...position,
        _key: uniqid(),
      };

      const positionWithVat = {
        ...defaultPosition,
        vatRate: this.vatData?.vatRate || get(position, 'vatRate') || 0,
      };

      return XOMETRY_VAT_ENABLED ? positionWithVat : defaultPosition;
    });

  private convertShippingAddress = (shippingAddress: any) => {
    if (!shippingAddress) return shippingAddress;

    const { phone, name, contactPhone, contactName, ...rest } = shippingAddress;

    return {
      ...rest,
      contactPhone: contactPhone || phone,
      contactName: contactName || name,
    };
  };
}

export default OrderConfirmationStore;
