import { TemplateDirectionEnum, TemplateLocaleEnum, TemplateTypeEnum } from '__generated__/types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useForm } from 'antd/lib/form/Form';
import { RELATED_TYPE_OPTIONS, useCategoryOptions } from 'components/TemplatesPage/utils';
import { Notification } from 'components/UI';
import { dateTimeFormat } from 'components/utils/formatters';
import React, { useEffect, useMemo } from 'react';
import { useStore } from 'stores/RootStore';
import { ModeEnum } from 'stores/TemplatesManagerModalStore';
import { useMessengerTemplateGroupCreateMutation } from 'utils/graphql/mutations/__generated__/messengerTemplateGroupCreate';
import { useMessengerTemplateGroupDeleteMutation } from 'utils/graphql/mutations/__generated__/messengerTemplateGroupDelete';
import { useMessengerTemplateGroupUpdateMutation } from 'utils/graphql/mutations/__generated__/messengerTemplateGroupUpdate';
import { useMessengerTemplateCategoriesQuery } from 'utils/graphql/queries/__generated__/messengerTemplateCategories';
import { useMessengerTemplatePlaceholdersQuery } from 'utils/graphql/queries/__generated__/messengerTemplatePlaceholders';
import { useUsers } from 'utils/hooks/useUsers';

import {
  TemplateCategoryEnum,
  TemplateModalFormFieldEnum,
  TemplateModalFormValues,
} from './TemplatesManagerModal.types';

export const useTemplatesManagerModal = () => {
  const [form] = useForm<TemplateModalFormValues>();
  const { templatesManagerModalStore } = useStore();

  const { getUserById } = useUsers();

  const { data: categoryData } = useMessengerTemplateCategoriesQuery({
    skip: !templatesManagerModalStore.isOpen,
  });

  const { data: placeholdersData } = useMessengerTemplatePlaceholdersQuery({
    variables: { type: templatesManagerModalStore.relatedType as TemplateTypeEnum },
    skip: !templatesManagerModalStore.relatedType,
  });

  const contentVariableOptions = useMemo(
    () =>
      placeholdersData?.messengerTemplatePlaceholders.content.map((item) => ({
        label: item.label,
        value: item.value,
      })),
    [placeholdersData?.messengerTemplatePlaceholders.content],
  );

  const emailsVariableOptions = useMemo(
    () =>
      placeholdersData?.messengerTemplatePlaceholders.recipients.map((item) => ({
        label: item.label,
        value: item.value,
      })),
    [placeholdersData?.messengerTemplatePlaceholders.recipients],
  );

  const [createTemplateGroup] = useMessengerTemplateGroupCreateMutation({
    refetchQueries: ['messengerTemplateGroupsCollection'],
    onCompleted: () => {
      Notification.Success('Template group has been successfully created');
      handleCloseTemplatesModal();
    },
    onError: (error) => {
      console.error('Mutation error:', error);
      Notification.Error('Failed to create template group');
    },
  });

  const [updateTemplateGroup] = useMessengerTemplateGroupUpdateMutation({
    refetchQueries: ['messengerTemplateGroupsCollection'],
    onCompleted: () => {
      Notification.Success('Template group has been successfully updated');
      handleCloseTemplatesModal();
    },
    onError: (error) => {
      console.error('Mutation error:', error);
      Notification.Error('Failed to update template group');
    },
  });

  const [deleteTemplateGroup] = useMessengerTemplateGroupDeleteMutation({
    refetchQueries: ['messengerTemplateGroupsCollection'],
    onCompleted: () => {
      Notification.Success('Template group has been successfully deleted');
      handleCloseTemplatesModal();
    },
    onError: (error) => {
      console.error('Mutation error:', error);
      Notification.Error('Failed to delete template group');
    },
  });

  const relatedTypeDisabled = templatesManagerModalStore.categoryName !== TemplateCategoryEnum.ERP_TEMPLATES;
  const isERPTemplates = templatesManagerModalStore.categoryName === TemplateCategoryEnum.ERP_TEMPLATES;
  const isCustom = templatesManagerModalStore.categoryName === TemplateCategoryEnum.CUSTOM;
  const isWhatsApp = templatesManagerModalStore.categoryName === TemplateCategoryEnum.WHATS_APP;
  const isCreateModal = templatesManagerModalStore.mode === ModeEnum.CREATE;
  const isUpdateModal = templatesManagerModalStore.mode === ModeEnum.UPDATE;

  const createOrUpdateTemplateGroup = isCreateModal ? createTemplateGroup : updateTemplateGroup;

  const buttonName = isCreateModal ? 'Create' : 'Save';

  const createdBy = templatesManagerModalStore.createdBy
    ? `by ${getUserById(templatesManagerModalStore.createdBy)?.name}`
    : '';
  const updatedBy = templatesManagerModalStore.updatedBy
    ? `by ${getUserById(templatesManagerModalStore.updatedBy)?.name}`
    : '';

  const createdTitle = templatesManagerModalStore.createdAt
    ? `Created ${dateTimeFormat(templatesManagerModalStore.createdAt)} ${createdBy}`
    : '';
  const updatedTitle = templatesManagerModalStore.updatedAt
    ? `Updated ${dateTimeFormat(templatesManagerModalStore.updatedAt)} ${updatedBy}`
    : '';

  const categoryOptions = useCategoryOptions();

  const relatedTypeOptions = RELATED_TYPE_OPTIONS.filter((option) => {
    if (isERPTemplates) {
      return option.value !== TemplateTypeEnum.Whatsapp && option.value !== TemplateTypeEnum.Custom;
    }

    return true;
  });

  const handleCategoryChange = (categoryId: string) => {
    const categoryName = categoryData?.messengerTemplateCategories.find(({ id }) => id === categoryId)?.title;

    if (categoryName) {
      templatesManagerModalStore.setTemplateCategory(form, categoryName, categoryId);
    }
  };

  const handleRelatedTypeChange = (type: TemplateTypeEnum) => {
    templatesManagerModalStore.setRelatedType(form, type);
  };

  const handleTemplateNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    templatesManagerModalStore.setTemplateName(form, event.target.value);
  };

  const handleDirectionChange = (direction: TemplateDirectionEnum) => {
    templatesManagerModalStore.setDirection(form, direction);
  };

  const handleEmails = (name: TemplateModalFormFieldEnum) => (value: string[]) => {
    switch (name) {
      case TemplateModalFormFieldEnum.To:
        templatesManagerModalStore.setTo(form, value);
        break;
      case TemplateModalFormFieldEnum.Cc:
        templatesManagerModalStore.setCc(form, value);
        break;
      case TemplateModalFormFieldEnum.Bcc:
        templatesManagerModalStore.setBcc(form, value);
        break;
      default:
        break;
    }
  };

  const handleLocaleChange = (locale: TemplateLocaleEnum) => {
    templatesManagerModalStore.setSelectedLocale(form, locale);
  };

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    templatesManagerModalStore.setLocales(templatesManagerModalStore.selectedLocale, { subject: event.target.value });
  };

  const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    templatesManagerModalStore.setLocales(templatesManagerModalStore.selectedLocale, { content: event.target.value });
  };

  const handleChangeShared = (event: CheckboxChangeEvent) => {
    const { checked } = event.target;
    templatesManagerModalStore.setShared(form, checked);
  };

  const handleCloseTemplatesModal = () => {
    templatesManagerModalStore.closeTemplatesModal();
    form.resetFields();
  };

  const handleCreateOrUpdateTemplate = () => {
    form
      .validateFields()
      .then(() => {
        if (templatesManagerModalStore.requestAttributes) {
          void createOrUpdateTemplateGroup({
            variables: {
              input: {
                attributes: templatesManagerModalStore.requestAttributes,
              },
            },
          });
        }
      })
      .catch((error) => {
        console.error('Form validation error:', error);
      });
  };

  const handleDeleteTemplateGroup = () => {
    if (templatesManagerModalStore.constantName) {
      void deleteTemplateGroup({
        variables: {
          input: {
            constantName: templatesManagerModalStore.constantName,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (templatesManagerModalStore.isOpen) {
      templatesManagerModalStore.setFormFields(form);
    }
  }, [form, templatesManagerModalStore, templatesManagerModalStore.isOpen]);

  return {
    form,
    buttonName,
    createdTitle,
    updatedTitle,
    categoryOptions,
    relatedTypeOptions,
    contentVariableOptions,
    emailsVariableOptions,
    selectedLocale: templatesManagerModalStore.selectedLocale,
    hasContent: templatesManagerModalStore.hasContent,
    isCreateModal,
    isUpdateModal,
    isCustom,
    isERPTemplates,
    isWhatsApp,
    readOnly: templatesManagerModalStore.readOnly,
    isOpen: templatesManagerModalStore.isOpen,
    shareChecked: templatesManagerModalStore.shared,
    relatedTypeDisabled,
    handleCategoryChange,
    handleRelatedTypeChange,
    handleTemplateNameChange,
    handleDirectionChange,
    handleEmails,
    handleLocaleChange,
    handleSubjectChange,
    handleContentChange,
    handleChangeShared,
    handleCloseTemplatesModal,
    handleCreateOrUpdateTemplate,
    handleDeleteTemplateGroup,
  };
};
