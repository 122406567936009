import { OmsInputsMessengerTemplateGroupType, TemplateDirectionEnum, TemplateTypeEnum } from '__generated__/types';
import React from 'react';
import { useMessengerTemplateCategoriesQuery } from 'utils/graphql/queries/__generated__/messengerTemplateCategories';

import { DirectionLabel } from './DirectionLabel/DirectionLabel';
import { TemplateGroupFragment } from './TemplatesPageTable/graphql/fragments/__generated__/templateGroup';

export const useCategoryOptions = () => {
  const { data: categoryData } = useMessengerTemplateCategoriesQuery();

  const categoryOptions = categoryData?.messengerTemplateCategories.map(({ id, title }) => ({
    label: title,
    value: id,
  }));

  return categoryOptions;
};

export const RELATED_TYPE_OPTIONS = Object.keys(TemplateTypeEnum).map((key) => ({
  label: key,
  value: TemplateTypeEnum[key as keyof typeof TemplateTypeEnum],
}));

export const DIRECTION_OPTIONS = Object.keys(TemplateDirectionEnum).map((key) => ({
  label: <DirectionLabel direction={TemplateDirectionEnum[key as keyof typeof TemplateDirectionEnum]} />,
  value: TemplateDirectionEnum[key as keyof typeof TemplateDirectionEnum],
}));

export const getTemplateGroupAttributes = (
  templateGroup: TemplateGroupFragment,
): OmsInputsMessengerTemplateGroupType | undefined => {
  if (templateGroup.name && templateGroup.category?.id) {
    return {
      blindCarbonCopy: templateGroup.blindCarbonCopy,
      carbonCopy: templateGroup.carbonCopy,
      categoryId: templateGroup.category?.id,
      direction: templateGroup.direction,
      locales: templateGroup.templates.map((template) => ({
        content: template.content,
        locale: template.locale,
        subject: template.subject ?? '',
      })),
      name: templateGroup.name,
      recipients: templateGroup.recipients,
      shared: templateGroup.shared,
      type: templateGroup.type,
    };
  }
};
