import styled from '@emotion/styled';
import CrossSvg from 'assets/images/icons/cross.svg';
import SwapSvg from 'assets/images/icons/swap.svg';

export const ContentContainer = styled.div`
  position: fixed;
  z-index: 999;
  height: calc(100vh - 50px);
  background: #fff;
  box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.25);

  &.isMobile {
    height: 100%;
    width: 100vw;
  }

  &.right:not(.isMobile) {
    right: 0;
    border-radius: 6px 0px 0px 6px;
  }

  &.left:not(.isMobile) {
    left: 0;
    border-radius: 0px 6px 6px 0px;
  }
`;

export const HeaderControlsBlock = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 14px;
  z-index: 10;
  margin-right: -12px;

  & div {
    margin-right: 12px;
  }
`;

export const CrossIcon = styled.div<{ size: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  cursor: pointer;
  background: url(${CrossSvg}) center/ ${(props) => props.size} no-repeat;
`;

export const SwapIcon = styled.div<{ size: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  cursor: pointer;
  background: url(${SwapSvg}) center/ ${(props) => props.size} no-repeat;
`;
