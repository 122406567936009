import { RolesPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

const RolesController: React.FC = () => (
  <>
    <Route path={routes.rolesPath()} component={RolesPage} exact />
  </>
);

export default RolesController;
