import { AxiosResponse } from 'axios';
import { Notification } from 'components/UI';
import { IAttachedFile } from 'interfaces';
import { reject } from 'lodash-es';
import { action, observable } from 'mobx';
import { RootStore } from 'stores/RootStore';
import omsApi, { camelizeKeys } from 'utils/axios';

interface AttachedFilesResponse {
  payload: {
    attached_files: IAttachedFile[];
  };
}
interface UploadFilesResponse {
  payload: {
    attached_file: IAttachedFile;
  };
}

class AttachedFilesStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable attachments: IAttachedFile[] = [];

  @observable fetchStatus = {
    isFetched: false,
    isFetching: false,
    fetchError: false,
  };

  @observable uploadStatus = {
    isFinished: false,
    isProcessing: false,
    errored: false,
  };

  @action fetch = async (url: string) => {
    this.fetchStatus.isFetched = false;
    this.fetchStatus.isFetching = true;

    try {
      const response = await omsApi.get<AttachedFilesResponse>(url);

      this.onFetchSuccess(response);

      return response;
    } catch (err: unknown) {
      this.onFetchError();

      Notification.Error('Failed to fetch attachments');

      return err;
    }
  };

  @action onFetchSuccess = (response: AxiosResponse<AttachedFilesResponse>): void => {
    const { payload } = response.data;

    this.fetchStatus.isFetched = true;
    this.fetchStatus.isFetching = false;

    this.attachments = camelizeKeys(payload?.attached_files || []).sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  };

  @action onFetchError = (): void => {
    this.fetchStatus.fetchError = true;
    this.fetchStatus.isFetching = false;
  };

  @action upload = async (url: string, formData: FormData) => {
    this.uploadStatus.isFinished = false;
    this.uploadStatus.isProcessing = true;

    try {
      const response = await omsApi.post<UploadFilesResponse>(url, formData);

      this.finishUpload();

      const attachment = response.data.payload.attached_file;
      this.addAttachment(camelizeKeys(attachment));

      Notification.Success(`Attachment ${attachment.name} added`);

      this.rootStore.activityRecordStore.refetch();
    } catch (err) {
      this.finishUpload();

      Notification.Error('Failed to upload attachment');
    }
  };

  @action delete = async (url: string, id: number) => {
    try {
      await omsApi.delete(url);

      this.deleteAttachment(id);

      Notification.Success('Attachment has been deleted');

      this.rootStore.activityRecordStore.refetch();
    } catch (err) {
      Notification.Error('Failed to delete attachment');
    }
  };

  @action addAttachment = (file: IAttachedFile): void => {
    this.attachments.push(file);
  };

  @action deleteAttachment = (id: number): void => {
    this.attachments = reject<IAttachedFile>(this.attachments, { id });
  };

  @action finishUpload = (): void => {
    this.uploadStatus.isFinished = true;
    this.uploadStatus.isProcessing = false;
  };
}

export default AttachedFilesStore;
