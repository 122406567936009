/* eslint-disable @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return */
import { notification } from 'antd';
import { AcceptOfferValues, IJobOffer } from 'interfaces';
import { action, observable } from 'mobx';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import omsApi, { camelizeKeys, decamelizeKeys, handleErrorMessage } from 'utils/axios';

class JobOfferStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loading = false;

  @observable currentJobOffer: IJobOffer | undefined;

  @action setLoading = (isLoading: boolean): void => {
    this.loading = isLoading;
  };

  @action setCurrentJobOffer = (currentJobOffer: IJobOffer): void => {
    this.currentJobOffer = currentJobOffer;
  };

  @action create = async (dealId: number, jobId: number, data: IJobOffer): Promise<any> => {
    const url = routesApi.dealJobOffersPath(`${dealId}`, `${jobId}`);
    this.setLoading(true);

    try {
      const response = await omsApi.post(url, {
        job_offer: decamelizeKeys(data),
      });
      window.location.href = routes.jobOfferPath(
        `${dealId}`,
        `${jobId}`,
        String((response.data as { id?: number })?.id),
      );
    } catch (err) {
      const receivedMessage = handleErrorMessage(err);
      notification.error({ message: receivedMessage });
      this.setLoading(false);
    }
  };

  @action update = async (dealId: number, jobId: number, id: number, data: any): Promise<any> => {
    const url = routesApi.dealJobOfferPath(`${dealId}`, `${jobId}`, `${id}`);
    this.setLoading(true);

    try {
      await omsApi.put(url, { job_offer: decamelizeKeys(data) });
      window.location.href = routes.jobOfferPath(`${dealId}`, `${jobId}`, `${id}`);
    } catch (err) {
      const receivedMessage = handleErrorMessage(err);
      notification.error({ message: receivedMessage });
      this.setLoading(false);
    }
  };

  @action reject = async (
    dealId: number,
    jobId: number,
    id: number,
    data: { decisionRefusalReason: string; notifyProvider: boolean },
  ): Promise<any> => {
    const url = routesApi.dealJobOfferRejectPath(`${dealId}`, `${jobId}`, `${id}`);
    this.setLoading(true);

    try {
      await omsApi.post(url, {
        job_offer: decamelizeKeys(data),
      });
      window.location.href = routes.jobOfferPath(`${dealId}`, `${jobId}`, `${id}`);
    } catch (err) {
      const receivedMessage = handleErrorMessage(err);
      notification.error({ message: receivedMessage });
      this.setLoading(false);
    }
  };

  @action accept = async (dealId: number, jobId: number, id: number, data: AcceptOfferValues): Promise<any> => {
    const url = routesApi.dealJobOfferAcceptPath(`${dealId}`, `${jobId}`, `${id}`);
    this.setLoading(true);

    try {
      await omsApi.post(url, {
        job_offer: decamelizeKeys(data),
      });
      window.location.href = routes.jobOfferPath(`${dealId}`, `${jobId}`, `${id}`);
    } catch (err) {
      const receivedMessage = handleErrorMessage(err);
      notification.error({ message: receivedMessage });
      this.setLoading(false);

      throw err;
    }
  };

  @action delete = async (dealId: number, jobId: number, id: number): Promise<any> => {
    const url = routesApi.dealJobOfferPath(`${dealId}`, `${jobId}`, `${id}`);
    this.setLoading(true);

    try {
      await omsApi.delete(url);
      window.location.href = `${routes.jobsPath(`${dealId}`)}/${jobId}/edit`;
    } catch (err) {
      const receivedMessage = handleErrorMessage(err);
      notification.error({ message: receivedMessage });
      this.setLoading(false);
    }
  };

  @action fetchJobOffer = async (dealId: number, jobId: number, id: number): Promise<IJobOffer> => {
    const url = routesApi.dealJobOfferPath(`${dealId}`, `${jobId}`, `${id}`);
    this.setLoading(true);

    try {
      const response = await omsApi.get(url);
      this.setLoading(false);
      const { payload } = response.data;
      const jobOffer = camelizeKeys(payload);
      this.setCurrentJobOffer(jobOffer);

      return jobOffer;
    } catch (err) {
      notification.error({ message: String(err) });
      this.setLoading(false);
      throw err;
    }
  };
}

export default JobOfferStore;
