import { gql } from '@apollo/client';

export const COUNTER_FETCH_QUERY = gql`
  query messengerCounter {
    messengerCounter {
      activeTasks
      unreadMessages
    }
  }
`;
