import 'config/i18n';
import 'antd/es/style/themes/default.less';
import 'antd/dist/antd.less';
import 'assets/styles/index.css';
import '@xometry/ant-ui/dist/index.css';
import '@xometry/ui/dist/index.css';

import { MonitoringContext } from '@xometry/ui';
import { XTheme } from '@xometry/uikit';
import { ErrorBoundaryWrapper } from 'core/app/ErrorBoundary/ErrorBoundaryWrapper';
import { GQLProvider } from 'core/app/GQLProvider/GQLProvider';
import { RootStoreProvider } from 'core/app/RootStoreProvider/RootStoreProvider';
import { bootstrap } from 'index.utils';
import React from 'react';

import App from './App';

const overrides = {
  '--x-color-background-summary': '#F0F5FF',
};

void bootstrap(document.getElementById('root')).then(({ root, monitoringClient }) => {
  root.render(
    <MonitoringContext.Provider value={monitoringClient}>
      <XTheme overrides={overrides}>
        <RootStoreProvider>
          <GQLProvider>
            <ErrorBoundaryWrapper>
              <App />
            </ErrorBoundaryWrapper>
          </GQLProvider>
        </RootStoreProvider>
      </XTheme>
    </MonitoringContext.Provider>,
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
