import { ShippingDirectionEnum } from '__generated__/types';
import { isDomestic } from 'collections/countries';
import { ShippingDirection, ShippingSource } from 'collections/shippingConstants';

interface IShipping {
  sourceAddress?: {
    country?: string | null;
  } | null;
  destinationAddress?: {
    country?: string | null;
  } | null;
}

export const isImport = (shipping?: IShipping) => {
  if (shipping == null) return false;

  const sourceDomestic = isDomestic(shipping.sourceAddress?.country);
  const destDomestic = isDomestic(shipping?.destinationAddress?.country);

  return !sourceDomestic && destDomestic;
};

export const isExport = (shipping?: IShipping) => {
  if (shipping == null) return false;

  const sourceDomestic = isDomestic(shipping.sourceAddress?.country);
  const destDomestic = isDomestic(shipping?.destinationAddress?.country);

  return sourceDomestic && !destDomestic;
};

export const isImportOrExport = (shipping?: IShipping) => {
  return isImport(shipping) || isExport(shipping);
};

const shippingSources = {
  p: ShippingSource.Provider,
  c: ShippingSource.Customer,
  x: ShippingSource.Xometry,
};

export const getSourceAndDestinationName = (direction?: ShippingDirection | ShippingDirectionEnum) => {
  if (!direction) return {};

  const sourceShortName = direction?.slice(0, 1) as keyof typeof shippingSources;
  const destinationShortName = direction?.slice(2, 3) as keyof typeof shippingSources;

  return {
    sourceName: shippingSources[sourceShortName],
    destinationName: shippingSources[destinationShortName],
  };
};
