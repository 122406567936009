import styled from '@emotion/styled';
import { Button } from 'antd';
import { colors } from 'components/UI/colors';
import { bc } from 'components/UI/variables';
import { Link as RouterLink } from 'react-router-dom';

export const Link = styled(RouterLink)`
  color: ${bc.link};
`;

export const ALink = styled.a`
  color: ${bc.link};
  text-decoration: underline;

  &:hover {
    text-decoration: underline;

    svg {
      color: ${bc.hover};
    }
  }

  svg {
    margin-left: 5px;
  }
`;

interface LinkAlikeProps {
  isActive?: boolean;
}

export const LinkAlike = styled.div<LinkAlikeProps>`
  color: ${bc.link};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${bc.hover};
  }

  ${({ isActive }) =>
    isActive &&
    `color: ${bc.hover};
  `}
`;

export const LinkButton = styled(Button)`
  display: inline-flex;
  align-items: center;

  color: ${colors.blue};
  padding: 0;
  & span {
    text-transform: none;
  }
`;

export const LinkButtonCondensed = styled(LinkButton)`
  font-size: inherit;
  line-height: inherit !important;
  height: auto;
  display: inline-flex;

  span {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const RightActionsBlock = styled.div`
  display: flex;
  align-items: center;
`;
