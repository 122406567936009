import { WorkspacesRfqOffer } from '__generated__/types';
import { responseStateMapping } from 'collections/rfqOffers';
import PreviewModal from 'components/shared/FilesPalette/PreviewModal';
import { LinkButton } from 'components/UI';
import pluralize from 'pluralize';
import React, { useState } from 'react';

export const ResponseStateDecorator = (rfqOffer: WorkspacesRfqOffer) => {
  const responseState = rfqOffer.responseState as keyof typeof responseStateMapping;
  const [visiblePreviewModal, setVisiblePreviewModal] = useState<boolean>(false);

  return (
    <>
      {responseStateMapping[responseState] && responseState !== 'empty' ? (
        <b style={{ color: responseStateMapping[responseState].color }}>{responseStateMapping[responseState].label}</b>
      ) : (
        <>&mdash;</>
      )}
      {rfqOffer.attachedFiles?.length ? (
        <div>
          <LinkButton type="link" onClick={() => setVisiblePreviewModal(true)}>
            {rfqOffer.attachedFiles.length} {pluralize('file', rfqOffer.attachedFiles.length)}
          </LinkButton>
        </div>
      ) : null}

      {visiblePreviewModal ? (
        <PreviewModal files={rfqOffer.attachedFiles || []} onCancel={() => setVisiblePreviewModal(false)} visible />
      ) : null}
    </>
  );
};
