/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { action, observable } from 'mobx';
import { RootStore } from 'stores/RootStore';
import omsApi from 'utils/axios';

class CreatableStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable createStatus = {
    isFinished: false,
    isProcessing: false,
    createError: false,
  };

  @action create = (url: string, data: unknown) => {
    this.rootStore.pageLoaderStore.enable('Creating Invoice');
    this.createStatus.isProcessing = true;

    return omsApi.post(url, this.mapData(data)).then(this.onCreateSuccess, this.onCreateError);
  };

  @action onCreateSuccess = (response: AxiosResponse<any>): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = response.data;

    this.assignOnCreate(payload);
    this.createStatus.isFinished = true;
    this.createStatus.isProcessing = false;
    this.rootStore.pageLoaderStore.disable();
  };

  @action onCreateError = (): void => {
    this.createStatus.isFinished = false;
    this.createStatus.isProcessing = false;
    this.createStatus.createError = false;
    this.rootStore.pageLoaderStore.disable();
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  @action assignOnCreate = (payload: any): void => {
    // eslint-disable-next-line no-console
    console.log('payload', payload);
    // eslint-disable-next-line no-alert
    alert('!!!OVERRIDE assignOnFetch for CreatableStore!!!');
  };

  // eslint-disable-next-line class-methods-use-this
  mapData<T>(data: T): any {
    // eslint-disable-next-line no-console
    console.log(data);
    // eslint-disable-next-line no-alert
    alert('!!!OVERRIDE mapData for CreatableStore!!!');
  }
}

export default CreatableStore;
