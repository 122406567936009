import lookers from 'collections/lookers';
import { observer } from 'mobx-react-lite';
import { LookerPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

const SalesController = observer(() => (
  <>
    <Route
      path={routes.lookers.sales.quotesPath()}
      exact
      render={(props) => <LookerPage {...props} title="Quotes | Sales | ERP" url={lookers.sales.quotes} />}
    />

    <Route
      path={routes.lookers.sales.analytics.bdmPerformancePath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="BDM Performance | Sales | ERP" url={lookers.sales.analytics.bdmPerformance} />
      )}
    />

    <Route
      path={routes.lookers.sales.analytics.ordersCurrentMonthPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Orders current month | Sales | ERP"
          url={lookers.sales.analytics.ordersCurrentMonth}
        />
      )}
    />

    <Route
      path={routes.lookers.sales.analytics.kamQuotationsPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="KAM Quotations | Sales | ERP" url={lookers.sales.analytics.kamQuotations} />
      )}
    />

    <Route
      path={routes.lookers.sales.analytics.monthlySalesMetricsPath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Monthly Sales Metrics | Sales | ERP"
          url={lookers.sales.analytics.monthlySalesMetricsPath}
        />
      )}
    />

    <Route
      path={routes.lookers.sales.analytics.npg()}
      exact
      render={(props) => <LookerPage {...props} title="NPG | Sales | ERP" url={lookers.sales.analytics.npg} />}
    />

    <Route
      path={routes.lookers.sales.analytics.clientDevelopmentDashboard()}
      exact
      render={(props) => (
        <LookerPage {...props} title="NPG | Sales | ERP" url={lookers.sales.analytics.clientDevelopmentDashboard} />
      )}
    />

    <Route
      path={routes.lookers.sales.analytics.customerBasePath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Customer Base | Sales | ERP" url={lookers.sales.analytics.customerBase} />
      )}
    />

    <Route
      path={routes.lookers.sales.analytics.wonDealsPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Won deals | Sales | ERP" url={lookers.sales.analytics.wonDeals} />
      )}
    />

    <Route
      path={routes.lookers.sales.analytics.lostDealsPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Lost deals | Sales | ERP" url={lookers.sales.analytics.lostDeals} />
      )}
    />

    <Route
      path={routes.lookers.sales.analytics.salesTargetsPath()}
      exact
      render={(props) => (
        <LookerPage {...props} title="Sales targets | Sales | ERP" url={lookers.sales.analytics.salesTargets} />
      )}
    />

    <Route
      path={routes.lookers.sales.analytics.salespeoplePerformancePath()}
      exact
      render={(props) => (
        <LookerPage
          {...props}
          title="Salespeople Performance | Sales | ERP"
          url={lookers.sales.analytics.salespeoplePerformance}
        />
      )}
    />
  </>
));

export default SalesController;
