import { getMonitoringClient } from 'core/monitoring/monitoring';
import { configure } from 'mobx';
import moment from 'moment';
import { createRoot } from 'react-dom/client';

export const bootstrap = async (node?: HTMLElement | null) => {
  const monitoringClient = await getMonitoringClient();
  await monitoringClient.init();

  if (!node) {
    throw new Error('No root node provided!');
  }

  configure({ enforceActions: 'observed' });
  // Date start from monday
  moment.locale('en', { week: { dow: 1 } });

  const root = createRoot(node);

  return {
    monitoringClient,
    root,
  };
};
