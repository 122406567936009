import { DownloadOutlined } from '@ant-design/icons';
import { Button, Image, Row, Spin } from 'antd';
import { Notification } from 'components/UI';
import { shortName } from 'components/utils/formatters';
import { map } from 'lodash-es';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { routesApi } from 'routes/api';
import omsApi, { camelizeKeys } from 'utils/axios';
import useDownload from 'utils/hooks/useDownload';

import { File } from './FilesPalette.types';
import PdfViewer from './PdfViewer';
import { FileListPreview, Modal, PdfViewerWrapper, Preview, PreviewModalContainer } from './styled';

interface PreviewSource {
  name: string;
  bigUrl: string;
}

interface Props {
  files: File[];
  onCancel: () => any;
  selectedFile?: File;
  visible?: boolean;
}

const PreviewModal: FC<Props> = ({ files, onCancel, selectedFile, visible }) => {
  const [currentFile, setCurrentFile] = useState(selectedFile || files[0]);
  const [currentPreviewSrc, setCurrentPreviewSrc] = useState<PreviewSource | undefined>();

  const download = useDownload();

  const currentFileExtension = currentFile.name.split('.').pop();
  const isPdf = currentFileExtension?.toLowerCase() === 'pdf';

  const setCurrentPreview = useCallback(async (file: File) => {
    try {
      const response = await omsApi.get<{ payload: PreviewSource }>(routesApi.attachedFilePreviewPath(file.id));
      setCurrentPreviewSrc(camelizeKeys(response.data.payload));
    } catch (e) {
      Notification.Error('Failed to fetch Preview');
    }
  }, []);

  useEffect(() => {
    void setCurrentPreview(currentFile);
  }, [currentFile, setCurrentPreview]);

  return (
    <Modal destroyOnClose footer={null} isFullPage onCancel={onCancel} title={currentFile.name} open={visible}>
      {currentPreviewSrc != null ? (
        <PreviewModalContainer>
          <div className="preview-container">
            {isPdf ? (
              <PdfViewerWrapper>
                <PdfViewer src={currentPreviewSrc.bigUrl} />
              </PdfViewerWrapper>
            ) : (
              <Image
                src={currentPreviewSrc.bigUrl}
                style={{
                  height: 'auto',
                  maxHeight: '80vh',
                  maxWidth: '100%',
                  width: 'auto',
                }}
              />
            )}
          </div>

          <div className="sidebar">
            {map(files, (file) => (
              <FileListPreview
                className={currentFile === file ? 'active' : ''}
                onClick={() => setCurrentFile(file)}
                type="link"
              >
                <Row className="preview-container" justify="center">
                  <Preview style={{ backgroundImage: file.url ? `url(${file.url})` : 'none' }}>
                    {!file.url && <span>{currentFileExtension?.toUpperCase()}</span>}
                  </Preview>

                  <Button
                    className="right-button"
                    icon={<DownloadOutlined />}
                    onClick={() => void download(routesApi.attachedFileDownloadPath(file.id), file.name)}
                    type="link"
                  />
                </Row>
                <span>{shortName(file.name, 40)}</span>
              </FileListPreview>
            ))}
          </div>
        </PreviewModalContainer>
      ) : (
        <Spin spinning size="large" tip="Loading..." />
      )}
    </Modal>
  );
};

export default PreviewModal;
