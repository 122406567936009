import { ObjectType } from 'interfaces';
import { action, observable } from 'mobx';

export type PartsDataViewScope =
  | ObjectType.OC
  | ObjectType.PO
  | ObjectType.OI
  | ObjectType.Payment
  | ObjectType.CL
  | ObjectType.QC
  | ObjectType.SH
  | ObjectType.SU
  | ObjectType.J
  | ObjectType.II;

class PartsDataViewStore {
  @observable showAllData = {
    [ObjectType.OC]: false,
    [ObjectType.PO]: false,
    [ObjectType.II]: false,
    [ObjectType.CL]: false,
    [ObjectType.SU]: false,
    [ObjectType.SH]: false,
    [ObjectType.OI]: false,
    [ObjectType.J]: false,
    [ObjectType.Payment]: false,
    [ObjectType.QC]: true,
  };

  isHidden = (scope: PartsDataViewScope) => !this.showAllData[scope];

  @action toggleView = (scope: PartsDataViewScope) => {
    this.showAllData[scope] = !this.showAllData[scope];
  };
}

export default PartsDataViewStore;
