import useCurrentUserPermissions from 'utils/hooks/useCurrentUserPermissions';

import { PermissionsSettings } from './collections';

export const useFiltersEditPermission = (permissionSettings: PermissionsSettings) => {
  const { allPermissions } = useCurrentUserPermissions();

  const permissionErrorMessage = `Permission "${permissionSettings.group}.${permissionSettings.filter}" required to proceed with the action`;

  const isFiltersChangingAllowed = Boolean(
    allPermissions?.[permissionSettings.group]?.find(({ name }) => name === permissionSettings.filter),
  );

  return {
    isFiltersChangingAllowed,
    permissionErrorMessage,
  };
};
