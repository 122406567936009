import { gql } from 'apollo-boost';

export interface IncomingInvoicesBulkBankPaymentCreateResponse {
  incomingInvoicesBulkBankPaymentCreate: {
    jobId: string;
  };
}

export const INCOMING_INVOICES_BULK_BANK_PAYMENT_CREATE = gql`
  mutation incomingInvoicesBulkBankPaymentCreate($attributes: JSON!) {
    incomingInvoicesBulkBankPaymentCreate(input: { attributes: $attributes }) {
      jobId
    }
  }
`;
