import { gql } from 'apollo-boost';
import { ColumnsMap, ColumnVisibility, QueryNameKey } from 'components/Workspaces/collections';
import { union } from 'lodash-es';

import {
  Column,
  listQueryTypeNames,
  WorkspaceGraphQLField,
  WorkspaceGraphQLObjectField,
  WorkspaceGraphQLScalarField,
} from './collections';
import { prepareSelectiveFields } from './prepareSelectiveFields';

export const attributesQuery = (query: QueryNameKey) => gql`
  query {
    __type(name: "${listQueryTypeNames[query]}") {
      name
      fields {
        name
        description
        type {
          ...typeFragment
        }
      }
    }
  }
  fragment typeFragment on __Type {
    name
    kind
    fields {
      ...fieldsFragment
    }
    ofType {
      name
      kind
      fields {
        ...fieldsFragment
      }
      ofType {
        name
        kind
        fields {
          ...fieldsFragment
        }
        ofType {
          name
          kind
          fields {
            ...fieldsFragment
          }
        }
      }
    }
  }
  fragment leafFieldFragment on __Field {
    name
    description
    type {
      name
      kind
      ofType {
        name
        kind
        ofType {
          name
          kind
          ofType {
            name
            kind
          }
        }
      }
    }
  }
  fragment fieldsFragment on __Field {
    name
    description
    type {
      name
      kind
      fields {
        ...leafFieldFragment
      }
      ofType {
        name
        kind
        fields {
          ...leafFieldFragment
        }
        ofType {
          name
          kind
          fields {
            ...leafFieldFragment
          }
          ofType {
            name
            kind
            fields {
              ...leafFieldFragment
            }
          }
        }
      }
    }
  }
`;

export const columnFilterable = (visibility?: ColumnVisibility) =>
  // eslint-disable-next-line no-bitwise
  (2 & (visibility ?? 7)) !== 0;

export const columnSortable = (visibility?: ColumnVisibility) =>
  // eslint-disable-next-line no-bitwise
  (1 & (visibility ?? 7)) !== 0;

export const columnVisible = (visibility?: ColumnVisibility) =>
  // eslint-disable-next-line no-bitwise
  (4 & (visibility ?? 7)) !== 0;

export type ObjectQueryResponse = Record<
  QueryNameKey,
  {
    objects: Array<{ id: number }>;
    total: number;
  }
>;

interface FilterSelectiveFieldsProps<T> {
  columnsSettings: ColumnsMap<T>;
  enabledColumns: Column[];
  requiredFields?: string[];
  scalarFields: WorkspaceGraphQLScalarField[];
  selectiveFieldsEnabled?: boolean;
  workspaceObjectFields: WorkspaceGraphQLObjectField[];
  sortFields: string[];
}

export function filterSelectiveFields<T extends object>({
  columnsSettings,
  enabledColumns,
  requiredFields,
  scalarFields,
  selectiveFieldsEnabled,
  workspaceObjectFields,
  sortFields,
}: FilterSelectiveFieldsProps<T>): WorkspaceGraphQLField[] {
  const queryFields: WorkspaceGraphQLField[] = [];

  if (selectiveFieldsEnabled) {
    const enabledColumnsKeys = enabledColumns.map((col) => col.key);

    let fieldNames = prepareSelectiveFields({
      columns: columnsSettings,
      enabledColumns: enabledColumnsKeys,
    });

    // Add required fields without which the table cannot work
    fieldNames = union(fieldNames, requiredFields, sortFields);

    queryFields.push(...scalarFields.filter((f) => fieldNames.includes(f.name)));
    queryFields.push(...workspaceObjectFields.filter((f) => fieldNames.includes(f.name)));
  } else {
    queryFields.push(...scalarFields);
    queryFields.push(...workspaceObjectFields);
  }

  return queryFields;
}

const queryObjectAttributes = (object: WorkspaceGraphQLField): string => {
  if (object.type.kind !== 'OBJECT') {
    return object.name;
  }

  return `
  ${object.name} {
    ${object.type.fields.map((f) => queryObjectAttributes(f as WorkspaceGraphQLField)).join('\n')}
  }
  `;
};

export const objectQuery = (query: QueryNameKey, fields: WorkspaceGraphQLField[]) =>
  gql`
    query ${query}(
        $timezoneOffset: Int!,
        $filterQuery: String!,
        $queryOptions: JSON,
        $sortParams: [WorkspacesInputsGenericSortingType!],
        $paginationParams: WorkspacesInputsGenericPaginationType,
        $presetId: ID,
      ) {
      ${query} (
        timezoneOffset: $timezoneOffset
        filterQuery: $filterQuery
        queryOptions: $queryOptions
        sortParams: $sortParams
        paginationParams: $paginationParams
        presetId: $presetId
      ) {
        objects {
          ${fields.length ? fields.map((f) => queryObjectAttributes(f)).join('\n') : 'id'}
        }
        total
      }
    }
  `;

export const triggerDownload = (filename: string, data: string, mime: string) => {
  const url = window.URL.createObjectURL(new Blob([Buffer.from(data, 'binary')], { type: mime }));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};
