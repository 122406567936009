import { TMessengerScreen } from '@xometry/ui';
import { action, computed, observable } from 'mobx';
import FetchableStore from 'stores/shared/FetchableStore';

export enum ESide {
  Right = 'right',
  Left = 'left',
}

export enum ESideOverlayType {
  Messenger = 'messenger',
  None = 'none',
}

export interface IMessengerInitialScreenParams {
  initialScreen: TMessengerScreen;
  initialMessageId?: undefined;
}
export interface IMessengerInitialMessageIdParams {
  initialScreen?: undefined;
  initialMessageId: string;
}

export type TSideOverlayContext =
  | ({
      type: ESideOverlayType.Messenger;
    } & (IMessengerInitialScreenParams | IMessengerInitialMessageIdParams))
  | {
      type: ESideOverlayType.None;
    };

class SideOverlayStore extends FetchableStore {
  @observable context: TSideOverlayContext = {
    type: ESideOverlayType.None,
  };

  @observable side = ESide.Right;

  @computed get isOpen() {
    return this.context.type !== ESideOverlayType.None;
  }

  @action openSideOverlay = (context: TSideOverlayContext, side: ESide = ESide.Right) => {
    this.side = side;
    this.context = context;
  };

  @action closeSideOverlay = () => {
    this.context = {
      type: ESideOverlayType.None,
    };
    this.side = ESide.Right;
  };

  @action toggleSide = () => {
    if (this.side === ESide.Right) {
      this.side = ESide.Left;
    } else {
      this.side = ESide.Right;
    }
  };
}

export default SideOverlayStore;
