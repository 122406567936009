import { MessengerEventBus } from '@xometry/ui';
import React from 'react';

export interface IAppContext {
  messengerEventBus: MessengerEventBus | null;
  setMessengerEventBus: (eventBus: MessengerEventBus) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const defaultSetter = (): void => {};

export const DEFAULT_APP_CONTEXT: IAppContext = {
  messengerEventBus: null,
  setMessengerEventBus: defaultSetter,
};

export const AppContext = React.createContext<IAppContext>(DEFAULT_APP_CONTEXT);
